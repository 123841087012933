import Map from "ol/Map";
import View from "ol/View";
import XYZ from "ol/source/XYZ";
import { transform } from "ol/proj";
import TileLayer from "ol/layer/Tile";
import { toLonLat } from 'ol/proj';
import { defaults } from "ol/control/defaults";


const initializeMap = (mapElement, setMap,setLatLng,setRemoveEventKey) => {
  const initialMap = new Map({
    view: new View({
      zoom: 4,
      maxZoom: 24,
      center: transform(
        [-90.27096030000001, 39.7200124],
        "EPSG:4326",
        "EPSG:3857"
      ),
    }),
    layers: [
      new TileLayer({
        source: new XYZ({

          // https://dev.ottermap.com/v1/api/mole/image/google-map/y/en/{x}/{y}/{z}
          // url: 'https://dev.ottermap.com/v1/api/mole/image/google-map/y/en/{x}/{y}/{z}',
          url: 'http://mt0.google.com/vt/lyrs=y&hl=en&x={x}&y={y}&z={z}',
          // url: `${mapUrl}/y/en/{x}/{y}/{z}`,

          
          crossOrigin: 'Anonymous',

        }),
        visible: true,
        title: 'Satellite',
      }),
      new TileLayer({
        source: new XYZ({
          // url: 'https://dev.ottermap.com/v1/api/mole/image/google-map/m/en/{x}/{y}/{z}',
          // url: 'https://dev.ottermap.com/otter/tiles/y/en/lyrs=y&hl=en&x={x}&y={y}&z={z}',

          url: 'http://mt0.google.com/vt/lyrs=m&hl=en&x={x}&y={y}&z={z}',

          // url: `${mapUrl}/m/en/{x}/{y}/{z}`,

          crossOrigin: 'Anonymous',
        }),
        visible: false,
        title: 'Road',
      }),
    ],
    target: mapElement.current,
    keyboardEventTarget: document,
    controls: defaults({
      zoom: false,
      attribution: false,
      rotate: false,
    }),
  });
  setMap(initialMap);

  // event related
  var eventKey=initialMap.on('click', e => {
    // console.log(`layer click: ${e.coordinate}`);
    let newCoordinate = toLonLat(e.coordinate);
    setLatLng(newCoordinate); 
  });
  console.log("event key",eventKey);
  setRemoveEventKey(eventKey);
};

export default initializeMap;
