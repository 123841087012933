import axios from 'axios';
import AuthToken from '../../context/AuthToken';
import { constants } from '../../utils/constants';
import { USER_ORG_CREATE_URL } from '../../helper/ApiUrl';

export default function GetCostFactors(
  setBlockArea,
  setImageryCost,
  setFeatureCost
) {
  const token = AuthToken();
  axios
    .get(`${USER_ORG_CREATE_URL}cost-factors`, {
      headers: {
        Authorization: `Token ${token}`,
        Accept: 'application/json',
      },
    })
    .then((response) => {
      const data = response.data.data;
      if (data) {
        setBlockArea(data.block_area);
        setFeatureCost(data.feature_cost);
        setImageryCost(data.hd_imagery_cost);
      }
    })
    .catch((error) => {
      setBlockArea(constants.base);
      setFeatureCost(constants.feature_cost_factor);
      setImageryCost(constants.image_cost_factor);
    });
}
