import React from 'react';
import './AlternateOrderPage.css';
import AddressSearch from '../../components/AddressSearch/AddressSearch';

const AlternateOrderPage = ({ handleError, handleSuccess }) => {
  return (
    <div className="alternate-order-container">
      <div className="alternate-search-bar">
        <AddressSearch
          handleError={handleError}
          handleSuccess={handleSuccess}
        />
      </div>
    </div>
  );
};

export default AlternateOrderPage;
