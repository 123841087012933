import axios from "axios";
import { ORDER_URL } from "../../helper/ApiUrl";
import { TOAST_TYPE } from "../../utils/constants";

export default function ExportImage(url, id, handleSuccess, handleError, token) {
    const formData = new FormData();
    formData.append('bucket_url', url);
    axios
        .post(`${ORDER_URL}${id}/export-image`, formData, {
            headers: {
                Authorization: `Token ${token}`,
                Accept: 'application/json',
            },
        })
        .then((response) => {
            if (response.data) {
                handleSuccess(TOAST_TYPE.SUCCESS, 'PNG Downloaded Successfully.');
            }
        })
        .catch((error) => {
            handleError(error);
        });
}