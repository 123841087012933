import React from 'react';
import './UploadOrderTable.css';
import Table from '@mui/material/Table';
import { Tooltip } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import { STUDIO_STATUS } from '../../utils/constants';
import TableContainer from '@mui/material/TableContainer';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const UploadOrderTableForFeedback = ({
    status,
    menuBar,
    endDate,
    startDate,
    addToRefs,
    orderList,
    setStatus,
    setMenuBar,
    setEndDate,
    setStartDate,
    handleDateFilter,
    handleStatusFilter,
    calculateRemainingTime,
  }) => {
  
    const handleArrowClickBar = (val) => {
      if (menuBar.length) {
        if (menuBar[0] === val) {
          setMenuBar({});
        } else {
          setMenuBar([val]);
        }
      } else setMenuBar([val]);
    };
  
    return (
      <TableContainer style={{ height: "100%", width: "100%" }}>
        <Table>
          <div className="upload-order-headings">
            <TableCell className="upload-cell-heading">Order Id</TableCell>
            <TableCell className="upload-cell-heading">Address</TableCell>
            <TableCell className="upload-cell-heading">Time Left</TableCell>
            <TableCell className="upload-cell-heading" ref={addToRefs}>
              <div
                className="order-cell-heading-div"
                onClick={() => handleArrowClickBar('STATUS_DROPDOWN')}
              >
                <span > Status</span>
                {menuBar[0] === 'STATUS_DROPDOWN' ? (
                  <KeyboardArrowUpIcon className="order-filter-icon" />
                ) : (
                  <KeyboardArrowDownIcon className="order-filter-icon" />
                )}
              </div>
              {menuBar[0] === 'STATUS_DROPDOWN' ? (
                <div className="order-status-filter upload-order-status-filter">
                  <h4>Property Status</h4>
                  <div
                    className="order-status-filter-two"
                    onClick={() => {
                      if (status.includes('in_progress')) {
                        setStatus(status.filter((e) => e !== 'in_progress'));
                      } else {
                        setStatus([...status, 'in_progress']);
                      }
                    }}
                  >
                    <input
                      type="checkbox"
                      name="in_progress"
                      checked={status.includes('in_progress') ? true : false}
                      onChange={() => {
                        if (status.includes('in_progress')) {
                          setStatus(status.filter((e) => e !== 'in_progress'));
                        } else {
                          setStatus([...status, 'in_progress']);
                        }
                      }}
                    />
                    <span>Inprogress</span>
                  </div>
                  <div
                    className="order-status-filter-three"
                    onClick={() => {
                      if (status.includes('measurement_completed')) {
                        setStatus(
                          status.filter((e) => e !== 'measurement_completed')
                        );
                      } else {
                        setStatus([...status, 'measurement_completed']);
                      }
                    }}
                  >
                    <input
                      type="checkbox"
                      name="measurement_completed"
                      checked={
                        status.includes('measurement_completed') ? true : false
                      }
                      onChange={(e) => {
                        if (status.includes('measurement_completed')) {
                          setStatus(
                            status.filter((e) => e !== 'measurement_completed')
                          );
                        } else {
                          setStatus([...status, 'measurement_completed']);
                        }
                      }}
                    />
                    <span>Complete</span>
                  </div>
                  <hr />
                  <button
                    onClick={() => {
                      if (status.length > 0) {
                        handleStatusFilter();
                      }
                    }}
                  >
                    ✔ Apply
                  </button>
                </div>
              ) : null}
            </TableCell>
            <TableCell className="upload-cell-heading" ref={addToRefs}>
              <div
                className="order-cell-heading-div"
                onClick={() => handleArrowClickBar('DATE_DROPDOWN')}
              >
                <span> Created On.</span>
                {menuBar[0] === 'DATE_DROPDOWN' ? (
                  <KeyboardArrowUpIcon className="order-filter-icon" />
                ) : (
                  <KeyboardArrowDownIcon className="order-filter-icon" />
                )}
              </div>
              {menuBar[0] === 'DATE_DROPDOWN' ? (
                <div className="order-date-filter">
                  <h4>Date</h4>
                  <div className="order-date-filter-one">
                    <span>From</span>
                    <input
                      type="date"
                      value={startDate.startDate}
                      onChange={(event) =>
                        setStartDate({ startDate: event.target.value })
                      }
                    />
                  </div>
                  <div className="order-date-filter-two">
                    <span>To</span>
                    <input
                      type="date"
                      value={endDate.endDate}
                      onChange={(event) =>
                        setEndDate({ endDate: event.target.value })
                      }
                    />
                  </div>
                  <hr />
                  <button onClick={handleDateFilter}>✔ Apply</button>
                </div>
              ) : null}
            </TableCell>
            <TableCell className='upload-cell-heading'>Studio Status</TableCell>
            {/* <TableCell className='upload-cell-heading'></TableCell> */}
            {/* <TableCell className="upload-cell-heading">Carto Name</TableCell>
            <TableCell className="upload-cell-heading">QA Name</TableCell> */}
          </div>
        </Table>
        <div style={{ overflowY: "scroll", height: "90%",overflowX:"hidden" }}>
          {orderList &&
            orderList.map((order) => {
              return (
                <a
                  key={order.id}
                  className="upload-order-details-anchor"
                  href={`/upload/order/${order.order_id}/tt34rees`}
                >
                  <div className="upload-order-details">
                    <div className="upload-order-cell">
                      <span className="upload-order-id">{order.id}</span>
                    </div>
                    <div className="upload-order-cell">
                      <span className="upload-order-address">
                        <Tooltip title={order.address} placement="top">
                          {order.address.length < 50 ? (
                            <p>{order.address}</p>
                          ) : (
                            <p>{order.address.slice(0, 50)}...</p>
                          )}
                        </Tooltip>
                      </span>
                    </div>
                    <div className="upload-order-cell">
                      {calculateRemainingTime(
                        order.placed_time,
                        order.total_time
                      ) ? (
                        <div style={{ width: '110px' }}>
                          {calculateRemainingTime(
                            order.placed_time,
                            order.total_time
                          ).slice(0, 5) === 'green' ? (
                            <span className="time-green">
                              {calculateRemainingTime(
                                order.placed_time,
                                order.total_time
                              ).slice(6)}
                            </span>
                          ) : calculateRemainingTime(
                            order.placed_time,
                            order.total_time
                          ).slice(0, 6) === 'yellow' ? (
                            <span className="time-yellow">
                              {calculateRemainingTime(
                                order.placed_time,
                                order.total_time
                              ).slice(7)}
                            </span>
                          ) : calculateRemainingTime(
                            order.placed_time,
                            order.total_time
                          ).slice(0, 3) === 'red' ? (
                            <span className="time-red">
                              {calculateRemainingTime(
                                order.placed_time,
                                order.total_time
                              ).slice(4)}
                            </span>
                          ) : (
                            <span className="time-red">Time Exceed</span>
                          )}
                        </div>
                      ) : (
                        <div style={{ width: '110px' }}>
                          <span
                            className={
                              order.status === 'measurement_completed'
                                ? 'order-placed'
                                : 'time-red'
                            }
                          >
                            {order.status === 'measurement_completed'
                              ? 'Order Placed'
                              : 'Time Exceed'}
                          </span>
                        </div>
                      )}
                    </div>
                    <div
                      className={
                        order.status === 'in_progress'
                          ? 'upload-progress upload-order-cell'
                          : order.status === 'measurement_completed'
                            ? 'upload-complete upload-order-cell'
                            : 'upload-pending upload-order-cell'
                      }
                    >
                      <span className="upload-order-status">
                        {order.status === 'in_progress'
                          ? 'In Progress'
                          : order.status === 'measurement_completed'
                            ? 'Completed'
                            : 'Pending'}
                      </span>
                    </div>
                    <div className="upload-order-cell">
                      {order.created_at.slice(0, 10)}
                    </div>
                    <div className='upload-progress upload-order-cell'>
                      <span className="upload-order-status">
                        {STUDIO_STATUS[order.studio_status]}
                      </span>
                    </div>
                    {/* <div className="upload-order-cell">
                        <span className="upload-order-status">Random Name</span>
                      </div>
                      <div className="upload-order-cell">
                        <span className="upload-order-status">Random Name</span>
                      </div> */}
                  </div>
                </a>
              );
            })}
        </div>
      </TableContainer>
    );
  };

export default UploadOrderTableForFeedback;

