import axios from 'axios';
import React from 'react';
import './OrganizationPage.css';
import { useEffect } from 'react';
import { Tooltip } from '@mui/material';
import { useNavigate } from 'react-router';
import AuthToken from '../../context/AuthToken';
import { USER_INFO_URL } from '../../helper/ApiUrl';
import LogoutIcon from '@mui/icons-material/Logout';
import { ReactComponent as SiteLogo } from '../../assets/logo.svg';
import { ReactComponent as SiteIcon } from '../../assets/icon.svg';
import useLogout from '../../utils/customHooks/logout.js';

const OrganizationPage = () => {
  const token = AuthToken();
  const navigate = useNavigate();
  const handleLogout = useLogout();

  const getOrgInfo = () => {
    axios
      .get(USER_INFO_URL, {
        headers: {
          Authorization: `Token ${token}`,
          Accept: 'application/json',
        },
      })
      .then((response) => {
        if (response.data.data.organization === null) {
          navigate('/organization');
        } else {
          navigate('/home');
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    getOrgInfo();
  }, []);

  const logoutBtnClick = () => {
  
    handleLogout()
  };


  return (
    <div className="organization-container">
      <div className="organization-left-container">
        <div className="organization-logo-container">
          <SiteLogo className="organization-logo" />
          <Tooltip title="Logout">
            <button onClick={logoutBtnClick}>
              <LogoutIcon className="logout-back-icon" />
            </button>
          </Tooltip>
        </div>
        <div className="organization-instructions-one">
          <p className="organization-instructions">
            Welcome, fellow landscapers! Your account is now activated and ready
            to bloom.
          </p>
        </div>
        <div className="organization-instructions-two">
          <p className="organization-instructions">
            If someone from your team has already signed up, ask them for the
            Organization Code so you can join the workspace and access all your
            properties in one place. Click "Join Organization," enter the code,
            and voila! You're ready to roll.
          </p>
          <div>
            <button onClick={() => navigate('/organization/join')}>
              JOIN ORGANIZATION
            </button>
          </div>
        </div>
        <div className="organization-instructions-three">
          <p className="organization-instructions">
            If you're the first to sign up, create your own workspace by
            clicking "Create Organization." It's as easy as planting a seed
            (well, almost).
          </p>
          <div>
            <button onClick={() => navigate('/organization/create')}>
              CREATE ORGANIZATION
            </button>
          </div>
        </div>
      </div>
      <div className="organization-right-container">
        <div className="organization-right-top">
          <h2>Elevate your process</h2>
        </div>
        <div className="organization-right-background-one"></div>
        <div className="organization-right-background-two"></div>
        <div className="organization-right-image">
          <SiteIcon />
        </div>
      </div>
    </div>
  );
};

export default OrganizationPage;
