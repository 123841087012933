import { Select } from "ol/interaction";

const redirectionToOrderNote = (map, viewId, location, navigate, id, orderNoteMarkerVisible, setOrderNoteData) => {
    if (map && !orderNoteMarkerVisible) {
        map.getLayers().forEach((layer) => {
            if (
                layer &&
                layer.get("title") &&
                layer.get("title") === "TempOrderNote"
            ) {
                map.removeLayer(layer);
            }
        });
        map.getInteractions().forEach((interaction) => {
            if (interaction instanceof Select) {
                interaction.setActive(true);
            }
        });
        map.getOverlays().forEach(function (overlay) {
            overlay.setPosition(undefined);
        });
        if (location.pathname[1] === "p") navigate(`/preview/order/${id}?view_id=${viewId}`);
        else navigate(`/order/${id}?view_id=${viewId}`);
        setOrderNoteData(null);
    }
}

export default redirectionToOrderNote;