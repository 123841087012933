import axios from "axios";
import AuthToken from "../../context/AuthToken";
import { SUBMIT_ORDER } from "../../helper/ApiUrl";
import { TOAST_TYPE } from "../../utils/constants";

export default function MarkOrderAsPreviewed(id, handleSuccess, setIsPreviewApproved) {
    const token = AuthToken();
    axios({
        url: `${SUBMIT_ORDER}${id}/confirm`,
        method: "PUT",
        headers: {
            Authorization: `Token ${token}`,
        },
    })
        .then((response) => {
            setIsPreviewApproved(true);
            handleSuccess(TOAST_TYPE.SUCCESS, 'Order marked as previewed');
        })
        .catch((error) => {
            console.error(error.message);
        });
}