import axios from "axios";
import "./ForgotPassword.css";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FORGOT_PASSWORD_URL } from "../../helper/ApiUrl";
import { IconButton, InputAdornment, TextField } from '@mui/material';

export default function ForgotPassword({ handleError, handleSuccess }) {
  const [email, setEmail] = useState("");
  const [emailSent, setEmailSent] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(false);

  const emailHandler = (e) => {
    setEmail(e.target.value);
  };

  const handleForgotPasswordSubmit = (e) => {
    e.preventDefault();
    setBtnDisabled(true);
    let formData = new FormData();
    formData.append("email", email);
    axios({
      url: FORGOT_PASSWORD_URL,
      method: "POST",
      data: formData,
    })
      .then((res) => {
        setEmailSent(true);
      })
      .catch((err) => {
        handleError(err);
      });
  };

  return (
    <div className="frgt-pass-container">
      <h2 className="frgt-pass-title">Forgot Password</h2>
      {!emailSent ? (
        <form
          onSubmit={handleForgotPasswordSubmit}
          className="frgt-pass-detail-form"
        >
          <div className="frgt-pass-detail">
            <div className="frgt-pass-detail-one">
              <label>Email</label>
              <TextField
                required
                margin="dense"
                variant="outlined"
                // id="outlined-required"
                label="Email"
                onChange={emailHandler}
              />
            </div>
            <div className="frgt-pass-detail-two">
              <button disabled={btnDisabled} type="submit" variant="contained">
                Send Reset Link
              </button>
            </div>
            <div className="frgt-pass-detail-three">
              <p>
                Remember Password?{" "}
                <Link to="/">
                  <span>Sign In</span>
                </Link>
              </p>
            </div>
          </div>
        </form>
      ) : (
        <div className="reset-link-send">
          <p>Reset link shared on your email</p>
          <Link to="/">
            <button>Back to Login</button>
          </Link>
        </div>
      )}
    </div>
  );
}
