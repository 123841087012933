import axios from "axios";
import { transformExtent } from "ol/proj";
import ImageLayer from 'ol/layer/Image.js';
import Static from 'ol/source/ImageStatic.js';
import { ORDER_URL } from "../../helper/ApiUrl";
import setNearmap from "./SetNearmap";

var images_loadStart_count = 0;
var images_loadEnd_count = 0;

const setImage = async (item, index, completedGridLyr, setNearmapLoader,) => {
    try {
        var projection = transformExtent(item.bounds, "EPSG:4326", "EPSG:3857");
        var imageLayer = new ImageLayer({
            source: new Static({
                url: item.url,
                imageExtent: projection,
                crossOrigin: "Anonymous"
            }),
            id: index,
            visible: true,
        });
        var lyrSource = imageLayer.getSource();
        lyrSource.on('imageloadstart', function (event) {
            images_loadStart_count++;
        });
        lyrSource.on('imageloadend', function (event) {
            // console.log(event.get('id'));
            images_loadEnd_count++;
            if (images_loadStart_count === images_loadEnd_count) {
                setNearmapLoader(false)
            };
        });
        lyrSource.on('imageloaderror', function (event) {
            console.log("error loading some images")
            setNearmapLoader(false)
        });
        completedGridLyr.current.getLayers().push(imageLayer);
        completedGridLyr.current.setZIndex(0);
    } catch (error) {
        console.log(error)
    }
}

const setCoordsToImage = (id, map, token, completedGridLyr, 
    setNearMapActive, setNearmapLoader, setNearmapDisabled,nearmapcoords,tileGridLyr,nearmapImageDate) => {

    axios
        .get(`${ORDER_URL}${id}/parcel-break`, {
            headers: {
                Authorization: `Token ${token}`,
                Accept: "application/json",
            },
        })
        .then((response) => {
            setNearmapLoader(true);
            if (response.data.all_polygons.length) {
                if(response.data.all_polygons.length<=19){ // 19 due to rate limiting of api
                    map.addLayer(completedGridLyr.current);
                    setNearMapActive(true);
                    setNearmapDisabled(false);
               
                    response.data.all_polygons.map((item, index) => {
                        setImage(item, index + 1, completedGridLyr, setNearmapLoader);
                    })
                }else if(response.data.all_polygons.length>19){
                    console.log(nearmapcoords, "date",nearmapImageDate)
                    if (nearmapcoords.length)
                        map.addLayer(completedGridLyr.current);
                        nearmapcoords.map((item) => {
                          setNearmap(map, nearmapImageDate, item, tileGridLyr);
                        });
                    setNearMapActive(true);
                    setNearmapLoader(false)
                    setNearmapDisabled(false);
                    
                }
                else{

                    setNearmapLoader(false)
                    setNearmapDisabled(true);
                }
             
            }
            else {
                setNearmapLoader(false)
                setNearmapDisabled(true);
            }
        })
        .catch((error) => {
            console.error(error.message);
        });
}

export default setCoordsToImage;