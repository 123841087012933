import Overlay from "ol/Overlay";
import { transform } from "ol/proj";

const setFeedbackOverlay = (map, viewId, toggleFeedbackMarkerVisible, feedbackMarker, navigate, container, feedbackLayerVisible, previewFeedback) => {
    if (feedbackMarker && map && feedbackLayerVisible) {
        toggleFeedbackMarkerVisible(true);
        navigate({ search: `view_id=${viewId}&fdbkid=${feedbackMarker.id}&prevfdbk=${previewFeedback}` });
        const overlay = new Overlay({
            id: 1,
            element: container,
            autoPan: {
                animation: {
                    duration: 250,
                },
            },
        });
        overlay.setPosition(
            transform(
                [feedbackMarker.long, feedbackMarker.lat],
                "EPSG:4326",
                "EPSG:3857"
            )
        );
        container.style.display = "flex";

        // if (isMobile) {
        //     var shiftFractionX = 0.43;
        //     var shiftFractionY = 0.35;
        // }
        // else {
        var shiftFractionX = 0.15;
        var shiftFractionY = 0.45;
        // }
        var originalCenter = transform(
            [feedbackMarker.long, feedbackMarker.lat],
            "EPSG:4326",
            "EPSG:3857"
        );
        var mapSize = map.getSize();
        var shiftX = shiftFractionX * mapSize[0];
        var shiftY = shiftFractionY * mapSize[1];
        var originalCenterPixel = map.getPixelFromCoordinate(originalCenter);
        if (originalCenterPixel) {
            var shiftedCenterPixel = [
                originalCenterPixel[0] + shiftX,
                originalCenterPixel[1] + shiftY
            ];
            var shiftedCenter = map.getCoordinateFromPixel(shiftedCenterPixel);
            map.getView().animate({
                duration: 1000,
                center: shiftedCenter
            });
        }

        // map.getView().animate({
        //     duration: 1000,
        //     center: transform(
        //         [feedbackMarker.long + 0.15, feedbackMarker.lat + 0.45],
        //         "EPSG:4326",
        //         "EPSG:3857"
        //     )
        // });
        map.addOverlay(overlay);
    }
}

export default setFeedbackOverlay;