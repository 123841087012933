import * as turf from "@turf/turf";
import { toLonLat } from "ol/proj";

const calculateLineArea = (coords) => {
  const lineCoords = [];
  for (let i = 0; i < coords.length; i++) {
    lineCoords.push(toLonLat(coords[i]));
  }
  const line = turf.lineString(lineCoords);
  const length = turf.length(line, { units: "feet" }).toFixed(2);
  return length;
};

export default calculateLineArea;
