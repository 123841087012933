import axios from "axios";
import "./ProjectIdPage.css";
import { useRef } from "react";
import { useEffect ,useCallback } from "react";
import React, { useState } from "react";
import BulkOrderTags from "./ProjectTags";
import Tooltip from "@mui/material/Tooltip";
import AuthToken from "../../context/AuthToken";
import "../OrdersDashboard/OrdersDashboard.css";
import ClearIcon from "@mui/icons-material/Clear";
import { TOAST_TYPE } from "../../utils/constants";
import MoveUpIcon from "@mui/icons-material/MoveUp";
import DeleteIcon from "@mui/icons-material/Delete";
import ArchiveIcon from "@mui/icons-material/Archive";
import { useScreenType } from "../../utils/commonMethods";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import Pagination from "../../components/Pagination/Pagination";
import { useNavigate, useSearchParams } from "react-router-dom";
import FilterListOffIcon from "@mui/icons-material/FilterListOff";
import OrdersTable from "../../components/OrdersTable/OrdersTable";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import {
  CREATE_PROPERTY_URL,
  PROJECT_ORDER_URL,
  MOLE_ORDER_URL,
} from "../../helper/ApiUrl";
import InputIcon from "@mui/icons-material/Input";
import PopUpBox from "../../components/PopUpBox/PopUpBox";
import { ImgSrc } from "../../assets/ConfirmDelete.svg";
import MoveToFolderImg from "../../assets/moveToFolder.svg";
import Checkbox from '@mui/material/Checkbox';




const ProjectIdDashboardPage = ({
  bulkId,
  handleError,
  handleSuccess,
  getProjectList,
  setDashboardStatus,
}) => {
  let filterURL = "";
  const menuRef = useRef([]);
  menuRef.current = [];
  const tagRef = useRef();
  const token = AuthToken();
  const navigate = useNavigate();
  const deleteOrderRef = useRef();
  const isMobile = useScreenType();
  const deleteOrderInnerRef = useRef();
  const [menuBar, setMenuBar] = useState([]);
  const [address, setAddress] = useState("");
  const [active, setActive] = useState(false);
  const [activeTag, setActiveTag] = useState(false);
  const [filterState, setFilterState] = useState();
  const [rowCount, setRowCount] = useState(0);
  const [orderList, setOrderList] = useState();
  const [pageCount, setPageCount] = useState(0);
  const [selectedIds, setSelectedIds] = useState([]);
  const [inputValue, setInputValue] = useState("10");
  const [invalidInput, setInvalidInput] = useState(false);
  const [criteriaName, setCriteriaName] = useState("Criteria");

  const [orderTags, setOrderTags] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const tags = searchParams.get("tags") || "";
  const min_area = searchParams.get("min_area") || "";
  const max_area = searchParams.get("max_area") || "";
  const user_id = searchParams.get("user_id") || "";
  const pageNum = searchParams.get("page") || 1;
  const end_Date = searchParams.get("end_date") || "";
  const start_Date = searchParams.get("start_date") || "";
  const property = searchParams.get("status") || "";
  const oprtn = searchParams.get("operation") || "in";
  const [isNot, setIsNot] = useState(oprtn);
  const [minArea, setMinArea] = useState(min_area);
  const [maxArea, setMaxArea] = useState(max_area);
  const [currentPage, setCurrentPage] = useState(pageNum);
  const [jumpToInput, setJumpToInput] = useState(currentPage);
  const [endDate, setEndDate] = useState({ endDate: end_Date });
  const [startDate, setStartDate] = useState({ startDate: start_Date });
  const [userId, setUserId] = useState(user_id.split(/,+/).filter(Boolean));
  const [status, setStatus] = useState(property.split(/,+/).filter(Boolean));
  const [selectTagArray, setSelectTagArray] = useState(
    tags.split(/,+/).filter(Boolean)
  );
  const [selectedStatus, setSelectedStatus] = useState([]);

  // for the All order Selected
  const [isAllSelected, setIsAllSelected] = useState(false);



  const handleKeyDown = (event) => {
    const input = event.target.value;
    if (event.key === "Enter") {
      event.preventDefault();
      if (input) {
        if (input < 0) {
          setJumpToInput(1);
          setCurrentPage(1);
        } else if (input > pageCount) {
          setJumpToInput(pageCount);
          setCurrentPage(pageCount);
        } else setCurrentPage(input);
      }
    }
  };

  useEffect(() => {
    searchParams.set("page", currentPage);
    setSearchParams(searchParams);
  }, [currentPage]);
console.log(address,"address")
  const setURL = () => {
    if (address) {
      console.log(address,"address inside if ")
      filterURL += `&address=${address}`;
      console.log(filterURL,"filterURL address ")
    }
    if (property) {
      filterURL += `&status=${property}`;
    }
    if (minArea) {
      filterURL += `&min_area=${minArea}`;
    }
    if (maxArea) {
      filterURL += `&max_area=${maxArea}`;
    }
    if (userId.length > 0) {
      let newId = "";
      userId.forEach((item) => (newId = newId + item + ","));
      filterURL += `&user_id=${newId.slice(0, newId.length - 1)}`;
    }
    if (isMobile) {
      filterURL += `&status=measurement_completed`;
    }
    if (status.length > 0) {
      let newStatus = "";
      status.forEach((item) => (newStatus = newStatus + item + ","));
      filterURL += `&status=${newStatus.slice(0, newStatus.length - 1)}`;
    }
    if (startDate.startDate || endDate.endDate) {
      filterURL += `&start_date=${startDate.startDate || start_Date}&end_date=${
        endDate.endDate || end_Date
      }`;
    }
    if (isNot && selectTagArray.length > 0) {
      let tags = "";
      selectTagArray.forEach((tag) => (tags += tag + ","));
      filterURL += `&tags=${tags.slice(
        0,
        tags.length - 1
      )}&tag_operation=${isNot}`;
    }
  };



  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      let isPresentInRef = false;
      for (let i in menuRef.current) {
        if (menuRef.current[i].contains(e.target)) {
          isPresentInRef = true;
        }
      }
      if (menuBar.length && menuRef.current && !isPresentInRef) {
        setIsNot("");
        setStatus([]);
        setMenuBar([]);
        setSelectTagArray([]);
        setCriteriaName("Criteria");
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [menuBar]);

  const getOrderList = () => {
    setURL();
    setFilterState(filterURL);
    console.log(bulkId, "bulkId in getOrderList");
    console.log(filterURL,"filterURL address before api call ")
    axios
      .get(
        `${PROJECT_ORDER_URL}?p=${currentPage}&psz=${inputValue}${filterURL}&bulk_oid=${bulkId}`,
        {
          headers: {
            Authorization: `Token ${token}`,
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        // HA
        console.log(response,"response getOrderlist")
        setOrderList(response.data.data);
      
        setRowCount(response.data.count);
        // setPageCount(Math.ceil(response.data.count / 10));
        setPageCount(response.data.total_pages);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          navigate("/access-denied");
        } else {
          console.error(error.message);
        }
      });
  };

 //  useEffect(() => {
//     const search = window.location.search;
//     const params = new URLSearchParams(search);

//     if (params.get("address")) {
//       setAddress(params.get("address"));
//     }

//     if (params.get("min_area")) {
//       setMinArea(params.get("min_area"));
//     }
//     if (params.get("max_area")) {
//       setMaxArea(params.get("max_area"));
//     }

//     if (params.get("user_id")) {
//       setUserId(params.get("user_id").split(","));
//     }

//     if (params.get("status")) {
//       setStatus(params.get("status").split(","));
//     }

//     if (params.get("start_date") || params.get("end_date")) {
//       setStartDate({ startDate: params.get("start_date") });
//       setEndDate({ endDate: params.get("end_date") });
//     }

//     if (params.get("operation") || params.get("tags")) {
//       setIsNot(params.get("operation", isNot));
//       setSelectTagArray(params.get("tags").split(","));
//     }

//     if (!invalidInput) {
//       getOrderList();
//     }
//   }, [address, active, currentPage, activeTag, inputValue]); 

  

// for debouncing the search -start
 
const [debounceaddress,setDebounceAddress] = useState('')
const [debounceTimeout, setDebounceTimeout] = useState(null);

  useEffect(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);

    // if (params.get("address")) {
    //   setAddress(params.get("address"));
    // }

    if (params.get("min_area")) {
      setMinArea(params.get("min_area"));
    }
    if (params.get("max_area")) {
      setMaxArea(params.get("max_area"));
    }

    if (params.get("user_id")) {
      setUserId(params.get("user_id").split(","));
    }

    if (params.get("status")) {
      setStatus(params.get("status").split(","));
    }

    if (params.get("start_date") || params.get("end_date")) {
      setStartDate({ startDate: params.get("start_date") });
      setEndDate({ endDate: params.get("end_date") });
    }

    if (params.get("operation") || params.get("tags")) {
      setIsNot(params.get("operation", isNot));
      setSelectTagArray(params.get("tags").split(","));
    }

    if (!invalidInput) {
      getOrderList();
    }
  }, [active, currentPage, activeTag, inputValue]);

  // const handleChangeSearchInput = useCallback((e)=>{
  //   const newValue = e.target.value;
  //   setAddress(newValue); 
  //   if(newValue === ""){
     
  //     address = ""
  //     debounceaddress =""
  //     getOrderList()

  //     console.log("this wokrms dfgdfgfd")
     
  //     if(address=== "" || debounceaddress==="")
  //       console.log("this wokrms")
  //       getOrderList()
      
  //   }  
    

  //   const regex = /[#$@%&*!^]/; 

  //   // if (!regex.test(newValue)) {
  //   //   setAddress(newValue);
  //   // } else {
  //   //   alert("Special characters  are not allowed.");
  //   // }

  //   if (regex.test(newValue)) {
  //     alert("Special characters are not allowed.");
  //     return;
  //   }

  //   // setAddress(newValue); 


  //   if (debounceTimeout) {
  //     clearTimeout(debounceTimeout);
  //   }
   
  //   const timeout = setTimeout(() => {
  //     setDebounceAddress(newValue); 
  //   }, 500);

  //   setDebounceTimeout(timeout);
  // },[ debounceTimeout])
// 
  const handleChangeSearchInput = (e) => {
    const newValue = e.target.value;
    setAddress(newValue);
 
    if (newValue === "") {
      setAddress("");
      setDebounceAddress("");
      clearTimeout(debounceTimeout); 
      getOrderList(); 
      return;
    }
  
   
    // const regex = /[#$@%&*!^]/;
    // if (regex.test(newValue)) {
    //   alert("Special characters are not allowed.");
    //   return;
    // }
  

    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }
  
    const timeout = setTimeout(() => {
      setDebounceAddress(newValue);
    }, 500);
  
    setDebounceTimeout(timeout);
  };
  
  // const handleChangeSearchInput = useCallback((e) => {
  //   const newValue = e.target.value;
  //   setAddress(newValue);
  //   // Clear input handling
  //   if (newValue === "") {
  //     setAddress("");
  //     setDebounceAddress("");
  //     clearTimeout(debounceTimeout); // Clear any existing debounce timeout
  //     getOrderList(); // Fetch the original data
  //     return;
  //   }
  
  //   // Special characters validation
  //   const regex = /[#$@%&*!^]/;
  //   if (regex.test(newValue)) {
  //     alert("Special characters are not allowed.");
  //     return;
  //   }
  
  //   // 
  
  //   // Debounce handling
  //   if (debounceTimeout) {
  //     clearTimeout(debounceTimeout);
  //   }
  
  //   const timeout = setTimeout(() => {
  //     setDebounceAddress(newValue);
  //   }, 500);
  
  //   setDebounceTimeout(timeout);
  // }, [debounceTimeout]);
  
  // useEffect(() => {
  //   if (debounceaddress) {
  //     SearchFunction();
  //   }

  // }, [debounceaddress]); 

  useEffect(() => {
    if (debounceaddress === "" || debounceaddress) {
      SearchFunction();
      getOrderList(); 
    } 
   
  }, [debounceaddress]);
  
  
// --------------------------------------------------  // 

const SearchFunction =  () => {
    const search = window.location.search;
    const params = new URLSearchParams(search);

    if (params.get('address')) {
      setAddress(params.get('address'));
    }

    if (!invalidInput) {
      getOrderList();
    }
  };


 


  

  // for debouncing the search -end

  const handleAreaFilter = () => {
    if (minArea || maxArea) {
      getOrderList();
      searchParams.set("min_area", minArea ? minArea : null);
      searchParams.set("max_area", maxArea ? maxArea : null);
      setSearchParams(searchParams);
    }
    setMenuBar([]);
  };

  const handleDateFilter = () => {
    if (startDate.startDate || endDate.endDate) {
      const start = new Date(startDate.startDate);
      const end = new Date(endDate.endDate);

      if (end < start) {
        handleSuccess(
          TOAST_TYPE.WARNING,
          "End date cannot be earlier than start date"
        );
        return;
      }

      getOrderList();
      searchParams.set(
        "start_date",
        startDate.startDate ? startDate.startDate : null
      );
      searchParams.set("end_date", endDate.endDate ? endDate.endDate : null);
      setSearchParams(searchParams);
    }
    setMenuBar([]);
  };

  const handleUserFilter = () => {
    if (userId.length > 0) {
      getOrderList();
      let newId = "";
      userId.forEach((item) => (newId = newId + item + ","));
      searchParams.set(
        "user_id",
        newId ? newId.slice(0, newId.length - 1) : null
      );
      setSearchParams(searchParams);
    }
    setMenuBar([]);
  };

  const handleStatusFilter = () => {
    if (status) {
      getOrderList();
      let newStatus = "";
      status.forEach((item) => (newStatus = newStatus + item + ","));
      searchParams.set(
        "status",
        newStatus ? newStatus.slice(0, newStatus.length - 1) : null
      );
      setSearchParams(searchParams);
    }
    setMenuBar([]);
  };

  const handleTagsFilter = () => {
    if (selectTagArray.length > 0) {
      let tags = "";
      selectTagArray.forEach((tag) => (tags += tag + ","));
      searchParams.set("operation", isNot);
      searchParams.set("tags", tags.slice(0, tags.length - 1));
      setSearchParams(searchParams);
      getOrderList();
    } else {
      handleSuccess(TOAST_TYPE.WARNING, "Please select any tag!");
    }
  };

  const clearAreaFilter = () => {
    setMinArea("");
    setMaxArea("");
    setActive(!active);
    searchParams.delete("min_area");
    searchParams.delete("max_area");
    setSearchParams(searchParams);
  };

  const clearUserFilter = () => {
    setUserId([]);
    setActive(!active);
    searchParams.delete("user_id");
    setSearchParams(searchParams);
  };

  const clearDateFilter = () => {
    setStartDate({ startDate: "" });
    setEndDate({ endDate: "" });
    setActive(!active);
    searchParams.delete("start_date");
    searchParams.delete("end_date");
    setSearchParams(searchParams);
  };

  const clearStatusFilter = () => {
    setStatus([]);
    setActive(!active);
    searchParams.delete("status");
    setSearchParams(searchParams);
  };

  const clearTagsFilter = () => {
    setIsNot("in");
    setActive(!active);
    setSelectTagArray([]);
    searchParams.delete("tags");
    setCriteriaName("Criteria");
    searchParams.delete("operation");
    setSearchParams(searchParams);
  };

  const clearAllFilter = () => {
    filterURL = "";
    setAddress("");
    setMinArea("");
    setMaxArea("");
    setStatus([]);
    setUserId([]);
    setIsNot("in");
    setAddress("");
    setActive(!active);
    setSelectTagArray([]);
    setCriteriaName("Criteria");
    setStartDate({ startDate: "" });
    searchParams.delete("tags");
    searchParams.delete("user_id");
    searchParams.delete("operation");
    searchParams.delete("min_area");
    searchParams.delete("max_area");
    searchParams.delete("start_date");
    searchParams.delete("end_date");
    searchParams.delete("status");
    setSearchParams(searchParams);
  };

  const addToRefs = (el) => {
    if (el && !menuRef.current.includes(el)) {
      menuRef.current.push(el);
    }
  };

  const handlePageChange = (data) => {
    let currentPage = data.selected + 1;
    setCurrentPage(currentPage);
    searchParams.set("page", currentPage);
    setSelectedIds([]);
    setActiveTag(!activeTag);
    setSearchParams(searchParams);
    const tableContainer = document.getElementById("yourTableContainerId");
    if (tableContainer) {
      tableContainer.scrollTop = 0;
    }
  };

  useEffect(() => {
    console.log(status);
    if (status) localStorage.setItem("bulkStatus", status);
  }, [status]);

  const deleteBulkDraftOrder = () => {
    let deleteIds = "";
    selectedIds.forEach((id) => (deleteIds += id + "%2C"));
    deleteIds = deleteIds.slice(0, deleteIds.length - 3);
    axios
      .delete(`${CREATE_PROPERTY_URL}?id=${deleteIds}`, {
        headers: {
          Authorization: `Token ${token}`,
          Accept: "application/json",
        },
      })
      .then((response) => {
        getOrderList();
        setSelectedIds([]);
        handleSuccess(TOAST_TYPE.SUCCESS, "Order Deleted Successfully!");
      })
      .catch((error) => {
        console.error(error.message);
      });
  };

  // useEffect(() => {
  //   const intervalId = setInterval(getOrderList, 3000);
  //   return () => clearInterval(intervalId);
  // }, []);


// start- for the checking all the orders are selected are not start
const handleMatchOrder = () => {
  if (selectedIds?.length !== orderList?.length) {
    const ids = [];
    orderList.map((item) => ids.push(item.id));
    setSelectedIds([...ids]);
  }
  axios
    .get(`${PROJECT_ORDER_URL}?match_count=${rowCount}${filterState}&bulk_oid=${bulkId}`, {
      headers: {
        Authorization: `Token ${token}`,
        Accept: 'application/json',
      },
    })
    .then((response) => {
      console.log(response,"response")
      setIsAllSelected(response?.data?.count_match);
    })
    .catch((error) => {
      handleError(error);
    });
};

// end -for the checking all the orders are selected are not 

  // Moved order
  const [bulkOrderId, setBulkOrderId] = useState();
  const handleMoveForm = () => {
  document.querySelector(".move-box-form").style.display="block"
   
  };

  const handleMoveOrderFromBulk = () => {
    // 3 Move All order from Bulk into normal order in one go
    //Move All Orders of a Bulk Order to Normal Orders when all_orders is "true" and source_bulk_order_id is provided
   if(isAllSelected){
    const movedData = {};
     const orderIdParam = selectedIds.every((id) => typeof id === "string")
       ? "true"
       : "false";
 
     axios({
       // url: `${MOLE_ORDER_URL}/${bulkId}/move?order_id=${orderIdParam}`,
      url:`${MOLE_ORDER_URL}/bulk/move?all_orders=true&source_bulk_order_id=${bulkId}`,
       method: "PATCH",
       data: movedData,
       headers: {
         Authorization: `Token ${token}`,
         Accept: "application/json",
       },
     })
       .then((response) => {
        
 
         getOrderList();
         document.querySelector(".deleteBulkBox").style.display = "none";
         // console.log(response,"patch response on move")
         handleSuccess(TOAST_TYPE.SUCCESS, "Order Moved Successfully!");
         setSelectedIds([]);
         document.querySelector(".wrapperPopUpBox").style.display = "none";
 
 
       })
       .catch((error) => {
         handleError(error);
       });
   } 
   // functionality
  // 1 Move Specific Orders of a Bulk Order to Normal Orders when order_id is "false" and source_bulk_order_id is provided


   // 1 Move specific Oders of Bulk into normal order bashboard
   else{
    const movedData = {
      order_ids: selectedIds,
     };
     const orderIdParam = selectedIds.every((id) => typeof id === "string")
       ? "true"
       : "false";
 
     axios({
       // url: `${MOLE_ORDER_URL}/${bulkId}/move?order_id=${orderIdParam}`,
       url: `${MOLE_ORDER_URL}/bulk/move?order_id=false&source_bulk_order_id=${bulkId}`,
       method: "PATCH",
       data: movedData,
       headers: {
         Authorization: `Token ${token}`,
         Accept: "application/json",
       },
     })
       .then((response) => {
         // /mole/order/<bulk_oid>/move
 
         getOrderList();
         document.querySelector(".deleteBulkBox").style.display = "none";
         // console.log(response,"patch response on move")
         handleSuccess(TOAST_TYPE.SUCCESS, "Order Moved Successfully!");
         setSelectedIds([]);
         document.querySelector(".wrapperPopUpBox").style.display = "none";
  
 
       })
       .catch((error) => {
         handleError(error);
       });
   }
  };



 const handleMoveOrder = () => {

// 4 Move All Oders of Bulk into another Bulk project
//Move All Orders of a Bulk Order to Another Bulk Order when all_orders is "true" and source_bulk_order_id is provided
if(isAllSelected){
  const movedData = { 
    bulk_order_id :bulkOrderId
  };

  axios({
    url:`${MOLE_ORDER_URL}/bulk/move?all_orders=true&source_bulk_order_id=${bulkId}`,
    method:'PATCH',
    data:movedData,
    headers:{
      Authorization: `Token ${token}`,
      Accept: 'application/json',
    }
  })
    
    .then((response) => {
      console.log(response,"patch response on move")
      document.querySelector(".wrapperPopUpBox").style.display="none"
      document.querySelector(".move-box-form").style.display="none"
      document.querySelector(".deleteBulkBox").style.display="none"
      
      getProjectList();
      getOrderList()
      handleSuccess(TOAST_TYPE.SUCCESS, 'Order Moved Successfully!');
    
      setSelectedIds([]);
     
    })
    .catch((error) => {
      handleError(error);
    });
}
// functionality
//Move Specific Orders of a Bulk Order to Another Bulk Order when order_id is "false" and source_bulk_order_id is provided
// 2 Move specific Oders of Bulk into another Bulk project
else{
  const movedData =
 
  {
     order_ids : selectedIds,
    bulk_order_id: bulkOrderId
    
  };
  const orderIdParam = selectedIds.every(id => typeof id === 'string') ? 'true' : 'false';


  axios({
    url:`${MOLE_ORDER_URL}/bulk/move?order_id=false&source_bulk_order_id=${bulkId}`,
    method:'PATCH',
    data:movedData,
    headers:{
      Authorization: `Token ${token}`,
      Accept: 'application/json',
    }
  })
    
    .then((response) => {
      console.log(response,"patch response on move")
      document.querySelector(".wrapperPopUpBox").style.display="none"
      document.querySelector(".move-box-form").style.display="none"
      document.querySelector(".deleteBulkBox").style.display="none"
      getProjectList();
      getOrderList()
      handleSuccess(TOAST_TYPE.SUCCESS, 'Order Moved Successfully!');
    
      setSelectedIds([]);
     
    })
    .catch((error) => {
      handleError(error);
    });
}

  
};

  // getting the Bulk order project list
  const [projectList, setProjectList] = useState();
  const getProjectLists = () => {
    console.log("Invoked")
    axios({
      // url:  `${MOLE_ORDER_URL}?p=${1}&psz=${10}`,
      // url:  `${MOLE_ORDER_URL}?all=true&bulk_summary=true`
      url:  `${MOLE_ORDER_URL}?bulk_summary=true`,
      method: "GET",
      headers: {
        Authorization: `Token ${AuthToken()}`,
      },
    })
      .then((res) => {
        setProjectList(res.data.data);
        // console.log(res.data.data, " project list  when move to bulk to bulk ProjectIdDashboard ")
      })
      .catch((err) => {
        handleError(err);
      });
  };


  useEffect(()=>{
  
    getProjectLists()
  },[ ])

  const [checkedState, setCheckedState] = useState({});

  const handleBulkNameCheckbox = (e, projectId) => {
    const isChecked = e.target.checked;
    setBulkOrderId(projectId)
    // setCheckedState((prevState) => ({
    

    //   ...prevState,
    //   [projectId]: isChecked,
    // }));
    setCheckedState((prevState) => ({
     [projectId]: isChecked,
    }));
  };

  const handleDivClick = (projectId) => {
    setBulkOrderId(projectId)

    // setCheckedState((prevState) => {
    //   const newState = { ...prevState, [projectId]: !prevState[projectId] };
    //   return newState;
    // });
    setCheckedState((prevState) => {
      const newState = {[projectId]: !prevState[projectId] };
      return newState;
    });
  };
  
  // getting the Bulk order project list 

  return (
    <div className="orders-dashboard">
      <BulkOrderTags
        tagRef={tagRef}
        orderTags={orderTags}
        activeTag={activeTag}
        selectedIds={selectedIds}
        setActiveTag={setActiveTag}
        setOrderTags={setOrderTags}
        setSelectedIds={setSelectedIds}
      />
      <div
        className="orders-dashboard-top-tollbar"
        style={
          selectedIds?.length > 0 ? { display: "block" } : { display: "none" }
        }
      >
        <div className="dashboard-top-tollbar-contant-container">
          <div className="property-selection-count">
            {/* Selected : {selectedIds?.length}/{orderList?.length} */}
            {isAllSelected ? (
              <div>{rowCount} properties selected</div>
            ) : (
              <div>
                Selected : {selectedIds?.length}/{orderList?.length}
              </div>
            )}

            {isAllSelected ? (
              <div onClick={() => { setSelectedIds([]); setIsAllSelected(false) }} className="select-all-backend-props">
                Clear Selection
              </div>
            ) : (
              <div className="select-all-backend-props" onClick={handleMatchOrder}>
                Select {rowCount} properties
              </div>
            )}

            

          </div>
          <div
            className="tollbar-dismiss-container"
            onClick={() => {
              setOrderTags([]);
              setSelectedIds([]);
              tagRef.current.style.display = "none";
              deleteOrderRef.current.style.opacity = 0;
              deleteOrderRef.current.style.pointerEvents = "none";
            }}
          >
            DISMISS X
          </div>
          <div className="top-tollbar-action-container">
            <Tooltip title="Move Order" placement="bottom">
              {/* <button style={{ padding:"0.2rem 0.4rem",marginRight:"0.2rem", cursor:"pointer",borderRadius:"5px", background:"green",color:"white",border:"none"}}
                onClick={handleMoveOrderFromBulk}
              ><InputIcon/></button> */}
              {/* <button style={{width:"1.5rem",marginRight:"0.2rem", cursor:"pointer",borderRadius:"5px",border:"none",background:"none",color:"#06372a"}}
               
                onClick={()=> document.querySelector(".wrapperPopUpBox").style.display="block"}
              >               <img src={ MoveToFolderImg}   style={{width:"100%" }} />
              </button> */}
              <img
                src={MoveToFolderImg}
                onClick={
                //  handelMoverPopUp
                 

                  () =>
                  (document.querySelector(".deleteBulkBox").style.display="block")
                }
                style={{ cursor: "pointer", width: "1.5rem" }}
              />
            </Tooltip>
            <Tooltip title="Tag" placement="bottom">
              <LocalOfferIcon
                onClick={(e) => {
                  e.preventDefault();
                  setActiveTag(!activeTag);
                  tagRef.current.style.display = "block";
                }}
                className="top-tollbar-action-icon"
              />
            </Tooltip>
            {/* <Tooltip title="Move" placement="bottom">
              <MoveUpIcon className="top-tollbar-action-icon" />
            </Tooltip>
            <Tooltip title="Archive" placement="bottom">
              <ArchiveIcon className="top-tollbar-action-icon" />
            </Tooltip> */}
            <Tooltip title="Delete" placement="bottom">
              <DeleteIcon
                onClick={() => {
                  deleteOrderRef.current.style.opacity = 1;
                  deleteOrderRef.current.style.pointerEvents = "auto";
                  document.querySelector(".wrapperPopUpBox").style.display= "block"
                }}
                // className="top-tollbar-action-icon"
                className={
                  selectedStatus.includes("diy") ||
                  selectedStatus.includes("draft")
                    ? "top-tollbar-action-icon"
                    : selectedStatus.includes("diy") &&
                      selectedStatus.includes("draft") &&
                      selectedStatus.includes("in_progress") &&
                      selectedStatus.includes("measurement_completed")
                    ? "top-tollbar-action-icon"
                    : "top-tollbar-action-icon-disable"
                }
              />
            </Tooltip>
          </div>
        </div>
      </div>
      <div ref={deleteOrderRef} className="no-layers-info-container">
        <div
          ref={deleteOrderInnerRef}
          // className="delete-layer-inner-modal delete-all-layer-inner-modal"
          className="wrapperPopUpBox "
        >
          <div className="CloseIconPopUpBox">
            <span
              onClick={() => {
                deleteOrderRef.current.style.opacity = 0;
                deleteOrderRef.current.style.pointerEvents = "none";
              }}
            >
            <box-icon name='x'></box-icon>
            </span>
          </div>
          <div className="ImgBoxPopUpBox">
          <box-icon name='error-alt' type='solid' flip='horizontal' animation='tada' color='#edba83' size="lg"></box-icon>
          </div>
          <div className=" Remove_margins_of_p">
          <p>
              Delete this order?
          </p>
           <p>
            Only draft orders can be deleted.
            </p>
          </div>
          <div className="view-modal-bttn "  id="view__model__BTNS">
            <button className="cancel"
              onClick={() => {
                deleteOrderRef.current.style.opacity = 0;
                deleteOrderRef.current.style.pointerEvents = "none";
              }}
            >
              Cancel
            </button>
            <button  className="delete" id="DeleteBtn"
              onClick={() => {
                deleteBulkDraftOrder();
                deleteOrderRef.current.style.opacity = 0;
                deleteOrderRef.current.style.pointerEvents = "none";
              }}
            >
              Delete
            </button>
          </div>
        </div>
      </div>
      <div className="orders-dashboard-search">
        <div
          onClick={() => {
            getOrderList();
            setDashboardStatus(false);
            getProjectList();
          }}
          className="excel-dashboard-switch-cont"
        >
          <ArrowBackIosNewIcon
            style={{ fontSize: "1em", marginRight: "0.5em" }}
          />
          <span style={{fontSize:"0.7rem"}}>Unprocessed  Orders</span>
        </div>
        {/* <div className="orders-dashboard-search-left">
          <input
            style={{ width: "66%", marginLeft: "2%" }}
            type="text"
            value={address}
            placeholder="Search by Order ID/Address"
            onChange={(e) => setAddress(e.target.value)}
          />
          <SearchOutlinedIcon className="orders-search-icon" />
          <button onClick={clearAllFilter}>
            <FilterListOffIcon className="clear-filter-icon" />
            Clear Filters
          </button>
        </div> */}
        <div className=" orders__dashboard__searchLeft " style={{width:"56%"}}>
          <div  className="left__input__box" style={{ width: "100%"}}>
          <input
            id="left__input__"
            type="text"
            value={address}
            placeholder="Search by Order ID/Address"
            // onChange={(e) => setAddress(e.target.value)}
            onChange={  handleChangeSearchInput }
          />
         
          <SearchOutlinedIcon className="Search__icon" />
         
          
          </div>
          <button onClick={clearAllFilter}>
            <FilterListOffIcon className=" clear-filter-icon" />
            Clear Filters
          </button>
        </div>

        <div className="orders-dashboard-search-right">
          {min_area || max_area ? (
            <div>
              <div className="filter-div">
                <span>Area</span>
                <ClearIcon
                  className="close-filter-icon"
                  onClick={clearAreaFilter}
                />
              </div>
            </div>
          ) : null}
          {user_id ? (
            <div>
              <div className="filter-div">
                <span>Creator</span>
                <ClearIcon
                  className="close-filter-icon"
                  onClick={clearUserFilter}
                />
              </div>
            </div>
          ) : null}
          {start_Date || end_Date ? (
            <div>
              <div className="filter-div">
                <span>Created On.</span>
                <ClearIcon
                  className="close-filter-icon"
                  onClick={clearDateFilter}
                />
              </div>
            </div>
          ) : null}
          {property && !isMobile ? (
            <div>
              <div className="filter-div">
                <span>Status</span>
                <ClearIcon
                  className="close-filter-icon"
                  onClick={clearStatusFilter}
                />
              </div>
            </div>
          ) : null}
          {tags ? (
            <div>
              <div className="filter-div">
                <span>Tags</span>
                <ClearIcon
                  className="close-filter-icon"
                  onClick={clearTagsFilter}
                />
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <div className="orders-dashboard-table">
        <OrdersTable
          isNot={isNot}
          bulkId={bulkId}
          status={status}
          userId={userId}
          menuBar={menuBar}
          minArea={minArea}
          maxArea={maxArea}
          endDate={endDate}
          setIsNot={setIsNot}
          activeTag={activeTag}
          addToRefs={addToRefs}
          startDate={startDate}
          pageCount={pageCount}
          orderList={orderList}
          setStatus={setStatus}
          setUserId={setUserId}
          setMenuBar={setMenuBar}
          setMinArea={setMinArea}
          setMaxArea={setMaxArea}
          setEndDate={setEndDate}
          filterState={filterState}
          handleError={handleError}
          selectedIds={selectedIds}
          criteriaName={criteriaName}
          setStartDate={setStartDate}
          setActiveTag={setActiveTag}
          setOrderList={setOrderList}
          handleSuccess={handleSuccess}
          setSelectedIds={setSelectedIds}
          selectTagArray={selectTagArray}
          setCriteriaName={setCriteriaName}
          handleUserFilter={handleUserFilter}
          handleAreaFilter={handleAreaFilter}
          handleTagsFilter={handleTagsFilter}
          handleDateFilter={handleDateFilter}
          setSelectTagArray={setSelectTagArray}
          handleStatusFilter={handleStatusFilter}
          selectedStatus={selectedStatus}
          setSelectedStatus={setSelectedStatus}
        />
      </div>
      <Pagination
        invalidInput={invalidInput}
        jumpToInput={jumpToInput}
        setJumpToInput={setJumpToInput}
        handleKeyDown={handleKeyDown}
        handlePageChange={handlePageChange}
        pageCount={pageCount}
        rowCount={rowCount}
        currentPage={currentPage}
        inputValue={inputValue}
        setInputValue={setInputValue}
      />

      <div 
        className="deleteBulkBox wrapperPopUpBox"
        >
        <div className="MainPopUpBox">
        <div className="CloseIconPopUpBox">
          <span className="" onClick={() => {
                document.querySelector(".deleteBulkBox").style.display = "none";
              }}>
             <box-icon name='x'></box-icon>
          </span>
        </div> 
        <div className="ImgBoxPopUpBox">
          <img className="" src={MoveToFolderImg} alt="image" />
        </div>
        <div  className="Remove_margins_of_p">
          <p>Where to move </p>
          <p>Are you sure to Move?</p>
        </div>

        <div
          className="view-modal-bttns "
          id="view__model__BTTNS"
        
        >
          <button
            className="cancle"
            onClick={ handleMoveOrderFromBulk}
          >
            Move to General
          </button>
          <button
            className="delete"
            id="BulkOrderDeleteBtn"
            onClick={handleMoveForm}
          >
            Move to Bulk
          </button>
        </div>
         </div>
      </div>
      {/* <PopUpBox 
        imageSrc= {MoveToFolderImg}
        title="Confirmation "
        message="Are you sure to Move?"
        additionalMsg ="This will move into the general order"
        btnText="Move"
        onRightBtnfunc={handleMoveOrderFromBulk}
      /> */}
      {/* <PopUpBox
        imageSrc={MoveToFolderImg}
        title="Where to move"
        message="Are you sure to Move?"
        // additionalMsg ="This will move into the general order"
        leftBtnText="Move to General"
        rightBtnText="Move to Bulk"
        onLeftBtnfunc={handleMoveOrderFromBulk}
        onRightBtnfunc={handleMoveForm}
      /> */}

      {/* MOVE into Bulk order Form  */}

      <div className="move-box-form" style={{position:"static"}}>
     
        <div className="move-into-bulk">
          <div
            style={{
              position: "fixed",
              top: "5px ",
              right: "5px ",
              cursor: "pointer",
            }}
          >
            <button
              onClick={() => {
                document.querySelector(".move-box-form").style.display = "none";
              }}
              style={{
                background: "none",
                border: "none",
                cursor: "pointer",
                fontSize: "1.6rem",
                zIndex:"1003"
              }}
            >
            <box-icon name='x'></box-icon>
            </button>
          </div>
          <h3>Select name to move into bulk order.</h3>
          {/* <div className='move-into-bulk-option-container'>
       { projectList?.map(

      (project, index)=>{
       
       return (
       <div key={index} className='checkbox-and-name' onClick={()=>{ console.log("clcked")} } style={{ cursor:"pointer"}}> 
       <input type='checkbox'
        onChange={(e)=>{ handleBulkNamecheckbox(e,project?.id)}}
       />
      {project?.name} </div>
     )
      
      }
     )}
  </div> */}
          <div className="move-into-bulk-option-container">
            {projectList?.map((project, index) => (
              <div
                key={index}
                className="checkbox-and-name"
                onClick={() => handleDivClick(project?.id)}
                style={{ cursor: "pointer" }}
              >
                <Checkbox
                   size="small"
                    color="success"
                  checked={checkedState[project?.id] || false}
                  onChange={(e) => handleBulkNameCheckbox(e, project?.id)}
                  onClick={(e) => e.stopPropagation()} // Prevent div click event from firing
                />
                {project?.name}
              </div>
            ))}
          </div>

          <div className="view-modal-bttns" id="move_order_btn">
            {" "}
            <button
              onClick={handleMoveOrder}
              style={{
                background: " #519259",
                color: "white",
                textWrap: "no-wrap",
                fontWeight:"500",
                letterSpacing:"0.08rem"
                
              }}
            >
              Move Order
            </button>{" "}
          </div>
        </div>
      </div>

      {/* MOVE Form  */}
    </div>
  );
};

export default ProjectIdDashboardPage;
