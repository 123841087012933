const removeSourceFeature = (map, feature) => {
  setTimeout(function () {
    map.getLayers().forEach((layer) => {
      if (layer.get('title') && layer.get('title') === 'Parcel') {
        const source = layer.getLayers().getArray()[0].getSource();
        console.log('Intersecting ', feature);
        source.removeFeature(feature);
      }
    });
  }, 300);
};

export default removeSourceFeature;
