import React, { useState, useEffect ,useRef} from 'react';
import './FolderFile.css';
import axios from 'axios';
import { FILE_UPLOAD, MOLE_ORDER_FILE_FOLDER ,LOGIN_URL} from '../../helper/ApiUrl';
import AuthToken from '../../context/AuthToken';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import './MyDocuments.css';
import './FolderFiles.css';
import InfoBox from './InfoBox';

import {} from '../../../src/App'
import { useNavigate, useParams } from 'react-router-dom';
import ToggleButtons from './ToggleButton';
import ImageView from './Imageview';
// import {handleSuccess, handleError} from '../../App'
import { TOAST_TYPE } from '../../utils/constants';
import PopUpBox from '../../components/PopUpBox/PopUpBox';
import { ToastContainer, toast } from 'react-toastify';
import { Table, TableBody, TableCell, TableHead, TableRow, Paper,TextField } from '@mui/material';
import FolderIcon from '@mui/icons-material/Folder';
import { IconButton, Menu, MenuItem, Tooltip } from '@mui/material';
import { ArrowDownward, ArrowUpward, MoreVert, Download, Visibility, Delete } from '@mui/icons-material';
import MoreVertIcon from '@mui/icons-material/MoreVert'; 
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/Delete';
import OttermapLoaderGif from '../../assets/ottermap-loading-gif.gif';





const RoutingFileExplorerr = (
  // { data, setSelectedImage, setCurrentView }
  {data ,handleSuccess,handleError,FilesAndFolder,dataUsage}

) => {


  // console.log(data,"----",setSelectedImage,"-----",setCurrentView)
  const [currentFolder, setCurrentFolder] = useState(null);
  const [bulkOrderId, setBulkOrderId] = useState(null);
  const [folderData, setFolderData] = useState(null);
  const [documents, setDocuments] = useState([]);
  const [layout, setLayout] = useState('list-view');
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredDocuments, setFilteredDocuments] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedFolders, setSelectedFolders] = useState([]);
  const [selectedImageName, setSelectedImageName] = useState([]);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null); // Store the current index
  const [all_URLS, setAll_URLS] = useState([]); 

  const [anchorEl, setAnchorEl] = useState(null);
  const [activeRow, setActiveRow] = useState(null);
  const [sortAsc, setSortAsc] = useState(true);

  const handleMenuClick = (event, rowIndex) => {
    setAnchorEl(event.currentTarget);
    setActiveRow(rowIndex);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setActiveRow(null);
  };

  const handleSortToggle = () => {
    setSortAsc(!sortAsc);
    // You can call a sorting function here that toggles the sorting of your data
  };

  // for the folder sec
  const [imageUrlForZipping, setAllImageUrlForZipping] = useState([]);
  // const [folderNameforZip, setFolderNameForZipping] = useState('folder');
  var folderNameforZip = 'folder'
  useEffect(()=>{ console.log(folderNameforZip,"folderNameforZip") },[folderNameforZip])

// call api for the getting all urls from the them, for the zipping process
const getFolderImageUrls = (folderId)=>{
  
    console.log('called')
    try {
     axios.get(`${MOLE_ORDER_FILE_FOLDER}?bulk_oid=${folderId}`, {
        headers: {
          Authorization: `Token ${token}`,
          Accept: 'application/json',
        },
      })
     
      .then((response)=>{
        const metaData = response.data.data.meta_data; 
      if (Array.isArray(metaData)) {
        const imageUrls = metaData.map(item => item.file_url); 
        console.log(imageUrls,"huzaifa"); 
        setAllImageUrlForZipping(imageUrls)
        startDownload(imageUrls)
        // handleDownloadZipFolder(imageUrls)
        
      } 
      })
      .catch((e)=>{
        console.log(e)
      })
      
    } catch (error) {
      console.error("Error fetching folder data:", error);
    }
  

}
const handleDownload =(selectedFolder)=>{
  folderNameforZip = selectedFolder.folder_name
  // setFolderNameForZipping(selectedFolder.folder_name)
  getFolderImageUrls(selectedFolder.folder_id)
  
}
// for the folder selection
  const handleClick = (event, folder) => {
    setAnchorEl(event.currentTarget);
    setSelectedFolder(folder); // Store the folder for the actions
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  // //////////////

useEffect(()=>{
  console.log(selectedImageIndex,"selectedImageIndex")
},[selectedImageIndex])
// Function to handle image preview
const previewImagehandler = (fileUrl) => {
  const index = all_URLS.indexOf(fileUrl); // Find the index of the image URL
  setSelectedImageIndex(index); // Set the current index
  setSelectedImage(fileUrl); // Set the selected image to display in the viewer
  setCurrentView('image');
};

// Function to handle next image
const handleNextImage = () => {
  if (selectedImageIndex < all_URLS.length - 1) {
    setSelectedImageIndex(selectedImageIndex + 1); // Move to next image
    setSelectedImage(all_URLS[selectedImageIndex + 1]); // Update the selected image
  }
};

// Function to handle previous image
const handlePrevImage = () => {
  if (selectedImageIndex > 0) {
    setSelectedImageIndex(selectedImageIndex - 1); // Move to previous image
    setSelectedImage(all_URLS[selectedImageIndex - 1]); // Update the selected image
  }
};

 
  const [isDownloading, setIsDownloading] = useState(false);
  const [isOpenZipDialog, setOpenZipDialog] = useState(false);
  const token = AuthToken();
const navigate = useNavigate()
  const [showModal, setShowModal] = useState(null);

   // for show images preview 
   const [currentView, setCurrentView] = useState('home');
   const [selectedFolder, setSelectedFolder] = useState(null);
   const [selectedImage, setSelectedImage] = useState(null);
   const [folderName, setFolderName] = useState(null);
 useEffect(()=>{console.log(folderName,"folderName")},[folderName])


  const {folderId} = useParams()

  const handleCloseImage = () => {
    setSelectedImage(null);
    setCurrentView('folder');
  };
useEffect(()=>{
  console.log(all_URLS,"all_URLS")
},[all_URLS])

  const fetchData = async () => {
    console.log('called')
    try {
      const response = await axios.get(`${MOLE_ORDER_FILE_FOLDER}?bulk_oid=${folderId}`, {
        headers: {
          Authorization: `Token ${token}`,
          Accept: 'application/json',
        },
      });
      // Array of URLs
      setFolderData(response.data.data);
      setDocuments(response.data.data.meta_data);
      const metaData = response.data.data.meta_data; // Accessing meta_data from response
    if (Array.isArray(metaData)) {
      const imageUrls = metaData.map(item => item.file_url); // Extracting file_url from each item
      console.log(imageUrls); // Array of file URLs
      setAll_URLS(imageUrls); // Storing the array of URLs
    } else {
      console.error('meta_data is not an array or missing:', metaData);
    }
      // console.log(response.data.data.meta_data,"abc")
      

      // setAll_URLS(imageUrls)


      

      console.error("response.data.data:", response.data.data,"response.data.data.meta_data",response.data.data.meta_data,"response.data.data.meta_data.file_url,",response.data.data.meta_data.file_url);
    } catch (error) {
      console.error("Error fetching folder data:", error);
    }
  };
  useEffect(() => {
    if (folderId !== null) {
      // const fetchData = async () => {
      //   try {
      //     const response = await axios.get(`${MOLE_ORDER_FILE_FOLDER}?bulk_oid=${bulkOrderId}`, {
      //       headers: {
      //         Authorization: `Token ${token}`,
      //         Accept: 'application/json',
      //       },
      //     });
      //     setFolderData(response.data.data);
      //     setDocuments(response.data.data.meta_data);
      //     setAll_URLS(response.data.data.meta_data.file_url)
      //   } catch (error) {
      //     console.error("Error fetching folder data:", error);
      //   }
      // };
      fetchData();
    }
  }, [folderId]);

  useEffect(() => {
    console.log(documents," useEffect documents")
    const results = documents.filter(doc =>
      doc.file_name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredDocuments(results)
    // if(results){
    //   setFilteredData(results)
    // }
    ;
  }, [searchTerm, documents]);
//  const  setFilteredData=(results)=>{
//   console.log(documents," useEffect---------- documents")

//   setFilteredDocuments(results)
//  }
const[folder__Name,setFolder__Name]=useState('')
  useEffect(()=>{
    console.log(folder__Name,"folder__Name")
  },[folder__Name])
  
  const handleFolderClick = (folder) => {
   
    setFolder__Name(folder.folder_name)
    setBulkOrderId(parseInt(folder.folder_id));
    setCurrentFolder(folder);
    navigate(`/mydocs/${folder.folder_id}`)
  };

  const handleBackClick = () => {
    setCurrentFolder(null);
    setFolderData(null);
    setBulkOrderId(null);
    setSelectedItems([]);
    setSelectedFolders([])
    navigate('/mydocs')
    setLayout('list-view')
    
  };

  // const previewImagehandler = (file_URL) => {
  //   setCurrentView('image');
  //   setSelectedImage(file_URL);
  // };

  const handleSelectedImageNameAndDelete = (file_name) => {
    setSelectedImageName((prevSelected) =>
      prevSelected.includes(file_name)
        ? prevSelected.filter((name) => name !== file_name)
        : [...prevSelected, file_name]
    );
    // Using a callback to ensure the state is updated before deleting
    setTimeout(() => {
      handleDelete();
    }, 0);
  };

  const handleDelete = async (filename) => {
   console.log("delete clicked") 
   const filenameArr = []
   filenameArr.push(filename)

    try {
      if(filename){
        console.log(filename,"selectedImageName")
      await axios.delete(`${MOLE_ORDER_FILE_FOLDER}`, {
        headers: {
          Authorization: `Token ${token}`,
        },
        data: { 
          bulk_oid:folderId,
          file_keys:filenameArr 
         },
      });
      // setDocuments(documents.filter(doc => !selectedItems.includes(doc.file_url)));
      fetchData()
      setSelectedItems([]);
      setSelectedImageName([])
      handleSuccess(TOAST_TYPE.SUCCESS,'Image Deleted Successfully!')
      
    }
    } catch (error) {
      // handleError(error);
      console.error("Error deleting files:", error);
      handleError(error)
    }
  };
  let selectedImagesforDelete =[]
  let handleSelectItemArr =[]
  // const handleSelectedImageName = (file_name) => {
  //   if (!selectedImagesforDelete.includes(file_name)) {
  //     selectedImagesforDelete.push(file_name);
  //   } else {
  //     console.log(selectedImagesforDelete, "this else selectedImagesforDelete");
  //     selectedImagesforDelete = selectedImagesforDelete.filter(
  //       (name) => name !== file_name
  //     );
  //   }
  //   // console.log(selectedImagesforDelete, "selectedImagesforDelete  Image Name array");
  //   console.log(selectedImagesforDelete, "Image Name array");
  // };

  const handleSelectItemArrfunc = (file_URL) => {
    if (!handleSelectItemArr.includes(file_URL)) {
      handleSelectItemArr.push(file_URL);
    } else {
      console.log(handleSelectItemArr, "this else handleSelectItemArr ");
      handleSelectItemArr = handleSelectItemArr.filter(
        (name) => name !== file_URL
      );
    }
    // console.log(handleSelectItemArr, "handleSelectItemArr selectedImagesforDelete ");
    console.log(handleSelectItemArr, "Image Name URl array");

  

    // setSelectedItems((prevSelected) =>
    //   prevSelected.includes(file_URL)
    //     ? prevSelected.filter((url) => url !== file_URL)
    //     : [...prevSelected, file_URL]
    // );
    // console.log(selectedItems, " selectedItems ---selectedImagesforDelete");
  };

  // const handleMultipleDelete = async () => {
   
  //   try {
  //     if(selectedImagesforDelete.length > 0){
  //       console.log(selectedImagesforDelete,"selectedImageName")
  //     await axios.delete(`${MOLE_ORDER_FILE_FOLDER}`, {
  //       headers: {
  //         Authorization: `Token ${token}`,
  //       },
  //       data: { 
  //         bulk_oid:folderId,
  //         file_keys:selectedImagesforDelete 
  //        },
  //     });
  //     // setDocuments(documents.filter(doc => !selectedItems.includes(doc.file_url)));
  //     fetchData()
  //     setSelectedItems([]);
  //     setSelectedImageName([])
  //     handleSuccess(TOAST_TYPE.SUCCESS,'Images Deleted Successfully!')
  //     selectedImagesforDelete=[]
  //     console.log( selectedImagesforDelete," selectedImagesforDelete=[]")
  //   }
  //   } catch (error) {
  //     // handleError(error)
  //     console.error("Error deleting files:", error);
  //   }
  // };
  const handleMultipleDelete = async () => {
   
    try {
      // if(selectedImagesforDelete.length > 0){
        console.log(selectedImagesforDelete,"selectedImageName")
      await axios.delete(`${MOLE_ORDER_FILE_FOLDER}`, {
        headers: {
          Authorization: `Token ${token}`,
        },
        data: { 
          bulk_oid:folderId,
          file_keys:selectedImageName 
         },
      });
      // setDocuments(documents.filter(doc => !selectedItems.includes(doc.file_url)));
      fetchData()
      setSelectedItems([]);
      setSelectedImageName([])
      handleSuccess(TOAST_TYPE.SUCCESS,'Images Deleted Successfully!')
      selectedImagesforDelete=[]
      console.log( selectedImagesforDelete," selectedImagesforDelete=[]")
    // }
    } catch (error) {
      handleError(error)
      console.error("Error deleting files:", error);
    }
  };
  const handleLeftBtnClick=()=>{
    document.querySelector('.wrapperPopUpBox').style.display="none"
  }
  const handleDeleteFolderOpen=()=>{
     document.querySelector('.wrapperPopUpBox').style.display="block"
  }

  const handleDeleteFolder = async (folderID) => {
    const data = selectedFolders
    let FolderIDD = parseInt(folderID)
    console.log(folderID,"folderID")
    console.log(folderName,"folderName")
    
    try {
      await axios.delete(`${MOLE_ORDER_FILE_FOLDER}`, {
        headers: {
          Authorization: `Token ${token}`,
        },
        //  data:{ bulk_oids:[FolderIDD]}
         data:{ bulk_oids:[folderName]}
        // data: { file_urls: selectedItems },
      });
      // setDocuments(documents.filter(doc => !selectedItems.includes(doc.file_url)));
      // setDocuments(documents.filter(doc => !selectedItems.includes(doc.file_url)));
     
      console.log(folderName,"folderName ye chal rha ");
      
      document.querySelector('.wrapperPopUpBox').style.display="none";
      toast.success('Folder Deleted Successfully!', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        
        });
      FilesAndFolder()
      // handleSuccess(TOAST_TYPE.SUCCESS,'Images Deleted Successfully!')
      setSelectedFolders([])
      setSelectedItems([]);
      setSelectedImageName([])
      
      setFolderName(null)
      
     
      
    } catch (error) {
      console.error("Error deleting Folders:", error);

      // handleError(error)
    }
  };

  const handleInfo = (file_URL) => {
    console.log("File info for:", file_URL);
  };

  const handleSelectItem = (file_URL) => {
    setSelectedItems(prevSelected =>
      prevSelected.includes(file_URL)
        ? prevSelected.filter(url => url !== file_URL)
        : [...prevSelected, file_URL]
    );
  };
  const handleSelectedImageName = (file_name) => {
   
    setSelectedImageName(prevSelected =>
      prevSelected.includes(file_name)
        ? prevSelected.filter(url => url !== file_name)
        : [...prevSelected, file_name]
    );
  };
  const handleSelectFolder = (file_URL) => {
    setSelectedFolders(prevSelected =>
      prevSelected.includes(file_URL)
        ? prevSelected.filter(url => url !== file_URL)
        : [...prevSelected, file_URL]
    );
  };
 

  const handleDownloadImage = (file_URL) => {
    const downloadURL = selectedImage || file_URL ;

    if (!downloadURL) {
      console.error("No file URL or selected image available for download.");
      return;
    }
    console.log(selectedImage,"selectedImageURL",downloadURL,"downloadURL")
  
    console.log(downloadURL, "downloadURL");
    const link = document.createElement('a');
    link.href = downloadURL;
    link.download = downloadURL.split('/').pop();
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    handleSuccess(TOAST_TYPE.SUCCESS,'Image Downloaded Successfully!')


    // console.log(file_URL,"file_URL")
    // const link = document.createElement('a');
    // link.href = file_URL;
    // link.download = file_URL.split('/').pop();
    // document.body.appendChild(link);
    // link.click();
    // document.body.removeChild(link);
  };
  const [progressMul, setProgressMul] = useState(0);
  const [isZippingMultiple, setIsZippingMultiple] = useState(false);

  const handleDownloadZip = async () => {
    setIsZippingMultiple(true);
    setProgressMul(0); 
    console.log(selectedItems,"selectedItems")
    if (selectedItems.length === 0) {
      alert("Please select at least one file to download.");
      return;
    }
    setIsDownloading(true);
    try {
      const zip = new JSZip();
      const files = await Promise.all(selectedItems.map(async (url) => {
        const response = await axios.get(url, { responseType: 'blob' });
        return { name: url.split('/').pop(), content: response.data };
      }));
      files.forEach(file => zip.file(file.name, file.content));
      const blob = await zip.generateAsync({ type: 'blob' }, (metadata) => {
        setProgressMul(metadata.percent); 
      });
      saveAs(blob,`${folderId}`);
    } catch (error) {
      console.error("Error creating zip file:", error);
    } finally {
      setIsDownloading(false);
      setIsZippingMultiple(false);
      setProgressMul(0); 
    }
  };


 
const [progress, setProgress] = useState(0);
const [isZipping, setIsZipping] = useState(false);

// const handleDownloadZipsInsideFolder = async () => {
//   setIsZipping(true);
//   setProgress(0); 
//   console.log(documents,"documents------------------")

//   try {
//     const zip = new JSZip();
    
//     const files = await Promise.all(documents.map(async (doc) => {
//       const response = await axios.get(doc.file_url, { responseType: 'blob' });
//       return { name: doc.file_name, content: response.data };
//     }));

//     files.forEach(file => zip.file(file.name, file.content));
    
//     const blob = await zip.generateAsync({ type: 'blob' }, (metadata) => {
//       setProgress(metadata.percent); 
//     });
    
//     saveAs(blob, `${folderId}`);
//     // saveAs(blob, `${currentFolder.folder_name}`);
    
//   } catch (error) {
//     console.error("Error creating zip file:", error);
    
//   } finally {
//     setIsZipping(false); 
//     setProgress(0); 
//   }

 
// };

// previous code 
// const handleDownloadZipsInsideFolder = async () => {
//   setIsZipping(true);
//   const zip = new JSZip();
//   console.log(all_URLS,"allll")
//   const imageUrls = all_URLS;
//   let downloadedCount = 0;

//   for (let i = 0; i < imageUrls.length; i++) {
//     const url = imageUrls[i];
//     const imageBlob = await downloadImageWithRetry(url);

//     if (imageBlob) {
//       // Add the image to the zip file
//       const imageName = `image-${i + 1}.jpg`; // You can change the name as needed
//       zip.file(imageName, imageBlob);
//     }

//     // Update progress
//     downloadedCount++;
//     const progressPercentage = (downloadedCount / imageUrls.length) * 100;
//     setProgress(progressPercentage);
//   }

//   // Generate zip and trigger download
//   zip.generateAsync({ type: "blob" }).then((blob) => {
//     saveAs(blob, "images.zip");
//     setIsZipping(false); // Hide the progress bar after completion
//     setProgress(0); // Reset progress
//   });
// };

// new code 


const [isShowDataUsage, setShowDataUsage] = useState(false);

  // Helper function to convert storage used to GB
  const convertToGB = (value) => {
   if(value){
    // console.log(value,"value")
    const [amount, unit] = value.split(' ');
    const parsedAmount = parseFloat(amount);

    switch (unit.toUpperCase()) {
      case 'KB':
        return parsedAmount / (1024 * 1024); 
      case 'MB':
        return parsedAmount / 1024; 
      case 'GB':
        return parsedAmount;
      default:
        return 0; 
    }
   }
  };

  const usedInGB = convertToGB(data?.storage_used);
  const limitInGB = parseFloat(data?.storage_limit);

  
  const percentage = (usedInGB / limitInGB) * 100;

  // Determine the progress bar color
  const progressColor = percentage >= 90 ? 'red' : 'green';



  // for the new folder zipping from the outside
   // Function to download an image with retry logic
  //  const cancelZipRef = useRef(false); // Ref to handle cancel logic

  //  // Function to extract the file name from a URL
  // const extractFileNameFromUrl = (url) => {
  //   return url.split("/").pop().replace(/\s+/g, "-"); // Replace spaces with hyphens
  // };
  // // Function to download an image with retry logic
  // const downloadImageWithRetry = async (url, retryCount = 1) => {
  //   try {
  //     const response = await axios.get(url, { responseType: "blob" });
  //     return response.data;
  //   } catch (error) {
  //     if (retryCount > 0) {
  //       console.log(`Retrying download for ${url}...`);
  //       return downloadImageWithRetry(url, retryCount - 1);
  //     } else {
  //       console.log(`Failed to download ${url}`);
  //       return null;
  //     }
  //   }
  // };

  // // Function to handle the zipping and downloading process
  // const handleDownloadZipFolder = async (selectedFolder) => {
  //   setIsZipping(true);
  //   cancelZipRef.current = false; // Reset cancel flag

  //   const zip = new JSZip();
  //   const imageUrls = selectedFolder; // Assuming imageUrls is passed externally
  //   let downloadedCount = 0;

  //   for (let i = 0; i < imageUrls.length; i++) {
  //     if (cancelZipRef.current) {
  //       console.log("Zipping process canceled.");
  //       break; // Exit the loop if the process is canceled
  //     }

  //     const url = imageUrls[i];
  //     const imageBlob = await downloadImageWithRetry(url);

  //     if (imageBlob) {
  //       // Extract file name from URL
  //       const imageName = extractFileNameFromUrl(url);
  //       zip.file(imageName, imageBlob);
  //     }

  //     // Update progress
  //     downloadedCount++;
  //     const progressPercentage = (downloadedCount / imageUrls.length) * 100;
  //     setProgress(progressPercentage);
  //   }

  //   if (!cancelZipRef.current) {
  //     // Generate zip and trigger download if not canceled
  //     zip.generateAsync({ type: "blob" }).then((blob) => {
  //       saveAs(blob, "images.zip");
  //       setIsZipping(false); // Hide the progress bar after completion
  //       setProgress(0); // Reset progress
  //     });
  //   } else {
  //     setIsZipping(false); // Reset UI after cancellation
  //     setProgress(0); // Reset progress
  //   }
  // };

  // // Function to cancel the zipping process
  // const handleCancelZipping = () => {
  //   cancelZipRef.current = true; // Set the cancel flag
  //   setIsZipping(false); // Hide the zipping UI
  //   setProgress(0); // Reset progress
  // };

// new code  for zipping
// this is from outside the folder
const isCancelledRef = useRef(false); 

const downloadImageWithRetry = async (url, retryCount = 1) => {
  try {
    const response = await axios.get(url, { responseType: "blob" });
    return response.data;
  } catch (error) {
    if (retryCount > 0) {
      console.log(`Retrying download for ${url}...`);
      return downloadImageWithRetry(url, retryCount - 1);
    } else {
      console.log(`Failed to download ${url}`);
      return null;
    }
  }
};

const handleDownloadZipFolder = async (selectedFolder, isCancelledRef) => {
  const zip = new JSZip();
  const imageUrls = selectedFolder;
  let downloadedCount = 0;

  for (let i = 0; i < imageUrls.length; i++) {
    if (isCancelledRef.current) {
      console.log("Zipping cancelled.");
      break; 
    }

    const url = imageUrls[i];
    const imageBlob = await downloadImageWithRetry(url);

    if (imageBlob) {
      
      const imageName = url.split("/").pop();
      zip.file(imageName, imageBlob);
    }

    // Update progress
    downloadedCount++;
    const progressPercentage = (downloadedCount / imageUrls.length) * 100;
    setProgress(progressPercentage);
  }

  if (!isCancelledRef.current) {
    // Generate zip and trigger download only if not cancelled
    zip.generateAsync({ type: "blob" }).then((blob) => {
      saveAs(blob, `${folderNameforZip}`);
      setIsZipping(false); // Hide the progress bar after completion
      setProgress(0); // Reset progress
    });
  } else {
    setIsZipping(false);
    setProgress(0);
  }
};



  const startDownload = async (imageUrls) => {
    isCancelledRef.current = false; // Reset the cancel state
    setIsZipping(true);
    await handleDownloadZipFolder(imageUrls, isCancelledRef);
  };

  const handleCancelZipping = () => {
    isCancelledRef.current = true; // Set the cancel state to true
  };

// this is for inside the folder 
const startDownloadZipInsideFolder = async () => {
  isCancelledRef.current = false; // Reset the cancel state
  setIsZipping(true);
  await handleDownloadZipsInsideFolder(all_URLS, isCancelledRef);
};
const handleDownloadZipsInsideFolder = async (selectedFolder, isCancelledRef) => {
  const zip = new JSZip();
  const imageUrls = selectedFolder;
  let downloadedCount = 0;

  for (let i = 0; i < imageUrls.length; i++) {
    if (isCancelledRef.current) {
      console.log("Zipping cancelled.");
      break; 
    }

    const url = imageUrls[i];
    const imageBlob = await downloadImageWithRetry(url);

    if (imageBlob) {
      
      const imageName = url.split("/").pop();
      zip.file(imageName, imageBlob);
    }

    // Update progress
    downloadedCount++;
    const progressPercentage = (downloadedCount / imageUrls.length) * 100;
    setProgress(progressPercentage);
  }

  if (!isCancelledRef.current) {
    // Generate zip and trigger download only if not cancelled
    zip.generateAsync({ type: "blob" }).then((blob) => {
      saveAs(blob, `${folderId}`);
      setIsZipping(false); // Hide the progress bar after completion
      setProgress(0); // Reset progress
    });
  } else {
    setIsZipping(false);
    setProgress(0);
  }
};

//this is for the multiple image select and download as zip
const startDownloadZipForMultipleImage = async () => {
  isCancelledRef.current = false; 
  setIsZipping(true);
  await handleDownloadZipForMultipleImage(selectedItems, isCancelledRef);
};
const handleDownloadZipForMultipleImage = async (selectedItems, isCancelledRef) => {
  const zip = new JSZip();
  const imageUrls = selectedItems;
  let downloadedCount = 0;

  for (let i = 0; i < imageUrls.length; i++) {
    if (isCancelledRef.current) {
      console.log("Zipping cancelled.");
      break; 
    }

    const url = imageUrls[i];
    const imageBlob = await downloadImageWithRetry(url);

    if (imageBlob) {
      
      const imageName = url.split("/").pop();
      zip.file(imageName, imageBlob);
    }

    // Update progress
    downloadedCount++;
    const progressPercentage = (downloadedCount / imageUrls.length) * 100;
    setProgress(progressPercentage);
  }

  if (!isCancelledRef.current) {
    // Generate zip and trigger download only if not cancelled
    zip.generateAsync({ type: "blob" }).then((blob) => {
      saveAs(blob, `${folderId}`);
      setIsZipping(false); 
      setProgress(0); 
    });
  } else {
    setIsZipping(false);
    setProgress(0);
  }
};

  // below is working code

  //  const downloadImageWithRetry = async (url, retryCount = 1) => {
  //   try {
  //     const response = await axios.get(url, { responseType: "blob" });
  //     return response.data;
  //   } catch (error) {
  //     if (retryCount > 0) {
  //       console.log(`Retrying download for ${url}...`);
  //       return downloadImageWithRetry(url, retryCount - 1);
  //     } else {
  //       console.log(`Failed to download ${url}`);
  //       return null;
  //     }
  //   }
  // };

  // Function to handle the zipping and downloading process
  // const handleDownloadZipFolder = async (selectedFolder) => {
  //   setIsZipping(true);
  //   const zip = new JSZip();
  //   // const imageUrls = await getFolderImageUrls(selectedFolder.folder_id);
  //   const imageUrls = selectedFolder;
  //   let downloadedCount = 0;

  //   for (let i = 0; i < imageUrls.length; i++) {
  //     const url = imageUrls[i];
  //     const imageBlob = await downloadImageWithRetry(url);

  //     if (imageBlob) {
  //       // Add the image to the zip file
  //       const imageName = `image-${i + 1}.jpg`; // You can change the name as needed
  //       zip.file(imageName, imageBlob);
  //     }

  //     // Update progress
  //     downloadedCount++;
  //     const progressPercentage = (downloadedCount / imageUrls.length) * 100;
  //     setProgress(progressPercentage);
  //   }

  //   // Generate zip and trigger download
  //   zip.generateAsync({ type: "blob" }).then((blob) => {
  //     saveAs(blob, "images.zip");
  //     setIsZipping(false); // Hide the progress bar after completion
  //     setProgress(0); // Reset progress
  //   });
  // };

  const [searchQuery, setSearchQuery] = useState('');
  const filteredData = data?.meta_data?.filter((folder) =>
    folder.folder_name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <>
    <div style={{ width: "100%",height:"100%" ,gap:"0"}}
     className={`${folderId ? "my-documents-content-cont" : ""}`}
    >

    <ToggleButtons  
      data={data}
      dataUsage={dataUsage}
      />
    
 {
  data  &&   <div style={{width:"100%",height:"10%",display:"flex",justifyContent:"center",alignItems:"center",padding:  "0 1rem",boxSizing:'border-box'}}>
  <TextField
  label="Search by Name"
  variant="outlined"
  fullWidth
  value={searchQuery}
  onChange={(e) => setSearchQuery(e.target.value)}
  style={{ width:"50%"}}
/>
</div>
 }
 
   
      {folderData ? (
        <div style={{ width: "100%" ,boxSizing:"border-box",paddingTop:"0",paddingBottom:"0" }}
         className='documents__container'
         >
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" ,flexWrap:"wrap",height:"10%"}}>
            <div className='back_download_delete_cont'>
              <button
                onClick={handleBackClick}
                style={{ background: "#519259", color: "white", borderRadius: "5px", padding: "0.4rem 0.6rem", border: "none", cursor: "pointer" }}
              >
                Back
              </button>
              { selectedItems.length > 0? 
                
                <button
                    onClick={startDownloadZipForMultipleImage}
                    
                    style={{ background: "#519259", color: "white", borderRadius: "5px", padding: "0.4rem 0.6rem", border: "none", cursor:"pointer", marginLeft: "10px" }}
                  >
                    {isDownloading ? "Downloading..." : "Download as Zip"}
                </button>
                :
                <button
                    onClick={startDownloadZipInsideFolder}
                    
                    style={{ background: "#519259", color: "white", borderRadius: "5px", padding: "0.4rem 0.6rem", border: "none", cursor:"pointer", marginLeft: "10px" }}
                  >
                    {isDownloading ? "Downloading..." : "Download as Zip"}
                </button>
                }
                {/* --------- */}
                
                {/* --------- */}
              {selectedItems.length > 0 && (
                <>
                  {/* <button
                    onClick={handleDownloadZip}
                    disabled={isDownloading}
                    style={{ background: "#519259", color: "white", borderRadius: "5px", padding: "0.4rem 0.6rem", border: "none", cursor: isDownloading ? "not-allowed" : "pointer", marginLeft: "10px" }}
                  >
                    {isDownloading ? "Downloading..." : "Download as Zip"}
                  </button> */}
                  <button
                    onClick={handleMultipleDelete}
                    style={{ background: "#edba83", color: "white", borderRadius: "5px", padding: "0.4rem 0.6rem", border: "none", cursor: "pointer", marginLeft: "10px" }}
                  >
                    Delete Selected
                  </button>
                </>
              )}
            </div>
            {/* <div style={{border:"1px solid #519259",overflow:"hidden",borderRadius:"5px", display:"grid",gridTemplateColumns:"85% auto",margin:"0.2rem 0",width:"20rem"}}> */}
              {/* <input */}
              <TextField
               label="Search Images"
                variant="outlined"
                fullWidth
                // type='text'
                // placeholder='Search Images'
                value={searchTerm}
                onChange={e => setSearchTerm(e.target.value)}
                style={{ width: "20rem", border:'none',}}
              />
              {/* <div
              style={{background:"#519259",height:"100%",display:"flex",justifyContent:"center",alignItems:"center"}}
              ><box-icon name='search' color='#ffffff' ></box-icon></div> */}
            {/* </div> */}
            <div 
             className="toggle_layout_btn_container" style={{ display: "flex", overflow: "hidden",borderRadius:"5px" }}
           >
              <button
                onClick={() => setLayout('list-view')}
                style={{ backgroundColor: layout === 'list-view' ? '#519259' : 'grey', color: 'white' }}
              >
                <box-icon name='list-ul' color='#ffffff'></box-icon>
              </button>
              <button
                onClick={() => setLayout('grid-view')}
                style={{ backgroundColor: layout === 'grid-view' ? '#519259' : 'grey', color: 'white' }}
              >
                <box-icon name='grid-horizontal' color='#ffffff'></box-icon>
              </button>
            </div>
          </div>
          <div className="Name_TotalImage" style={{display:"flex ", justifyContent:"space-between "}}><h2>Project ID: {console.log(folderData,"folderName")}{folderId} {folderData?.folder_name}</h2> <h6>Total Images: {documents.length}</h6> {console.log(currentFolder,"CurrentFolder",documents,"documents")}</div>
          
          {filteredDocuments.length > 0 ? (
  <div className={layout === 'grid-view' ? '' : 'list-view'} style={{ height: "80%", }}>
    <Paper style={{ overflow: 'auto',  width: "100%", height: "100%" }} 
    
    >
      {layout === 'list-view' ? (
        // List view: Display data in table format
        <Table stickyHeader>
          <TableHead>
            <TableRow>
            <TableCell></TableCell>
            <TableCell>
            Name
            {/* <IconButton onClick={handleSortToggle}>
              {sortAsc ? <ArrowDownward /> : <ArrowUpward />}
            </IconButton> */}
          </TableCell>
              <TableCell>Size</TableCell>
              {/* <TableCell>Last Modified</TableCell> */}
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredDocuments.map((file, index) => (
              <TableRow key={index} style={{ borderBottom: '1px solid' }}>
                <TableCell>
                  <input
                    type="checkbox"
                    checked={selectedItems.includes(file.file_url) && selectedImageName.includes(file.file_name)}
                    onChange={() => {
                      handleSelectItem(file.file_url);
                      handleSelectedImageName(file.file_name);
                    }}
                  />
                </TableCell>
                <TableCell onClick={() => previewImagehandler(file.file_url)} style={{cursor:"pointer"}}>{file.file_name}</TableCell>
                <TableCell>{file.file_size}</TableCell>
                {/* <TableCell>{file.last_modified}</TableCell> */}
                {/* <TableCell>
                  <div className="action-buttons">
                    <div className="downloaddiv checkboxdiv" title="Download" onClick={() => handleDownloadImage(file.file_url)}>
                      <box-icon type='solid' name='download'></box-icon>
                    </div>
                    <div className="previewdiv checkboxdiv" title="Preview" onClick={() => { previewImagehandler(file.file_url); setSelectedImage(file.file_url); }}>
                      <box-icon name='show-alt'></box-icon>
                    </div>
                    <div className="delete-checkboxdiv checkboxdiv" title="Delete" onClick={() => handleDelete(file.file_name)}>
                      <box-icon type='solid' name='trash-alt'></box-icon>
                    </div>
                  </div>
                </TableCell> */}
                 <TableCell>
              <IconButton onClick={(event) => handleMenuClick(event, index)}>
                <MoreVert />
              </IconButton>
              {activeRow === index && (
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                >
                  <MenuItem onClick={() => {handleDownloadImage(file.file_url);
                    handleMenuClose()
                  }}>
                    <Tooltip title="Download">
                      <IconButton sx={{ bgcolor: 'transparent', '&:hover': { bgcolor: 'rgba(0, 0, 0, 0.1)', borderRadius: '50%' } }}>
                        <Download />
                      </IconButton>
                    </Tooltip>
                    Download
                  </MenuItem>
                  <MenuItem onClick={() => {previewImagehandler(file.file_url)
                    handleMenuClose()
                  }}>
                    <Tooltip title="Preview">
                      <IconButton sx={{ bgcolor: 'transparent', '&:hover': { bgcolor: 'rgba(0, 0, 0, 0.1)', borderRadius: '50%' } }}>
                        <Visibility />
                      </IconButton>
                    </Tooltip>
                    Preview
                  </MenuItem>
                  <MenuItem onClick={() => {handleDelete(file.file_name)
                    handleMenuClose()
                  }}>
                    <Tooltip title="Delete">
                      <IconButton sx={{ bgcolor: 'transparent', '&:hover': { bgcolor: 'rgba(0, 0, 0, 0.1)', borderRadius: '50%' } }}>
                        <Delete />
                      </IconButton>
                    </Tooltip>
                    Delete
                  </MenuItem>
                </Menu>
              )}
            </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : (
        // Grid view: Display data in a grid format
        <div className="grid-container" style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))', gap: '16px' }}>
          {filteredDocuments.map((file, index) => (
            <div key={index} className="grid-item" style={{ border: "1px solid #ccc", borderRadius: "8px", padding: "10px" }}>
              <div className="grid_img_box" style={{ width: "100%", cursor: "pointer", height: "150px" }} onClick={() => previewImagehandler(file.file_url)}>
                <img src={file.file_url} alt={file.file_name} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
              </div>
              <div className="file__name_title" style={{boxSizing:"border-box"}}>
                <h5  style={{ marginTop:"0px !important" ,padding:"0.2rem",fontSize:"0.8rem"}}>{file.file_name}</h5>
              </div>
              <div className="action-buttons" style={{width:"100%",justifyContent:"space-around",marginTop:"0.4rem"}}>
                <div className="downloaddiv checkboxdiv" title="Download" onClick={() => handleDownloadImage(file.file_url)}>
                  <box-icon type='solid' name='download'></box-icon>
                </div>
                <div className="previewdiv checkboxdiv" title="Preview" onClick={() => { previewImagehandler(file.file_url); setSelectedImage(file.file_url); }}>
                  <box-icon name='show-alt'></box-icon>
                </div>
                <div className="delete-checkboxdiv checkboxdiv" title="Delete" onClick={() => handleDelete(file.file_name)}>
                  <box-icon type='solid' name='trash-alt'></box-icon>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </Paper>
  </div>
) : (
  <div className="alternate-table-container"> <h1 style={{color:"green"}}>No Images found</h1></div>
)}

        </div>
      ) : folderData===null && data==null ?<div className="alternate-table-container Loaders-Div">
           
      { documents.length === 0 ?<img src={OttermapLoaderGif} alt="Ottermap Loader Gif" /> :<h1>Loading...</h1>}
       
    </div> : (
        <div style={{ width: "100%",height:"85%" }} className='documents__container'>
      <Paper style={{ overflow: 'auto', width: "100%", height: "100%" }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Project Id</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Size</TableCell>
              {/* <TableCell>Last Modified</TableCell> */}
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody style={{position:filteredData.length === 0 ?" relative":"none"}} >
            {filteredData.map((folder, index) => (
              <TableRow
                key={index}
                hover
                sx={{
                  '&:hover': {
                    backgroundColor: '#f0f0f0', // Change background on hover
                  },
                }}
              >
                <TableCell onClick={() => handleFolderClick(folder)}>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' ,gap:"0.8rem",cursor:"pointer"}}>
                <FolderIcon style={{ marginBottom: 5 }} />
                  {folder.folder_id}
                  </div>
                  </TableCell>
                <TableCell onClick={() => handleFolderClick(folder)}>
  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' ,gap:"0.8rem",cursor:"pointer"}}>
    {/* <FolderIcon style={{ marginBottom: 5 }} /> */}
    {folder.folder_name}
  </div>
</TableCell>
                <TableCell>{folder.folder_size}</TableCell>
                {/* <TableCell>{folder.last_modified}</TableCell> */}
                <TableCell>
                  <IconButton onClick={(event) => handleClick(event, folder)}>
                    <MoreVertIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
            {filteredData.length === 0 && <div
            style={{color:"green",display:"flex",justifyContent:"center",alignItems:"center",width:"90%",height:"90%",position:"absolute",top:"5rem"}}
            ><h1>No Order Found</h1></div>}

          </TableBody>
        </Table>
      </Paper>

      {/* Popup for actions (Download and Delete) */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem onClick={() => { handleDownload(selectedFolder); handleClose(); }}>
          <DownloadIcon style={{ marginRight: 8 }} /> Download as zip
        </MenuItem>
        <MenuItem onClick={() => {  handleClose();handleDeleteFolderOpen();
          setFolderName(selectedFolder.folder_id)
         }}>
          <DeleteIcon style={{ marginRight: 8 }} /> Delete
        </MenuItem>
      </Menu>
    </div>
      )}
    </div>

    {/* use abhi krna h  */}

{/* 
    <div key={index} className='Wrapper__Containerr'>
              {item.file_type === 'folder' ? (

                <div className="folder__Container" >
                  <div onClick={() => handleFolderClick(item)} style={{ display: "grid", gridTemplateColumns: "auto 90%", alignItems: "center",boxSizing:"border-box" ,cursor:"pointer"}}>
                    <box-icon name='folder' type='solid' color='#f9b400' size='md'></box-icon>
                    <div>{item.folder_id} : {item.folder_name}</div>

                  </div>
                  <div className="downloaddiv checkboxdiv" title="Delete" onClick={() =>{
                     
                     handleSelectFolder(item.folder_id)
                     handleDeleteFolderOpen(item.folder_id)
                     setFolderName(item.folder_id)
                     }
                  }>
                    <box-icon type='solid' name='trash-alt'></box-icon>
                  </div>
                  <div className="downloaddiv checkboxdiv" title="Info" onClick={() => handleInfo(item.file_url)}>
                    
                    <div>
      <button style={{cursor:"pointer",background:"none",border:"none"}} onClick={() => setShowModal(index)}><box-icon type='solid' name='info-circle'></box-icon></button>
      {showModal=== index && <InfoBox folderInfo={{folder_name:item.folder_name,folder_id:item.folder_id,folder_size:item.folder_size}}  onClose={() => setShowModal(false)} />}
    </div>
                  </div>
                </div>
              ) : (
                <div className="Wrapper__Container">
                  <div className="namediv">{item.file_name}</div>
                  <div className="downloaddiv checkboxdiv" title="Download" onClick={() => handleDownloadImage(item.file_url)}>
                    <box-icon type='solid' name='download'></box-icon>
                  </div>
                  <div className="previewdiv checkboxdiv" title="Preview" onClick={() => previewImagehandler(item.file_url)}>
                    <box-icon name='show-alt'></box-icon>
                  </div>
                  <div className="previewdiv checkboxdiv" title="Delete" >
                    <box-icon type='solid' name='trash-alt'></box-icon>
                  </div>
                </div>
              )}
            </div> */}


    {/* use abhi krna h  */}

{/* -------------- */}
      {/* {currentView === 'image' && (
       <ImageView
         imageSrc={selectedImage}
         onClose={handleCloseImage}
         onDownload={handleDownloadImage}
       />
     )} */}
     

{/* {currentView === 'image' && (
 
<ImageView
    images={all_URLS} // Array of image URLs
    currentIndex={selectedImage} // Initial image index to display
    onClose={handleCloseImage} // Function to close the image viewer
    onDownload={handleDownloadImage} // Function to download the image
  />
)} */}
{currentView === 'image' && (
  <ImageView
    images={all_URLS} // Array of image URLs
    currentIndex={selectedImageIndex} // Pass the selected image index
    onClose={handleCloseImage} // Function to close the image viewer
    onDownload={handleDownloadImage} // Function to download the image
    onNext={handleNextImage} // Handle next image
    onPrev={handlePrevImage} // Handle previous image
  />
)}

{/* Circular progress bar for zipping process */}
{isZipping && (
        <div
          style={{ position: "absolute", zIndex: "1002" }}
          className="zipping_container"
        >
          <div style={{ width: "3rem", height: "3rem" }}>
            <CircularProgressbar
              value={progress}
              text={`${Math.round(progress)}%`}
              styles={buildStyles({
                textColor: "white",
                pathColor: "green",
                trailColor: "white",
              })}
            />
          </div>
          <p>Zipping...</p>
          <button onClick={handleCancelZipping} style={{background:"white",color:"green",padding:"0.2rem 0.6rem",cursor:"pointer",
            border:"none",borderRadius:"5px",
          }}>Cancel</button> 
        </div>
      )}
      {/* Circular progress bar for zipping process */}
      {/* {isZipping && (
        <div
          style={{ position: "absolute", zIndex: "1002" }}
          className="zipping_container"
        >
          <div style={{ width: "3rem", height: "3rem" }}>
            <CircularProgressbar
              value={progress}
              text={`${Math.round(progress)}%`}
              styles={buildStyles({
                textColor: "white",
                pathColor: "green",
                trailColor: "white",
              })}
            />
          </div>
          <p>Zipping...</p>
          <button onClick={handleCancelZipping}>Cancel</button> 
        </div>
      )} */}

     {/* Delete Modal */}
     <PopUpBox

        title="Confirmation"
        message="Delete this folder?"
        additionalMsg=""
          onRightBtnfunc={handleDeleteFolder}
         onLeftBtnfunc={handleLeftBtnClick}
        leftBtnText="Cancel"
        rightBtnText="Delete"
        iconComponent={<box-icon name="error-alt" type="solid" flip="horizontal" animation="tada" color="#edba83" size="lg"></box-icon>}
    
        
     
      />

</>
  );
};

export default RoutingFileExplorerr;
