import Feature from 'ol/Feature';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Polygon, LinearRing } from 'ol/geom';
import { Fill, Stroke, Style } from 'ol/style';

const measurementStyle = [
  new Style({
    stroke: new Stroke({
      color: '#FFF455',
      width: 2,
    }),
    fill: new Fill({
      color: 'rgb(255, 255, 255,0.2)',
    }),
  }),
];

const renderAreaFeature = (map, areaFeatures, measurementRef) => {
  const drawnFeatures = [];
  if (areaFeatures.length)
    areaFeatures.map((polyData) => {
      polyData.data.map((item, index) => {
        if (index === 0) {
          const feature = new Feature({
            geometry: new Polygon([item]),
          });
          const new_polygon = feature.getGeometry();
          polyData.data.map((hole, reIndex) => {
            if (reIndex > 0) {
              const linearRing = new LinearRing(hole);
              new_polygon.appendLinearRing(linearRing);
            }
          });
          feature.setId(polyData.id);
          drawnFeatures.push(feature);
        }
      });
    });

  const polyLayer = new VectorLayer({
    source: new VectorSource({
      features: drawnFeatures,
    }),
    style: measurementStyle,
    title: 'Draw',
  });
  measurementRef.current.getLayers().push(polyLayer);
  map.getLayers().forEach(function (layer) {
    if (layer && layer.get('title') === 'Measurement') {
      map.removeLayer(layer);
    }
  });
  measurementRef.current.setZIndex(2);
  map.addLayer(measurementRef.current);
};

export default renderAreaFeature;
