import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import './DeleteWarningModal.css';
import CloseIcon from '@mui/icons-material/Close';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide({cancel, submit, title, description, icon, setOpen, open, cancelText, submitText}) {


  const handleClose = () => {
    setOpen(false);
  };

  const dialogStyles = {
    width: '100% !important',
    minWidth: '350px !important',
    maxWidth: '30vw !important',
    height: '100% !important',
    minHeight: '200px !important',
    maxHeight: '28vh !important',
    borderRadius: "20px !important",
    // MUI responsive breakpoints
    '@media (min-width: 2560px)': {
      minWidth: '25vw !important',
      // You can uncomment the following lines if needed
      // maxWidth: '60vw !important',
      // maxHeight: '30vh !important',
    },
  };


  return (
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        PaperProps={{
          sx: dialogStyles, 
        }}
        aria-describedby="alert-dialog-slide-description"
      >
        <Box sx={{display: "flex", width: "100%", justifyContent: "flex-end",}}><IconButton size="small" sx={{marginRight: "5px"}} onClick={() => setOpen(false)}><CloseIcon fontSize="inherit" /></IconButton></Box>
        <Box sx={{display: "flex", justifyContent: "center", width: "100%"}}>{icon}</Box>
        <DialogTitle sx={{textAlign: "center", fontSize: "clamp(20px, 1.5vw, 1.5vw)", padding: "0px"}}>{title}</DialogTitle>
        <DialogContent sx={{padding: "0px"}}>
          <DialogContentText sx={{textAlign: "center",fontSize: "clamp(14px, 1vw, 1vw)", marginTop: "5px"}} id="alert-dialog-slide-description">
            {description}
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{display: "flex", justifyContent: "center"}}>
          <Button variant="outlined" className='ottermap-button-outlined' onClick={cancel ? cancel : handleClose}>{cancelText}</Button>
          <Button variant="contained" className='ottermap-button' onClick={submit}>{submitText}</Button>
        </DialogActions>
      </Dialog>
  );
}