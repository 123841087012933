import React from "react";
import "./OtpVerifiedPage.css";
import { Link } from "react-router-dom";
import { ReactComponent as SiteLogo } from "../../assets/logo.svg";

const OtpVerifiedPage = () => {
  return (
    <div className="otp-verified-container">
      <div className="auth-logo-left-container">
        <SiteLogo className="right-side-logo right_side_logos" />
      </div>
      <div className="otp-verified-header">
        <div>
          <h1>OTP Verified!</h1>
        </div>
      </div>
      <div className="otp-verified-body">
        <div className="otp-verified-body-text">
          <p>Hi there,</p>
          <p>
            Our team is currently setting up your account. We'll notify you via
            email, once we have your account active. Stay tuned!
          </p>
          <p>
            In the meanwhile, you can check out this video to see how you can
            order property measurements with Ottermap.
          </p>
        </div>
        <div className="otp-verified-body-video">
       
          <iframe
            width="100%"
            height="315"
            title="demo-video"
            frameborder="0"
            allowfullscreen
            src="https://www.youtube.com/embed/9k2Y-krFkqQ"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          style={{borderRadius:"10px"}}
          ></iframe>
       
      </div>
     
    </div>
     <div className="otp-verified-footer">
        <Link to="/" class="link_404">
          Back to Login
        </Link>
      </div>
  </div>
  );
};

export default OtpVerifiedPage;
