import axios from 'axios';
import './BulkOrderChangeContainer.css';
import Tooltip from '@mui/material/Tooltip';
import AuthToken from '../../context/AuthToken';
import React, { useState, useEffect, useRef } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ORDER_URL, MOLE_ORDER_URL } from '../../helper/ApiUrl';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { STATUS, STATUS_VAL, TOAST_TYPE } from '../../utils/constants';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const BulkOrderChangeContainer = ({
  id,
  address,
  bulkOrderId,
  handleSuccess,
  bulkOrderStatus,
  setBulkOrderStatus,
  handleCustomSuccess,
}) => {
  const token = AuthToken();
  const innerRef = useRef();
  const navigate = useNavigate();
  const [changeBulkData, setChangeBulkData] = useState({});
  const [showStatusBar, setShowStatusBar] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const numberOfFilters = [
    STATUS.DRAFT,
    STATUS.IN_PROGRESS,
    STATUS.MEASUREMENT_COMPLETED,
  ];

  const handleStatusFilter = () => {
    if (bulkOrderStatus && bulkOrderStatus.length) {
      let newbulkOrderStatus = '';
      bulkOrderStatus.forEach(
        (item) => (newbulkOrderStatus = newbulkOrderStatus + item + ',')
      );
      searchParams.set(
        'status',
        newbulkOrderStatus
          ? newbulkOrderStatus.slice(0, newbulkOrderStatus.length - 1)
          : null
      );
      setSearchParams(searchParams);
    } else if (bulkOrderStatus?.length === 0) {
      setSearchParams({});
    }
  };

  const getParcelData = () => {
    axios
      .get(`${ORDER_URL}${id}?status=${bulkOrderStatus.join(',')}`, {
        headers: {
          Authorization: `Token ${token}`,
          Accept: 'application/json',
        },
      })
      .then((response) => {
        const data = response.data.data;
        setChangeBulkData({
          next_data: data.next_data,
          prev_data: data.prev_data,
        });
      })
      .catch((error) => {
        console.error(error.message);
      });
  };

  const handleApplyFilter = () => {
    localStorage.setItem('bulkStatus', bulkOrderStatus);
    axios
      .get(
        `${MOLE_ORDER_URL}/${bulkOrderId}/filter?status=${bulkOrderStatus.join(
          ','
        )}`,
        {
          headers: {
            Authorization: `Token ${token}`,
            Accept: 'application/json',
          },
        }
      )
      .then((response) => {
        if (response.data) {
          // if (response.data.order_id !== id) {
          navigate(`/order/${response.data.order_id}`);
          window.location.reload();
          // }
        }
      })
      .catch((error) => {
        console.error(error.message);
        handleSuccess(
          TOAST_TYPE.WARNING,
          'No property with selected status found'
        );
      });
  };

  const handleClickOutSide = (e) => {
    if (innerRef.current) {
      if (!innerRef.current.contains(e.target)) {
        setShowStatusBar(false);
      }
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutSide, true);
  }, []);

  useEffect(() => {
    getParcelData();
  }, []);

  useEffect(() => {
    handleStatusFilter();
  }, [bulkOrderStatus]);

  return (
    <div className="bulk-next-prev-btn-cont">
      <Tooltip
        title={changeBulkData.next_data ? changeBulkData.next_data.address : ''}
      >
        <div
          onClick={() =>
            changeBulkData.next_data
              ? (window.location.href = `/order/${changeBulkData.next_data.order_id}`)
              : null
          }
          className={`${changeBulkData.next_data ? 'bulk-next-btn' : 'bulk-next-btn-dsbl'
            }`}
        >
          <ArrowBackIcon className="bulk-next-prev-icon" />
        </div>
      </Tooltip>
      <div className="bulk-address-status-cont">
        <Tooltip title={address} >
          <div className="bulk-address-cont">{address}</div>
        </Tooltip>
        <div
          ref={innerRef}
          className="bulk-status-cont"
          onClick={() => setShowStatusBar(!showStatusBar)}
        >
          <div
            style={{ position: 'relative' }}
            className="order-cell-heading-div"
          >
            <span>Status</span>
            {showStatusBar ? (
              <KeyboardArrowUpIcon className="order-filter-icon" />
            ) : (
              <KeyboardArrowDownIcon className="order-filter-icon" />
            )}
            {showStatusBar ? (
              <div
                onClick={(e) => e.stopPropagation()}
                className="bulk-status-filter"
              >
                <label>
                  <div>
                    <input
                      type="checkbox"
                      checked={bulkOrderStatus?.length === 3 ? true : false}
                      onChange={() => {
                        if (bulkOrderStatus?.length === 3) {
                          setSearchParams({});
                          setBulkOrderStatus([]);
                        } else {
                          setBulkOrderStatus(numberOfFilters);
                        }
                      }}
                    />
                    <span>Select All</span>
                  </div>
                </label>
                {numberOfFilters.map((item, index) => (
                  <label>
                    <div key={index}>
                      <input
                        name={item}
                        type="checkbox"
                        checked={bulkOrderStatus.includes(item)}
                        onChange={() =>
                          bulkOrderStatus.includes(item)
                            ? setBulkOrderStatus(
                              bulkOrderStatus.filter((e) => e !== item)
                            )
                            : setBulkOrderStatus([...bulkOrderStatus, item])
                        }
                      />
                      <span>{STATUS_VAL[item]}</span>
                    </div>
                  </label>
                ))}
                <div className="tags-filter-div-two">
                  <button
                    style={{ width: '100%', paddingBottom: '0.3em' }}
                    onClick={() => {
                      if (bulkOrderStatus.length > 0) {
                        handleApplyFilter();
                      } else {
                        handleCustomSuccess(
                          TOAST_TYPE.WARNING,
                          'Please select one of the following options!'
                        );
                      }
                    }}
                  >
                    ✔ Apply
                  </button>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <Tooltip
        title={changeBulkData.prev_data ? changeBulkData.prev_data.address : ''}
      >
        <div
          onClick={() =>
            changeBulkData.prev_data
              ? (window.location.href = `/order/${changeBulkData.prev_data.order_id}`)
              : null
          }
          className={`${changeBulkData.prev_data ? 'bulk-prev-btn' : 'bulk-prev-btn-dsbl'
            }`}
        >
          <ArrowForwardIcon className="bulk-next-prev-icon" />
        </div>
      </Tooltip>
    </div>
  );
};

export default BulkOrderChangeContainer;
