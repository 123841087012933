import "./SettingsPage.css";
import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import Drawer from '@mui/material/Drawer';
import { useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';
import SettingsLeftBar from "../../components/SettingsLeftBar/SettingsLeftBar";
import useLogout from './../../utils/customHooks/logout.js';

const SettingsPage = () => {

  const navigate = useNavigate();
  const handleLogout = useLogout();
  const [settingsAnchor, setSettingsAnchor] = useState(null);
  const settingsOpen = Boolean(settingsAnchor);

  const logoutBtnClick = () => {
    handleLogout()
  };

  return (
    <div className="settings-container">
      <div className="settings-top-container">
        <h2>Settings</h2>
        <MenuIcon className="settings-menu-icon" onClick={(e) => setSettingsAnchor(e.currentTarget)} />
      </div>
      <Drawer
        id='filter-accordian-div'
        anchor='right'
        open={settingsOpen}
        onClose={() => setSettingsAnchor(null)}
      >
        <div style={{ width: "230px", background: "#ecffe0", display: "flex", flexDirection: "column", justifyContent: "space-between", height: "100%" }}>
          <SettingsLeftBar setSettingsAnchor={setSettingsAnchor} />
          <div>
            <hr />
            <div style={{ display: "flex", alignItems: "center", padding: "0.5em 1em 1em 1em" }} onClick={logoutBtnClick}>
              <LogoutIcon className="sidebar-icon" />
              <span style={{ marginLeft: "0.5em" }}>Logout</span>
            </div>
          </div>
        </div>
      </Drawer>
      {/* <hr /> */}
      <div className="settings-bottom-container">
        <div className="settings-bottom-left">
          <SettingsLeftBar setSettingsAnchor={setSettingsAnchor} />
        </div>
        <div className="settings-bottom-right">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default SettingsPage;
