import {
  ORDER_URL,
  STUDIO_URL,
  ADD_FEEDBACK,
  LAYERS_DATA_URL,
  UPLOAD_LAYERS_DATA_URL,
} from "../../helper/ApiUrl";
import JSZip from "jszip";
import axios from "axios";
import "./UploadOrderPage.css";
import { saveAs } from "file-saver";
import Switch from "@mui/material/Switch";
import Tooltip from '@mui/material/Tooltip';
import AddIcon from "@mui/icons-material/Add";
import FormGroup from "@mui/material/FormGroup";
import AuthToken from "../../context/AuthToken";
import React, { useState, useEffect } from "react";
import PublishIcon from "@mui/icons-material/Publish";
import DownloadIcon from "@mui/icons-material/Download";
import { useNavigate, useParams } from "react-router-dom";
import FormControlLabel from "@mui/material/FormControlLabel";
import UserPrompt from "../../components/UserPrompt/UserPrompt";
import { FEEDBACK_STATUS, TOAST_TYPE } from "../../utils/constants";
import { ReactComponent as CheckIcon } from "../../assets/CheckBlack.svg";
import { ReactComponent as PropertyIcon } from "../../assets/Property.svg";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import NotificationImportantIcon from "@mui/icons-material/NotificationImportant";

import { encodeJwtToken } from "../../utils/EncodeJwt";

import PopUpBox from "../../components/PopUpBox/PopUpBox";
import { Dialog, DialogTitle, DialogContent, Button, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import redirectionToFeedback from "../../OpenLayers/OLFeatures/RedirectionToFeedback";


const UploadOrderPage = ({ handleError, handleSuccess }) => {
  const token = AuthToken();
  const { id } = useParams();
  const navigate = useNavigate();
  const [files, setFiles] = useState({});
  const [jpgUrl, setjpgUrl] = useState();
  const [tiifUrl, setTiifUrl] = useState();
  const [parcelId, setParcelId] = useState();
  const [isActive, setIsActive] = useState();
  const [orderNote, setOrderNote] = useState();
  const [address, setAddress] = useState(null);
  const [resolved, setResolved] = useState(false);
  const [jpgLoaded, setJpgLoaded] = useState(true);
  const [tiifLoaded, setTiifLoaded] = useState(true);
  const [feedbackData, setFeedbackData] = useState([]);
  const [previewStatus, setPreviewStatus] = useState();
  const [promptUser, togglePromptUser] = useState(false);
  const [studioStatus, setStudioStatus] = useState(null);
  const [layerUploadData, setUploadLayerData] = useState([]);
  const [isPreviewApproved, setIsPreviewApproved] = useState();
  const [orderAttachments, setOrderAttachments] = useState([]);
  const [isAttachmentAndNotes, setIsAttachmentAndNotes] = useState(false);

  useEffect(()=>{
   
    }
  ,[orderNote,orderAttachments])

  const getLayers = () => {
    axios
      .get(`${LAYERS_DATA_URL}/${id}/feature`, {
        headers: {
          Authorization: `Token ${token}`,
          Accept: "application/json",
        },
      })
      .then((response) => {
        setUploadLayerData(response.data.data);
      })
      .catch((error) => {
        console.error(error.message);
      });
  };

  const fetchImage = () => {
    axios
      .get(`${ORDER_URL}${id}/image`, {
        headers: {
          Authorization: `Token ${token}`,
          Accept: "application/json",
        },
      })
      .then((response) => {
        const data = response.data.data;
        if (data.length) {
          data.map((item) => {
            setIsActive(item.is_active);
            if (item.url) {
              setjpgUrl(item.url);
              setJpgLoaded(true);
            }
            if (item.tiif_url) {
              setTiifUrl(item.tiif_url);
              setTiifLoaded(true);
            }
          });
        }
      })
      .catch((error) => {
        console.error(error.message);
      });
  };

  const downloadData = () => {
    axios
      .get(`${ORDER_URL}${id}/view`, {
        headers: {
          Authorization: `Token ${token}`,
          Accept: "application/json",
        },
      })
      .then((res) => {
        if (res.data.data) {
          const viewId = res.data.data[0].id;
          axios
            .get(`${ORDER_URL}${id}/view/${viewId}/layers`, {
              headers: {
                // Authorization: `Token ${token}`,
                "x-api-key":`${encodeJwtToken()}`,
                Accept: "application/json",
              },
            })
            .then((res) => {
              if (res.data.data) {
                const data = res.data.data;
                var zip = new JSZip();
                data.map((item) => {
                  zip.file(`${item.name}.json`, JSON.stringify(item.data));
                });
                zip.generateAsync({ type: "blob" }).then(function (content) {
                  saveAs(content, "geojson.zip");
                });
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAddress = () => {
    axios
      .get(`${ORDER_URL}${id}`, {
        headers: {
          Authorization: `Token ${token}`,
          Accept: "application/json",
        },
      })
      .then((response) => {
        const data = response.data.data;
        setParcelId(data.id);
        setAddress(data.address);
        setOrderNote(data.order_note);
        setStudioStatus(data.studio_status);
        setPreviewStatus(data.preview_data);
        setIsPreviewApproved(data.is_approved);
        setOrderAttachments(data.attachments);
      })
      .catch((error) => {
        console.error(error.message);
      });
  };

  useEffect(() => {
    getLayers();
    fetchImage();
    getAddress();
  }, []);

  const handleUpload = () => {
    const formData = new FormData();

    for (let key in files) {
      formData.append(`${key}`, files[key]);
    }

    axios
      .post(`${UPLOAD_LAYERS_DATA_URL}/${id}/view`, formData, {
        headers: {
          Authorization: `Token ${token}`,
          Accept: "application/json",
        },
      })
      .then((response) => {
        setIsPreviewApproved(false);
        handleSuccess(TOAST_TYPE.SUCCESS, `Layers Uploaded`);
      })
      .catch((error) => {
        handleError(error);
      });
  };


 
  const cancelPopUp =()=>{
    document.querySelector(".wrapperPopUpBox").style.display = "none";
  }
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };
  const handleDownloadIFNoteAttach = () => {
    if (isChecked) {
    axios
      .get(`${LAYERS_DATA_URL}/${id}?download=true`, {
        responseType: "arraybuffer",
        headers: {
          Authorization: `Token ${token}`,
          Accept: "application/json",
        },
      })
      .then((response) => {
        const fileName = `${parcelId}.geojson`;
        downloadBlob(response.data, fileName);
      })
      .catch((error) => {
        console.error(error.message);
      });
    }
  };
  const handleDownload = () => {
    
    if(orderNote || orderAttachments){
      document.querySelector(".wrapperPopUpBox").style.display = "block";
  }
  else{
    axios
      .get(`${LAYERS_DATA_URL}/${id}?download=true`, {
        responseType: "arraybuffer",
        headers: {
          Authorization: `Token ${token}`,
          Accept: "application/json",
        },
      })
      .then((response) => {
        const fileName = `${parcelId}.geojson`;
        downloadBlob(response.data, fileName);
      })
      .catch((error) => {
        console.error(error.message);
      });
    }
  };

  function downloadBlob(blob, filename) {
    const url = URL.createObjectURL(
      new Blob([blob], { type: "application/json" })
    );
    const a = document.createElement("a");
    a.href = url;
    a.download = filename || "download";
    a.click();
  }

  const generateJpg = () => {
    setJpgLoaded(false);
    axios
      .post(
        `${ORDER_URL}${id}/image`,
        {},
        {
          headers: {
            Authorization: `Token ${token}`,
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        setjpgUrl(response.data.url);
        setJpgLoaded(true);
        setIsActive(true);
      })
      .catch((error) => {
        console.error(error.message);
      });
  };

  const generateTiif = () => {
    setTiifLoaded(false);
    axios
      .put(
        `${ORDER_URL}${id}/image`,
        {},
        {
          headers: {
            Authorization: `Token ${token}`,
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        const data = response.data.data;
        data.map((item) => {
          setTiifUrl(item.url);
        });
        setTiifLoaded(true);
        window.location.reload();
      })
      .catch((error) => {
        console.error(error.message);
      });
  };

  const getFeedbackData = () => {
    axios({
      url: `${ADD_FEEDBACK}${id}/feedback`,
      method: "GET",
      headers: {
        Authorization: `Token ${token}`,
      },
    })
      .then((res) => {
        if ("data" in res) {
          setFeedbackData(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getFeedbackData();
  }, [resolved]);

  const resolvedFeedback = (feedbackId) => {
    let formData = new FormData();
    formData.append("status", "resolved");
    axios({
      url: `${ORDER_URL}${id}/feedback/${feedbackId}/status`,
      method: "PUT",
      data: formData,
      headers: {
        Authorization: `Token ${AuthToken()}`,
      },
    })
      .then((res) => {
        if ("data" in res) {
          setResolved(!resolved);
          handleSuccess(TOAST_TYPE.SUCCESS, `Feedback is Resolved`);
        }
      })
      .catch((err) => {
        handleError(err);
      });
  };

  const handleChangeIsActive = () => {
    axios({
      url: `${ORDER_URL}${id}/image/active`,
      method: "PUT",
      data: { is_active: !isActive },
      headers: {
        Authorization: `Token ${AuthToken()}`,
      },
    })
      .then((response) => {
        setIsActive(!isActive);
        handleSuccess(TOAST_TYPE.SUCCESS, "Image status changed successfully.");
      })
      .catch((error) => {
        console.error(error.message);
      });
  };

  const handleTogglePromptUser = () => {
    if (previewStatus) {
      if (isPreviewApproved)
        togglePromptUser(true);
      // else handleSuccess(TOAST_TYPE.WARNING, "Mark order as previewed to submit the order");
    }
    else togglePromptUser(true);
  }

  const moveToStudio = () => {
    if (!studioStatus) {
      axios
        .post(
          `${STUDIO_URL}${id}`,
          {},
          {
            headers: {
              Authorization: `Token ${token}`,
              Accept: "application/json",
            },
          }
        )
        .then((response) => {
          navigate(`/upload/studio/${id}`);
        })
        .catch((error) => {
          console.error(error.message);
        })
    } else navigate(`/upload/studio/${id}`);
  };
  const handlePreviewFeedback = ()=>{
    // redirectionToFeedback(feedbackId);
    
  }
  // for feedback attachment
 
  const [openModal, setOpenModal] = useState(false);
  const [open, setOpen] = useState(false);

  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  
  const handleClickOpen = (imageUrl) => {
    setSelectedImage(imageUrl);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedImage(null);
  };

 
  const handleModalOpen = (attachments) => {
    setSelectedImages(attachments); 
    setOpenModal(true);
  };

  const handleModalClose = () => {
    setOpenModal(false);
    setSelectedImages([]);
  };



  // for order attachment view
  // const [selectedImage, setSelectedImage] = useState(null); // For modal preview

  const extractFileName = (url) => {
    const fullFileName = url.split("/").pop();
    return fullFileName.replace(/^\d+_/, ''); 
  };

  const handlePreview = (imageUrl) => {
    setSelectedImage(imageUrl); 
  };

 
  const handleClosePreview = () => {
    setSelectedImage(null);
  };
  return (
    <div className="upload-order-page">
      <div className="upload-order-page-left">
        <div className="upload-order-page-left-one">
          <PropertyIcon className="upload-order-page-property-icon" />
          <p>{address}</p>
        </div>
        <div className="upload-order-page-left-two">
          <div className="upload-order-page-left-two-left">
            <p>
              <label htmlFor="user-notes">User Notes</label>
            </p>
            <div className="order-notes-textarea">{orderNote}</div>
          </div>
          {/* <div className="upload-order-page-left-two-right">
            <p>Download Attachments</p>
            <div className="download-attachment">
              <div>
                <span>1</span>
              </div>
              <div>
                <span>Feedback</span>
                <NotificationImportantIcon className="download-attachment-icon" />
              </div>
            </div>
          </div> */}
        </div>
        <div className="upload-order-page-left-three">
          <p>Order Attachments</p>
          <div>
            {/* {orderAttachments &&
              orderAttachments.map((attachment, index) => {
                return (
                  <div className="order-attachment-cont" key={index}>
                    <div className="order-attachment-inner-cont">
                      
                      <div>
                        <span>File {++index}</span>
                      </div>
                    </div>
                    <a style={{ textDecoration: "none" }} href={attachment}>
                      <div className="generate-button">Download</div>
                    </a>
                  </div>
                );
              })} */}
              {orderAttachments && orderAttachments.map((attachment, index) => {
  // Extract file extension and check if it's an image format
  const fileExtension = attachment.split('.').pop().toLowerCase();
  const isImage = ['jpg', 'jpeg', 'png', 'gif'].includes(fileExtension);

  return (
    <div className="order-attachment-cont" key={index}>
      <div className="order-attachment-inner-cont">
        <div>
          <span>{extractFileName(attachment)}</span>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "20%",
            gap: "0.4rem"
          }}
        >
          {/* Show Preview button only if it's an image */}
          
            <button onClick={() => handlePreview(attachment)} className="preview-buttons"
             style={{ visibility: isImage ? 'visible' : 'hidden' }}
            >
              <i className="fa fa-eye" title="Preview"></i>
            </button>
         

          <a href={attachment} download style={{ textDecoration: "none", width: "fit-content" }}>
            <i className="fa fa-download" title="Download"></i>
          </a>
        </div>
      </div>
    </div>
  );
})}


      {/* Modal for image preview */}
      {selectedImage && (
        <div className="modal">
          <div className="modal-content">
            <span className="close-button" onClick={handleClosePreview}>&times;</span>
            <img src={selectedImage} alt="Preview" className="modal-image" />
          </div>
        </div>
      )}
          </div>
        </div>
        <div className="upload-order-page-left-three">
        <p>Feedback Log</p>
        <div className="feedback-log-cont">
        {feedbackData &&
          feedbackData.map((feedback, index) => {
            if (feedback.status === FEEDBACK_STATUS.OPEN || feedback.status === FEEDBACK_STATUS.REOPEN)
              return (
                <div className="feedback-log" key={feedback.id}>
                  <div className="feedback-log-one">
                    <div>
                      <span>{++index}</span>
                    </div>
                    <div>
                      <span>{feedback.title}</span>
                    </div>

                   
                    {feedback?.attachments?.length > 0 && (
                      <div>
                        <Button variant="contained" onClick={() => handleModalOpen(feedback.attachments)} style={{background:"#b4e197",fontSize:"0.7rem",padding: "4px 15px",
                        color:"black"        }}>
                          Attachment
                        </Button>
                      </div>
                    )}
                  </div>

                  <div className="feedback-log-two">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`/preview/order/${id}?fdbkid=${feedback.id}&prevfdbk=true`}
                    >
                      <button className="order-preview-btn">
                        <VisibilityOutlinedIcon className="order-preview-icon" />
                        <span>Preview</span>
                      </button>
                    </a>

                    <button className="order-upload-btn" onClick={() => resolvedFeedback(feedback.id)}>
                      <CheckIcon className="order-upload-icon" />
                      <span>Done</span>
                    </button>
                  </div>
                </div>
              );
          })}
      </div>

      
      <Dialog open={openModal} onClose={handleModalClose} fullWidth maxWidth="md">
        <DialogTitle>
          Attachments
          <IconButton
            aria-label="close"
            onClick={handleModalClose}
            sx={{ position: 'absolute', right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
            {selectedImages.map((eachImageUrl, index) => (
              <div key={index} style={{ width: '150px', height: '150px', cursor: 'pointer' }}>
                <img
                  src={eachImageUrl}
                  alt={`attachment-${index}`}
                  style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                  
                  onClick={() => handleClickOpen(eachImageUrl)}
                />
              </div>
            ))}
          </div>
        </DialogContent>

        
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
            style={{ position: 'absolute', right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <img
            src={selectedImage}
            alt="Selected"
            style={{ width: '100%', height: 'auto' }}
          />
        </DialogContent>
      </Dialog>
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
            style={{ position: 'absolute', right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <img
            src={selectedImage}
            alt="Selected"
            style={{ width: '100%', height: 'auto' }}
          />
        </DialogContent>
      </Dialog>
      </Dialog>


        {/* <p style={{display:"flex", gap:"0.8rem" ,height:"auto",}}>
        <p onClick={()=>setFeedbackAtttachmentActive(false)}  style={{cursor:"pointer"}}>Feedback Log</p> 
        <p onClick={()=>setFeedbackAtttachmentActive(true)} style={{cursor:"pointer"}}>Feedback Attachments</p>
        </p>
       
         { !isFeedbackAtttachmentActive &&
          <div className="feedback-log-cont">
            {feedbackData &&
              feedbackData.map((feedback, index) => {
                if (
                  feedback.status === FEEDBACK_STATUS.OPEN ||
                  feedback.status === FEEDBACK_STATUS.REOPEN
                )
                  return (
                    <div className="feedback-log" key={feedback.id}>
                      <div className="feedback-log-one">
                        <div>
                          <span>{++index}</span>
                        </div>
                        <div>
                          <span>{feedback.title}</span>
                        </div>
                        
                        { feedback?.attachments?.length>0 && feedback?.attachments.map((eachImageUrl,index)=>(
                          <div style={{width:"2rem", height:"2rem"}}>
                            <img  style={{maxWidth:"100%",}}
                            src={eachImageUrl} />
                          </div>
                        ))}
                      </div>
                      <div className="feedback-log-two">
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={`/order/${id}?fdbkid=${feedback.id}&prevfdbk=true`}
                        >
                          <button className="order-preview-btn">
                            <VisibilityOutlinedIcon className="order-preview-icon" />
                            <span>Preview</span>
                          </button>
                        </a>

                        <button
                          className="order-upload-btn"
                          onClick={() => resolvedFeedback(feedback.id)}
                        >
                          <CheckIcon className="order-upload-icon" />
                          <span>Done</span>
                        </button>
                      </div>
                    </div>
                  );
              })}
          </div>} */}
         {/* { isFeedbackAtttachmentActive &&
          <div className="feedback-log-cont">

            {feedbackData &&
              feedbackData.map((feedback, index) => {
               
                  return (
                    <div className="feedback-log" key={feedback.id}>
                      <div className="feedback-log-one">
                        <div>
                          <span>{++index}</span>
                        </div>
                        <div>
                          <span>{feedback.title}</span>
                        </div>
                      </div>
                      <div className="feedback-log-two">
                        { feedback?.attachments?.length>0 && feedback?.attachments.map((eachImageUrl,index)=>(
                          <div style={{width:"2rem", height:"2rem"}}>
                            <img  style={{maxWidth:"100%",}}
                            src={eachImageUrl} />
                          </div>
                        ))}
                      </div>
                    </div>
                  );
              })}
          </div>} */}
        </div>
        <div className="upload-order-page-left-five">
          <div className="jpg-main-container">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div>Jpg Download</div>
              <FormGroup>
                <FormControlLabel
                  label="HD Image"
                  control={
                    <Switch
                      label="HD Image"
                      checked={isActive}
                      onChange={handleChangeIsActive}
                      color="success"
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                />
              </FormGroup>
            </div>
            <div className="jpg-container">
              <div className="jpg-link">{jpgUrl}</div>
              {jpgUrl && jpgLoaded ? (
                <a href={jpgUrl}>
                  <div className="generate-button">Download</div>
                </a>
              ) : jpgLoaded ? (
                <div onClick={generateJpg} className="generate-button">
                  Generate
                </div>
              ) : (
                <div className="generate-button loader">Loading...</div>
              )}
            </div>
          </div>
          <div className="tiif-main-container">
            <div>Tiif Download</div>
            <div className="tiif-container">
              <div className="tiif-link">{tiifUrl}</div>
              {tiifUrl && tiifLoaded ? (
                <a href={tiifUrl}>
                  <div className="generate-button">Download</div>
                </a>
              ) : tiifLoaded ? (
                <div
                  onClick={jpgUrl && generateTiif}
                  className={
                    jpgUrl ? "generate-button" : "generate-button loader"
                  }
                >
                  Generate
                </div>
              ) : (
                <div className="generate-button loader">Loading...</div>
              )}
            </div>
          </div>
        </div>
        <div className="upload-order-page-left-four">
          {/* <div className="upload-order-page-left-four-left">
            <p>Carto Logs</p>
            <div>
              <textarea />
            </div>
          </div> */}
          <div className="upload-order-page-left-four-right">
            {/* <div>
              <button className="carto-preview-btn">
                <VisibilityOutlinedIcon className="carto-preview-icon" />
                <span>Preview</span>
              </button>
            </div>
            <hr /> */}
            {/* <div> */}
            {/* <button className="carto-download-btn">
                <DownloadIcon className="carto-download-icon" />
                <span>Download Image</span>
              </button> */}
            <button onClick={handleDownload} className="carto-download-btn">
              <DownloadIcon className="carto-download-icon" />
              <span>Download Parcel</span>
            </button>
            <button
              disabled={!jpgUrl}
              onClick={moveToStudio}
              className={!jpgUrl ? 'studio-btn-disabled carto-download-btn' : 'carto-download-btn'}
              style={{ background: "#519259", color: "#ffffff" }}
            >Move to Studio
            </button>
            {/* </div> */}
          </div>
        </div>
      </div>
      <div className="upload-order-page-right">
        <div className="upload-order-page-right-one">
          <p>Upload Layers</p>
          <div className="upload-layers">
            <div className="upload-layers-one">
              {layerUploadData &&
                layerUploadData.map((feature) => {
                  return (
                    <div className="upload-layers-one-outter" key={feature.id}>
                      <label htmlFor={feature.id}>
                        <AddIcon className="upload-layers-add-icon" />
                        <span>{feature.name}</span>
                      </label>
                      <input
                        required
                        type="file"
                        id={feature.id}
                        onChange={(e) =>
                          setFiles({
                            ...files,
                            [feature.id]: e.target.files[0],
                          })
                        }
                      />
                    </div>
                  );
                })}
            </div>
            <div className="upload-layers-two">
              <div className="upload-layers-two-left">
                <span>{layerUploadData.length} Features</span>
              </div>
              <div className="upload-layers-two-right">
                <button
                  onClick={downloadData}
                  className="upload-layers-download-btn"
                >
                  <DownloadIcon className="upload-layers-download-icon" />
                  <span>Download Data</span>
                </button>
                <button
                  onClick={handleUpload}
                  className="upload-layers-upload-btn"
                >
                  <PublishIcon className="upload-layers-download-icon" />
                  <span>Upload Layers</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="upload-order-page-right-two">
          <p>New Carto Logs</p>
          <div className="new-cacrto-log-one">
            <textarea />
            <button>
              <AddIcon className="upload-layers-add-icon" />
              <span>New Note</span>
            </button>
          </div>
          <div className="new-cacrto-log-two">
            <button className="new-cacrto-log-preview-btn">
              <a href={`/preview/order/${id}`} target="_blank">
                <VisibilityOutlinedIcon className="new-cacrto-log-preview-icon" />
                <span>Preview</span>
              </a>
            </button>
            <Tooltip title={!isPreviewApproved && previewStatus ? "Mark order as previewed to submit" : ""}>
              <button
                onClick={handleTogglePromptUser}
                className="new-cacrto-log-submit-btn"
                style={!isPreviewApproved && previewStatus ? { opacity: "0.5" } : { opacity: "1" }}
              >
                <CheckIcon className="new-cacrto-log-submit-icon" />
                <span>Submit Order</span>
              </button>
            </Tooltip>
          </div>
        </div>
      </div>
      <div className="user-prompt-container">
        {promptUser ? (
          <UserPrompt
            togglePromptUser={togglePromptUser}
            handleSuccess={handleSuccess}
            handleError={handleError}
          />
        ) : null}
      </div>

      {/* Modal Open for the if property has notes and attachments show a popup before downloading parcel */}
      <div className="wrapperPopUpBox">
      <div className="MainPopUpBox">
        <div className="CloseIconPopUpBox">
          <span
            className=""
            onClick={() => {
              document.querySelector(".wrapperPopUpBox").style.display = "none";
            }}
          >
            <box-icon name="x"></box-icon>
          </span>
        </div>

        <div className="Remove_margins_of_p">
          
          {isChecked ? <p>Download parcel</p> : <p>Please Acknowledge</p>}

          <div style={{fontSize:'0.8rem',margin:"0.4rem 0 0",display:'flex',alignItems:'center',}}>
            <input
              type="checkbox"
              id="acknowledgeCheckbox"
              onChange={handleCheckboxChange}
            />
            <label htmlFor="acknowledgeCheckbox"   > I have read and reviewed the notes and attachments</label>
          </div>
        </div>

        <div className="view-modal-bttn" id="view__model__BTNS">
          <button className="cancel" onClick={cancelPopUp}>
            Cancel
          </button>
          <button
            disabled={!isChecked}
            className={`delete ${isChecked ? "" : "studio-btn-disabled"}`}
            id="DeleteBtn"
            onClick={handleDownloadIFNoteAttach}
            style={{
              backgroundColor: isChecked ? "#519259" : "gray",
              cursor: isChecked ? "pointer" : "not-allowed",
            }}
          >
            Download
          </button>
        </div>

        {isChecked ? null : (
          <p style={{ color: "red", marginTop: "10px", textAlign: "center" ,fontSize:"0.6rem",letterSpacing:'1px',lineHeight:'1.2'}}>
            Please acknowledge that you have read and reviewed <br />the notes and attachments to enable the download.
          </p>
        )}
      </div>
    </div>
      {/* Modal Open for the if property has notes and attachments show a popup before downloading parcel */}
    </div>
  );
};

export default UploadOrderPage;
