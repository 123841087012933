import './ParcelAccordian.css';
import Tooltip from '@mui/material/Tooltip';
import { useLocation } from 'react-router-dom';
import { CONVERT } from '../../utils/constants';
import React, { useState, useEffect } from 'react';
import FunctionsIcon from '@mui/icons-material/Functions';
import { useLayerContext } from '../../context/LayerContext';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Circle as CircleStyle, Fill, Stroke, Style } from 'ol/style';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

export default function ParcelAccordian({
  path,
  setPath,
  result,
  number,
  selected,
  orderActive,
  setSelected,
  measurementUnit,
}) {
  const location = useLocation();
  const { olMap } = useLayerContext();
  const [measure, setMeasure] = useState(true);
  const [totalMeasure, setTotalMeasure] = useState(0);
  const pathDecider = location.pathname.substring(0, 12);
  const [layerVisible, setLayerVisible] = useState(true);

  useEffect(() => {
    setTotalMeasure(result);
  }, [result]);

  useEffect(() => {
    var res = 0;
    path.map((item) => {
      if (item.visible) res = parseFloat(res) + parseFloat(item.measurement);
    });
    setTotalMeasure(res);
  }, [measure]);

  const handleAccordianToggle = (num) => {
    if (selected === num) {
      return setSelected(null);
    }
    setSelected(num);
  };

  const toggleParcelFeatures = () => {
    if (olMap) {
      olMap.getLayers().forEach(function (layer) {
        if (layer && layer.get('title') === 'Parcel') {
          layer.getLayers().forEach((item) => {
            item.getSource().forEachFeature((feature) => {
              if (layerVisible)
                var hoverStyleOne = new Style({
                  stroke: new Stroke({
                    color: 'transparent',
                  }),
                });
              else
                var hoverStyleOne = new Style({
                  stroke: new Stroke({
                    color: 'red',
                    width: 2,
                  }),
                });
              feature.set('visible', !layerVisible);
              feature.setStyle(hoverStyleOne);
            });
          });
        }
      });
    }
  };

  useEffect(() => {
    toggleParcelFeatures();
    setLayerVisible(orderActive);
    setMeasure(orderActive);
  }, [orderActive]);

  const handleLayerVisibility = (e) => {
    toggleParcelFeatures();
    setLayerVisible(!layerVisible);
    setMeasure(!measure);
  };

  const handleFeatureVisibility = (e) => {
    if (olMap) {
      let bool = true;
      olMap.getLayers().forEach(function (layer) {
        if (layer && layer.get('title') === 'Parcel') {
          layer.getLayers().forEach((item) => {
            item.getSource().forEachFeature((feature) => {
              if (feature.getId() == e.target.id.split('__')[1]) {
                if (feature.get('visible'))
                  var hoverStyleOne = new Style({
                    stroke: new Stroke({
                      color: 'transparent',
                    }),
                  });
                else
                  var hoverStyleOne = new Style({
                    stroke: new Stroke({
                      color: 'red',
                      width: 2,
                    }),
                  });
                feature.set('visible', !feature.get('visible'));
                feature.setStyle(hoverStyleOne);
              }
            });
          });
        }
      });
      olMap.getLayers().forEach(function (layer) {
        if (layer && layer.get('title') === 'Parcel') {
          layer.getLayers().forEach((item) => {
            item.getSource().forEachFeature((feature) => {
              if (feature.get('visible')) {
                setLayerVisible(true);
                bool = false;
                return;
              }
            });
          });
        }
      });
      if (bool) setLayerVisible(false);
    }
    setMeasure(!measure);
  };

  return (
    <div className="feature-cont">
      <div className="feature-cont-outter">
        <div
          className={
            pathDecider === '/share/order'
              ? 'feature-cont-share-outter-one'
              : 'feature-cont-outter-one'
          }
        >
          <div
            className="change-color hideOnScreenshot"
            style={{ backgroundColor: 'red' }}
          ></div>
          <span  className='hideOnScreenshot'></span>
          <input
            id="parcel"
            type="checkbox"
            checked={layerVisible}
            onChange={handleLayerVisibility}
            className="featureLayerToggle hideOnScreenshot"
          />
          <span>Parcel</span>
          <Tooltip
            placement="right"
            title={
              totalMeasure
                ? measurementUnit === 'sqft'
                  ? (totalMeasure * CONVERT.acre_sqft).toFixed(2)
                  : Number(totalMeasure)?.toFixed(2)
                : '____'
            }
          >
            <span
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'end',
              }}
            >
              <span
                style={
                  measurementUnit === 'acres'
                    ? { width: '4em', textAlign: 'right', marginRight: '5px' }
                    : { width: '5em', textAlign: 'right', marginRight: '5px' }
                }
                className="measurement-val"
              >
                {totalMeasure
                  ? measurementUnit === 'sqft'
                    ? Math.floor(totalMeasure * CONVERT.acre_sqft)
                    : Number(totalMeasure)?.toFixed(2)
                  : '____'}
              </span>
              <span>{measurementUnit === 'acres' ? ' acres' : ' sqft'}</span>
            </span>
          </Tooltip>
          {/* <div className="expand-layer-container"> */}
          {/* {selected === number ? (
              <KeyboardArrowUpIcon
                className="arrow"
                onClick={() => {
                  handleAccordianToggle(number);
                }}
              />
            ) : (
              <KeyboardArrowDownIcon
                className="arrow"
                onClick={() => {
                  handleAccordianToggle(number);
                }}
              />
            )} */}
          {/* </div> */}
        </div>
      </div>
      <div
        className={`feature-panel ${selected === number ? 'accordian-active' : ''
          }`}
      >
        <div className="feature-panel-heading">
          <div>ID</div>
          <div>Measurement</div>
        </div>
        <ul>
          {path.map((item, index) => {
            {/* console.log('Parcel', Number(item?.measurement).toFixed(2)); */ }
            return (
              <li key={index}>
                <div className="order-layer-feature-ids">
                  <div className="acc-inner-meas-one">
                    <input
                      type="checkbox"
                      checked={item.visible}
                      id={`parcel__${item.id}`}
                      onChange={handleFeatureVisibility}
                    />
                    <span>ID {item.id}</span>
                  </div>
                  <div className="acc-inner-meas-two">
                    <span>
                      {item.measurement
                        ? measurementUnit === 'sqft'
                          ? Math.floor(
                            item.measurement * CONVERT.acre_sqft
                          ).toFixed(2)
                          : item?.measurement
                        : '____'}
                    </span>
                    <span>
                      {measurementUnit === 'acres' ? ' acres' : ' sqft'}
                    </span>
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
        <div className="sum-container">
          <div>
            <FunctionsIcon /> Sum*{' '}
          </div>
          <span>
            {' '}
            {totalMeasure
              ? measurementUnit === 'sqft'
                ? Math.floor(totalMeasure * CONVERT.acre_sqft)
                : Math.floor(totalMeasure)
              : '____'}
          </span>
          <span>{measurementUnit === 'acres' ? ' acres' : ' sqft'}</span>
        </div>
        <div className="footer-note">{`*Sum of all parcels`}</div>
      </div>
    </div>
  );
}
