import axios from "axios";
import "./ProjectIdPage.css";
import Papa from "papaparse";
import Tooltip from "@mui/material/Tooltip";
import { useParams } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import AuthToken from "../../context/AuthToken";
import { MenuItem, Select } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import { MOLE_ORDER_URL } from "../../helper/ApiUrl";
import { useLoader } from "../../context/LoaderProvider";
import React, { useState, useEffect, useRef } from "react";
import ProjectIdDashboardPage from "./ProjectIdDashboardPage";
import { TOAST_TYPE, ORDER_STATUS } from "../../utils/constants";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Drawer, Box, LinearProgress, Typography } from "@mui/material";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import CloudUploadIcon from "../../assets/bxs-cloud-upload.svg";
import ReportIcon from '@mui/icons-material/Report';
import InfoIcon from "@mui/icons-material/Info";

const ProjectIdPage = ({ handleSuccess, handleError }) => {
  const token = AuthToken();
  const innerRef = useRef();
  const outerRef = useRef();
  
  const areaCalculationCancelledRef  = useRef(false)
  const { id } = useParams();
  const deleteOrderRef = useRef();
  const innerMappingRef = useRef();
  const outerMappingRef = useRef();
  const deleteOrderInnerRef = useRef();
  const [status, setStatus] = useState();
  // const [status, setStatus] = useState([]);
  const [open, setOpen] = useState(false);
  const [action, setAction] = useState("");
  const [actionData, setActionData] = useState("");
  const [progress, setProgress] = useState();
  const [fileData, setFileData] = useState(null);
  const [orderList, setOrderList] = useState([]);
  const [isUpload, setIsUpload] = useState(false);
  const { startLoader, stopLoader } = useLoader();
  const [selectedIds, setSelectedIds] = useState([]);
  const [processedData, setProcessedData] = useState([]);
  const [ProcessedDatabefore, setProcessedDataBefore] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [isDataChanged, setIsDataChanged] = useState(false);
  const [processOrdersIds, setProcessOrdersIds] = useState([]);
  const [selfReviewOrder, setSelfRevieworder] = useState(false);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [selfReviewButton, setSelfReviewButton] = useState(false);
  const cancelCalculatingArea = useRef(false)
  const [csvFileName, setCsvFileName] = useState(null);
  const [effectHasRun, setEffectHasRun] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [mapping, setMapping] = useState({});
  const [userHeaders, setUserHeaders] = useState(null);
  const [systemHeaders, setSystemHeaders] = useState([
    "Site",
    "Street Address",
    "City",
    "State",
    "Zip Code",
  ]);
  const [dropdownValues, setDropdownValues] = useState([]);
  const [dropdownEnable, setDropdownEnable] = useState(null);

  const dashboard = searchParams.get("dashboard") || true;
  const [dashboardStatus, setDashboardStatus] = useState(JSON.parse(dashboard));

  const getProjectList = () => {
    axios({
      url: `${MOLE_ORDER_URL}/${id}`,
      method: "GET",
      headers: {
        Authorization: `Token ${AuthToken()}`,
      },
    })
      .then((res) => {
        let dataOrder = res?.data?.data;
        setStatus(dataOrder.status);
        let data = dataOrder?.orders;
        // startLoader();
        if (data)
          setOrderList(data.map((item) => ({ ...item, checked: false })));
        // stopLoader();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const uploadProjectList = (file) => {
    const formData = new FormData();
    formData.append("bo_file", file);
    axios
      .post(`${MOLE_ORDER_URL}/${id}/upload`, formData, {
        headers: {
          Authorization: `Token ${AuthToken()}`,
          Accept: "application/json",
        },
      })
      .then((res) => {
        if (res.status === 200 || 201) {
          setFileData("");
          setIsUpload(!isUpload);
          handleSuccess(TOAST_TYPE.SUCCESS, `File Uploaded successfully!`);
        }
      })
      .catch((error) => {
        handleError(error);
      });
  };

  // for tracking with count =0 on page refresh .

  
  let count = false ;
  useEffect(()=>{ console.log(ProcessedDatabefore,"ProcessedDatabefore") },[ProcessedDatabefore]) 
  const handleAllCheckboxChange = (e , stat) => {
    const { checked } = e.target;
    count = true; //HA
    console.log(checked,"checked")
    console.log(selectAllChecked,"selectAllChecked before")
    if (checked) {
      setProcessOrdersIds(
        orderList
          .filter((item) => item.status === ORDER_STATUS.INITIATE)
          .map((item) => item.id)
      );
      let selectedIds = [];
      orderList.map((item) => selectedIds.push(item.id));
      setSelectedIds([...selectedIds]);
      setStatus([...status, stat]);
      // let statusArray = []
      // orderList.map((item) => statusArray.push(item.status));
      // setStatus([...statusArray]);
    } else {
      setProcessOrdersIds([]);
      setSelectedIds([]);
      // setStatus([]);
    }
    setOrderList(
      orderList.map((item) => {
        return { ...item, checked: !selectAllChecked };
      })
    );

  //  if(count)
    setSelectAllChecked(!selectAllChecked);

  //  count = false

    console.log(selectAllChecked,"selectAllChecked Aftre")
  };

  const handleSingleCheckboxChange = (e, id, stat) => {
    const { checked } = e.target;
    console.log(checked,"checked single")
    if (checked && !processOrdersIds.includes(id)) {
      console.log("this above code if  ")
      if (
        (orderList.find((order) => order.id === id) || {}).status ===
        ORDER_STATUS.INITIATE
      )
        setProcessOrdersIds([...processOrdersIds, id]);
      setSelectedIds([...selectedIds, id]);
      setStatus([...status, stat]);
    }
    // if(selectAllChecked)
    if (!checked) {
      console.log("this if block is executing ")
      
      if (processOrdersIds.includes(id))
        console.log( processOrdersIds.includes(id),"  processOrdersIds.includes(id), this if block is executing ")
        console.log( processOrdersIds," processOrdersIds, this if block is executing ")
        setProcessOrdersIds(processOrdersIds.filter((item) => item !== id));
      setSelectedIds(selectedIds.filter((item) => item !== id));
      console.log( status," status , this if block is executing ")
      // setStatus([...status, stat]); //HA
      setStatus(status.filter((item) => item !== stat));
    }

    setOrderList(
      orderList.map((item) => {
        if (item.id === id) return { ...item, checked: !item.checked };
        return item;
      })
    );
  };

  const deleteBulkDraftOrder = () => {
    let deleteIds = "";
    selectedIds.forEach((id) => (deleteIds += id + "%2C"));
    deleteIds = deleteIds.slice(0, deleteIds.length - 3);
    axios
      .delete(`${MOLE_ORDER_URL}/${id}?order_ids=${deleteIds}`, {
        headers: {
          Authorization: `Token ${token}`,
          Accept: "application/json",
        },
      })
      .then((response) => {
        getProjectList();
        setSelectedIds([]);
        handleSuccess(TOAST_TYPE.SUCCESS, "Orders Deleted Successfully!");
      })
      .catch((error) => {
        console.error(error.message);
      });
  };

  const LinearProgressWithLabel = (props) => {
    return (
      <Box sx={{ display: "flex", alignItems: "center", width: "70%" }}>
        <Box sx={{ width: "100%", mr: 1 }}>
          <LinearProgress color="inherit" variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2">{`${Math.round(
            props.value
          )}%`}</Typography>
        </Box>
      </Box>
    );
  };

  const processOrders = async (orderId) => {
    try {
      const response = await axios({
        url: `${MOLE_ORDER_URL}/${id}/process/${orderId}`,
        method: "PATCH",
        headers: {
          Authorization: `Token ${AuthToken()}`,
          Accept: "application/json",
        },
      });
      if (response.data) return response.data;
    } catch (error) {
      handleError(error);
      throw error;
    }
  };

  const handleProcess = async () => {
    document.querySelector(".orders-dashboard-top-tollbar").style.display="none"
   
    setOpen(true);
    setProgress(0);
    setIsDataChanged(true);
    areaCalculationCancelledRef.current = false

    for (const orderId of processOrdersIds) {

      // if the processing is cancelled then break the loop and save the orders with the calculated area
      if (areaCalculationCancelledRef.current) {
        setOpen(false);
        setProgress(0);
        setIsDataChanged(true);
        break;
      }
      const data = await processOrders(orderId);

      if (data) {
        const updatedValues = {
          id: orderId,
          latitude: data.lat,
          longitude: data.long,
          parcel_area: data.parcel_area,
          status: ORDER_STATUS.PROCESSED,
          checked: true,
        };
        // loop to the updatedValues and filter those order who are slected only 
        
        // setProcessedData((prev) => {
        //   // Filter out any existing data for this orderId before adding the new data
        //   const newData = prev.filter(item => item.id !== orderId);
        //   return [...newData, updatedValues];
        // }); 
          //  setProcessedDataBefore((prev)=>[...prev,updatedValues])
          //  const filteredData = ProcessedDatabefore.filter(obj => obj.id>1 );

         setProcessedData((prev) => [...prev, updatedValues]);
         setProgress((prevProgress) => prevProgress + 1);
   
      }
    }

    document.querySelector(".orders-dashboard-top-tollbar").style.display="block"
  }
  
  const handleClose = (event, reason) => {
    if (reason === "backdropClick") return;
    handleCancelProcess();
  };

  const handleCancelProcess = () => {
    setOpen(false);
    setIsDataChanged(false);
    setProcessOrdersIds([]);
  };

  const handleMoveToDraft = async () => {
    setStatus(ORDER_STATUS.DRAFT);
    try {
      const response = await axios({
        url: `${MOLE_ORDER_URL}/${id}/place`,
        method: "POST",
        headers: {
          Authorization: `Token ${AuthToken()}`,
          Accept: "application/json",
        },
        data:{
          review_type: actionData
        }
      });
    } catch (error) {
      handleError(error);
      throw error;
    }
  };

  useEffect(() => {
    getProjectList();
  }, [isUpload]);

  // useEffect(() => {
  //   if (fileData) uploadProjectList();
  // }, [fileData]);

  useEffect(() => {
    if (progress === processOrdersIds.length) {
      handleCancelProcess();
      setSelectAllChecked(false);
      handleSuccess(TOAST_TYPE.SUCCESS, `Orders processed successfully!`);
    }
  }, [progress]);

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (isDataChanged)
        e.returnValue =
          "Orders processing is in progress. Do you really want to refresh the page?";
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isDataChanged]);

  useEffect(() => {
    if (orderList.length) {
      const updatedSelectAllChecked = orderList.every((item) => item.checked);
      setSelectAllChecked(updatedSelectAllChecked);
      setSelfReviewButton(
        orderList.some(function (obj) {
          return obj.status === ORDER_STATUS.PROCESSED;
        })
      );
    }
  }, [orderList]);

  useEffect(() => {
    if (processedData.length)
      setOrderList(
        orderList.map((item) => {
          const matchingObj = processedData.find(
            (firstObj) => firstObj.id === item.id
          );
          if (matchingObj) return { ...item, ...matchingObj };
          else return item;
        })
      );
      
  }, [processedData]);

  useEffect(() => {
   console.log(orderList,"orderList")
      
  }, [orderList]);

  // useEffect(() => {
  //   if (orderList.length < 1) {
  //     startLoader();
  //   } else {
  //     stopLoader();
  //   }
  // }, [orderList]);

  // const handleDownload = () => {
  //   // Define the path to your CSV file
  //   const filePath = "/ottermap-project.csv";

  //   // Create a link element
  //   const link = document.createElement("a");

  //   // Set the link's href to the file path
  //   link.href = process.env.PUBLIC_URL + filePath;

  //   // Set the download attribute to specify the file name
  //   link.download = "ottermap-project.csv";

  //   // Append the link to the body
  //   document.body.appendChild(link);

  //   // Trigger a click on the link
  //   link.click();

  //   // Remove the link from the body
  //   document.body.removeChild(link);
  //   handleSuccess(TOAST_TYPE.SUCCESS, `Download Sample Successfully!`);
  // };

 
// test Ask User where to download-----------HA
  const handleDownload = async () => {
    try {
      // Define the path to your CSV file
      const filePath = process.env.PUBLIC_URL + "/ottermap-project.csv";
  
      // Fetch the content of the CSV file
      const response = await fetch(filePath);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const fileContent = await response.text();
  
      await saveFile('ottermap-project.csv', fileContent);
  
      // Show success message after the file is saved
      handleSuccess(TOAST_TYPE.SUCCESS, `Sample Downloaded Successfully!`);
    } catch (e) {
      if (e.name === 'AbortError') {
        // User canceled the file save dialog
        handleSuccess(TOAST_TYPE.INFO, `Download canceled by the user.`);
      } else {
        console.error('Error:', e);
        handleSuccess(TOAST_TYPE.ERROR, `Download Failed!`);
      }
    }
  };
  
  async function saveFile(fileName, fileContent) {
    try {
      
      const options = {
        suggestedName: fileName,
        types: [
          {
            description: 'CSV Files',
            accept: { 'text/csv': ['.csv'] },
          },
        ],
      };
      const handle = await window.showSaveFilePicker(options);
   const writableStream = await handle.createWritable();
     await writableStream.write(fileContent);
   await writableStream.close();
    } catch (e) {
      throw e;
    }
  }
  
  

  

 

  const handleClickOutSide = (e) => {
    if (innerRef.current) {
      if (!innerRef.current.contains(e.target)) {
        outerRef.current.style.opacity = 0;
        outerRef.current.style.pointerEvents = "none";
      }
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutSide, true);
    startLoader();
    setTimeout(() => {
      stopLoader();
    }, 1000);
  }, []);

  useEffect(() => {
    searchParams.set("dashboard", dashboardStatus);
    setSearchParams(searchParams);
  }, [dashboard, dashboardStatus]);

  const handleAction = () => {
    // if (action === 'Review & Submit') {
    //   handleMoveToDraft();
    // } else
    if (action === "Self Review & Submit") {
      handleMoveToDraft();
      setDashboardStatus(true);
    } else if (action === "Submit for Ottermap Review") {
      handleMoveToDraft();
      setDashboardStatus(true);
    }
    setSelectedIds([]);
  };

  useEffect(() => {
    if (status && status === ORDER_STATUS.DRAFT && !dashboardStatus)
      setSelfRevieworder(true);
  }, [status, dashboardStatus]);

  const handleFileChange = (e) => {

    // setting the processOrdersIds state empy if the user uploads a new file
    setProcessOrdersIds([])

    const file = e.target.files[0];
    if (file) {
      setCsvFileName(file.name);
      Papa.parse(file, {
        //Handling empty headers
        transformHeader: (header, index) => {
          if (header.trim() === "") return `Header ${index + 1}`;
          return header.trim();
        },

        complete: (result) => {
          const headers = result.meta.fields;
          if (headers.length < systemHeaders.length) {
            handleClearStates();
            handleSuccess(
              TOAST_TYPE.WARNING,
              "CSV file must have at least 5 fields with Site, Street Address, City, State and Zip Code mandatory "
            );
            return;
          }
          setUserHeaders(headers);

          //Removing empty rows
          const newArray = result.data.filter((obj) => {
            return Object.values(obj).some(
              (val) => val !== "" && val !== null && val !== undefined
            );
          });
          setFileData(newArray);

          outerMappingRef.current.style.display = "block";
        },
        header: true,
      });
    }
    e.target.value = null;
  };

  useEffect(() => {
    if (userHeaders && userHeaders.length) {
      const initialValues = Array(userHeaders.length).fill("Select");
      setDropdownValues(initialValues);
      const initialState = Array(userHeaders.length).fill(false);
      if (initialState.length > 0) initialState[0] = true;
      setDropdownEnable(initialState);
    }
  }, [userHeaders]);

  const handleMappingChange = (userHeader, systemHeader, index) => {
    const alreadyExist = Object.keys(mapping).find(
      (key) => mapping[key] === userHeader
    );
    const newMapping = { ...mapping, [systemHeader]: userHeader };
    if (alreadyExist !== undefined) delete newMapping[alreadyExist];
    setMapping(newMapping);

    const initialValues = [...dropdownValues];
    initialValues[index] = systemHeader;
    setDropdownValues(initialValues);
    if (index < dropdownEnable.length - 1) {
      const newArray = [...dropdownEnable];
      newArray[index + 1] = true;
      setDropdownEnable(newArray);
    }
  };

  function swapKeysAndValues(obj) {
    const swappedObj = {};
    for (const key in obj) {
      swappedObj[obj[key]] = key;
    }
    return swappedObj;
  }

  function replaceKeys(obj, mapping) {
    const newObj = {};
    for (const key in obj) {
      if (key in mapping) newObj[mapping[key]] = obj[key];
      else newObj[key] = obj[key];
    }
    return newObj;
  }

  function checkRequiredHeaders(mapping) {
    const requiredKeys = [
      "Site",
      "Street Address",
      "City",
      "State",
      "Zip Code",
    ];
    const missingKeys = requiredKeys.filter((key) => !(key in mapping));
    return missingKeys.length === 0 ? false : missingKeys;
  }

  const handleCsvProceed = () => {
    let requiredKeysNotFound = checkRequiredHeaders(mapping);
    const headersWithEmptyValuesInColumn = [];
    // const newArr = fileData.map((obj) =>
    //   replaceKeys(obj, swapKeysAndValues(mapping))
    // );

    const cleanData = fileData.map((obj) => {
      const cleanedObj = {};
      Object.keys(obj).forEach((key) => {
        cleanedObj[key.trim()] = obj[key].trim(); // Trim both key and value
      });
      return cleanedObj;
    });
  
    const newArr = cleanData.map((obj) =>
      replaceKeys(obj, swapKeysAndValues(mapping))
    );
    //Checking empty values in Required headers column
    newArr.forEach((row) => {
      Object.entries(row).forEach(([header, value]) => {
        if (
          value.trim() === "" &&
          !headersWithEmptyValuesInColumn.includes(header) &&
          systemHeaders.includes(header)
        )
          headersWithEmptyValuesInColumn.push(header);
      });
    });

    if (requiredKeysNotFound) {
      setErrorMsg(
        requiredKeysNotFound.length === 1
          ? `${requiredKeysNotFound[0]} field is required`
          : `${requiredKeysNotFound
              .slice(0, -1)
              .join(", ")} and ${requiredKeysNotFound.slice(
              -1
            )} fields are required`
      );
      return;
    } else if (headersWithEmptyValuesInColumn.length > 0) {
      handleClearStates();
      outerMappingRef.current.style.display = "none";
      handleSuccess(
        TOAST_TYPE.WARNING,
        headersWithEmptyValuesInColumn.length === 1
          ? `Header ${headersWithEmptyValuesInColumn[0]} cannot have empty fields`
          : `Headers ${headersWithEmptyValuesInColumn
              .slice(0, -1)
              .join(", ")} and ${headersWithEmptyValuesInColumn.slice(
              -1
            )} cannot have empty fields`
      );
    } else {
      var csv = Papa.unparse(newArr);
      const blob = new Blob([csv], { type: "text/csv" });
      const fileName = csvFileName;
      const newFile = new File([blob], fileName);
      uploadProjectList(newFile);
      // const link = document.createElement('a');
      // link.href = window.URL.createObjectURL(blob);
      // link.download = fileName;
      // document.body.appendChild(link);
      // link.click();
      // document.body.removeChild(link);
      outerMappingRef.current.style.display = "none";
      handleClearStates();
    }
  };

  const handleClearDropdown = (userHeader) => {
    const alreadyExist = Object.keys(mapping).find(
      (key) => mapping[key] === userHeader
    );
    if (alreadyExist !== undefined) {
      const newMapping = { ...mapping };
      delete newMapping[alreadyExist];
      setMapping(newMapping);
      const updatedArray = dropdownValues.map((item) =>
        item === alreadyExist ? "Select" : item
      );
      setDropdownValues(updatedArray);
    }
  };

  useEffect(() => {
    if (
      userHeaders &&
      systemHeaders &&
      userHeaders.length &&
      systemHeaders.length &&
      !effectHasRun
    ) {
      let newMapping = { ...mapping };
      const initialSelectedOptions = userHeaders.map((userHeader) => {
        let uHeader = userHeader.split(" ").join("").toLowerCase();
        if (uHeader === "siteid") uHeader = "site";
        if (uHeader === "address") uHeader = "streetaddress";
        if (
          uHeader === "pincode" ||
          uHeader === "postalcode" ||
          uHeader === "pin" ||
          uHeader === "zip"
        )
          uHeader = "zipcode";
        const matchingSystemHeader = systemHeaders.find((systemHeader) => {
          let sHeader = systemHeader.split(" ").join("").toLowerCase();
          if (sHeader === uHeader) {
            newMapping[systemHeader] = userHeader;
            return true;
          } else return false;
        });
        return matchingSystemHeader ? matchingSystemHeader : "Select";
      });
      setMapping(newMapping);
      setDropdownValues(initialSelectedOptions);
      setEffectHasRun(true);
    }
  }, [userHeaders, systemHeaders]);

  const handleClearStates = () => {
    setMapping({});
    setErrorMsg(null);
    setUserHeaders(null);
    setDropdownValues([]);
    setDropdownEnable(null);
    setEffectHasRun(false);
  };


  // if order status is draft then show the projects page
  if (status && status === ORDER_STATUS.DRAFT && dashboardStatus)
    return (
      <ProjectIdDashboardPage
        bulkId={id}
        handleError={handleError}
        handleSuccess={handleSuccess}
        getProjectList={getProjectList}
        setDashboardStatus={setDashboardStatus}
      />
    );
    
  else
    return (
      <div className="my-projects-container">

        {/* top bar for deletion and selection  */}
        <div
          className="orders-dashboard-top-tollbar"
          style={ selectedIds?.length > 0 ? { display: "block" } : { display: "none" }}
        >
          <div className="dashboard-top-tollbar-contant-container">
            <div className="property-selection-count">
              Selected : {selectedIds?.length}/{orderList?.length}
            </div>
            <div
              className="tollbar-dismiss-container"
              onClick={() => {
                setOrderList( orderList.map((item) => { return { ...item, checked: false } }) );
                setSelectAllChecked(false);
                setSelectedIds([]);
                setProcessOrdersIds([]);
                deleteOrderRef.current.style.opacity = 0;
                deleteOrderRef.current.style.pointerEvents = "none";
              }}
            >
              DISMISS X
            </div>
            <div className="top-tollbar-action-container">
              <Tooltip title="Delete" placement="bottom">
                <DeleteIcon
                  onClick={() => {
                    deleteOrderRef.current.style.opacity = 1;
                    deleteOrderRef.current.style.pointerEvents = "auto";
                  }}
                  className="top-tollbar-action-icon"
                />
              </Tooltip>
            </div>
          </div>
        </div>

        {/* delete modal  */}
        <div ref={deleteOrderRef} className="no-layers-info-container" style={{padding: "20px",}}>
          <div ref={deleteOrderInnerRef} className="delete-layer-inner-modals">
            <div className="view-modal-top">
              <span
                onClick={() => {
                  deleteOrderRef.current.style.opacity = 0;
                  deleteOrderRef.current.style.pointerEvents = "none";
                }}
              >
                <box-icon name='x'></box-icon>
              </span>
            </div>
            <div className="no-layer-mid-containerr Remove_margins_of_p">
            <ReportIcon sx={{color: "#508d69", fontSize: "clamp(25px, 4vw, 1.20vw) !important"}} />
            <p style={{fontSize:"1.4rem ",fontWeight:"600"}}>Confirmation</p>
              <p>You want to delete this order!</p>
              <p>This action can't be undone.</p>
            </div>
            <div className="view-modal-bttn" id="view__model__BTNS">
              <button
                onClick={() => {
                  deleteOrderRef.current.style.opacity = 0;
                  deleteOrderRef.current.style.pointerEvents = "none";
                }}
                 className="cancel"
              >
                Cancel
              </button>
              <button className="delete" id="DeleteBtn"
                onClick={() => {
                  if (!open) {
                    deleteBulkDraftOrder();
                    deleteOrderRef.current.style.opacity = 0;
                    deleteOrderRef.current.style.pointerEvents = "none";
                  }
                }}
              >
                Delete
              </button>
            </div>
          </div>
        </div>

        {/* area calculation modal */}
        
        <div style={{ flexDirection: "row-reverse", padding: "20px", paddingTop: "60px" }} className="orders-action-container" >
          {status === ORDER_STATUS.DRAFT ? (
            <div
              onClick={() => { setDashboardStatus(true); setSelectedIds([]);}}
              style={{ width: "12em" }}
              className="excel-dashboard-switch-cont"
            >
              <span>Processed Orders</span>
              <ArrowForwardIosIcon style={{ fontSize: "1em", marginLeft: "0.5em" }} />
            </div>
          ) : null}
          <Drawer anchor="top" open={open} onClose={handleClose} className="popup-drawer" >
            <div>
              {parseInt(progress)} of {processOrdersIds.length} processed
            </div>

            <div style={{ display: "flex", justifyContent: "center" }}>

              <LinearProgressWithLabel value={ progress * (100 / (processOrdersIds.length ? processOrdersIds.length : 1))} />

            </div>
            <button style={{marginLeft: "auto", marginRight: "auto"}} onClick={() => areaCalculationCancelledRef.current = true}>
              cancel
            </button>
          </Drawer>
        </div>

        {/* orders table  */}
      
          {orderList?.length > 0 ? (
            <div style={{padding: "20px 20px"}}>
            <div style={{ width: "200px", height: "35px", marginLeft: "auto", marginBottom: "20px", }}>
                    <label
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                        width: "100%",
                        gap: "10px",
                        background: "#508d69",
                        borderRadius: "5px",
                        cursor: "pointer"
                      }}
                      htmlFor="order"
                    >
                      <div
                        className="clouduploadbox"
                        style={{display:"flex",justifyContent:"space-around",alignItems:"center"}}
                      >
                        <img
                          src={CloudUploadIcon}
                          style={{ width: "30px", color: "white" }}
                          alt='upload icon'
                        />
                      </div>
                      <span  style={{color: "white",marginTop:"4px" }}>Upload CSV</span>
                    </label>
                    <input
                      id="order"
                      type="file"
                      accept=".csv"
                      placeholder=""
                      style={{display:"none"}}
                      onChange={handleFileChange}
                    />
            </div>
            <div 
            // className="orders-table-container inUploadBulkForRemoveGrayBorder" 
            // style={{ height: "60vh", overflowY: "scroll", borderTop: "1px solid #ccc", borderBottom: "1px  solid #ccc" }}>
            style={{ height: "60vh", overflowY: "scroll", }}>
  <table style={{ borderCollapse: "collapse", width: "100%" }}>
    <thead style={{ position: "sticky", top: "0", backgroundColor: "#ffffff", zIndex: "2"}}>
      <tr>
        <th style={{maxWidth: "90px"}}>
          {/* <span style={{ marginLeft: "2%" }}>Select All</span> */}
          <FormControlLabel control={<Checkbox size="small" sx={{marginLeft: "11px", zIndex: "2 !important"}}  color="success" type="checkbox" checked={selectAllChecked} onChange={(e) => {e.stopPropagation(); handleAllCheckboxChange(e)}}/>} label="Select All" />
        </th>
        <th>Site Id</th>
        <th>Address</th>
        <th>Zip Code</th>
        <th>Lat/Lng</th>
        <th>Area</th>
      </tr>
    </thead>
    <tbody>
      {orderList.map((item) => (
        <tr key={item.id}>
          <td>
            <Checkbox
              style={{zIndex: "1 !important"}}
              size="small"
              color="success"
              checked={item.checked}
              onChange={(e) =>
                handleSingleCheckboxChange(e, item.id, item.status)
              }
            />
          </td>
          <td>{item.site_id}</td>
          <td>{item.full_address}</td>
          <td>{item.zipcode}</td>
          <td>
            {item.latitude && item.longitude
              ? item.latitude.toFixed(2) + "/ " + item.longitude.toFixed(2)
              : "--"}
          </td>
          <td>{item.parcel_area ? item.parcel_area : "--"}</td>
        </tr>
      ))}
    </tbody>
  </table>
            </div>

            </div>
          ) : (
            <div style={{width: "100%", display: "flex", justifyContent: "center"}}>
            <div className="no-bulk-project-container" style={{ width: "45%" }}>
              {status === ORDER_STATUS.DRAFT ? (
                <>
                  <h4>
                    All orders has been submitted for measurements, Upload more.
                  </h4>
                  <div className="upload-order-with-excel">
                    {/* <label style={{ marginRight: "1em" }} htmlFor="order">
                      <AddIcon className="upload-layers-add-icon" />
                      <span
                        style={{ display: "inline-block", textWrap: "nowrap" }}
                      >
                        Upload CSV
                      </span>
                    </label> */}
                    <label
                      style={{ marginRight: "1em", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column"}}
                      htmlFor="order"
                    >
                      <div className="clouduploadbox" style={{ width: "100%", height: "auto" }} >
                        <img
                          src={CloudUploadIcon}
                          style={{ maxWidth: "80%", color: "white" }}
                          alt='upload icon'
                        />
                      </div>
                      <span>Upload CSV</span>
                    </label>
                    <input
                      id="order"
                      type="file"
                      accept=".csv"
                      placeholder=""
                      onChange={handleFileChange}
                    />
                  </div>
                </>
              ) : (
                <>
                  <h2 style={{ marginBottom: "0px" }}>
                    No order uploaded in this project.
                  </h2>

                  <div className="upload-order-with-excel">
                    <p style={{ textAlign: "center" }}>
                      Download sample CSV, review format, then upload your
                      orders CSV.
                    </p>

                    <label
                      style={{
                        marginRight: "1em",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                      htmlFor="order"
                    >
                      <div
                        className="clouduploadbox"
                        style={{ width: "100%", height: "auto" }}
                      >
                        <img
                          src={CloudUploadIcon}
                          style={{ maxWidth: "80%", color: "white" }}
                          alt="upload icon"
                        />
                      </div>
                      <span>Upload CSV</span>
                    </label>
                    <input
                      id="order"
                      type="file"
                      accept=".csv"
                      placeholder=""
                      onChange={handleFileChange}
                    />
                  </div>
                  <div className="downloaadSampleDiv">
                    <label
                      htmlFor="sample"
                      onClick={handleDownload}
                      id="downloadSample"
                    >
                      Download Sample
                    </label>
                  </div>
                </>
              )}
            </div>
            </div>
          )}

        {/* status === ORDER_STATUS.EVALUATING ||
          status === ORDER_STATUS.PRE_DRAFT ||
          selfReviewOrder === true ? */}
        {/* {console.log(
          status,
          "--status",
          selfReviewOrder,
          "--selfReviewOrder",
          ORDER_STATUS.EVALUATING,
          "--ORDER_STATUS.EVALUATING",
          ORDER_STATUS.PRE_DRAFT,
          "----ORDER_STATUS.PRE_DRAFT"
        )} */}

        <div style={{display: "flex", justifyContent: "flex-end"}}>
        {/* submit, review and calculate area buttons  */}
        <div style={{width: "fit-content"}} className="orders-action-button-container orders-action-container">
            {/* <span>Orders Count: {orderList.length}</span> */}
          
            <button
              style={{
                display: "flex",
                justifyContent: "spaceBetween",
                alignItems: "center",
              }}
              onClick={() => {
                outerRef.current.style.opacity = 1;
                setActionData('otter')
                setAction("Submit for Ottermap Review");
                outerRef.current.style.pointerEvents = "auto";
                document.querySelector(".orders-dashboard-top-tollbar").style.display="none"
              }}
              disabled={!selfReviewButton || !orderList.length}
              className={
                !selfReviewButton || !orderList.length ? "disable-button" : ""
              }
            >
              <Tooltip
                title="Orders with unclear location or Parcel will be emailed
                    for your confirmation. Someone from Ottermap will review the parcels."
              >
               
                 
                 <InfoIcon style={{ background: "none", width: "fit-content", marginLeft: "0px" }}  />

              </Tooltip>
              Submit for Ottermap Review
            </button>
       
            
            <Tooltip title="Validate the parcel before measurement.">
              <button
                onClick={() => {
                  setActionData('self')
                  setAction("Self Review & Submit");
                  outerRef.current.style.opacity = 1;
                  outerRef.current.style.pointerEvents = "auto";
                  document.querySelector(".orders-dashboard-top-tollbar").style.display="none"
                }}
                disabled={!selfReviewButton || !orderList.length}
                className={
                  !selfReviewButton || !orderList.length ? "disable-button" : ""
                }
              >
                Self Review & Submit
              </button>
            </Tooltip>

            <button
              className={
                processOrdersIds.some((id) =>
                  orderList.some( (order) => order.id == id && order.status == ORDER_STATUS.INITIATE)) ? "" : "disable-button"
              }
              onClick={handleProcess}
              disabled={!processOrdersIds.some((id) => orderList.some( (order) => order.id == id && order.status == ORDER_STATUS.INITIATE )) }
            >
              Calculate Area
            </button>
            {/* <button
                  onClick={() => {
                    setAction('Review & Submit');
                    outerRef.current.style.opacity = 1;
                    outerRef.current.style.pointerEvents = 'auto';
                  }}
                >
                  Review & Submit
                </button>} */}
        </div>
        
        <div ref={outerRef} className="review-and-submit-warning-outer">
          <div
            ref={innerRef}
            className={
              action === "Submit for Ottermap Review"
                ? "review-and-submit-warning-inner-b"
                : "review-and-submit-warning-inner"
            }
          >
            <div className="raise-feedback-inner-top">
              <span
                onClick={() => {
                  outerRef.current.style.opacity = 0;
                  outerRef.current.style.pointerEvents = "none";
                }}
              >
              <box-icon name='x'></box-icon>
              </span>
            </div>
            <div className="review-and-submit-warning-inner-mid">
              {action === "Self Review & Submit" ? (
                <p>Confirm self-review and place orders?</p>
              ) : action === "Submit for Ottermap Review" ? (
                <div>
                  {/* <p>Confirm Ottermap Team review and orders?</p> */}
                  <p style={{fontSize:"1.4rem "}}>Confirm Submission</p>
                  <p>Ottermap Team will review of your orders?</p>
                  {/* <span>
                    Note: Orders with unclear location or Parcel will be emailed
                    for your confirmation.
                  </span> */}
                </div>
              ) : (
                <p>Are you sure, you want to proceed?</p>
              )}
            </div>
            <div className="view-modal-bttn" id="view__model__BTNS">
              <button className="cancel"
                onClick={() => {
                  outerRef.current.style.opacity = 0;
                  outerRef.current.style.pointerEvents = "none";
                }}
              >
                Cancel
              </button>
              <button className="delete" id="DeleteBtn"
                onClick={() => {
                  handleAction();
                  outerRef.current.style.opacity = 0;
                  outerRef.current.style.pointerEvents = "none";
                }}
              >
                Proceed
              </button>
            </div>
          </div>
        </div>

        <div ref={outerMappingRef} className="header-swapping-outer-cont" style={{zIndex: "10"}}>
          <div
            ref={innerMappingRef}
            className="review-and-submit-warning-inner header-review-and-submit-warning-inner"
          >
            <div className="header-mapping-heading">
              Match the User Headers with Corresponding System Headers
            </div>
            <div className="header-mapping-main-cont">
              <div className="header-mapping-fields-cont">
                <div
                  style={{ fontWeight: "600" }}
                  className="header-mapping-fields-header"
                >
                  User Headers
                </div>
                <div
                  style={{ fontWeight: "600" }}
                  className="header-mapping-fields-header"
                >
                  System Headers
                </div>
              </div>
              <div className="headers-data-cont">
                {userHeaders && dropdownEnable
                  ? userHeaders.map((userHeader, index) => (
                      <div className="header-mapping-fields-cont" key={index}>
                        <div className="header-mapping-fields-header">
                          {userHeader} :
                        </div>
                        <Select
                          className="header-mapping-fields"
                          value={dropdownValues[index]}
                          onChange={(e) =>
                            handleMappingChange(
                              userHeader,
                              e.target.value,
                              index
                            )
                          }
                        >
                          <MenuItem value="Select" sx={{fontSize: "clamp(14px, 4vw, 0.90vw) !important"}}>Select</MenuItem>
                          {systemHeaders.map((systemHeader, index2) => (
                            <MenuItem
                              key={index2}
                              sx={{fontSize: "clamp(14px, 4vw, 0.90vw) !important"}}
                              value={systemHeader}
                              style={{ display: dropdownValues.includes(systemHeader) && "none" }}
                            >
                              {systemHeader}
                              {systemHeader !== "Select" && (
                                <span
                                  style={{ color: "red", marginLeft: "0.2em" }}
                                >
                                  *
                                </span>
                              )}
                            </MenuItem>
                          ))}
                        </Select>
                        <div className="clear-dropdown-icon-cont">
                          {Object.values(mapping).includes(userHeader) ? (
                            <Tooltip placement="right" title="Clear">
                              <CancelOutlinedIcon
                                className="clear-dropdown-icon"
                                onClick={() => handleClearDropdown(userHeader)}
                              />
                            </Tooltip>
                          ) : null}
                        </div>
                      </div>
                    ))
                  : null}
              </div>
            </div>
            <div className="error-msg-cont">
              {errorMsg ? `(Error: ${errorMsg})` : ""}
            </div>
            <div className="view-modal-bttns" style={{ marginTop: "0" }}>
              <Button
                className="ottermap-button-outlined "
                onClick={() => {
                  outerMappingRef.current.style.display = "none";
                  handleClearStates();
                }}
              >
                Cancel
              </Button>
              <Button className="ottermap-button" onClick={() => handleCsvProceed()}>Proceed</Button>
            </div>
          </div>
        </div>
        </div>

      </div>
    );
};


export default ProjectIdPage;
