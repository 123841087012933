import axios from "axios";
import { ADD_FEEDBACK } from "../../helper/ApiUrl";

const getFeedbackData = (id, token, setFeedbackLayer) => {
    axios({
        url: `${ADD_FEEDBACK}${id}/feedback`,
        method: "GET",
        headers: {
            Authorization: `Token ${token}`,
        },
    })
        .then((res) => {
            if ("data" in res) {
                setFeedbackLayer(res.data.data);
            }
        })
        .catch((err) => {
            console.log(err);
        });
};

export default getFeedbackData;