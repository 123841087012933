import Feature from "ol/Feature";
import { Polygon } from "ol/geom";
import * as turf from "@turf/turf";
import { v4 as uuidv4 } from "uuid";
import { Draw } from "ol/interaction";
import * as olExtent from "ol/extent";
import GeoJSON from "ol/format/GeoJSON";
import parcelStyle from "../OLStyles/ParcelStyle";
import { TOAST_TYPE } from "../../utils/constants";
import calculateArea from "../OLFeatures/CalculateArea";
import removeAllInteraction from "../OLFeatures/RemoveInteraction";
import { Fill, Stroke, Style, Circle as CircleStyle } from 'ol/style';

const cutPolygon = (
  map,
  polygonList,
  setZoomLayer,
  setParcelEdit,
  setPolygonList,
  setParcelStyle,
  handleSuccess
) => {

  const cutStartStyle = new Style({
    stroke: new Stroke({
      color: "white",
      width: 3,
      lineDash: [10],
    }),
    fill: new Fill({
      color: "rgba(0, 0, 255, 0.1)",
    }),
    image: new CircleStyle({
      radius: 7,
      fill: new Fill({
        color: 'rgba(173, 216, 230, 0.7)',
      }),
      stroke: new Stroke({
        color: 'rgba(255, 255, 255, 0.7)',
        width: 2,
      }),
    }),
  });

  const draw = new Draw({
    type: "Polygon",
    style: cutStartStyle,
  });

  setZoomLayer(false);
  setParcelEdit(false);
  removeAllInteraction(map);
  map.addInteraction(draw);
  setParcelStyle(parcelStyle);
  // document.body.classList.remove("edit-cursor");
  // document.body.classList.remove('split-cursor');
  // document.body.classList.remove("crosshair-cursor");
  // document.body.classList.add("cut-cursor");

  // const mapContainer = document.getElementById('map-cont');
  const mapContainer = document.querySelector('.ol-viewport');

  mapContainer.classList.remove('edit-cursor');
  mapContainer.classList.remove('split-cursor');
  mapContainer.classList.remove('crosshair-cursor');
  mapContainer.classList.add('cut-cursor');
  // let mapContainer = document.getElementById('map-cont');
  //   mapContainer.classList.add('cut-cursor');

  draw.on("drawend", function (e) {
    let olParser = new GeoJSON();
    let drawnFeaturesArray = olParser.writeFeaturesObject([e.feature]);
    var feature = e.feature;
    var poly = feature.getGeometry().getCoordinates();
    if (feature.getGeometry().getType() === "Polygon") {
      var kinkedPoly = turf.polygon(poly);
      var unkinkedPoly = turf.unkinkPolygon(kinkedPoly);
      if (unkinkedPoly.features.length > 1) {
        handleSuccess(
          TOAST_TYPE.WARNING,
          "Self intersecting polygon is not supported"
        );
        return false;
      }
    }
    let drawnFeatureCoordinates =
      drawnFeaturesArray.features[0].geometry.coordinates[0];
    const drawnFeature = new Feature({
      geometry: new Polygon([drawnFeatureCoordinates]),
    });

    const drawnPolygon = drawnFeature.getGeometry();

    const extent1 = drawnFeature.getGeometry().getExtent();

    map.getLayers().forEach((layer) => {
      if (layer.get("title") && layer.get("title") === "Parcel") {
        layer.getLayers().forEach((item) => {
          item.getSource().forEachFeature((feature) => {
            const extent2 = feature.getGeometry().getExtent();
            if (olExtent.intersects(extent1, extent2)) {
              const featureId = feature.id_;
              const selectedPolygon = feature.getGeometry();
              const firstPolygon = turf.polygon(
                selectedPolygon.getCoordinates()
              );
              const secondPolygon = turf.polygon(drawnPolygon.getCoordinates());
              const intersection = turf.intersect(firstPolygon, secondPolygon);
              if (intersection) {
                const finalPolygon = turf.difference(
                  firstPolygon,
                  intersection
                );

                if (!finalPolygon) {
                  polygonList = polygonList.filter(
                    (polygon) => polygon.id != featureId
                  );
                  setPolygonList(polygonList);
                }

                if (
                  finalPolygon &&
                  finalPolygon.geometry.type === "MultiPolygon"
                ) {
                  polygonList = polygonList.filter(
                    (polygon) => polygon.id != featureId
                  );
                  setPolygonList(polygonList);
                  finalPolygon.geometry.coordinates.forEach((item, index) => {
                    const cutData = {
                      id: uuidv4(),
                      data: item,
                      visible: true,
                      measurement: calculateArea(item),
                    };
                    polygonList.push(cutData);
                    setPolygonList(polygonList);
                  });
                } else if (
                  finalPolygon &&
                  finalPolygon.geometry.type === "Polygon"
                ) {
                  polygonList = polygonList.filter(
                    (polygon) => polygon.id != featureId
                  );
                  setPolygonList(polygonList);
                  const olCoord = finalPolygon.geometry.coordinates;
                  const length = finalPolygon.geometry.coordinates[0].length;
                  const cutData = {
                    id: featureId,
                    data: olCoord,
                    visible: true,
                    measurement: calculateArea(olCoord),
                  };
                  if (length > 0) {
                    polygonList.push(cutData);
                    setPolygonList(polygonList);
                  }
                }
              }
            }
          });
        });
      }
    });
    setPolygonList([...polygonList]);
  });
};

export default cutPolygon;
