
import Overlay from "ol/Overlay";

export default function ClickInteraction(map, elem, setMoveFeatureData, contextInteraction, setSingleClickKey) {
    const featureMoveOverlay = new Overlay({
        id: 3,
        element: elem,
        autoPan: {
            animation: {
                duration: 250,
            },
        },
        offset: [20, 0]
    });
    let key;
    key = map.on('contextmenu', function (evt) {
        var featureLayer = map.forEachFeatureAtPixel(evt.pixel, function (feature, layer) {
            return [feature, layer];
        });
        if (featureLayer && featureLayer[0].get("color")) {
            evt.preventDefault();
            if (featureLayer[0].getGeometry().getType() === "MultiPoint")
            setMoveFeatureData([[featureLayer[0]], featureLayer[1].get('id'), "point"]);
            else if (featureLayer[0].getGeometry().getType() === "LineString")
            setMoveFeatureData([[featureLayer[0]], featureLayer[1].get('id'), "line"]);
            else setMoveFeatureData([[featureLayer[0]], featureLayer[1].get('id'), "polygon"]);
            elem.style.display = "block";
            featureMoveOverlay.setPosition(evt.coordinate);
            map.addOverlay(featureMoveOverlay);
        }
    });
    setSingleClickKey(key);
    map.on('click', function (evt) {
        if (!featureMoveOverlay.getElement().contains(evt.originalEvent.target))
            featureMoveOverlay.setPosition(undefined);
    });
};