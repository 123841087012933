import { transformExtent } from "ol/proj";
import ImageLayer from 'ol/layer/Image.js';
import XYZ from "ol/source/XYZ";
import TileLayer from "ol/layer/Tile";
import Static from 'ol/source/ImageStatic.js';
import { NEARMAP_IMAGE_API } from "../../helper/ApiUrl";

const setNearmap = (map, date, item, tileGridLyr) => {
  if (map && date) {
    var tileGridImage;
    var projection = transformExtent(item, "EPSG:4326", "EPSG:3857");

    tileGridImage = new TileLayer({
      extent: projection,
      source: new XYZ({
        url: `https://api.nearmap.com/tiles/v3/Vert/{z}/{x}/{y}.jpg?apikey=${process.env.REACT_APP_NEARMAP_KEY}&until=${date}`,

        // url: `https://dev.ottermap.com/v1/api/mole/tiles/{z}/{x}/{y}?date=${date}`,
        
         crossOrigin: 'Anonymous'
      }),
     
    });
    tileGridLyr.current.getLayers().array_.push(tileGridImage);
    map.getLayers().forEach(function (layer) {
      if (layer && layer.get("title") === "Tile Imagery") {
        map.removeLayer(layer);
      }
    });
    map.addLayer(tileGridLyr.current);
    // date = date.replaceAll("-", "");
    // try {
    //   var projection = transformExtent(item, "EPSG:4326", "EPSG:3857");
    //   var imageLayer = new ImageLayer({
    //     source: new Static({
    //       url: `${NEARMAP_IMAGE_API}bbox=${item[1]},${item[0]},${item[3]},${item[2]}&zoom=Best&date=${date}&apikey=${process.env.REACT_APP_NEARMAP_KEY}`,
    //       imageExtent: projection,
    //       crossOrigin: "Anonymous"
    //     }),
    //     visible: true,
    //   });
    //   tileGridLyr.current.getLayers().push(imageLayer);
    //   tileGridLyr.current.setZIndex(0);
    // } catch (error) {
    //   console.log(error)
    // }
  }
};

export default setNearmap;


// tileGridImage = new TileImage({
//     attributions: '',
//     projection: 'EPSG:4326',
//     minZoom: 0,
//     maxZoom: 24,
//     tileGrid: new TileGrid({
//         extent: [-180,-90,180,90],
//         origin: [-180,-90],
//         resolutions: [1.40625,0.703125,0.3515625,0.17578125,0.087890625,0.0439453125,0.02197265625,0.010986328125,0.0054931640625,0.00274658203125,0.001373291015625,0.0006866455078125,0.00034332275390625,0.000171661376953125,8.58306884765625e-05,4.291534423828125e-05,2.1457672119140625e-05,1.07288360595703125e-05,5.36441802978515625e-06,2.68220901489257812e-06,1.34110450744628906e-06, 6.70552253723144531e-07,3.35276126861572266e-07],
//         // 6.70552253723144531e-07,3.35276126861572266e-07,1.67638063430786133e-07
//         tileSize: [256, 256]
//     }),
//     tileUrlFunction: function(tileCoord) {
//         console.timeLog(tileCoord)
//         return (`https://api.nearmap.com/tiles/v3/Vert/{z}/{x}/{y}.jpg?apikey=NGE2MjY2YTAtYWRhYi00MTQ4LWFjZDItMTlmZGMxZWQxY2Iy&until=${date}`
//             .replace('{z}', String(tileCoord[0]))
//             .replace('{x}', String(tileCoord[1]))
//             .replace('{y}', String(-tileCoord[2]-1)));
//     },
//     crossOrigin: "Anonymous"
// })
// let olTile = new OlLayerTile({
//     extent: projection,
//     source: tileGridImage
//   })

//   this.olmap.addLayer(tileGridLyr);

// if (map && imageUrl && imageBound && isActive) {
//     try {
//         var projection = transformExtent(imageBound, "EPSG:4326", "EPSG:3857");
//         var imageLayer = new ImageLayer({
//             source: new Static({
//                 url: imageUrl,
//                 imageExtent: projection,
//                 crossOrigin: "Anonymous"
//             }),
//             visible: true,
//         });
//         nearmapImageRef.current.getLayers().push(imageLayer);
//         nearmapImageRef.current.setZIndex(0);
//         map.addLayer(nearmapImageRef.current);
//     } catch (error) {
//         console.log(error);
//     }
// }
// function lon2tile(lon, zoom) { return (Math.floor((lon + 180) / 360 * Math.pow(2, zoom))); }
// function lat2tile(lat, zoom) { return (Math.floor((1 - Math.log(Math.tan(lat * Math.PI / 180) + 1 / Math.cos(lat * Math.PI / 180)) / Math.PI) / 2 * Math.pow(2, zoom))); }

// // date = date.replaceAll('-', '');
// var projection = transformExtent(nearmapcoords, "EPSG:4326", "EPSG:3857");

// function blobToDataURL(blob, callback) {
//     var a = new FileReader();
//     a.onload = function (e) {
//         callback(e.target.result);
//     };
//     a.readAsDataURL(blob);
// }

// var zoom = 16;
// var top_tile = lat2tile(nearmapcoords[1], zoom);
// var left_tile = lon2tile(nearmapcoords[0], zoom);
// var bottom_tile = lat2tile(nearmapcoords[3], zoom);
// var right_tile = lon2tile(nearmapcoords[2], zoom);

// var width = Math.abs(left_tile - right_tile) + 1;
// var height = Math.abs(top_tile - bottom_tile) + 1;

// if (map && date && nearmapcoords.length) {
//     axios
//         // .get(`https://us0.nearmap.com/staticmap?bbox=${nearmapcoords[1]},${nearmapcoords[0]},${nearmapcoords[3]},${nearmapcoords[2]}&zoom=Best&date=${date}&httpauth=false&apikey=NGE2MjY2YTAtYWRhYi00MTQ4LWFjZDItMTlmZGMxZWQxY2Iy`, {
//         //     responseType: 'blob',
//         // })
//         .get(`https://api.nearmap.com/tiles/v3/Vert/${zoom}/${left_tile}/${top_tile}.jpg?apikey=NGE2MjY2YTAtYWRhYi00MTQ4LWFjZDItMTlmZGMxZWQxY2Iy&until=${date}`, { responseType: 'blob', })
//         .then((response) => {
//             blobToDataURL(response.data, function (dataurl) {
//                 var imageLayer = new ImageLayer({
//                     source: new Static({
//                         url: dataurl,
//                         imageExtent: projection,
//                         crossOrigin: "Anonymous"
//                     }),
//                     visible: true,
//                 });
//                 nearmapImageRef.current.getLayers().push(imageLayer);
//                 map.getLayers().forEach((layer) => {
//                     if (layer && layer.get("title") && layer.get("title") === "NearmapImage")
//                         map.removeLayer(layer);
//                 });
//                 nearmapImageRef.current.setZIndex(0);
//                 map.addLayer(nearmapImageRef.current);
//             });
//             console.log(response)
//         })
//         .catch((error) => {
//             console.log(error);
//         });
// }
