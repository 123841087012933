import React, { useRef, useCallback } from "react";
import {
  Polygon
} from "@react-google-maps/api";

import { STATUS } from "../../utils/constants";

export default function RenderPolygon({ data, handleDivClick, handleMouseOut, isActive, uniqueColor, parcelEdit, path, setPath, calculateArea, status}) {
  const polygonRef = useRef(null);
  const listenersRef = useRef([]);

  listenersRef.current = [];

  const addToRefs = (el) => {
    if (el && !listenersRef.current.includes(el)) {
      listenersRef.current.push(el);
    }
  };

  const onEdit = () => {
    if (polygonRef.current) {
      const measurement = (calculateArea(polygonRef.current.getPath().getArray()));
      const nextPath = polygonRef.current
        .getPath()
        .getArray()
        .map(latLng => {
          return { lat: latLng.lat(), lng: latLng.lng() };
        });

      if (nextPath.length > 2) {
        var item = path.find(x => {
          return x.id === data.id;
        });
        item.measurement = measurement
        item.data = nextPath
        setPath([...path])
      }

    }
  };
  const onLoad = useCallback(
    (polygon) => {
      polygonRef.current = polygon;
      const path = polygon.getPath();
    },
    [onEdit]
  );


  return (
    <Polygon
      // Make the Polygon editable / draggable
      key={data.id}
      editable={parcelEdit}
      draggable={false}
      path={data.data}
      visible={data.visible}
      options={{ strokeOpacity: 0.8, strokeColor: "#FF0000", fillColor: isActive === data?.id ? uniqueColor : "#000000", fillOpacity: 0, clickable: status === STATUS.DRAFT ? true : false }}
      // Event used when manipulating and adding points
      onMouseUp={status === STATUS.DRAFT ? onEdit : false}
      // // Event used when dragging the whole Polygon
      onMouseOut={status === STATUS.DRAFT ? () => handleMouseOut() : false}
      // // Event used when dragging the whole Polygon
      onDragEnd={status === STATUS.DRAFT ? onEdit : false}
      onLoad={onLoad}
      ref={addToRefs}
      // onUnmount={onUnmount}
      onMouseOver={status === STATUS.DRAFT ? () => handleDivClick(data) : false}
    />
  );
}