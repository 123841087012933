import React from 'react'
import './FolderFile.css';
import { Height } from '@mui/icons-material';


const CheckDataUsage = ({data,dataUsage}) => {
     // Helper function to convert storage used to GB
  const convertToGB = (value) => {
    if(value){
     // console.log(value,"value")
     const [amount, unit] = value.split(' ');
     const parsedAmount = parseFloat(amount);
 
     switch (unit.toUpperCase()) {
       case 'KB':
         return parsedAmount / (1024 * 1024); 
       case 'MB':
         return parsedAmount / 1024; 
       case 'GB':
         return parsedAmount;
       default:
         return 0; 
     }
    }
   };
 
   const usedInGB = convertToGB(data?.storage_used);
   const limitInGB = parseFloat(data?.storage_limit);
 
   
   const percentage = (usedInGB / limitInGB) * 100;
 
   // Determine the progress bar color
   const progressColor = percentage >= 90 ? 'red' : 'white';
 
  return (
    <div className="data_usage_container" style={{textAlign:"center",height:"100%",boxSizing:"border-box",display:"flex",justifyContent:"center",}}>
    {/* <div className="data_usage__PopUp data_usage_cont" style={{textAlign:"center"}}> */}
        {/* <div className='data_usage_cont' style={{background:"white"}}> */}
        <p  style={{fontSize:"0.9rem",fontWeight:"600",background:"white" ,padding:"2px"}}>
          <p style={{fontSize:"0.9rem",fontWeight:"600",background:"grey",color:"white"}}>
             Usage 
        <span  style={{fontSize:"0.7rem",fontWeight:"600", margin:"0 !important",background:"green",display:"flex",justifyContent:"center",alignItems:"center"}} >  
                    {dataUsage?.storage_used} / {dataUsage?.storage_limit}
                    {/* <div  style={{width:"100%" ,height:"4px",background:"gray",padding:"0 ",overflow:"hidden",borderRadius:"10px"}}> 
        <div
          
           style={{
            background: progressColor,
            width: `${percentage}%`,
            height: '100%',
            
          }}
        > </div>

        </div>  */}
        </span>
        </p>
        </p>
       
         {/* <p style={{fontSize:"0.8rem",fontWeight:"600", margin:"0 !important"}}>  
          {dataUsage?.storage_used} / {dataUsage?.storage_limit}
         </p>  */}
        {/* <div  style={{width:"100%" ,height:"5px",background:"gray",padding:"0 ",overflow:"hidden",borderRadius:"10px"}}> 
        <div
          
           style={{
            background: progressColor,
            width: `${percentage}%`,
            height: '100%',
            
          }}
        > </div>

        </div> */}
        {/* <p className='percentage'>{percentage.toFixed(2)}% <span>storage used</span></p> */}
         
        {/* </div> */}
       
  </div>
  
  )
}

export default CheckDataUsage