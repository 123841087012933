import axios from 'axios';
import { useRef } from 'react';
import { useEffect } from 'react';
import React, { useState } from 'react';
import '../ProjectIdPage/ProjectIdPage.css';
import AuthToken from '../../context/AuthToken';
import '../OrdersDashboard/OrdersDashboard.css';
import ClearIcon from '@mui/icons-material/Clear';
import { TOAST_TYPE } from '../../utils/constants';
import { PROJECT_ORDER_URL } from '../../helper/ApiUrl';
import { useScreenType } from '../../utils/commonMethods';
import Pagination from '../../components/Pagination/Pagination';
import { useNavigate, useSearchParams } from 'react-router-dom';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';
import OrdersTable from '../../components/OrdersTable/OrdersTable';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

const UploadBulkProjectIdDashboard = ({
    bulkId,
    handleError,
    handleSuccess,
    getProjectList,
    setDashboardStatus,
}) => {
    let filterURL = '';
    const menuRef = useRef([]);
    menuRef.current = [];
    const token = AuthToken();
    const navigate = useNavigate();
    const isMobile = useScreenType();
    const [menuBar, setMenuBar] = useState([]);
    const [address, setAddress] = useState('');
    const [active, setActive] = useState(false);
    const [activeTag, setActiveTag] = useState(false);
    const [filterState, setFilterState] = useState();
    const [rowCount, setRowCount] = useState(0);
    const [orderList, setOrderList] = useState();
    const [pageCount, setPageCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [inputValue, setInputValue] = useState('10');
    const [invalidInput, setInvalidInput] = useState(false);
    const [criteriaName, setCriteriaName] = useState('Criteria');
    const [jumpToInput, setJumpToInput] = useState(currentPage);

    const [searchParams, setSearchParams] = useSearchParams();
    const tags = searchParams.get('tags') || '';
    const min_area = searchParams.get('min_area') || '';
    const max_area = searchParams.get('max_area') || '';
    const user_id = searchParams.get('user_id') || '';
    const end_Date = searchParams.get('end_date') || '';
    const start_Date = searchParams.get('start_date') || '';
    const property = searchParams.get('status') || '';
    const oprtn = searchParams.get('operation') || 'in';
    const [isNot, setIsNot] = useState(oprtn);
    const [minArea, setMinArea] = useState(min_area);
    const [maxArea, setMaxArea] = useState(max_area);
    const [endDate, setEndDate] = useState({ endDate: end_Date });
    const [startDate, setStartDate] = useState({ startDate: start_Date });
    const [userId, setUserId] = useState(user_id.split(/,+/).filter(Boolean));
    const [status, setStatus] = useState(property.split(/,+/).filter(Boolean));
    const [selectTagArray, setSelectTagArray] = useState(
        tags.split(/,+/).filter(Boolean)
    );

    const handleKeyDown = (event) => {
        const input = event.target.value;
        if (event.key === 'Enter') {
            event.preventDefault();
            if (input) {
                if (input < 0) {
                    setJumpToInput(1);
                    setCurrentPage(1);
                } else if (input > pageCount) {
                    setJumpToInput(pageCount);
                    setCurrentPage(pageCount);
                } else setCurrentPage(input);
            }
        }
    };

    useEffect(() => {
        searchParams.set('page', currentPage);
        setSearchParams(searchParams);
    }, [currentPage]);

    const setURL = () => {
        if (address) {
            filterURL += `&address=${address}`;
        }
        if (property) {
            filterURL += `&status=${property}`;
        }
        if (minArea) {
            filterURL += `&min_area=${minArea}`;
        }
        if (maxArea) {
            filterURL += `&max_area=${maxArea}`;
        }
        if (userId.length > 0) {
            let newId = '';
            userId.forEach((item) => (newId = newId + item + ','));
            filterURL += `&user_id=${newId.slice(0, newId.length - 1)}`;
        }
        if (isMobile) {
            filterURL += `&status=measurement_completed`;
        }
        if (status.length > 0) {
            let newStatus = '';
            status.forEach((item) => (newStatus = newStatus + item + ','));
            filterURL += `&status=${newStatus.slice(0, newStatus.length - 1)}`;
        }
        if (startDate.startDate || endDate.endDate) {
            filterURL += `&start_date=${startDate.startDate || start_Date}&end_date=${endDate.endDate || end_Date
                }`;
        }
        if (isNot && selectTagArray.length > 0) {
            let tags = '';
            selectTagArray.forEach((tag) => (tags += tag + ','));
            filterURL += `&tags=${tags.slice(
                0,
                tags.length - 1
            )}&tag_operation=${isNot}`;
        }
    };

    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            // If the menu is open and the clicked target is not within the menu,
            // then close the menu
            let isPresentInRef = false;
            for (let i in menuRef.current) {
                if (menuRef.current[i].contains(e.target)) {
                    isPresentInRef = true;
                }
            }
            if (menuBar.length && menuRef.current && !isPresentInRef) {
                setIsNot('');
                setStatus([]);
                setMenuBar([]);
                setSelectTagArray([]);
                setCriteriaName('Criteria');
            }
        };
        document.addEventListener('mousedown', checkIfClickedOutside);
        return () => {
            // Cleanup the event listener
            document.removeEventListener('mousedown', checkIfClickedOutside);
        };
    }, [menuBar]);

    const getOrderList = () => {
        setURL();
        setFilterState(filterURL);
        axios
            .get(
                `${PROJECT_ORDER_URL}?p=${currentPage}&psz=${inputValue}${filterURL}&bulk_oid=${bulkId}`,
                {
                    headers: {
                        Authorization: `Token ${token}`,
                        Accept: 'application/json',
                    },
                }
            )
            .then((response) => {
                setOrderList(response.data.data);
                setRowCount(response.data.count);
                // setPageCount(Math.ceil(response.data.count / 10));
                setPageCount(response.data.total_pages);
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    navigate('/access-denied');
                } else {
                    console.error(error.message);
                }
            });
    };

    useEffect(() => {
        const search = window.location.search;
        const params = new URLSearchParams(search);

        if (params.get('address')) {
            setAddress(params.get('address'));
        }

        if (params.get('min_area')) {
            setMinArea(params.get('min_area'));
        }
        if (params.get('max_area')) {
            setMaxArea(params.get('max_area'));
        }

        if (params.get('user_id')) {
            setUserId(params.get('user_id').split(','));
        }

        if (params.get('status')) {
            setStatus(params.get('status').split(','));
        }

        if (params.get('start_date') || params.get('end_date')) {
            setStartDate({ startDate: params.get('start_date') });
            setEndDate({ endDate: params.get('end_date') });
        }

        if (params.get('operation') || params.get('tags')) {
            setIsNot(params.get('operation', isNot));
            setSelectTagArray(params.get('tags').split(','));
        }

        if (!invalidInput) {
            getOrderList();
        }
    }, [address, active, currentPage, activeTag, inputValue]);

    const handleAreaFilter = () => {
        if (minArea || maxArea) {
            getOrderList();
            searchParams.set('min_area', minArea ? minArea : null);
            searchParams.set('max_area', maxArea ? maxArea : null);
            setSearchParams(searchParams);
        }
        setMenuBar([]);
    };

    const handleDateFilter = () => {
        if (startDate.startDate || endDate.endDate) {
            getOrderList();
            searchParams.set(
                'start_date',
                startDate.startDate ? startDate.startDate : null
            );
            searchParams.set('end_date', endDate.endDate ? endDate.endDate : null);
            setSearchParams(searchParams);
        }
        setMenuBar([]);
    };

    const handleUserFilter = () => {
        if (userId.length > 0) {
            getOrderList();
            let newId = '';
            userId.forEach((item) => (newId = newId + item + ','));
            searchParams.set(
                'user_id',
                newId ? newId.slice(0, newId.length - 1) : null
            );
            setSearchParams(searchParams);
        }
        setMenuBar([]);
    };

    const handleStatusFilter = () => {
        if (status) {
            getOrderList();
            let newStatus = '';
            status.forEach((item) => (newStatus = newStatus + item + ','));
            searchParams.set(
                'status',
                newStatus ? newStatus.slice(0, newStatus.length - 1) : null
            );
            setSearchParams(searchParams);
        }
        setMenuBar([]);
    };

    const handleTagsFilter = () => {
        if (selectTagArray.length > 0) {
            let tags = '';
            selectTagArray.forEach((tag) => (tags += tag + ','));
            searchParams.set('operation', isNot);
            searchParams.set('tags', tags.slice(0, tags.length - 1));
            setSearchParams(searchParams);
            getOrderList();
        } else {
            handleSuccess(TOAST_TYPE.WARNING, 'Please select any tag!');
        }
    };

    const clearAreaFilter = () => {
        setMinArea('');
        setMaxArea('');
        setActive(!active);
        getOrderList();
        searchParams.delete('min_area');
        searchParams.delete('max_area');
        setSearchParams(searchParams);
    };

    const clearUserFilter = () => {
        setUserId([]);
        setActive(!active);
        searchParams.delete('user_id');
        setSearchParams(searchParams);
    };

    const clearDateFilter = () => {
        setStartDate({ startDate: '' });
        setEndDate({ endDate: '' });
        setActive(!active);
        getOrderList();
        searchParams.delete('start_date');
        searchParams.delete('end_date');
        setSearchParams(searchParams);
    };

    const clearStatusFilter = () => {
        setStatus('');
        setActive(!active);
        getOrderList();
        searchParams.delete('status');
        setSearchParams(searchParams);
    };

    const clearTagsFilter = () => {
        setIsNot('in');
        setActive(!active);
        setSelectTagArray([]);
        searchParams.delete('tags');
        setCriteriaName('Criteria');
        searchParams.delete('operation');
        setSearchParams(searchParams);
        getOrderList();
    };

    const clearAllFilter = () => {
        filterURL = '';
        setAddress('');
        setMinArea('');
        setMaxArea('');
        setStatus('');
        setUserId([]);
        setIsNot('in');
        setAddress('');
        setActive(!active);
        setSelectTagArray([]);
        setCriteriaName('Criteria');
        setStartDate({ startDate: '' });
        searchParams.delete('tags');
        searchParams.delete('user_id');
        searchParams.delete('operation');
        searchParams.delete('min_area');
        searchParams.delete('max_area');
        searchParams.delete('start_date');
        searchParams.delete('end_date');
        searchParams.delete('status');
        setSearchParams(searchParams);
        // getOrderList();
    };

    const addToRefs = (el) => {
        if (el && !menuRef.current.includes(el)) {
            menuRef.current.push(el);
        }
    };

    const handlePageChange = (data) => {
        let currentPage = data.selected + 1;
        setCurrentPage(currentPage);
        searchParams.set('page', currentPage);
        setActiveTag(!activeTag);
        setSearchParams(searchParams);
    };

    useEffect(() => {
        if (status) localStorage.setItem('bulkStatus', status);
    }, [status]);

    return (
        <div className="orders-dashboard">
            <div className="orders-dashboard-search">
                <div
                    onClick={() => {
                        getOrderList();
                        setDashboardStatus(false);
                        getProjectList();
                    }}
                    className="excel-dashboard-switch-cont"
                >
                    <ArrowBackIosNewIcon
                        style={{ fontSize: '1em', marginRight: '0.5em' }}
                    />
                    <span>Unprocessed Orders</span>
                </div>

                {/* <div className="orders-dashboard-search-left">
                    <input
                        style={{ width: '66%', marginLeft: '2%' }}
                        type="text"
                        placeholder="Search Address"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                    />
                    <SearchOutlinedIcon className="orders-search-icon" />
                    <button onClick={clearAllFilter}>
                        <FilterListOffIcon className="clear-filter-icon" />
                        Clear Filters
                    </button>
                </div> */}

                <div className="orders__dashboard__searchLeft " style={{width:"56%"}}>
                <div  className="left__input__box" style={{ width: "100%"}}>
                
                    <input
                              id="left__input__"
                        type="text"
                        placeholder="Search Address"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                    />
                    <SearchOutlinedIcon className="orders-search-icon Search__icon" />
                    </div>
                    <button onClick={clearAllFilter}>
                        <FilterListOffIcon className="clear-filter-icon" />
                        Clear Filters
                    </button>
                </div>


                <div className="orders-dashboard-search-right">
                    {min_area || max_area ? (
                        <div>
                            <div className="filter-div">
                                <span>Area</span>
                                <ClearIcon
                                    className="close-filter-icon"
                                    onClick={clearAreaFilter}
                                />
                            </div>
                        </div>
                    ) : null}
                    {user_id ? (
                        <div>
                            <div className="filter-div">
                                <span>Creator</span>
                                <ClearIcon
                                    className="close-filter-icon"
                                    onClick={clearUserFilter}
                                />
                            </div>
                        </div>
                    ) : null}
                    {start_Date || end_Date ? (
                        <div>
                            <div className="filter-div">
                                <span>Created On.</span>
                                <ClearIcon
                                    className="close-filter-icon"
                                    onClick={clearDateFilter}
                                />
                            </div>
                        </div>
                    ) : null}
                    {property && !isMobile ? (
                        <div>
                            <div className="filter-div">
                                <ClearIcon
                                    className="close-filter-icon"
                                    onClick={clearStatusFilter}
                                />
                            </div>
                        </div>
                    ) : null}
                    {tags ? (
                        <div>
                            <div className="filter-div">
                                <span>Tags</span>
                                <ClearIcon
                                    className="close-filter-icon"
                                    onClick={clearTagsFilter}
                                />
                            </div>
                        </div>
                    ) : null}
                </div>
            </div>
            <div className="orders-dashboard-table">
                <OrdersTable
                    isNot={isNot}
                    status={status}
                    userId={userId}
                    menuBar={menuBar}
                    setIsNot={setIsNot}
                    activeTag={activeTag}
                    addToRefs={addToRefs}
                    minArea={minArea}
                    maxArea={maxArea}
                    endDate={endDate}
                    startDate={startDate}
                    pageCount={pageCount}
                    orderList={orderList}
                    setStatus={setStatus}
                    setUserId={setUserId}
                    setMenuBar={setMenuBar}
                    setMinArea={setMinArea}
                    setMaxArea={setMaxArea}
                    setEndDate={setEndDate}
                    filterState={filterState}
                    handleError={handleError}
                    criteriaName={criteriaName}
                    setStartDate={setStartDate}
                    setActiveTag={setActiveTag}
                    setOrderList={setOrderList}
                    handleSuccess={handleSuccess}
                    selectTagArray={selectTagArray}
                    setCriteriaName={setCriteriaName}
                    handleUserFilter={handleUserFilter}
                    handleAreaFilter={handleAreaFilter}
                    handleTagsFilter={handleTagsFilter}
                    handleDateFilter={handleDateFilter}
                    setSelectTagArray={setSelectTagArray}
                    handleStatusFilter={handleStatusFilter}
                />
            </div>
            <Pagination
                invalidInput={invalidInput}
                jumpToInput={jumpToInput}
                setJumpToInput={setJumpToInput}
                handleKeyDown={handleKeyDown}
                handlePageChange={handlePageChange}
                pageCount={pageCount}
                currentPage={currentPage}
                inputValue={inputValue}
                setInputValue={setInputValue}
                rowCount={rowCount}

            />
        </div>
    );
};

export default UploadBulkProjectIdDashboard;
