import React from 'react';
import { Navigate } from 'react-router-dom';
import AuthToken from '../context/AuthToken';

const RedirectHomeRouter = () => {
  const auth = AuthToken();
  const role = localStorage.getItem('role');
  return auth ? (
    <Navigate to={role !== 'otter_user' ? '/4O4' : '/upload/order'} />
  ) : (
    <Navigate to="/" />
  );
};

export default RedirectHomeRouter;
