import React from "react";

const AccessDeniedPage = () => {
  return (
    <div style={{ textAlign: "center" }}>
      <h2>Access Denied, You Don’t Have Permission To Access on This Server</h2>
    </div>
  );
};

export default AccessDeniedPage;
