import axios from "axios";
import "./TeamManagement.css";
import ContactUs from "../ContactUs/ContactUs";
import TeamTable from "../TeamTable/TeamTable";
import AuthToken from "../../context/AuthToken";
import React, { useState, useEffect } from "react";
import getUsersDetails from "../../OpenLayers/OLFeatures/GetUsersDetails";

const TeamManagement = () => {
  const token = AuthToken();
  const [usersDetails, setUsersDetails] = useState([]);

  useEffect(() => {
    getUsersDetails(token, setUsersDetails);
  }, []);

  return (
    <div className="team-manage-container">
      <div className="team-management">
        <div className="team-management-left">
          <div className="management-left-top">
            <p>Organization Name</p>
            <h4>Workspace</h4>
          </div>
          <div className="management-left-bottom">
            <TeamTable usersDetails={usersDetails} />
          </div>
        </div>
        <hr />
        <div className="team-management-right">
          <ContactUs />
        </div>
      </div>
    </div>
  );
};

export default TeamManagement;
