import axios from 'axios';
import React from 'react';
import { useState } from 'react';
import './CreateOrganization.css';
import { Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { USER_ORG_CREATE_URL } from '../../helper/ApiUrl';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useTokenContext } from '../../context/TokenContext';
import { ReactComponent as SiteLogo } from '../../assets/logo.svg';
import { ReactComponent as SiteIcon } from '../../assets/icon.svg';
import AuthToken from '../../context/AuthToken';

const CreateOrganization = () => {
  const navigate = useNavigate();
  const [orgName, setOrgName] = useState(null);
  const { authToken, rememberMe } = useTokenContext();
  const token = AuthToken();

  const handleOrgName = (e) => {
    const { name, value } = e.target;
    setOrgName({
      ...orgName,
      [name]: value,
    });
  };

  const createOrg = () => {
    axios
      .post(USER_ORG_CREATE_URL, orgName, {
        headers: {
          Authorization: `Token ${token}`,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        navigate('/home');
        window.location.reload(false);
      })
      .catch((error) => {
        console.error(error.message);
      });
  };

  return (
    <div className="organization-container">
      <div className="organization-left-container">
        <div className="organization-logo-container">
          <SiteLogo className="organization-logo" />
          <Tooltip title="Back">
            <button onClick={() => navigate('/organization')}>
              <ArrowBackIcon className="arrow-back-icon" />
            </button>
          </Tooltip>
        </div>
        <div className="create-org-container">
          <h2 className="create-org-title">Create Organization</h2>
          <div className="create-org-box">
            <label>Org. Name</label>
            <input
              required
              type="text"
              name="org_name"
              onChange={handleOrgName}
            />
          </div>
          <div className="create-org-box org-box-two">
            <label>Address</label>
            <input required type="text" name="org_address" />
          </div>
          <div className="create-org-btn">
            <button onClick={createOrg}>SUBMIT</button>
          </div>
        </div>
      </div>
      <div className="organization-right-container">
        <div className="organization-right-top">
          <h2>Elevate your process</h2>
        </div>
        <div className="organization-right-background-one"></div>
        <div className="organization-right-background-two"></div>
        <div className="organization-right-image">
          <SiteIcon />
        </div>
      </div>
    </div>
  );
};

export default CreateOrganization;
