import { useNavigate } from 'react-router-dom';

const useLogout = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('authToken');
    sessionStorage.removeItem('authToken');
    sessionStorage.removeItem('paymentWarningVisibility');
    localStorage.removeItem('role');
    // localStorage.removeItem('uf_completed');
    // localStorage.removeItem('uf_reports_to_send');
    // localStorage.removeItem('checklistsDismiss');
    // localStorage.removeItem('tours');
    // localStorage.removeItem('uf_banners');
    // localStorage.removeItem('loglevel');
    // localStorage.removeItem('uf_tags');
    // localStorage.clear();
    // sessionStorage.clear();
    navigate('/');
    window.location.reload(false);
  };

  return handleLogout;
};

export default useLogout;
