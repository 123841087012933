import React, { useContext, useState, createContext } from "react";

const UndoRedoContext = createContext();

export function UndoRedoContextProvider({ children }) {
    const [undoStack, setUndoStack] = useState([]);
    const [redoStack, setRedoStack] = useState([]);
    const [saveUndoStack, setSaveUndoStack] = useState([]);
    const [saveRedoStack, setSaveRedoStack] = useState([]);
    const [currentPointerIdx, setCurrentPointerIdx] = useState(null);

    return (
        <UndoRedoContext.Provider
            value={{
                undoStack,
                redoStack,
                setUndoStack,
                setRedoStack,
                saveUndoStack,
                saveRedoStack,
                setSaveUndoStack,
                setSaveRedoStack,
                currentPointerIdx,
                setCurrentPointerIdx,
            }}
        >
            {children}
        </UndoRedoContext.Provider>
    );
}

export function useUndoRedoContext() {
    const context = useContext(UndoRedoContext);
    if (context === undefined) {
        throw new Error("Context must be used within a Provider");
    }
    return context;
}
