import React, { forwardRef, useImperativeHandle, useState } from 'react';
import './Loader.css';
import OttermapLoaderGif from '../../assets/ottermap-loading-gif.gif';

function Loader(props, ref) {
  const [loading, setLoading] = useState(false);

  useImperativeHandle(
    ref,
    () => ({
      start: () => setLoading(true),
      stop: () => setLoading(false),
      isLoading: () => loading >= 1,
    }),
    []
  );

  if (loading) {
    return (
      <div className="loader-container">
        <img src={OttermapLoaderGif} alt="Ottermap Loader Gif" />
      </div>
    );
  } else return null;
}

export default forwardRef(Loader);
