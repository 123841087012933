export const constants = {
  googleMapsApiKey: "AIzaSyDBGfTvC4A7kbh7oNALkfhLXzc1IVjPfKs",
  base: 5,
  image_cost_factor: 1,
  feature_cost_factor: 0.5,
};

export const STATUS = {
  DIY: "diy",
  DRAFT: "draft",
  IN_PROGRESS: "in_progress",
  MEASUREMENT_COMPLETED: "measurement_completed",
};

export const STATUS_VAL = {
  diy: "DIY",
  draft: "DRAFT",
  in_progress: "IN PROGRESS",
  measurement_completed: "MEASUREMENT COMPLETED",
};

export const STUDIO_STATUS = {
  studio_progress: "Progress",
  studio_qc: "QC",
  studio_completed: "Completed",
}

export const CONVERT = {
  miles_ft: 5280,
  sqm_sqft: 10.7639,
  sqm_acre: 4047,
  acre_sqft: 43560.00001,
};

export const TOAST_TYPE = {
  SUCCESS: "success",
  WARNING: "warning",
  ERROR: "error",
};

export const FEEDBACK_STATUS = {
  OPEN: "open",
  RESOLVED: "resolved",
  REOPEN: "re_open",
  APPROVE: "approve",
};

export const ORDER_STATUS = {
  INITIATE: "initiate",
  UPLOADED: "uploaded",
  PROCESSED: "processed",
  PRE_DRAFT: "pre_draft",
  EVALUATING: "evaluating",
  DRAFT: "draft",
}

export const OPS_STATUS = {
  NOT_STARTED: "not_started",
  STARTED: "started",
}

export const tokenExpirationTime = 144000000;
