import React from "react";
import "./PopUpBox.css";
import "boxicons"

const PopUpBox = ({ imageSrc, title, message, additionalMsg,onRightBtnfunc, onLeftBtnfunc, leftBtnText,rightBtnText ,iconComponent  }) => {
  // console.log(imageSrc ,title ,message,additionalMsg,onDelete)
  const rightBtnHandler = () => {
    
    onRightBtnfunc();
    // document.querySelector(".wrapperPopUpBox").style.display="none" 
  };

  const closeHandler = () => {
    //  document.querySelector(".wrapperPopUpBox").style.display="none" 
    // onCancel();
  
  };
  const leftBtnHandler = () => {
    // document.querySelector(".wrapperPopUpBox").style.display="none" 
    // onCancel();
    onLeftBtnfunc();
  };

  return (
    <div className="wrapperPopUpBox" >
      <div className="MainPopUpBox">
        <div className="CloseIconPopUpBox">
          <span className="" onClick={() => {
                document.querySelector(".wrapperPopUpBox").style.display = "none";
              }}>
             <box-icon name='x'></box-icon>
          </span>
        </div>
        <div className="ImgBoxPopUpBox">
          {/* <img className="" src={imageSrc} alt="image" /> */}
         {imageSrc ? <img className="" src={imageSrc} alt="image" /> : iconComponent  }
          {/* {iconComponent} */}
       
        </div>
        <div className="Remove_margins_of_p">
          <p>{title}</p>
          <p>{message}</p>
          <p>{additionalMsg}</p>
        </div>
        <div className="view-modal-bttn" id="view__model__BTNS">
          <button className="cancel" onClick={leftBtnHandler}>
            {leftBtnText}
          </button>
          <button className="delete" id="DeleteBtn" onClick={rightBtnHandler}>
            {rightBtnText}
          </button>
        </div>
      </div>
    </div>
  );
};

export default PopUpBox;
