import React from "react";
import "./ContactUs.css";

const ContactUs = () => {
  return (
    <div className="contact-us-container">
      <div className="contact-us-one">
        <h5>Contact</h5>
        <p>Need to get in touch with us?</p>
      </div>
      <div className="contact-us-two">
        <input type="text" placeholder="random@gmail.com" />
        <button>Email</button>
      </div>
    </div>
  );
};

export default ContactUs;
