import Feature from "ol/Feature";
import { transform } from "ol/proj";
import { Point } from "ol/geom";
import { Style, Text, Stroke, Fill } from "ol/style";
import Icon from "ol/style/Icon";
import feedbackMarkerIcon from "../../assets/feedbackMarker.svg";
import { FEEDBACK_STATUS } from "../../utils/constants";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";

const showFeedbackLayer = (map, viewId, feedbackLayerVisible, location, id, navigate, feedbackLayer, setFeedbackMarker, feedbackMarkerRef, toggleFeedbackMarkerVisible, previewFeedback, feedbackId) => {
    if (map && !feedbackLayerVisible) {
        map.getLayers().forEach(function (layer) {
            if (layer && layer.get("title") === "FeedbackMarker")
                map.removeLayer(layer);
        });
        map.getOverlays().forEach(function (overlay) {
            overlay.setPosition(undefined);
        });
        if (feedbackId) {
            if (location.pathname[1] === "p") navigate(`/preview/order/${id}?view_id=${viewId}&fdbkid=${feedbackId}`);
            else navigate(`/order/${id}?view_id=${viewId}&fdbkid=${feedbackId}`);
        } else {
            if (location.pathname[1] === "p") navigate(`/preview/order/${id}?view_id=${viewId}`);
            else navigate(`/order/${id}?view_id=${viewId}`);
        }
    }
    if (feedbackLayer.length && feedbackLayerVisible) {
        map.getLayers().forEach(function (layer) {
            if (layer && layer.get("title") === "FeedbackMarker")
                map.removeLayer(layer);
        });
        const features = [];
        feedbackLayer.map((item, index) => {
            const iconFeature = new Feature({
                geometry: new Point(
                    transform([item.long, item.lat], "EPSG:4326", "EPSG:3857")
                ),
                id: item.id,
                title: item.title,
                description: item.description,
            });
            const iconStyle = new Style({
                image: new Icon({
                    anchor: [0.5, 20],
                    anchorXUnits: "fraction",
                    anchorYUnits: "pixels",
                    src: feedbackMarkerIcon,
                    color:
                        item.status === FEEDBACK_STATUS.OPEN ||
                            item.status === FEEDBACK_STATUS.REOPEN
                            ? "red"
                            : item.status === FEEDBACK_STATUS.APPROVE
                                ? "green"
                                : "yellow",
                }),
                text: new Text({
                    font: '13px Calibri,sans-serif',
                    fill: new Fill({ color: '#000' }),
                    text: String(index + 1),
                    offsetX: 0,
                    offsetY: -12,
                    rotateWithView: true,
                    // backgroundFill: new Fill({ color: '#519259' }),
                    // backgroundStroke: new Stroke({ color: '#FFFFFF' }),
                    padding: [1, 1, 1, 4]
                })
            });
            iconFeature.setStyle(iconStyle);
            features.push(iconFeature);
        });
        const vectorSource = new VectorSource({
            features: features,
        });
        const vectorLayer = new VectorLayer({
            source: vectorSource,
        });
        feedbackMarkerRef.current.getLayers().push(vectorLayer);
        feedbackMarkerRef.current.setZIndex(4);
        map.addLayer(feedbackMarkerRef.current);
    }
}

export default showFeedbackLayer;