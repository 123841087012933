import axios from "axios";
import { NEARMAP_VERIFY_URL, ORDER_URL } from "../../helper/ApiUrl";
import { STATUS } from "../../utils/constants";

const getNearmapImage = (
  id,
  token,
  nearmapcoords,
  setNearmapSurveyData
) => {
  if (nearmapcoords[0][0] && nearmapcoords[0][1]) {
    axios
      .get(`${ORDER_URL}${id}/image/coverage`, {
        headers: {
          Authorization: `Token ${token}`,
          Accept: "application/json",
        },
      })
      .then((response) => {
        if (response.data.data) {
          setNearmapSurveyData(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
};

export default getNearmapImage;
