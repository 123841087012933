import axios from "axios";
import "./ResetPassword.css";
import React, { useState } from "react";
import { RESET_PASSWORD_URL } from "../../helper/ApiUrl";
import { useSearchParams, useNavigate } from "react-router-dom";
import { TOAST_TYPE } from "../../utils/constants";
import { IconButton, InputAdornment, TextField } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const inputStyle = {
  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: '1px solid #06372a',
  },
  '& .MuiOutlinedInput-root': {
    '&:hover fieldset': {
      border: 'none',
    },
     '&.Mui-focused fieldset': {
       border: 'none',
    },
     '& fieldset': {
       border: 'none',
     },
  },
  // '& input': {
  //   height: '45px',
  //   padding: '0px',
  //   paddingLeft: '10px',
  //   outline: 'none',
  // },
};

export default function ResetPassword({ handleError, handleSuccess }) {
  const [password, setPassword] = useState("");
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [retypePassword, setRetypePassword] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [passwordVisibleConfirm, setPasswordVisibleConfirm] = useState(false);

  const token = searchParams.get("user");

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const togglePasswordVisibilityConfirm = () => {
    setPasswordVisibleConfirm(!passwordVisibleConfirm);
  };

  const passwordHandler = (e) => {
    setPassword(e.target.value);
  };

  const retypePasswordHandler = (e) => {
    setRetypePassword(e.target.value);
  };

  const navigate = useNavigate();

  const handleResetPasswordSubmit = (e) => {
    e.preventDefault();
    setBtnDisabled(true);
    if (password !== retypePassword) {
      handleSuccess(TOAST_TYPE.WARNING, "Password does not match");
      setBtnDisabled(false);
      return;
    }
    let formData = new FormData();
    formData.append("token", token);
    formData.append("password", password);
    axios({
      url: RESET_PASSWORD_URL,
      method: "POST",
      data: formData,
    })
      .then((res) => {
        localStorage.removeItem("authToken");
        sessionStorage.removeItem("authToken");
        handleSuccess(TOAST_TYPE.SUCCESS, 'Password Updated Successfully!');
        navigate("/");
      })
      .catch((err) => {
        console.error(err);
      });
  };
  return (
    <div className="resert-pass-container">
      <h2 className="reset-pass-title">Reset Password</h2>
      <form onSubmit={handleResetPasswordSubmit} className="reset-pass-form">
        <div className="reset-pass-details">
          <div className="reset-pass-one">
            <label>New Password</label>
            <TextField
              required
              margin="dense"
              variant="outlined"
              // sx={inputStyle}
              value={password}
              onChange={passwordHandler}
              type={passwordVisible ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={togglePasswordVisibility}>
                      {passwordVisible ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

          </div>
          <div className="reset-pass-one">
            <label>Confirm Password</label>
            <TextField
              required
              margin="dense"
              variant="outlined"
              // sx={inputStyle}
              value={retypePassword}
              type={passwordVisibleConfirm ? 'text' : 'password'}
              onChange={retypePasswordHandler}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={togglePasswordVisibilityConfirm}>
                      {passwordVisibleConfirm ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}

            />
          </div>
          <div className="reset-pass-two">
            <button disabled={btnDisabled} type="submit" variant="contained">
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
