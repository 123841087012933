import './TopBar.css';
import React, { useRef } from 'react';
import { useUserContext } from '../../context/UserContext';

const TopBar = ({ setWarningVisibility }) => {
  const tollbarRef = useRef();
  const { userInfo } = useUserContext();

  const handleLaterButtonClick = () => {
    sessionStorage.setItem('paymentWarningVisibility', 'false');
    setWarningVisibility(false);
  };

  const handlePayNowButtonClick = () => {
    setWarningVisibility(false); // Hide the warning popup
  };

  return (
    <div
      className="topbar-main-container"
      ref={tollbarRef}
      // style={{
      //   color: userInfo?.header_data?.text_color,
      //   background: userInfo?.header_data?.bg_color,
      //   fontWeight: userInfo?.header_data?.font_weight,
      // }}
    >
      <div className="topbar-close-container" onClick={handleLaterButtonClick}>
        x
      </div>
      <div className="topbar-image-container">
        <img
          alt="credit-card-icon"
          src="https://cdn-icons-png.flaticon.com/512/7037/7037916.png"
        />
      </div>

      <div className="topbar-text-container">
        <div className="topbar-warning-container">
          <h3>{userInfo?.header_data?.header_text}</h3>
          <p>{userInfo?.header_data?.description}</p>
        </div>
        <div className="topbar-action-container">
          <a href="mailto:info@ottermap.com">
            <button onClick={handlePayNowButtonClick}>Contact Us</button>
          </a>
          {/* <p onClick={handleLaterButtonClick}>I'll do it later</p> */}
        </div>
      </div>
    </div>
  );
};

export default TopBar;
