import React from "react";
import "./ProgressBar.css";
import { Tooltip } from "@mui/material";
import { STATUS } from "../../utils/constants";
import { ReactComponent as CheckBlackIcon } from "../../assets/CheckBlack.svg";

import {ArrowUp} from "../../assets/ArrowUpIcon.svg"
import {ArrowDown} from "../../assets/ArrowDownIcon.svg"

const ProgressBar = ({ status, step, setStep, featureSelected }) => {

  const row = [];
  for (let i = 1; i <= 4; i++) {
    if (i < step) {
      if (i === 1)
        row.push(
          <>
            <div className="progress-number completed">
              <CheckBlackIcon />
            </div>
          </>
        );
      else
        row.push(
          <>
            <hr className="progress-line progress-line-active" />
            <div
              onClick={() => status !== STATUS.IN_PROGRESS ? setStep(i) : null}
              className="progress-number completed"
            >
              <CheckBlackIcon />
            </div>
          </>
        );
    } else if (i === step) {
      if (i === 1)
        row.push(
          <>
            <div
              onClick={() => status !== STATUS.IN_PROGRESS ? setStep(i) : null}
              className="progress-number activeStep"
            >
              {i}
            </div>
          </>
        );
      else
        row.push(
          <>
            <hr className="progress-line progress-line-active" />
            <div
              onClick={() => status !== STATUS.IN_PROGRESS ? setStep(i) : null}
              className="progress-number activeStep"
            >
              {i}
            </div>
          </>
        );
    } else
      row.push(
        <>
          <hr className="progress-line" />
          <div
            onClick={() => featureSelected && featureSelected.length ? setStep(i) : null}
            className="progress-number pending"
          >
            {i}
          </div>
        </>
      );
  
   
    }

    // for step 4
   
  if(status === STATUS.IN_PROGRESS ){

    row.pop()
    row.push(
      <>
         <hr className="progress-line progress-line-active" />
        <div className="progress-number completed">
          <CheckBlackIcon />
        </div>
      </>
    );

  }

  return (
    <div className="progress-main-container">
      <div className="progress-top-container">
        {row.map((item, index) => {
          return (
            <Tooltip
              title={
                index === 0
                  ? "Select Address"
                  : index === 1
                    ? "Parcel Confirmation"
                    : index === 2
                      ? "Feature Selection"
                      : index === 3
                        ? "Place Order"
                        : null
              }
              className="MuiTooltip-tooltip"
            >
              <div key={index}>{item}</div>
              
            </Tooltip>
          );
        })}
      </div>
    </div>
  );
};

export default ProgressBar;
