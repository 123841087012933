import { Select } from "ol/interaction";

const redirectionToFeedback = (map, viewId, feedbackMarkerVisible, navigate, location, id, setFeedbackMarker,feedbackId) => {
   

    if (map && !feedbackMarkerVisible) {
        map.getLayers().forEach((layer) => {
            if (
                layer &&
                layer.get("title") &&
                layer.get("title") === "TempFeedbackMarker"
            ) {
                map.removeLayer(layer);
            }
        });
        map.getInteractions().forEach((interaction) => {
            if (interaction instanceof Select) {
                interaction.setActive(true);
            }
        });
        map.getOverlays().forEach(function (overlay) {
            overlay.setPosition(undefined);
        });
        // if (location.pathname[1] === "p") navigate(`/preview/order/${id}?view_id=${viewId}`);
        // else navigate(`/order/${id}?view_id=${viewId}`);
       
        // Determine if you need the `preview` or `order` route, and include all query parameters
    let targetPath = `/order/${id}?view_id=${viewId}`;
    if (location.pathname.startsWith("/p")) {
      targetPath = `/preview/order/${id}?view_id=${viewId}`;
    }

    // Add feedback parameters to the target path
    console.log(feedbackId,"fdkid")
    if (feedbackId) {
        console.log(feedbackId,"feedback_Id_for_Preview")
      targetPath += `&fdbkid=${feedbackId}&prevfdbk=true`;
      console.log(targetPath," targetPath feedback_Id_for_Preview")
    }

    // Navigate to the new path
    navigate(targetPath);

        setFeedbackMarker(null);
    }
    else if(location.pathname.startsWith("/p")){
        let targetPath = `/order/${id}?view_id=${viewId}`;
        if (location.pathname.startsWith("/p")) {
            targetPath = `/preview/order/${id}?view_id=${viewId}`;
            if (feedbackId) {
                console.log(feedbackId,"feedback_Id_for_Preview")
              targetPath += `&fdbkid=${feedbackId}&prevfdbk=true`;
              console.log(targetPath," targetPath feedback_Id_for_Preview")
              // Navigate to the new path
            navigate(targetPath);
        
            
          }
          
        }
    
        
    }
}

export default redirectionToFeedback;