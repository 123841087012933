import axios from "axios";
import { ORDER_URL } from "../../helper/ApiUrl";

const getOrderNotesData = (id, token, setOrderNotesLayer) => {
    axios({
        url: `${ORDER_URL}${id}/notes`,
        method: "GET",
        headers: {
            Authorization: `Token ${token}`,
        },
    })
        .then((res) => {
            if ("data" in res) {

                console.log(res.data.data,"res.data.data in GetoorderNotesData")
                setOrderNotesLayer(res.data.data);
            }
        })
        .catch((err) => {
            console.log(err);
        });
};

export default getOrderNotesData;