// import './LayersTypeContainer.css';
// import React, { useState } from 'react';
// import RoadView from '../../assets/RoadView.jpg';
// import LayersIcon from '@mui/icons-material/Layers';
// import SatelliteView from '../../assets/background-image.jpeg';

// export default function LayersTypeContainer({
//   map,
//   handleLayerTypeMap,
//   handleLayerTypeSatellite,
// }) {
//   const [isHovered, setIsHovered] = useState(false);
//   const [layerKind, setLayerKind] = useState('Satellite');

//   const handleMouseLeave = () => {
//     setTimeout(() => {
//       setIsHovered(false);
//     }, 700);
//   };

  
//   // const [isLayerOne, setIsLayerOne] = useState(false);

 
//   // const toggleLayer= () => {
//   //   setIsLayerOne(!isLayerOne);
//   // };
// //   <button  onClick={toggleLayer}> 
// //   {isLayerOne ? 'Map' : 'Satellite'}
// // </button>

//   return (
//     <div className="layerSwitch" onMouseLeave={handleMouseLeave}>
//       <div className={`layerOptions ${isHovered ? 'visibleDiv' : ''}`}>
//         <div
//           className={`layerOption ${
//             layerKind === 'Road' ? 'activeLayerKind' : ''
//           }`}
//           onClick={() => handleLayerTypeMap(map, setLayerKind)}
//         >
//           <img src={RoadView} alt="map-styleimage" />
//           <span>Map</span>
//         </div>
//         <div
//           className={`layerOption ${
//             layerKind === 'Satellite' ? 'activeLayerKind' : ''
//           }`}
//           onClick={() => handleLayerTypeSatellite(map, setLayerKind)}
//         >
//           <img src={SatelliteView} alt="map-styleimage" />
//           <span>Satellite</span>
//         </div>
//       </div>
//       <div
//         onClick={() => setIsHovered(!isHovered)}
//         className="layerSelectedOption"
//       >
//         <div className="layerSelectedOption-innerContainer">
//           <img
//             src={layerKind === 'Road' ? RoadView : SatelliteView}
//             alt="map-styleimage"
//           />
//           <span>
//             <LayersIcon style={{ fontSize: '1em', margin: '0 0.3em 0 0' }} />
//             Layers
//           </span>
//         </div>
//       </div>
//     </div>
//   );
// }

import './LayersTypeContainer.css';
import React, { useState } from 'react';
import RoadView from '../../assets/RoadView.jpg';
import LayersIcon from '@mui/icons-material/Layers';
import SatelliteView from '../../assets/background-image.jpeg';

export default function LayersTypeContainer({
  map,
  handleLayerTypeMap,
  handleLayerTypeSatellite,
}) {
  const [layerKind, setLayerKind] = useState('Satellite');

  const toggleLayer = () => {
    if (layerKind === 'Satellite') {
      handleLayerTypeMap(map,setLayerKind);
      setLayerKind('Road');
    } else {
      handleLayerTypeSatellite(map ,setLayerKind);
      setLayerKind('Satellite');
    }
  };

  return (
    <div className="layerSwitch" onClick={toggleLayer}>
      <div className="layerSelectedOption">
        <div className="layerSelectedOption-innerContainer">
          <img
            src={layerKind === 'Road' ? RoadView : SatelliteView}
            alt="map-styleimage"
          />
          <span>
            <LayersIcon style={{ fontSize: '1em', margin: '0 0.3em 0 0' }} />
            Layers
          </span>
        </div>
      </div>
    </div>
  );
}
