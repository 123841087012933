import { Point } from 'ol/geom';
import Icon from 'ol/style/Icon';
import Feature from 'ol/Feature';
import { transform } from 'ol/proj';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Style, Text, Fill } from 'ol/style';
import orderNotesMarkerIcon from '../../assets/orderNotesMarker.svg';

const showOrderNotesLayer = (
  map,
  viewId,
  orderNoteLayerVisible,
  location,
  navigate,
  id,
  orderNotesLayer,
  setOrderNoteData,
  orderNotesMarkerRef,
  toggleOrderNoteMarkerVisible,
  orderNoteId
) => {
  if (map && !orderNoteLayerVisible) {
    map.getLayers().forEach(function (layer) {
      if (layer && layer.get('title') === 'OrderNotesMarker')
        map.removeLayer(layer);
    });
    map.getOverlays().forEach(function (overlay) {
      overlay.setPosition(undefined);
    });
    if (orderNoteId) {
      if (location.pathname[1] === 'p')
        navigate(
          `/preview/order/${id}?view_id=${viewId}&ordntid=${orderNoteId}`
        );
      else navigate(`/order/${id}?view_id=${viewId}&ordntid=${orderNoteId}`);
    } else {
      if (location.pathname[1] === 'p')
        navigate(`/preview/order/${id}?view_id=${viewId}`);
      else navigate(`/order/${id}?view_id=${viewId}`);
    }
  }
  if (orderNotesLayer.length && orderNoteLayerVisible) {
    map.getLayers().forEach(function (layer) {
      if (layer && layer.get('title') === 'OrderNotesMarker')
        map.removeLayer(layer);
    });
    const features = [];
    orderNotesLayer.map((item, index) => {
      const iconFeature = new Feature({
        geometry: new Point(
          transform([item.long, item.lat], 'EPSG:4326', 'EPSG:3857')
        ),
        id: item.id,
        title: item.title,
        description: item.description,
        attachments: item.attachments,
      });
      const iconStyle = new Style({
        image: new Icon({
          anchor: [0.5, 20],
          anchorYUnits: 'pixels',
          anchorXUnits: 'fraction',
          src: orderNotesMarkerIcon,
        }),
        text: new Text({
          offsetX: 0,
          offsetY: -3,
          rotateWithView: false,
          padding: [1, 1, 1, 4],
          text: String(index + 1),
          font: '13px Calibri,sans-serif',
          fill: new Fill({ color: '#000' }),
        }),
      });
      iconFeature.setStyle(iconStyle);
      features.push(iconFeature);
    });
    const vectorSource = new VectorSource({
      features: features,
    });
    const vectorLayer = new VectorLayer({
      source: vectorSource,
    });
    orderNotesMarkerRef.current.getLayers().push(vectorLayer);
    orderNotesMarkerRef.current.setZIndex(5);
    map.addLayer(orderNotesMarkerRef.current);
  }
};

export default showOrderNotesLayer;
