import React, { useContext, useState, createContext } from "react";

const LayerContext = createContext();

export function LayerContextProvider({ children }) {
  const [olMap, setOlMap] = useState();
  const [layerID, setLayerID] = useState("");
  const [layerName, setLayerName] = useState("");
  const [layerType, setLayerType] = useState("");
  const [layerStyle, setLayerStyle] = useState(null);
  const [cutInteraction, setCutInteraction] = useState(false);
  const [lineInteraction, setLineInteraction] = useState(false);
  const [editInteraction, setEditInteraction] = useState(false);
  const [splitInteraction, setSplitInteraction] = useState(false);
  const [pointInteraction, setPointInteraction] = useState(false);
  const [cutAllInteraction, setCutAllInteraction] = useState(false);
  const [polygonInteraction, setPolygonInteraction] = useState(false);
  const [multipleSelectInteraction, setMultipleSelectInteraction] = useState(false);

  return (
    <LayerContext.Provider
      value={{
        olMap,
        layerID,
        setOlMap,
        layerName,
        layerType,
        layerStyle,
        setLayerID,
        setLayerName,
        setLayerType,
        setLayerStyle,
        cutInteraction,
        editInteraction,
        lineInteraction,
        splitInteraction,
        pointInteraction,
        setCutInteraction,
        cutAllInteraction,
        polygonInteraction,
        setLineInteraction,
        setEditInteraction,
        setSplitInteraction,
        setPointInteraction,
        setCutAllInteraction,
        setPolygonInteraction,
        multipleSelectInteraction,
        setMultipleSelectInteraction
      }}
    >
      {children}
    </LayerContext.Provider>
  );
}

export function useLayerContext() {
  const context = useContext(LayerContext);
  if (context === undefined) {
    throw new Error("Context must be used within a Provider");
  }
  return context;
}
