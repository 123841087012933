import "./AuthPage.css";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import Carousel from "react-material-ui-carousel";
import { ReactComponent as SiteLogo } from "../../assets/logo.svg";
import { ReactComponent as SiteIcon } from "../../assets/loginOne.svg";
import ForgotPassword from "../../pages/ForgotPassword/ForgotPassword";
import ResetPassword from "../../pages/ResetPasswordPage/ResetPassword";
import LoginContainer from "../../components/LoginContainer/LoginContainer";
import { ReactComponent as SiteIcon2 } from "../../assets/man-uplifting.svg";
import SignUpContainer from "../../components/SignUpContainer/SignUpContainer";
import TermAndConditions from "../../pages/TermAndConditions/TermAndConditions";

export default function AuthPage({ handleError, handleSuccess }) {
  const location = useLocation();
  const [invitedBy, setInvitedBy] = useState("");

  return (
    <div className="auth-container">
      <div className="auth-left-container">
        <div className="auth-logo-container">
          <div className="auth-logo-left-container">
            <SiteLogo className="right-side-logo" />
          </div>
          {invitedBy && location.pathname === "/signup" ? (
            <div className="auth-logo-right-container">
              <p>
                You've been invited by <span> {invitedBy.name}</span> to join
                their org. <span>{invitedBy.org}</span> on Ottermap. Create an
                account to log in to your new dashboard.
              </p>
            </div>
          ) : null}
        </div>
        <div>
          {location.pathname === "/signup" ? (
            <SignUpContainer
              handleError={handleError}
              setInvitedBy={setInvitedBy}
              handleSuccess={handleSuccess}
            />
          ) : location.pathname === "/forgot-password" ? (
            <ForgotPassword
              handleError={handleError}
              handleSuccess={handleSuccess}
            />
          ) : location.pathname === "/reset-password/user" ? (
            <ResetPassword
              handleError={handleError}
              handleSuccess={handleSuccess}
            />
          ) : location.pathname === "/terms-and-conditions" ? (
            <TermAndConditions />
          ) : (
            <LoginContainer
              handleError={handleError}
              handleSuccess={handleSuccess}
            />
          )}
        </div>
      </div>
      <div className="auth-right-container">
        <Carousel>
          <div>
            <div className="auth-right-top">
              <h2>Elevate your sales process</h2>
            </div>
            <div className="auth-right-background-one"></div>
            <div className="auth-right-background-two"></div>
            <div className="auth-right-image">
              <SiteIcon className="carousel-logo" />
            </div>
          </div>
          <div>
            <div className="auth-right-top">
              <h2>Smoothen your sales process with Ottermap</h2>
            </div>
            <div className="auth-right-background-one"></div>
            <div className="auth-right-background-two"></div>
            <div className="auth-right-image">
              <SiteIcon2 className="carousel-logo" />
            </div>
          </div>
        </Carousel>
      </div>
    </div>
  );
}
