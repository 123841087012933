import * as turf from "@turf/turf";
import { v4 as uuidv4 } from "uuid";
import * as olExtent from "ol/extent";
import { Draw } from "ol/interaction";
import GeoJSON from "ol/format/GeoJSON";
import { TOAST_TYPE } from "../../utils/constants";
import PolygonSlice from "../OLFeatures/PolygonSlice";
import calculateArea from "../DIYFeatures/CalculateArea";
import sendLayerData from "../DIYFeatures/SendLayerDataDiy";
import deleteLayerFeature from "../DIYFeatures/DeleteFeature";
import latLongToCoordinates from "../DIYFeatures/LatLongToCoords";
import removeAllInteraction from "../OLFeatures/RemoveInteraction";
import { Fill, Stroke, Style, Circle as CircleStyle } from 'ol/style';

export default function SplitInteraction(
    id,
    map,
    name,
    type,
    viewId,
    layerId,
    setZoomLayer,
    handleSuccess,
    uploadedLayer,
    setUploadedLayer,
    setUndoStack,
    setRedoStack,
) {
    const splitStartStyle = new Style({
        stroke: new Stroke({
            color: "white",
            width: 3,
            lineDash: [10],
        }),
        fill: new Fill({
            color: "rgba(0, 0, 255, 0.1)",
        }),
        image: new CircleStyle({
            radius: 7,
            fill: new Fill({
                color: 'rgba(173, 216, 230, 0.7)',
            }),
            stroke: new Stroke({
                color: 'rgba(255, 255, 255, 0.7)',
                width: 2,
            }),
        }),
    });

    var draw = new Draw({
        type: 'LineString',
        style: splitStartStyle,
    });
    setZoomLayer(false);
    removeAllInteraction(map);
    map.addInteraction(draw);
    // document.body.classList.remove('edit-cursor');
    // document.body.classList.remove('cut-cursor');
    // document.body.classList.remove('crosshair-cursor');
    //  document.body.classList.add('split-cursor');

    //  const mapContainer = document.getElementById('map-cont');
    const mapContainer = document.querySelector('.ol-viewport');

  mapContainer.classList.remove('edit-cursor');
  mapContainer.classList.remove('cut-cursor');
  mapContainer.classList.remove('crosshair-cursor');
  mapContainer.classList.add('split-cursor');

     
    // let mapContainer = document.getElementById('map-cont');
    // mapContainer.classList.add('split-cursor');
    draw.on("drawend", function (e) {
        let format = new GeoJSON();
        var lineFeature = e.feature;
        var lineGeoJSON = format.writeFeatureObject(lineFeature, {
            dataProjection: "EPSG:4326",
            featureProjection: "EPSG:3857"
        });
        var lineGeometry = e.feature.getGeometry();
        var poly = lineGeometry.getCoordinates();
        for (let i = 0; i < poly.length - 2; i++) {
            for (let j = i + 2; j < poly.length - 1; j++) {
                const intersect = turf.lineIntersect(
                    turf.lineString([poly[i], poly[i + 1]]),
                    turf.lineString([poly[j], poly[j + 1]])
                );
                if (intersect.features.length > 0) {
                    handleSuccess(
                        TOAST_TYPE.WARNING,
                        "Self intersecting line is not supported"
                    );
                    return false;
                }
            }
        }

        // undo redo context, passing the previous layer in the undo stack
        let stateUpdate = JSON.parse(JSON.stringify(uploadedLayer));
        setUndoStack((prevUndoStack) => [...prevUndoStack, stateUpdate]);
        setRedoStack([]);


        var lineExtent = lineGeometry.getExtent();
        map.getLayers().forEach((layer) => {
            if (layer.get("title") && layer.get("title") === "Order") {
                layer.getLayers().forEach((item) => {
                    if (item.get("title") && item.get("title") === name) {
                        if (item.get('visible'))
                            item.getSource().forEachFeature((feature) => {
                                var featureExtent = feature.getGeometry().getExtent();
                                if (olExtent.intersects(lineExtent, featureExtent)) {
                                    var featureGeoJSON = format.writeFeatureObject(feature, {
                                        dataProjection: "EPSG:4326",
                                        featureProjection: "EPSG:3857"
                                    });
                                    var featureId = feature.get("id");
                                    const geoType = featureGeoJSON.geometry.type;
                                    const sliced =
                                        geoType === "Polygon"
                                            ? PolygonSlice(featureGeoJSON, lineGeoJSON)
                                            : turf.lineSplit(featureGeoJSON, lineGeoJSON);
                                    if (sliced.features.length) {
                                        deleteLayerFeature(
                                            id,
                                            viewId,
                                            layerId,
                                            featureId,
                                            uploadedLayer,
                                            setUploadedLayer
                                        );
                                        uploadedLayer.map((layer) => {
                                            if (layer.name === name) {
                                                layer.data = layer.data.filter(
                                                    (feature) => feature.id != `${featureId}`
                                                );
                                            }
                                        });
                                        setUploadedLayer(uploadedLayer);

                                        var maxNewId;
                                        uploadedLayer.forEach((layer) => {
                                            if (layer.name === name) {
                                                maxNewId = layer.data.reduce((maxId, obj) => {
                                                    return Math.max(maxId, obj.newId)
                                                }, 0);
                                            }
                                        });
                                        sliced.features.forEach((item, index) => {
                                            const frontEndId = uuidv4();
                                            uploadedLayer.map((layer) => {
                                                if (layer.name === name) {
                                                    const cutData = {
                                                        id: frontEndId,
                                                        newId: maxNewId + index + 1,
                                                        data: latLongToCoordinates(item.geometry.coordinates),
                                                        visible: true,
                                                        measurement: calculateArea(latLongToCoordinates(item.geometry.coordinates), true),
                                                    };
                                                    layer.data.push(cutData);
                                                }
                                            });
                                            setUploadedLayer(uploadedLayer);
                                            const backEndData = {
                                                layer_data: {
                                                    type: "Feature",
                                                    properties: {
                                                        id: featureId,
                                                        Area: calculateArea(latLongToCoordinates(item.geometry.coordinates), true),
                                                    },
                                                    geometry: {
                                                        type: geoType,
                                                        coordinates: item.geometry.coordinates
                                                    },
                                                },
                                            };
                                            sendLayerData(
                                                id,
                                                name,
                                                viewId,
                                                layerId,
                                                frontEndId,
                                                backEndData,
                                                uploadedLayer,
                                                setUploadedLayer
                                            );
                                        });
                                    }
                                }
                            });
                    }
                });
            }
        });
    });
}