import './AddressBar.css';
import React from 'react';
import { ReactComponent as PropertyIcon } from '../../assets/Property.svg';
import { Tooltip } from '@material-ui/core';

export default function AddressBar({ address }) {

  function truncateLine(text, maxLength = 55, ellipsis = '...') {
    return text.length > maxLength
      ? text.slice(0, maxLength - ellipsis.length) + (text.lastIndexOf(' ') > -1 ? ellipsis : text.slice(-ellipsis.length))
      : text;
  }

  return (
    <Tooltip title={address} placement='top'>
      <div className="property-address-container-main">
        <PropertyIcon className="property-icon" />
        <p>{truncateLine(address ?? "")}</p>
      </div>
    </Tooltip>
  );
}
