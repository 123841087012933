import { transformExtent } from 'ol/proj';
import ImageLayer from 'ol/layer/Image';
import Static from 'ol/source/ImageStatic';

const setHDImage = (
  map,
  imageUrl,
  imageBound,
  nearmapImageRef,
  setNearMapActive,
  setNearmapLoader,
  setNearmapDisabled
) => {
  try {
    var projection = transformExtent(imageBound, 'EPSG:4326', 'EPSG:3857');
    var imageLayer = new ImageLayer({
      source: new Static({
        url: imageUrl,
        imageExtent: projection,
        crossOrigin: 'Anonymous',
      }),
      visible: true,
    });
    nearmapImageRef.current.getLayers().push(imageLayer);
    setNearMapActive(true);
    setNearmapLoader(false);
    setNearmapDisabled(false);
    nearmapImageRef.current.setZIndex(0);
    map.addLayer(nearmapImageRef.current);
  } catch (error) {
    console.log(error);
  }
};

export default setHDImage;
