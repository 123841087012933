import { toLonLat } from 'ol/proj';
import * as turf from '@turf/turf';
import GeoJSON from 'ol/format/GeoJSON';
import { Draw, Snap } from 'ol/interaction';
import { TOAST_TYPE } from '../../utils/constants';
import calculateArea from '../DIYFeatures/CalculateArea';
import sendLayerData from '../DIYFeatures/SendLayerData';
import calculateLineArea from '../DIYFeatures/CalculateLineArea';
import removeAllInteraction from '../OLFeatures/RemoveInteraction';
import coordinatesToLatLong from '../DIYFeatures/TransformCoordinates';
import VectorSource from 'ol/source/Vector';
import { Fill, Stroke, Style, Circle as CircleStyle } from 'ol/style';

const drawLayer = ( id, map, name, type, viewId, layerId, setZoomLayer, handleSuccess, uploadedLayer, setUploadedLayer, setUndoStack, setRedoStack ) => {

  var layerType = type === 'polygon' ? 'Polygon' : type === 'line' ? 'LineString' : 'Point';

  const drawStartStyle = new Style({
    fill: new Fill({
      color: 'rgba(0,0,0,0)',
    }),
    stroke: new Stroke({
      color: 'rgba(255, 255, 255, 0.7)',
      width: 3,
    }),
    image: new CircleStyle({
      radius: 7,
      fill: new Fill({
        color: 'rgba(173, 216, 230, 0.7)',
      }),
      stroke: new Stroke({
        color: 'rgba(255, 255, 255, 0.7)',
        width: 2,
      }),
    }),
  });

  const draw = new Draw({
    // source: source,
    type: layerType,
    style: drawStartStyle
  });

  setZoomLayer(false);
  removeAllInteraction(map);
  map.addInteraction(draw);
  // document.body.classList.remove('cut-cursor');
  // document.body.classList.remove('edit-cursor');
  // document.body.classList.remove('split-cursor');
  // document.body.classList.add('crosshair-cursor');


  
  // const mapContainer = document.getElementById('map-cont');
  const mapContainer = document.querySelector('.ol-viewport');

  mapContainer.classList.remove('edit-cursor');
  mapContainer.classList.remove('split-cursor');
  mapContainer.classList.remove('cut-cursor');
  mapContainer.classList.add('crosshair-cursor');
  // let mapContainer = document.getElementById('map-cont');
  // mapContainer.classList.add('crosshair-cursor');


  var mergedSource = new VectorSource();
  map.getLayers().forEach((layer) => {
    if (layer.get('title') && layer.get('title') === 'Order') {
      layer.getLayers().forEach((item) => {
        var layerFeatures = item.getSource().getFeatures();
        if (layerFeatures && layerFeatures.length) mergedSource.addFeatures(layerFeatures);
      });
    }
  });
  const snap = new Snap({
    source: mergedSource,
  });
  map.addInteraction(snap);

  draw.on('drawend', function (e) {
    let parser = new GeoJSON();
    let drawnFeatures = parser.writeFeaturesObject([e.feature]);
    var feature = e.feature;
    var poly = feature.getGeometry().getCoordinates();
    
    if (feature.getGeometry().getType() === 'Polygon') {
      var kinkedPoly = turf.polygon(poly);
      var unkinkedPoly = turf.unkinkPolygon(kinkedPoly);
      if (unkinkedPoly.features.length > 1) {
        handleSuccess(
          TOAST_TYPE.WARNING,
          'Self intersecting polygon is not supported'
        );
        return false;
      }
    } else if (feature.getGeometry().getType() === 'LineString') {
      for (let i = 0; i < poly.length - 2; i++) {
        for (let j = i + 2; j < poly.length - 1; j++) {
          const intersect = turf.lineIntersect(
            turf.lineString([poly[i], poly[i + 1]]),
            turf.lineString([poly[j], poly[j + 1]])
          );
          if (intersect.features.length > 0) {
            handleSuccess(
              TOAST_TYPE.WARNING,
              'Self intersecting line is not supported'
            );
            return false;
          }
        }
      }
    }

    const fontEndCoords = type === 'polygon'
        ? [drawnFeatures.features[0].geometry.coordinates[0]]
        : type === 'line'
          ? drawnFeatures.features[0].geometry.coordinates
          : [drawnFeatures.features[0].geometry.coordinates];

    const backEndCoords = type === 'polygon'
        ? [drawnFeatures.features[0].geometry.coordinates[0]]
        : type === 'line'
          ? drawnFeatures.features[0].geometry.coordinates
          : drawnFeatures.features[0].geometry.coordinates;

    const backEndData = {
      layer_data: {
        type: 'Feature',
        properties: {
          id: Math.floor(Math.random() * 10) + 1,
          Area: calculateArea([
            drawnFeatures.features[0].geometry.coordinates[0],
          ]),
        },
        geometry: {
          type: layerType,
          coordinates:
            type === 'point'
              ? toLonLat(backEndCoords)
              : coordinatesToLatLong(backEndCoords, type),
        },
      },
    };

    let newFeature;

        
    console.log(uploadedLayer, "stateUpdate in draw")
    // undo redo context, passing the previous layer in the undo stack
    let stateUpdate = JSON.parse(JSON.stringify(uploadedLayer));
    setUndoStack((prevUndoStack) => [...prevUndoStack, stateUpdate]);
    setRedoStack([]);

    uploadedLayer.map((layer) => {
      if (layer.name === name) {
         newFeature = {
          id: id,
          newId: layer.data.length + 1,
          data: fontEndCoords,
          visible: true,
          measurement:
            type === 'polygon'
              ? calculateArea(fontEndCoords, true)
              : type === 'line'
                ? calculateLineArea(fontEndCoords)
                : 0,
        };
        layer.data.push(newFeature);
      }
    });


    console.log(uploadedLayer, newFeature, name, "uploadedLayerInDraw")

    setUploadedLayer([...uploadedLayer]);

    sendLayerData( id, name, viewId, layerId, backEndData, uploadedLayer, setUploadedLayer);
  });
};

export default drawLayer;
