import axios from 'axios';
import AuthToken from './AuthToken';
import { useNavigate } from 'react-router-dom';
import { USER_INFO_URL } from '../helper/ApiUrl';
import React, { useContext, useState, useEffect, createContext } from 'react';

const UserContext = createContext();

export function UserContextProvider({ children }) {
  const token = AuthToken();
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState('');
  const [selfCredits, setSelfCredits] = useState();
  const [organization, setOrganization] = useState();

  useEffect(() => {
    if (token) {
      axios
        .get(USER_INFO_URL, {
          headers: {
            Authorization: `Token ${token}`,
            Accept: 'application/json',
          },
        })
        .then((response) => {
          setUserInfo(response.data.data);
          setOrganization(response.data.data.organization);
          localStorage.setItem('role', response.data.data.role);
          if (response.data.data.organization === null) {
            navigate('/organization');
          }
        })
        .catch((error) => {
          if (error?.response?.status === 401) {
            localStorage.removeItem('authToken');
            sessionStorage.removeItem('authToken');
            navigate('/');
          } else if (error?.response?.status === 402) {
            navigate('/account-suspension');
          }
        });
    }
  }, []);

  return (
    <UserContext.Provider
      value={{
        userInfo,
        setUserInfo,
        selfCredits,
        organization,
        setSelfCredits,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

export function useUserContext() {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('Context must be used within a Provider');
  }
  return context;
}
