import React, { useState } from "react";
import {
  GoogleMap,
  useLoadScript,
  DrawingManager,
} from "@react-google-maps/api";

import RenderPolygon from "../../components/RenderPolygon/RenderPolygon";
import PolygonCheckBox from "../../components/PolygonCheckBox/PolygonCheckbox";
import "@reach/combobox/styles.css";

const libraries = ["places", "drawing"];
let mapContainerStyle = {
  height: "100vh",
  width: "100vw",
};

const center = { lat: 52.52047739093263, lng: 13.36653284549709 };
const map_options = {
  draggable: true, // make map draggable
  zoomControlOptions: { position: 9 },
  keyboardShortcuts: false, // disable keyboard shortcuts
  scaleControl: true, // allow scale controle
  scrollwheel: true, // allow scroll wheel
  streetViewControl: true,
  mapTypeControl: true,
  mapTypeId: "hybrid", //options are "roadmap, satellite, hybrid and terrain"
};

export default function TrialOrder() {
  const [path, setPath] = useState([]);
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "",
    libraries,
  });
  const [markers, setMarkers] = useState({});
  const [showDrawing, setShowDrawing] = useState(false);
  const [uniqueColor, setUniqueColor] = useState(null);
  const [isActive, setIsActive] = useState(null);

  const onMapClick = React.useCallback((e) => {
    setMarkers({
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
      time: new Date(),
    });
  }, []);

  const mapRef = React.useRef();
  const onMapLoad = React.useCallback((map) => {
    mapRef.current = map;
  }, []);

  const handleDivClick = (poly) => {
    setIsActive(poly?.id);
    setUniqueColor("blue");
  };

  const handleMouseOut = () => {
    setIsActive(null);
  };

  if (loadError) return "Error";
  if (!isLoaded) return "Loading...";
  return (
    <div>
      <button
        onClick={() => {
          setShowDrawing(!showDrawing);
        }}
      >
        Click me
      </button>
      {path.map((poly) => (
        <PolygonCheckBox data={poly} setPath={setPath} path={path} />
      ))}
      <GoogleMap
        id="map"
        mapContainerStyle={mapContainerStyle}
        zoom={12}
        center={center}
        onClick={onMapClick}
        onLoad={onMapLoad}
        options={map_options}
      >
        {showDrawing ? (
          <DrawingManager
            drawingMode="polygon"
            onPolygonComplete={(polygon) => {
              const nextPath = polygon
                .getPath()
                .getArray()
                .map((latLng) => latLng.toJSON());

              if (nextPath.length > 2) {
                setPath((prevProps) => [
                  ...prevProps,
                  { id: prevProps.length + 1, data: nextPath, visible: true },
                ]);
              } else {
                alert("Not a valid polygon");
              }
              polygon.setMap(null);
            }}
            options={{
              drawingControl: false,
              drawingControlOptions: {
                drawingModes: ["polygon"],
              },
              polygonOptions: { editable: true },
            }}
          />
        ) : null}
        {path.map((poly) => (
          <RenderPolygon
            data={poly}
            handleDivClick={handleDivClick}
            handleMouseOut={handleMouseOut}
            isActive={isActive}
            uniqueColor={uniqueColor}
          />
        ))}
      </GoogleMap>
    </div>
  );
}
