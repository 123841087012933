import axios from 'axios';
import JSZip from 'jszip';
import './MeasurementPanel.css';
import tokml from '@maphubs/tokml';
import { saveAs } from 'file-saver';
import Menu from '@mui/material/Menu';
import html2canvas from 'html2canvas';
import { unByKey } from 'ol/Observable';
import MenuItem from '@mui/material/MenuItem';
import SaveIcon from '@mui/icons-material/Save';
import ShareIcon from '@mui/icons-material/Share';
import AuthToken from '../../../context/AuthToken';
import demoImage2 from '../../../assets/ottermap.svg';
import DownloadIcon from '@mui/icons-material/Download';
import ContentCutIcon from '@mui/icons-material/ContentCut';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import UndoIcon from '@mui/icons-material/Undo';
import RedoIcon from '@mui/icons-material/Redo';
import Tooltip from '@mui/material/Tooltip';
import SquareFootIcon from '@mui/icons-material/SquareFoot';
import { useLocation, useParams } from 'react-router-dom';
import React, { useEffect, useState, useRef } from 'react';
import FeedbackModal from '../../FeedbackModal/FeedbackModal';
import { STATUS, TOAST_TYPE } from '../../../utils/constants';
import { useLayerContext } from '../../../context/LayerContext';
import { USER_ORG_CREATE_URL, ORDER_URL } from '../../../helper/ApiUrl';
import saveAllLayers from '../../../OpenLayers/DIYFeatures/SaveAllLayers';
import hoverEffect from '../../../OpenLayers/OLInteractions/HoverInteraction';
import cutAllLayer from '../../../OpenLayers/DIYInteractions/CutAllInteraction';
import removeAllInteraction from '../../../OpenLayers/OLFeatures/RemoveInteraction';
import measurePolygon from '../../../OpenLayers/DIYInteractions/MeasurementInteraction';
import coordinatesToLatLong from '../../../OpenLayers/DIYFeatures/TransformCoordinates';
import { useUndoRedoContext } from '../../../context/UndoRedoContext';
import {useUserContext} from './../../../context/UserContext.jsx';
import { useSearchParams } from 'react-router-dom';
import { encodeJwtToken } from '../../../utils/EncodeJwt.js';
import feedback_Icon from '../../../assets/feedback-svgrepo-com.svg'

const MeasurementPanel = ({
  id,
  map,
  status,
  viewId,
  address,
  hex2rgba,
  hoverElem,
  notesModal,
  toolbarRef,
  contentRef,
  layersCount,
  handleReset,
  hoverMapKey,
  polygonList,
  notesButton,
  handleError,
  downloadPng,
  downloadData,
  setZoomLayer,
  viewPanelRef,
  mapClickable,
  handleSuccess,
  uploadedLayer,
  outerViewName,
  areaFeatures,
  setAreaFeatures,
  measurementRef,
  feedbackLayer,
  isStudioOrder,
  cutLayersName,
  setResetLayer,
  setPolygonList,
  setHoverMapKey,
  setCutLayersName,
  setFeedbackAdded,
  setUploadedLayer,
  feedbackModalShow,
  setFeedbackMarker,
  pngWithMeasureRefs,
  toggleMapClickable,
  setFeedbackEditable,
  setFeedbackModalShow,
  isContextInteraction,
  setOrderNotesModalShow,
  setAllFeedbackResolved,
  setFeedbackLayerVisible,
  setOrderNoteLayerVisible,
  toggleFeedbackMarkerVisible,
  toggleOrderNoteMarkerVisible,
  setMultipleContextInteraction,
}) => {
  const { olMap, cutAllInteraction, setCutAllInteraction, setLayerStyle, setCutInteraction, setLineInteraction, setEditInteraction, setPointInteraction, setSplitInteraction, setPolygonInteraction, setMultipleSelectInteraction } = useLayerContext();
  const {undoStack, redoStack, setUndoStack, setRedoStack, setSaveUndoStack, setSaveRedoStack, currentPointerIdx, setCurrentPointerIdx } = useUndoRedoContext();
  const innerRef = useRef();
  const outerRef = useRef();
  const loaderRef = useRef();
  const token = AuthToken();
  const params = useParams();
  const {organization} = useUserContext()
  const role = localStorage.getItem('role');
  const location = useLocation();
  const [orgLogo, setOrgLogo] = useState(null);
  const [anchorEl, setAnchorEl] = useState();
  const open = Boolean(anchorEl);
  const [isAreaActive, setIsAreaActive] = useState(false);
  const [saveResetModal, setSaveResetModal] = useState('');
  const { protocol, hostname, port } = window.location;
  const [searchParams, setSearchParams] = useSearchParams();
  const rootRoute = `${protocol}//${hostname}${port ? `:${port}` : ''}`;
  const ism = searchParams.get('ism');
  const [link, setLink] = useState(`${rootRoute}/share${location.pathname}?view_id=${viewId}${ism ? `&ism=${ism}` : ''}`);


  console.log(undoStack, redoStack, 'undostack')
  
  const handleUndo = () => {
    removeAllInteraction(map);
    closeDiyUndoRedoTool();
    document.body.classList.remove('cut-cursor');
    document.body.classList.remove('edit-cursor');
    document.body.classList.remove('split-cursor');
    document.body.classList.remove('crosshair-cursor');
    if (undoStack.length > 0) {
      const previousState = JSON.parse(
        JSON.stringify(undoStack[undoStack.length - 1])
      );
      setUploadedLayer(previousState);
      setUndoStack((prevUndoStack) => prevUndoStack.slice(0, -1));
      let stateUpdate = JSON.parse(JSON.stringify(uploadedLayer));
      setRedoStack((prevRedoStack) => [...prevRedoStack, stateUpdate]);
    }
  };

  const handleRedo = () => {
    removeAllInteraction(map);
    closeDiyUndoRedoTool();
    if (redoStack.length > 0) {
      const nextState = redoStack[redoStack.length - 1];
      setUploadedLayer(nextState);
      setRedoStack((prevRedoStack) => prevRedoStack.slice(0, -1));
      let stateUpdate = JSON.parse(JSON.stringify(uploadedLayer));
      setUndoStack((prevUndoStack) => [...prevUndoStack, stateUpdate]);
    }
  };

    // for hiding the something if it will be the in preview mode 
    const prevfdbkk = searchParams.get("prevfdbk");
    const fdbkid = searchParams.get("fdbkid");
    const isPreviewPath = location.pathname.includes("/preview");
      // for hiding the something if it will be the in preview mode 

  // useEffect(() => {
  //   let stak = [];
  //   undoStack.forEach((items) => {
  //     items.forEach((item) => {
  //       if (item.name === 'Parking Lots') {
  //         stak.push(item.data[0].measurement);
  //       }
  //     });
  //   });
  //   console.log(stak, 'undo');
  // }, [undoStack]);

  // useEffect(() => {
  //   let stak = [];
  //   redoStack.forEach((items) => {
  //     items.forEach((item) => {
  //       if (item.name === 'Parking Lots') {
  //         stak.push(item.data[0].measurement);
  //       }
  //     });
  //   });
  //   console.log(stak, 'redo');
  // }, [redoStack]);

  const downloadKml = () => {
    var zip = new JSZip();
    downloadData.map((item) => {
      var kmlFile = tokml(item.data);
      zip.file(`${item.name}.kml`, kmlFile);
    });
    zip.generateAsync({ type: 'blob' }).then(function (content) {
      saveAs(content, 'kml.zip');
      handleSuccess(TOAST_TYPE.SUCCESS,"KML Downloaded Successfully!");
    });
    handleClose();
  };
 

const downloadGeoJson = () => {
  axios({
    url: `${ORDER_URL}${params.id}/view/${viewId}/layers?download=zip`,
    method: 'GET',
    headers: {
      "x-api-key": encodeJwtToken(),
    },
    responseType: 'blob',
  })
    .then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'geojson.zip'); 
      document.body.appendChild(link);
      link.click();
      link.remove(); 

      handleSuccess(TOAST_TYPE.SUCCESS,"GeoJSON Downloaded Successfully!");
      handleClose();
    })
    .catch((e) => {
      handleError(e);
      handleClose();
    });
};
const downloadExcel = () => {
  axios({
    url: `${ORDER_URL}${params.id}/view/${viewId}/layers?download=xlsx`,
    method: 'GET',
    headers: {
      "x-api-key": encodeJwtToken(),
    },
    responseType: 'blob',
  })
    .then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'excel.zip'); 
      document.body.appendChild(link);
      link.click();
      link.remove(); 

      handleSuccess(TOAST_TYPE.SUCCESS,"Excel Downloaded Successfully!");
      handleClose();
    })
    .catch((e) => {
      handleError(e);
      handleClose();
    });
};

  const handleClose = () => {
    setAnchorEl(null);
  };

  const showFeedbackModal = () => {
    unByKey(hoverMapKey);
    notesButton.current.style.display = 'block';
    notesModal.current.style.display = 'none';
    setFeedbackLayerVisible(true);
    setFeedbackModalShow(true);
    setOrderNotesModalShow(false);
    setOrderNoteLayerVisible(false);
    toggleMapClickable(false);
    toggleOrderNoteMarkerVisible(false);
    var element = document.getElementById('map-cont');
    element.classList.remove('cursor-change-order-note');
  };

  const closeDiyTool = () => {
    unByKey(currentPointerIdx);
    unByKey(hoverMapKey);
    // setLayerStyle(null); // commented out because this leads to no highlighting background for selected feature in the measurement panel
    setCutInteraction(false);
    setCutAllInteraction(false);
    setEditInteraction(false);
    setLineInteraction(false);
    setPointInteraction(false);
    setSplitInteraction(false);
    isContextInteraction(true);
    setMultipleContextInteraction(false);
    removeAllInteraction(olMap);
    setPolygonInteraction(false);
    setMultipleSelectInteraction(false);
    hoverEffect(olMap, hex2rgba, hoverMapKey, setHoverMapKey, hoverElem);
    // toolbarRef.current.style.display = 'none';
    document.body.classList.remove('cut-cursor');

    document.body.classList.remove('edit-cursor');
    document.body.classList.remove('split-cursor');
    document.body.classList.remove('crosshair-cursor');
  };

  const closeDiyUndoRedoTool = () => {
    unByKey(currentPointerIdx);
    unByKey(hoverMapKey);
    // setLayerStyle(null); // commented out because this leads to no highlighting background for selected feature in the measurement panel
    setCutInteraction(false);
    setCutAllInteraction(false);
    setEditInteraction(false);
    setLineInteraction(false);
    setPointInteraction(false);
    setSplitInteraction(false);
    isContextInteraction(true);
    setMultipleContextInteraction(false);
    removeAllInteraction(olMap);
    setPolygonInteraction(false);
    setMultipleSelectInteraction(false);
    // toolbarRef.current.style.display = 'none';
    document.body.classList.remove('cut-cursor');
    document.body.classList.remove('edit-cursor');
    document.body.classList.remove('split-cursor');
    document.body.classList.remove('crosshair-cursor');
  };

  const copyLink = () => {
    navigator.clipboard.writeText(link);
    handleSuccess(TOAST_TYPE.SUCCESS, 'Copied to Clipboard');
  };

  const getOrgInfo = () => {
    axios
      .get(USER_ORG_CREATE_URL, {
        headers: {
          Authorization: `Token ${token}`,
          Accept: 'application/json',
        },
      })
      .then((response) => {
        setOrgLogo(response.data.data.org_logo);
      })
      .catch((error) => {
        console.error(error.message);
      });
  };

  useEffect(() => {
    getOrgInfo();
  }, []);

  const handleSaveLayer = () => {

    setUndoStack([]); // setting the undo stack empty after saving parcel
    setRedoStack([]); // setting the redo stack empty after saving parcel

    uploadedLayer.map((layer) => {
      if (cutLayersName.includes(layer.name)) {
        const featuresArray = [];
        const layerData = {
          layer_data: {
            data: {
              features: featuresArray,
            },
          },
        };
        layer.data.map((item) => {
          const feature = {
            id: 110894,
            geometry_data: {
              type: 'Feature',
              properties: {
                id: item.id,
                Area: item.measurement,
              },
              geometry: {
                type:
                  layer.type === 'polygon'
                    ? 'Polygon'
                    : layer.type === 'line'
                      ? 'LineString'
                      : 'Point',
                coordinates: coordinatesToLatLong(item.data, layer.type),
              },
            },
          };
          featuresArray.push(feature);
        });
        loaderRef.current.style.opacity = 1;
        saveAllLayers( id, viewId, layer.id, layerData, undoStack, redoStack, setSaveUndoStack, setSaveRedoStack);
      }
    });
    let stateUpdate = JSON.parse(JSON.stringify(uploadedLayer));
    setResetLayer(stateUpdate);

    // setUndoStack([]);
    // setRedoStack([]);
    loaderRef.current.style.opacity = 0;
    handleSuccess(TOAST_TYPE.SUCCESS, 'Layers saved successfully!');
  };

  const handleClickOutSide = (e) => {
    if (innerRef.current) {
      if (!innerRef.current.contains(e.target)) {
        outerRef.current.style.opacity = 0;
        outerRef.current.style.pointerEvents = 'none';
      }
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutSide, true);
  }, []);
  const feature_cont_outter_oneNodeList = document.querySelectorAll(".feature-cont-outter-one")
     
  console.log(organization, Number(process.env.REACT_APP_ORG_ID) === Number(organization), process.env.REACT_APP_ORG_ID, "organization")
  const downloadPngWithMeasurement = async () => {
    if (contentRef.current) {
      // var newDiv = document.createElement('div');
      // newDiv.classList.add('comp-right-logo-img-cont');
      // const logoElement1 = document.createElement('img');
      // logoElement1.src = demoImage2;
      // newDiv.appendChild(logoElement1);

      // var newDiv2 = document.createElement('div');
      // newDiv2.classList.add('comp-left-logo-img-cont');
      // const logoElement2 = document.createElement('img');
      // logoElement2.src = orgLogo;
      // newDiv2.appendChild(logoElement2);

      // contentRef.current.appendChild(newDiv);
      // contentRef.current.appendChild(newDiv2);

  

      if(Number(process.env.REACT_APP_ORG_ID) === Number(organization)) {
        document.querySelector('.client-logo-div').style.display = 'flex'; // showing the company logo

      }
      // document.querySelector('.comp-right-logo-img-cont').style.display = 'block';
      document.querySelector('.address-box-for-snapshot').style.display = 'flex'; // showing the address and id bar
      document.querySelector('.Powered-by-box').style.display = 'flex'; // showing the powered by ottermap box
      document.querySelector('.close-btn-div').style.marginTop = '0px'; // showing the powered by ottermap box
      document.querySelector('.measurement-panel-text').style.display = 'none'; // adding margin in the bottom
      document.querySelector('.client-logo-image').style.objectFit = 'contain'; // adding margin in the bottom
      document.querySelectorAll('.feature-name-span').forEach((item) => {
        item.style.margin = '0px auto 0px 10px';
      });

      
      // document.querySelector('.feature-name-span').style.marginLeft = '10px'; // adding margin in the bottom
        
      notesButton.current.style.display = 'none';
      viewPanelRef.current.style.display = 'none';
       let map_leftbar = document.querySelector(".map-leftbar-main-container")
       let feature_visibility_container = document.querySelector(".feature-visibility-container")

       map_leftbar.style.width="fit-content"
       feature_visibility_container.style.padding="1rem"
       feature_visibility_container.style.paddingBottom="5px"

      // document.querySelector(".feature-cont-outter-one").style.gridTemplateColumns="50% 50% "; 60% 33% 33%
      feature_cont_outter_oneNodeList.forEach((eachItem) => {
        
        eachItem.style.display = "flex";
        eachItem.style.justifyContent = "space-between";
        eachItem.style.fontWeight = "600";
      });
      
      
      pngWithMeasureRefs.current.forEach(
        (elem) => (elem.style.display = 'none')
        // (elem) => (elem.style.visibility = 'hidden')
      );
      // console.log( pngWithMeasureRefs," pngWithMeasureRefs")

      const canvas = await html2canvas(contentRef.current,  {
        useCORS: true,    // Attempt to load images with CORS enabled
        allowTaint: false // Ensure the canvas is not tainted
      });
      const screenshotUrl = canvas.toDataURL('image/png');
      const downloadLink = document.createElement('a');
      downloadLink.href = screenshotUrl;
      downloadLink.download = `${address}.png`;
      downloadLink.click();


     
      // contentRef.current.removeChild(newDiv);
      // contentRef.current.removeChild(newDiv2);
      if(Number(process.env.REACT_APP_ORG_ID) === Number(organization)) {
        document.querySelector('.client-logo-div').style.display = 'none'; // hiding the client logo

      }
      // document.querySelector('.comp-right-logo-img-cont').style.display = 'none';
      document.querySelector('.address-box-for-snapshot').style.display = 'none';// hiding the address and id bar
      document.querySelector('.Powered-by-box').style.display = 'none';// hiding the powered by ottermap box
      document.querySelector('.close-btn-div').style.marginTop = '20px'; // showing the powered by ottermap box
      document.querySelector('.close-btn-div').style.marginBottom = '8px'; // showing the powered by ottermap box
      document.querySelector('.measurement-panel-text').style.display = 'block'; // adding margin in the bottom
      document.querySelectorAll('.feature-name-span').forEach((item) => {
        item.style.margin = '0px';
      });
      map_leftbar.style.width="25%"
      feature_visibility_container.style.padding="4% 3%"
      notesButton.current.style.display = 'block';
      viewPanelRef.current.style.display = 'flex';
      // document.querySelector(".feature-cont-outter-one").style.gridTemplateColumns="5% 9% 7% 40% 28% 11%";
      feature_cont_outter_oneNodeList.forEach((eachItem)=> 
        eachItem.style.display="grid"
        // eachItem.style.width="grid"
       )
      pngWithMeasureRefs.current.forEach(
        (elem) => (elem.style.display = 'flex')
      );
      feature_cont_outter_oneNodeList.forEach((eachItem) => {
        eachItem.style.fontWeight = "500";
      });
      handleClose();

      handleSuccess(TOAST_TYPE.SUCCESS, 'Image Downloaded Successfully.');
    }
  };

 

  const EditingTools = (
    <div className="common-editing-tool">
      <Tooltip title="Undo">
        <UndoIcon
          onClick={undoStack.length ? handleUndo : null}
          className={`undo-redo-btn ${undoStack.length && 'undo-redo-btn_active'
            }`}
          sx={{ fontSize: '1.4rem' ,padding:"0.2rem" }}
        />
      </Tooltip>
      <Tooltip title="Redo" sx={{ fontSize: '1.4rem' }}>
        <RedoIcon
          onClick={redoStack.length ? handleRedo : null}
          className={`undo-redo-btn ${redoStack.length && 'undo-redo-btn_active'
            }`}
        />
      </Tooltip>
      <Tooltip title="Reset">
        <RestartAltIcon
          // onClick={handleReset}
          onClick={() => {
            if (undoStack.length || redoStack.length) {
              setSaveResetModal('reset');
              outerRef.current.style.opacity = 1;
              outerRef.current.style.pointerEvents = 'auto';
            }
          }}
          className={`undo-redo-btn ${(redoStack.length || undoStack.length) &&
            'undo-redo-btn_active'
            }`}
          sx={{ fontSize: '1.4rem' ,padding:"0.2rem"  }}
        />
      </Tooltip>

      
      <Tooltip title="Multilayer Cut">
        <ContentCutIcon
          sx={{ fontSize: '1.1rem', padding:"0.4rem" }}
          className={layersCount !== uploadedLayer.length ? "common-editing-tool-icon-disabled" : "common-editing-tool-icon"}
          onClick={() => {
            if (layersCount === uploadedLayer.length) { // otter-116 fix (removing cutAllInteraction because it wont let user use the cut interaction after esc press)
            // if (!cutAllInteraction && layersCount === uploadedLayer.length) {
              
              unByKey(hoverMapKey);
              setCutInteraction(false);
              setCutAllInteraction(true);
              cutAllLayer(
                map,
                setUndoStack,
                setRedoStack,
                setZoomLayer,
                handleSuccess,
                uploadedLayer,
                cutLayersName,
                setUploadedLayer,
                setCutLayersName,
                setCurrentPointerIdx
              );
              unByKey(currentPointerIdx);
              setLineInteraction(false);
              setEditInteraction(false);
              setPointInteraction(false);
              setSplitInteraction(false);
              isContextInteraction(false);
              setMultipleContextInteraction(false);
              setPolygonInteraction(false);
              setMultipleSelectInteraction(false);
            }
          }}
        />
      </Tooltip>
      <Tooltip title="Save">
        <SaveIcon
          className={layersCount !== uploadedLayer.length ? "common-editing-tool-icon-disabled" : "common-editing-tool-icon"}
          onClick={() => {
            if (layersCount === uploadedLayer.length) {
              setSaveResetModal('save');
              outerRef.current.style.opacity = 1;
              outerRef.current.style.pointerEvents = 'auto';
            }
          }}
          sx={{ fontSize: '1.3rem' ,padding:"0.2rem" }}
        />
      </Tooltip>
      {/* <Tooltip title="Measurement">
                <SquareFootIcon
                  className={
                    isAreaActive
                      ? 'common-editing-tool-icon-active'
                      : 'common-editing-tool-icon'
                  }
                  onClick={() => {
                    if (isAreaActive) {
                      setAreaFeatures([]);
                      map.getLayers().forEach((layer) => {
                        if (layer?.get('title') === 'AreaLabel') {
                          map.removeLayer(layer);
                        }
                      });
                      map.getLayers().forEach((layer) => {
                        if (layer?.get('title') === 'AreaLabel') {
                          map.removeLayer(layer);
                        }
                      });
                      removeAllInteraction(map);
                      setIsAreaActive(!isAreaActive);
                    } else {
                      measurePolygon(
                        map,
                        isAreaActive,
                        areaFeatures,
                        setZoomLayer,
                        handleSuccess,
                        measurementRef,
                        setAreaFeatures,
                        setIsAreaActive
                      );
                    }
                  }}
                />
              </Tooltip> */}
      <div ref={loaderRef} className="loader_outer-container">
        <div className="main_loader_container">
          <span className="bulk_loader"></span>
        </div>
      </div>
    </div>
  )

  return (
    <div className="measurement-panel-header-container">
      <div
        style={{
          display: 'flex',
          width: '100%',
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}
      >
        <div className="measurement-panel-header-container-left hideOnScreenshot"
        style={{justifyContent:(role !== 'otter_user' && !isStudioOrder &&
            status === STATUS.MEASUREMENT_COMPLETED &&
            outerViewName === 'Default View') ? "flex-end":"space-around"}}
        >
          {isStudioOrder ? EditingTools : outerViewName !== 'Default View' ? EditingTools : null}
          {role !== 'otter_user' && !isStudioOrder &&
            status === STATUS.MEASUREMENT_COMPLETED &&
            outerViewName === 'Default View' ? (
            <Tooltip title="Raise feedback/issue(in the map)">
            <button onClick={showFeedbackModal} style={{display:"flex",justifyContent:"center",alignItems:"center",gap:"0.4rem" ,
                        visibility: (isPreviewPath && !fdbkid) ? "hidden" : "visible",

            }}>
            <img src={feedback_Icon} style={{maxWidth:"1rem"}}/> 
             {/* Raise feedback */}
            </button>
            </Tooltip>  
            
          ) : null}
        </div>
        {feedbackModalShow && (
          <div className="feedbackModalContainer">
            <FeedbackModal
              handleError={handleError}
              closeDiyTool={closeDiyTool}
              onClose={showFeedbackModal}
              mapClickable={mapClickable}
              handleSuccess={handleSuccess}
              feedbackLayer={feedbackLayer}
              setFeedbackAdded={setFeedbackAdded}
              setFeedbackMarker={setFeedbackMarker}
              toggleMapClickable={toggleMapClickable}
              setFeedbackEditable={setFeedbackEditable}
              setFeedbackModalShow={setFeedbackModalShow}
              setAllFeedbackResolved={setAllFeedbackResolved}
              setFeedbackLayerVisible={setFeedbackLayerVisible}
              toggleFeedbackMarkerVisible={toggleFeedbackMarkerVisible}
            />
          </div>
        )}
        {!isStudioOrder ?
          <div className="measurement-panel-header-container-right hideOnScreenshot" 
          style={{
          visibility: (isPreviewPath && prevfdbkk === "true") || isPreviewPath ===true ? "hidden" : "visible",
        }}
          >
            {role !== 'otter_user' ? (
              <div style={{ display: 'flex' }}>
                <DownloadIcon
                  onClick={(e) => {
                    setAnchorEl(e.currentTarget);
                    closeDiyTool();
                  }}
                  className="measurement-panel-header-right-icon"
                />
                <Menu
                  id="long-menu"
                  MenuListProps={{
                    'aria-labelledby': 'long-button',
                  }}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  PaperProps={{
                    style: {
                      // maxHeight: 49 * 4.5,
                      width: '25ch',
                    },
                  }}
                  sx={{
                    top: '2%',
                  }}
                >
                  <MenuItem className="order-action-menu-item-download">
                    <div onClick={downloadKml}>
                      <DownloadIcon className="download-icon-act" />
                      <span>Download KML</span>
                    </div>
                  </MenuItem>
                  <MenuItem className="order-action-menu-item-download">
                    <a
                      // href={`${ORDER_URL}${params.id}/view/${viewId}/layers?download=zip`}
                      onClick={downloadGeoJson}
                    >
                      <div>
                        <DownloadIcon className="download-icon-act" />
                        <span>Download GeoJson</span>
                      </div>
                    </a>
                  </MenuItem>
                  <MenuItem className="order-action-menu-item-download">
                    <a
                      // href={`${ORDER_URL}${params.id}/view/${viewId}/layers?download=xlsx`}
                      onClick={downloadExcel}
                    >
                      <div>
                        <DownloadIcon className="download-icon-act" />
                        <span>Download as Excel</span>
                      </div>
                    </a>
                  </MenuItem>
                  <MenuItem className="order-action-menu-item-download">
                    <div
                      onClick={() => {
                        downloadPng();
                        handleClose();
                      }}
                    >
                      <DownloadIcon className="download-icon-act" />
                      <span>Download PNG</span>
                    </div>
                  </MenuItem>
                  <MenuItem className="order-action-menu-item-download">
                    {status !== 'diy' && (
                      <div
                        id="png-with-measurement"
                        onClick={downloadPngWithMeasurement}
                      >
                        <DownloadIcon className="download-icon-act" />
                        <span>Download PNG with Measurements</span>
                      </div>
                    )}
                  </MenuItem>
                </Menu>
              </div>
            ) : null}
            {role !== 'otter_user' ? (
              <ShareIcon
                onClick={copyLink}
                className="measurement-panel-header-right-icon"
              />
            ) : null}
            {/* <MoreVertIcon className="measurement-panel-header-right-icon" /> */}
          </div> : null}
        <div ref={outerRef} className="review-and-submit-warning-outer">
          <div ref={innerRef} className="review-and-submit-warning-inner">
            <div className="raise-feedback-inner-top">
              <span
                onClick={() => {
                  outerRef.current.style.opacity = 0;
                  outerRef.current.style.pointerEvents = 'none';
                }}
              >
                X
              </span>
            </div>
            <div className="review-and-submit-warning-inner-mid-div">
              <p>Are you certain you wish to {saveResetModal} the changes?</p>
            </div>
            <div className="view-modal-bttns">
              <button
                onClick={() => {
                  outerRef.current.style.opacity = 0;
                  outerRef.current.style.pointerEvents = 'none';
                }}
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  closeDiyTool();
               saveResetModal && saveResetModal === 'save'
                    ? handleSaveLayer()
                    : handleReset();
                  outerRef.current.style.opacity = 0;
                  outerRef.current.style.pointerEvents = 'none';
                }}
              >
                Proceed
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="comp-right-logo-img-cont">
        <img src={demoImage2} alt="Ottermap Logo" />
      </div> */}
    </div>
  );
};

export default MeasurementPanel;
