import axios from "axios";
import { TEAM_DETAILS_URL } from "../../helper/ApiUrl";

const getUsersDetails = (token, setUsersDetails) => {
    axios
        .get(TEAM_DETAILS_URL, {
            headers: {
                Authorization: `Token ${token}`,
                Accept: "application/json",
            },
        })
        .then((response) => {
            setUsersDetails(response.data.data);
        })
        .catch((error) => {
            console.error(error.message);
        });
};

export default getUsersDetails;