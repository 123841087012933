import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import CloseIcon from '@mui/icons-material/Close';

const OrderTags = ({
  tagRef,
  orderTags,
  removeTagOnClick,
  suggestionRef,
  handleFocus,
  tagName,
  addTagOnEnter,
  setTagName,
  finalTagArray,
  handleSearch,
  addTagOnClick,
  allTags,
}) => {
  return (
    <div className="order-tags-div" ref={tagRef}>
      <div className="tags-div-close-icon-container">
        <CloseIcon
          onClick={() => (tagRef.current.style.display = 'none')}
          style={{ color: '#000000', cursor: 'pointer', fontSize: '1em' }}
        />
      </div>
      <div className="order-tags-div-zero">
        <div className="address-div-one-bottom">
          {orderTags &&
            orderTags.map((tag) => {
              return (
                <Tooltip title={tag}>
                  <span
                    style={{
                      color: '#06372a',
                      backgroundColor: '#f9f490',
                      cursor: 'pointer',
                    }}
                    onClick={() => removeTagOnClick(tag)}
                  >
                    {tag.slice(0, 3)}..x
                  </span>
                </Tooltip>
              );
            })}
        </div>
      </div>
      <div
        className="order-tags-div-one"
        onClick={() => {
          suggestionRef.current.style.display = 'none';
        }}
      >
        <h4>Tags</h4>
      </div>
      <div className="order-tags-div-two">
        <input
          type="text"
          onFocus={handleFocus}
          value={tagName}
          onKeyPress={addTagOnEnter}
          placeholder="Search or Create New"
          onChange={(e) => setTagName(e.target.value)}
        />
        <div className="tag-suggestion-outer" ref={suggestionRef}>
          <div className="tag-suggestion">
            {finalTagArray &&
              handleSearch(finalTagArray).map((tag, index) => {
                return (
                  <div
                    key={index}
                    onClick={() => {
                      addTagOnClick(tag.name);
                    }}
                  >
                    {tag.tag_type === 'user' ? (
                      <span
                        key={index}
                        style={{
                          color: '#06372a',
                          background: '#f9f490',
                          cursor: 'pointer',
                        }}
                      >
                        {tag.name}
                      </span>
                    ) : null}
                  </div>
                );
              })}
            <div>
              <span className="create-a-new-tag-div">
                Press Enter to create a new tag
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="order-tags-div-three">
        <div className="tags-div-three-top">
          <span>Common Tags</span>
          <div className="common-tags">
            {finalTagArray &&
              finalTagArray.map((tag, index) => {
                return (
                  <div
                    onClick={() => {
                      addTagOnClick(tag.name);
                    }}
                  >
                    {tag.tag_type === 'user' ? (
                      <span
                        key={index}
                        style={{
                          color: '#06372a',
                          background: '#f9f490',
                          cursor: 'pointer',
                        }}
                      >
                        {tag.name}
                      </span>
                    ) : null}
                  </div>
                );
              })}
          </div>
        </div>
        <div className="tags-div-three-bottom">
          <span>Admin Tags</span>
          <div className="common-tags">
            {allTags &&
              allTags.map((tag, index) => {
                return (
                  <div key={index}>
                    {tag.tag_type === 'admin' ? (
                      <span
                        key={index}
                        style={{
                          color: '#06372a',
                          background: '#f9f490',
                          cursor: 'pointer',
                        }}
                      >
                        {tag.name}
                      </span>
                    ) : null}
                  </div>
                );
              })}
          </div>
        </div>
      </div>
      <div className="order-tags-div-four">
        <button
          onClick={() => {
            tagRef.current.style.display = 'none';
          }}
        >
          ✓ Done
        </button>
      </div>
    </div>
  );
};

export default OrderTags;
