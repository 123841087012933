import React, { useContext, useState, createContext } from "react";

const PublicAddressContext = createContext();

export function PublicAddressContextProvider({ children }) {
  const [address, setAddress] = useState(null);
  const [latLng, setLatLng] = useState([0, 0]);


  return (
    <PublicAddressContext.Provider
      value={{
        address,
        setAddress,
        latLng,
        setLatLng
      }}
    >
      {children}
    </PublicAddressContext.Provider>
  );
}

export function usePublicAddressContext() {
  const context = useContext(PublicAddressContext);
  if (context === undefined) {
    throw new Error("Context must be used within a Provider");
  }
  return context;
}
