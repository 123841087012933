import {
  Combobox,
  ComboboxList,
  ComboboxInput,
  ComboboxOption,
  ComboboxPopover,
} from "@reach/combobox";
import axios from "axios";
import { v4 as uuidv4 } from 'uuid';
import React, { useState, useRef } from "react";
import "./PublicAddressSearch.css";
import { TOAST_TYPE } from "../../utils/constants";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import { useScreenType } from "../../utils/commonMethods";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import { useLocation, useNavigate } from "react-router-dom";
import { usePublicAddressContext } from "../../context/PublicAddressContext";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { ReactComponent as ArrowIcon } from "../../assets/Arrow.svg";
import { IconButton, Box } from '@mui/material';
import {EXPLORE_LOCATION_COORDINATES_URL, EXPLORE_ADDRESS_SUGGESSTION_URL} from './../../helper/ApiUrl.js';
import { encodeJwtToken } from "../../utils/EncodeJwt.js";


export default function AddressSearch({ handleError, handleSuccess }) {
  const isMobile = useScreenType();
  const [value, setValue] = useState("");
  const [addressSuggestions, setAddressSuggestions] = useState([]);
  const { setAddress, setLatLng } = usePublicAddressContext();
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname;
  const debounceTimeout = useRef(null);

  const searchAddress = (value) => {
    console.log(encodeURIComponent(value), "encodeURIComponent(value)");
  
    axios.get(`${EXPLORE_ADDRESS_SUGGESSTION_URL}?address=${encodeURIComponent(value)}`,{

      headers:{
        "x-api-key": `${encodeJwtToken()}`,
        Accept: 'application/json',
      }
    })
      .then(response => {
        console.log(response.data); // Handle the response data
        setAddressSuggestions(response?.data?.data);
      })
      .catch(error => {
        console.error('Error:', error); // Handle any errors
      });
  };

  const debouncedSearchAddress = (value) => {
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }
    debounceTimeout.current = setTimeout(() => {
      searchAddress(value);
    }, 300); // Adjust the debounce time as needed
  };

  const GetCoordinates = (completeAddress) => {
    console.log(completeAddress, "completeAddress");

    return axios.get(`${EXPLORE_LOCATION_COORDINATES_URL}?address=${encodeURIComponent(completeAddress)}`,{
      headers:{
        "x-api-key":`${encodeJwtToken()}`,
        Accept:'application/json'
      }
    })
      .then(response => {
        console.log(response.data); // Handle the response data
        return response.data;
      })
      .catch(error => {
        console.error('Error:', error); // Handle any errors
      });
  };

  const handleInput = (e) => {
    setValue(e.target.value);
    debouncedSearchAddress(e.target.value);
  };

  const handleSelect = async (address) => {
    try {
      GetCoordinates(address).then((data) => {
        if (data) {
          setAddress(address);
          setLatLng([data?.long, data?.lat]);
          navigate("/explore");
        }
      });
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const handleKeyPress = async (event) => {
    if (event.key === "Enter" && value) {
      handleSelect(value);
    }
  };

  const handleClick = () => {
    if (value) {
      handleSelect(value);
    }
  };

  const locateMe = () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        handleSelect(`${latitude},${longitude}`);
  
      },
      (error) => {
        switch (error.code) {
          case 1: // Permission Denied
            handleSuccess(TOAST_TYPE.WARNING, 'Please allow location access');
            break;
          case 2: // Position Unavailable
            handleSuccess(TOAST_TYPE.ERROR, 'Location information is unavailable');
            break;
          case 3: // Timeout
            handleSuccess(TOAST_TYPE.ERROR, 'Location request timed out');
            break;
          default:
            handleSuccess(TOAST_TYPE.ERROR, 'An unknown error occurred');
        }
  
        // Log error for debugging
        console.error("Error getting location", error);
      }
    );
  };
  
  console.log(path, "path in map");

  return (
    <Box sx={{display: "flex", alignItems: "center", width: "100%", justifyContent: "center",}}>
    <Combobox onSelect={handleSelect} className="public-search-bar-wrapper">
      <div className="public-search">
        <SearchOutlinedIcon className="search-icon" />
        <ComboboxInput
          value={value}
          style={{fontSize: "clamp(14px, 1vw, 1vw)", borderTopRightRadius: "0px", borderBottomRightRadius: "0px"}}
          onChange={handleInput}
          placeholder="Search Address or Lat Long"
          onKeyPress={handleKeyPress}
        />
        <MyLocationIcon
          onClick={locateMe}
          className="public-locate-me-icon"
          // sx={path === "/home" ? { right: "8%", marginTop: "1.5%" } : undefined}
        />
      </div>

      <ComboboxPopover style={{borderRadius: "8px", overflow: "hidden"}} >
        <ComboboxList className="public-search-suggestions-list">
          {addressSuggestions?.map((item) => (
            <div key={uuidv4()} className="public-search-suggestions-item">
              <FmdGoodIcon />
              <ComboboxOption key={item} value={item} className="public-search-suggestions-option" />
            </div>
          ))}
        </ComboboxList>
      </ComboboxPopover>
    </Combobox>
    <IconButton onClick={handleClick} size="small" className="public-arrow-icon">
          <ArrowIcon className="public-child-arrow" />
    </IconButton>
    </Box>
  );
}
