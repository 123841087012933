import {
  FILE_UPLOAD,
  INVITE_MEMBER,
  TEAM_DETAILS_URL,
  USER_ORG_CREATE_URL,
} from '../../helper/ApiUrl';
import axios from 'axios';
import { useState } from 'react';
import { useEffect } from 'react';
import './OrganizationSetting.css';
import Avatar from '@mui/material/Avatar';
import { Tooltip } from '@material-ui/core';
import { useDropzone } from 'react-dropzone';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import AuthToken from '../../context/AuthToken';
import { TOAST_TYPE } from '../../utils/constants';
import React, { useRef, useCallback } from 'react';
import CircularProgress from '@mui/joy/CircularProgress';
import { useScreenType } from '../../utils/commonMethods';
import { userPermission } from '../../helper/permissions';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import CloudUploadGreen  from '../../assets/CloudGreenIconn.svg'


const OrganizationSetting = ({ handleError, handleSuccess }) => {
  const token = AuthToken();
  const outerRef = useRef();
  const innerRef = useRef();
  const isMobile = useScreenType();
  const maxFileSize = 10 * 1024 * 1024;
  const roleDropDownRef = useRef();
  const revokeInviteRef = useRef();
  const inviteInnerRef = useRef();
  const inviteOutterRef = useRef();
  const [name, setName] = useState('');
  const revokeInviteInnerRef = useRef();
  const [orgCode, setOrgCode] = useState();
  const role = localStorage.getItem('role');
  const [error, setError] = useState(false);
  const [orgLogo, setOrgLogo] = useState(null);
  const [orgName, setOrgName] = useState({});
  const [status, setStatus] = useState(false);
  const [selected, setSelected] = useState(null);
  const [isUploaded, setIsUploaded] = useState(false);
  const [invitationId, setInvitationId] = useState();
  const [usersDetails, setUsersDetails] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [isProgressive, setIsProgressive] = useState(false);
  const [selectedFile, setSelectedFile] = useState(undefined);
  const [invitedUsersList, setInvitedUsersList] = useState([]);
  const [revokeInvitation, setRevokeInvitation] = useState(false);
  const [inputFields, setInputFields] = useState([
    { value: '' },
    { value: '' },
    { value: '' },
  ]);

  const handleInputChange = (event, index) => {
    const value = event.target.value;
    setInputFields((prevState) => {
      const inputFields = [...prevState];
      inputFields[index].value = value;
      return inputFields;
    });
  };

  const handleAddInput = () => {
    setInputFields((prevState) => [...prevState, { value: '' }]);
  };

  const handleAccordianToggle = (num) => {
    if (selected === num) {
      return setSelected(null);
    }
    setSelected(num);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setOrgName({
      ...orgName,
      [name]: value,
    });
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    axios
      .patch(
        USER_ORG_CREATE_URL,
        { org_logo: orgLogo, org_name: orgName.org_name },
        {
          headers: {
            Authorization: `Token ${token}`,
            Accept: 'application/json',
          },
        }
      )
      .then((response) => {
        if (response.status === 204) {
          setStatus(true);
          handleSuccess(
            TOAST_TYPE.SUCCESS,
            'Organization details has been updated!'
          )
        } else {
          setError(true);
        }
      })
      .catch((error) => {
        console.error(error.message);
      });
  };

  const getOrgInfo = () => {
    axios
      .get(USER_ORG_CREATE_URL, {
        headers: {
          Authorization: `Token ${token}`,
          Accept: 'application/json',
        },
      })
      .then((response) => {
        setName(response.data.data.org_name);
        setOrgCode(response.data.data.org_code);
        setOrgLogo(response.data.data.org_logo);
      })
      .catch((error) => {
        console.error(error.message);
      });
  };

  const getUsersDetails = () => {
    axios
      .get(TEAM_DETAILS_URL, {
        headers: {
          Authorization: `Token ${token}`,
          Accept: 'application/json',
        },
      })
      .then((response) => {
        setUsersDetails(response.data.data);
      })
      .catch((error) => {
        console.error(error.message);
      });
  };

  const sendInvitation = () => {
    let emails = '';
    inputFields.map((item) => {
      emails += item.value + ',';
    });
    const emailsData = {
      emails: emails,
    };

    axios
      .post(INVITE_MEMBER, emailsData, {
        headers: {
          Authorization: `Token ${token}`,
          Accept: 'application/json',
        },
      })
      .then((response) => {
        setRevokeInvitation(!revokeInvitation);
        setInputFields((prevState) =>
          prevState.map((inputField) => ({ value: '' }))
        );
      })
      .catch((error) => {
        console.error(error.message);
      });
  };

  const getInvitedUsers = () => {
    axios
      .get(INVITE_MEMBER, {
        headers: {
          Authorization: `Token ${token}`,
          Accept: 'application/json',
        },
      })
      .then((response) => {
        setInvitedUsersList(response.data.data);
      })
      .catch((error) => {
        console.error(error.message);
      });
  };

  useEffect(() => {
    getInvitedUsers();
  }, [revokeInvitation]);

  const resendInvite = (invitationId) => {
    axios
      .put(
        `${INVITE_MEMBER}/${invitationId}`,
        {},
        {
          headers: {
            Authorization: `Token ${token}`,
            Accept: 'application/json',
          },
        }
      )
      .then((response) => {
        setRevokeInvitation(!revokeInvitation);
      })
      .catch((error) => {
        console.error(error.message);
      });
  };

  const revokeInvite = () => {
    axios
      .delete(`${INVITE_MEMBER}/${invitationId}`, {
        headers: {
          Authorization: `Token ${token}`,
          Accept: 'application/json',
        },
      })
      .then((response) => {
        setRevokeInvitation(!revokeInvitation);
      })
      .catch((error) => {
        console.error(error.message);
      });
  };

  useEffect(() => {
    getOrgInfo();
    getUsersDetails();
  }, []);

  const changeRole = (memberId, memberRole) => {
    const roleData = { role: `${memberRole}` };
    axios
      .put(`${TEAM_DETAILS_URL}/${memberId}`, roleData, {
        headers: {
          Authorization: `Token ${token}`,
          Accept: 'application/json',
        },
      })
      .then((response) => {
        getUsersDetails();
      })
      .catch((error) => {
        console.error(error.message);
      });
  };

  const handleClickOutSide = (e) => {
    if (innerRef.current) {
      if (!innerRef.current.contains(e.target)) {
        outerRef.current.style.opacity = 0;
        outerRef.current.style.pointerEvents = 'none';
      }
    }
    if (inviteInnerRef.current) {
      if (!inviteInnerRef.current.contains(e.target)) {
        inviteOutterRef.current.style.opacity = 0;
        inviteOutterRef.current.style.pointerEvents = 'none';
      }
    }
    if (revokeInviteInnerRef.current) {
      if (!revokeInviteInnerRef.current.contains(e.target)) {
        revokeInviteRef.current.style.opacity = 0;
        revokeInviteRef.current.style.pointerEvents = 'none';
      }
    }
    if (roleDropDownRef.current) {
      if (!roleDropDownRef.current.contains(e.target)) {
      } else {
      }
    }
  };

  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles.length === 0)
      setErrorMessage('Select not more than 1 file');
    let file = acceptedFiles[0];
    if (file.size > maxFileSize)
      setErrorMessage('File size is greater than 10mb');
    else {
      setSelectedFile(file);
      setErrorMessage('');
    }
  }, []);

  const handleUpload = () => {
    if (selectedFile) {
      setIsProgressive(true);
      const formData = new FormData();
      formData.append('file', selectedFile);
      axios
        .post(FILE_UPLOAD, formData, {
          headers: {
            Authorization: `Token ${token}`,
            Accept: 'application/json',
          },
        })
        .then((response) => {
          if (response.data.url) {
            setOrgLogo(response.data.url);
            setSelectedFile(undefined);
            setIsProgressive(false);
            outerRef.current.style.opacity = 0;
            outerRef.current.style.pointerEvents = 'none';
            setIsUploaded(!isUploaded);
            handleSuccess(TOAST_TYPE.SUCCESS, 'Organization Logo Uploaded.');
          }
        })
        .catch((error) => {
          handleError(error);
        });
    } else {
      handleSuccess(TOAST_TYPE.WARNING, 'Please choose file to upload!');
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    onDrop,
    accept: {
      'image/jpeg': [],
      'image/png': [],
      'image/jpg': [],
    },
  });

  useEffect(() => {
    document.addEventListener('click', handleClickOutSide, true);
  }, []);

  const copyCode = () => {
    navigator.clipboard.writeText(orgCode);
    handleSuccess(TOAST_TYPE.SUCCESS, 'Code Copied to Clipboard');
  };


  return (
    <div className="organization-setting-container">
      <div className="organization-setting">
        <div className="organization-setting-left">
          <div className="organization-profile-pic">
            <div className="profile-pic-heading">
              <h4>Logo</h4>
            </div>
            <div className="profile-pic-content">
              <Avatar
                className="org-pic-image"
                alt="organization-logo"
                src={
                  orgLogo
                    ? orgLogo
                    : 'https://i.pinimg.com/736x/9a/64/8a/9a648ac91d323b0931448ab34055f967.jpg'
                }
              />
              <button
                className="org-logo-edit-button"
                onClick={() => {
                  outerRef.current.style.opacity = 1;
                  outerRef.current.style.pointerEvents = 'auto';
                }}
              >
                <EditIcon className="org-logo-edit-icon" />
              </button>
            </div>
          </div>
          <div className="organization-details">
            <form onSubmit={handleUpdate}>
              <div>
                <div className="profile-pic-heading">
                  <h4>Name</h4>
                </div>
                <input
                  readOnly={
                    role === 'user'
                      ? !userPermission.permissions.ORG_MODYFY
                      : false
                  }
                  className="formInput"
                  type="text"
                  name="org_name"
                  autoComplete="off"
                  defaultValue={name}
                  onChange={handleChange}
                />
              </div>
              <div className="org-setting-two">
                <button
                  type="submit"
                  disabled={
                    role === 'user'
                      ? !userPermission.permissions.ORG_MODYFY
                      : false
                  }
                  className="saveButton"
                >
                  ✓ Save
                </button>
              </div>
            </form>
          </div>
          <div>
            {error && <span className="error">Something went wrong!</span>}
            {/* {status && (
              <span className="success">
                Organization details has been updated!
              </span>
            )} */}

          </div>
          <div className="invite-member">
            <div className="profile-pic-heading">
              <h4>Member</h4>
            </div>
            <p>
              By clicking on this button you can invite one or multiple members
              at once.
            </p>
            <button
              className="saveButton"
              disabled={
                role === 'user'
                  ? !userPermission.permissions.TEAM_MODYFY
                  : false
              }
              onClick={() => {
                inviteOutterRef.current.style.opacity = 1;
                inviteOutterRef.current.style.pointerEvents = 'auto';
              }}
            >
              Invite People
            </button>
          </div>
          <div className="org-code">
            <div className="profile-pic-heading">
              <h4>Code</h4>
            </div>
            <div className="workspace-code-copy">
              <p>
                Your workspace code is <span>{orgCode}</span>
              </p>{' '}
              <Tooltip title="Copy Code" placement="top">
                <ContentCopyIcon onClick={copyCode} className='copy-code-icon'
                />
              </Tooltip>
            </div>
          </div>
        </div>
        <div className="organization-setting-right">
          <div className="organization-setting-right-one">
            <h4>Team Members</h4>
            {usersDetails.map((user) => {
              return (
                <div className="org-user-card">
                  <div className="org-user-card-one">
                    <Avatar
                      alt="Cindy Baker"
                      className="org-user-card-one-img"
                      src="https://i.pinimg.com/736x/9a/64/8a/9a648ac91d323b0931448ab34055f967.jpg"
                    />
                  </div>
                  <div className="org-user-card-two">
                    <p className="org-user-card-two-sp">{user.name}</p>
                    <span className="org-user-card-two-sp">{user.email}</span>
                    {isMobile && (
                      <div
                        className="org-user-card-three"
                        ref={roleDropDownRef}
                      >
                        {' '}
                        {user.role === 'admin' ? (
                          <AdminPanelSettingsIcon className="view-icon" />
                        ) : (
                          <SupervisorAccountIcon className="view-icon" />
                        )}
                        <div
                          className={
                            selected === user.id
                              ? 'role-operation-btn open'
                              : 'role-operation-btn'
                          }
                          onClick={() => {
                            if (role !== 'user') handleAccordianToggle(user.id);
                          }}
                        >
                          <span className="view-btn-text">
                            {user.role.charAt(0).toUpperCase() +
                              user.role.slice(1)}
                          </span>
                          <KeyboardArrowDownIcon className="view-panel-arrow-icon" />
                        </div>
                        <ul className="view-panel-list-items role-list-items">
                          <div className="role-list-items-div">
                            <li
                              className="view-panel-item"
                              onClick={() => {
                                changeRole(user.id, 'admin');
                                handleAccordianToggle(user.id);
                              }}
                            >
                              <span className="view-panel-text">Admin</span>
                            </li>
                            <li
                              className="view-panel-item"
                              onClick={() => {
                                changeRole(user.id, 'user');
                                handleAccordianToggle(user.id);
                              }}
                            >
                              <span className="view-panel-text">User</span>
                            </li>
                          </div>
                        </ul>
                      </div>
                    )}
                  </div>
                  {!isMobile && (
                    <div className="org-user-card-three" ref={roleDropDownRef}>
                      {' '}
                      {user.role === 'admin' ? (
                        <AdminPanelSettingsIcon className="view-icon" />
                      ) : (
                        <SupervisorAccountIcon className="view-icon" />
                      )}
                      <div
                        className={
                          selected === user.id
                            ? 'role-operation-btn open'
                            : 'role-operation-btn'
                        }
                        onClick={() => {
                          if (role !== 'user') handleAccordianToggle(user.id);
                          // if (user.role === 'admin') handleSuccess(TOAST_TYPE.WARNING, 'Are you certain wish to change from admin to user. Note - this action cant be undone');

                        }}
                      >
                        <span className="view-btn-text">
                          {user.role.charAt(0).toUpperCase() +
                            user.role.slice(1)}
                        </span>
                        <KeyboardArrowDownIcon className="view-panel-arrow-icon" />
                      </div>
                      <ul className="view-panel-list-items role-list-items">
                        <div className="role-list-items-div">
                          {user.role.charAt(0).toUpperCase() +
                            user.role.slice(1) !== 'Admin' && <li
                              className="view-panel-item"
                              onClick={() => {
                                changeRole(user.id, 'admin');
                                handleAccordianToggle(user.id);
                              }}
                            >
                              <span className="view-panel-text">Admin</span>
                            </li>}

                          {user.role.charAt(0).toUpperCase() +
                            user.role.slice(1) !== 'User' && <li
                              className="view-panel-item"
                              onClick={() => {
                                changeRole(user.id, 'user');
                                handleAccordianToggle(user.id);

                              }}
                            >
                              <span className="view-panel-text" >User</span>
                            </li>
                          }
                        </div>
                      </ul>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
          <div className="organization-setting-right-two">
            <h4>Invited Members</h4>
            {invitedUsersList &&
              invitedUsersList.map((user) => {
                return (
                  <div className="invite-user-card">
                    <div className="org-user-card-one">
                      <Avatar
                        alt="Cindy Baker"
                        className="org-user-card-one-img"
                        src="https://i.pinimg.com/736x/9a/64/8a/9a648ac91d323b0931448ab34055f967.jpg"
                      />
                    </div>
                    <div className="invite-user-card-two">
                      <p>{user.invited_user}</p>
                      <span className="invite-pending org-user-card-two-sp">
                        {user.status.charAt(0).toUpperCase() +
                          user.status.slice(1)}
                      </span>
                      {isMobile && (
                        <div className="invite-user-card-three">
                          <button
                            disabled={
                              role === 'user'
                                ? !userPermission.permissions.TEAM_MODYFY
                                : false
                            }
                            className="resend-invite-btn"
                            onClick={() => resendInvite(user.id)}
                          >
                            Resend Invite
                          </button>
                          <button
                            disabled={
                              role === 'user'
                                ? !userPermission.permissions.TEAM_MODYFY
                                : false
                            }
                            className="revoke-invite-btn"
                            onClick={() => {
                              setInvitationId(user.id);
                              revokeInviteRef.current.style.opacity = 1;
                              revokeInviteRef.current.style.pointerEvents =
                                'auto';
                            }}
                          >
                            Revoke Invite
                          </button>
                        </div>
                      )}
                    </div>
                    {!isMobile && (
                      <div className="invite-user-card-three">
                        <button
                          disabled={
                            role === 'user'
                              ? !userPermission.permissions.TEAM_MODYFY
                              : false
                          }
                          className="resend-invite-btn"
                          onClick={() => resendInvite(user.id)}
                        >
                          Resend Invite
                        </button>
                        <button
                          disabled={
                            role === 'user'
                              ? !userPermission.permissions.TEAM_MODYFY
                              : false
                          }
                          className="revoke-invite-btn"
                          onClick={() => {
                            setInvitationId(user.id);
                            revokeInviteRef.current.style.opacity = 1;
                            revokeInviteRef.current.style.pointerEvents =
                              'auto';
                          }}
                        >
                          Revoke Invite
                        </button>
                      </div>
                    )}
                  </div>
                );
              })}
          </div>
          <div ref={revokeInviteRef} className="revoke-invite-container">
            <div
              ref={revokeInviteInnerRef}
              className="revoke-invite-inner-container"
            >
              <div className="revoke-invite-inner-top">
                <h4>Revoke Invite</h4>
                <span
                  onClick={() => {
                    revokeInviteRef.current.style.opacity = 0;
                    revokeInviteRef.current.style.pointerEvents = 'none';
                  }}
                >
                  <box-icon name='x'></box-icon>
                </span>
              </div>
              <div className="revoke-invite-inner-mid">
                <p>
                  Revoking an invite will no longer allow this person to become
                  a member of your organization. You can always invite them
                  again if you change your mind
                </p>
              </div>
              <div className="revoke-invite-inner-bottom">
                <button
                  className="resend-invite-btn"
                  onClick={() => {
                    revokeInviteRef.current.style.opacity = 0;
                    revokeInviteRef.current.style.pointerEvents = 'none';
                  }}
                >
                  Keep Invite
                </button>
                <button
                  className="revoke-invite-btn"
                  onClick={() => {
                    revokeInvite();
                    revokeInviteRef.current.style.opacity = 0;
                    revokeInviteRef.current.style.pointerEvents = 'none';
                  }}
                >
                  Revoke Invite
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div ref={outerRef} className="file-upload-outer-container">
        <div ref={innerRef} className="file-upload-inner-container">
          <div className="file-upload-inner-top">
            <h4>Upload Media</h4>
            <span
              onClick={() => {
                outerRef.current.style.opacity = 0;
                outerRef.current.style.pointerEvents = 'none';
              }}
            >
              X
            </span>
          </div>
          <div className="file-upload-container">
            <div className="dropzone-container" {...getRootProps()}>
              <input {...getInputProps()} />
              <p className="dropzone-text">
                Drag and drop file here, or click here to select file (max size:{' '}
                {maxFileSize / 1024 / 1024} MB)
              </p>
              <div className='clouduploadbox' style={{width:"100%",height:"auto" }}>
                       <img src={ CloudUploadGreen}   style={{maxWidth:"80%" }} />
                    
                       </div>
            </div>
            {errorMessage && (
              <div className="error-message">{errorMessage}</div>
            )}
            {selectedFile ? (
              <div className="file-list-container">
                <h2 className="file-list-heading">Selected File:</h2>
                <ul className="file-list">
                  <Tooltip title={selectedFile.name}>
                    <li key={selectedFile.name} className="file-item">
                      <div className="file-item-img-container">
                        <img
                          className="file-image"
                          src={URL.createObjectURL(selectedFile)}
                          alt={selectedFile.name}
                        />
                      </div>
                      <div className="file-info">
                        <p className="file-name">
                          {selectedFile.name.slice(0, 12)}
                        </p>
                        <p className="file-size">{selectedFile.size} bytes</p>
                        <button
                          className="file-remove-button"
                          onClick={() => setSelectedFile(undefined)}
                        >
                          Remove
                        </button>
                      </div>
                    </li>
                  </Tooltip>
                </ul>
              </div>
            ) : null}
          </div>
          <div className="file-upload-inner-bottom">
            <button
              onClick={() => {
                setSelectedFile(undefined);
                outerRef.current.style.opacity = 0;
                outerRef.current.style.pointerEvents = 'none';
              }}
            >
              Discard
            </button>
            <button onClick={handleUpload}>
              {isProgressive ? (
                <CircularProgress
                  size="sm"
                  value={25}
                  color="success"
                  determinate={false}
                />
              ) : null}
              <span className="file-upload-progress-span">Upload</span>
            </button>
          </div>
        </div>
      </div>
      <div ref={inviteOutterRef} className="invite-member-otter-container">
        <div ref={inviteInnerRef} className="invite-member-inner-container">
        <div style={{display:"flex", justifyContent:"end" }}><span style={{cursor:"pointer"}}
              onClick={() => {
                inviteOutterRef.current.style.opacity = 0;
                inviteOutterRef.current.style.pointerEvents = 'none';
              }}
            >
             <box-icon name='x'></box-icon>
            </span></div>
          <div className="invite-member-inner-container-top">
            <h5>Invite Members to your Organization</h5>
            {/* <span
              onClick={() => {
                inviteOutterRef.current.style.opacity = 0;
                inviteOutterRef.current.style.pointerEvents = 'none';
              }}
            >
             <box-icon name='x'></box-icon>
            </span> */}
          </div>
          <div className="invite-member-inner-container-mid">
            <div className="invite-member-inner-container-mid-one">
              <label>Email Address</label>
              <div className="invite-member-inner-container-mid-one-input">
                {inputFields.map((inputField, index) => (
                  <input
                    type="text"
                    value={inputField.value}
                    placeholder="Eg. john@example.com"
                    onChange={(event) => handleInputChange(event, index)}
                  />
                ))}
              </div>
              <div
                className="invite-member-inner-container-mid-one-add"
                onClick={handleAddInput}
              >
                <AddIcon className="invite-member-add-icon"/>
                <span>Add More</span>
              </div>
            </div>
          </div>
          <div className="invite-member-inner-container-bottom">
            <button
              onClick={() => {
                sendInvitation();
                inviteOutterRef.current.style.opacity = 0;
                inviteOutterRef.current.style.pointerEvents = 'none';
              }}
            >
              Send Invitation
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrganizationSetting;
