// import React from 'react';
 import './imageview.css';
//  import './newImageview.css'

// const ImageView = ({ imageSrc, onClose, onDownload }) => {
//   return (
// <div className="container">
//   <div className="modal">
//     <div className='download_Cross_div' >
    
//     <button  onClick={onDownload} className="download__button">
//     <box-icon type='solid' name='download'></box-icon>
//     </button>
//     <button  onClick={onClose} className="close-button" >
//     <box-icon name='x'></box-icon>
//     </button>
//     </div>
//     <div className='preview_box'>
//     <img src={imageSrc} alt="Image" className="image" />
//     </div>
    
//   </div>
// </div>

//   );
// };

// export default ImageView;


import React from "react";
import { TransformWrapper, TransformComponent, useControls } from "react-zoom-pan-pinch";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import DownloadIcon from "@mui/icons-material/Download";
import CloseIcon from "@mui/icons-material/Close";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import ReplayIcon from "@mui/icons-material/Replay"; // Reset icon

const Controls = () => {
  const { zoomIn, zoomOut, resetTransform } = useControls();

  return (
    <div className="zoom-controls">
      <button className="zoom-button" onClick={() => zoomOut()}>
        <ZoomOutIcon style={{color:"white"}}/>
      </button>
      <button className="zoom-button" onClick={() => resetTransform()}>
        <ReplayIcon  style={{color:"white"}}/>
      </button>
      <button className="zoom-button" onClick={() => zoomIn()}>
        <ZoomInIcon  style={{color:"white"}}/>
      </button>
    </div>
  );
};

const ImageView = ({ images, currentIndex, onClose, onDownload, onNext, onPrev }) => {
  const extractFileNameFromUrl = (url) => {
    return url.split("/").pop().replace(/\s+/g, "-"); // Replace spaces with hyphens
  };
  const imageName = extractFileNameFromUrl(images[currentIndex]);
  return (
    <div className="container">
      <div className="modal">
        {/* Top Bar with Download and Close */}
        <div className="download_Cross_div" style={{justifyContent:"space-evenly"}}>
        <h3 style={{width:"100%",color:"white",fontWeight:"500",textAlign:"center"}}>{imageName}</h3>

          <button onClick={onDownload} className="close_download_btn download__button" >
            <DownloadIcon />
          </button>
          <button onClick={onClose} className=" close_download_btn close-buttonn" >
            <CloseIcon />
          </button>
        </div>

        {/* Image Preview with Navigation and Zoom */}
        <div className="preview_box">
          {/* Previous Button */}
          <button onClick={onPrev} disabled={currentIndex === 0} className="icon-button nav-button">
            <ArrowBackIcon />
          </button>
          {/* Zoomable and Pannable Image */}
          <div style={{maxHeigh:"100%",display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column"}} >
          <TransformWrapper
            initialScale={1}
            defaultPositionX={0}
            defaultPositionY={0}
            wheel={{ step: 0.2 }} // Allows mouse wheel zoom control
            zoomAnimation={{ animationTime: 300 }} // Smooth animation on zoom
            className="image_Container"
            style={{maxHeight:"80%"}}
          >
            <TransformComponent className="image_transform" style={{justifyContent:"center !important"}}>
              <img
                src={images[currentIndex]}
                alt={imageName}
                style={{ maxWidth: "70%", maxHeight: "100%" }}
                className="image"
              />
            </TransformComponent>

            {/* Add the Zoom Controls Below the Image */}
            <Controls />
          </TransformWrapper>
          </div>

          {/* Next Button */}
          <button onClick={onNext} disabled={currentIndex === images.length - 1} className="icon-button nav-button">
            <ArrowForwardIcon />
          </button>
        </div>
      </div>
    </div>
  );
};

export default ImageView;
