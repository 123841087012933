import {
  USER_INFO_URL,
  ADD_VIEW_USER,
  GET_LAYERS_URL,
  ORDER_LIST_URL,
  FEEDBACK_ORDER_LIST,
} from '../../helper/ApiUrl';
import axios from 'axios';
import './UploadOrderDashboard.css';
import React, { useRef } from 'react';
import Modal from '@mui/material/Modal';
import Badge from '@mui/material/Badge';
import Tooltip from '@mui/material/Tooltip';
import { useState, useEffect } from 'react';
import AuthToken from '../../context/AuthToken';
import TuneIcon from '@mui/icons-material/Tune';
import ClearIcon from '@mui/icons-material/Clear';
import CloseIcon from '@mui/icons-material/Close';
import { TOAST_TYPE } from '../../utils/constants';
import LogoutIcon from '@mui/icons-material/Logout';
import { useScreenType } from '../../utils/commonMethods';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import { useSearchParams, useNavigate } from 'react-router-dom';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import UploadOrderTable from '../../components/UploadOrderTable/UploadOrderTable';
import NotificationImportantOutlinedIcon from '@mui/icons-material/NotificationImportantOutlined';
import Pagination from '../../components/Pagination/Pagination';
import { Button, IconButton, TextField } from '@mui/material';
import useLogout from './../../utils/customHooks/logout.js';
import UploadOrderTableWithBulk from '../../components/UploadOrderTable/UploadOrderTableWithBulk.jsx';
import UploadOrderTableForFeedback from '../../components/UploadOrderTable/UploadOrderTableForFeedback.jsx';


const UploadOrderDashboard = ({ handleSuccess, handleError }) => {
  let filterURL = '';
  const token = AuthToken();
  const handleLogout = useLogout();
  // const statusRef = useRef();
  const menuRef = useRef([]);
  const navigate = useNavigate();
  const isMobile = useScreenType();
  const [menuBar, setMenuBar] = useState([]);
  const [address, setAddress] = useState('');
  const [active, setActive] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [layerId, setLayerId] = useState(null);
  const [userData, setUserData] = useState({});
  const [pageCount, setPageCount] = useState(0);
  const [orderList, setOrderList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [inputValue, setInputValue] = useState('10');
  const [addUserEmail, setAddUserEmail] = useState('');
  const [feedbackCount, setFeedbackCount] = useState(0);
  const [viewUsersList, setViewUsersList] = useState([]);
  const [openSettings, setOpenSettings] = useState(false);
  const [invalidInput, setInvalidInput] = useState(false);
  const [addUserPassword, setAddUserPassword] = useState('');
  const [jumpToInput, setJumpToInput] = useState(currentPage);
  const [addUserFirstName, setAddUserFirstName] = useState('');
  const [openAddUserModal, setOpenAddUserModal] = useState(false);
  const [openViewUserModal, setOpenViewUserModal] = useState(false);
  const [feedbackListActive, setFeedbackListActive] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const pageNum = searchParams.get('page') || 1;
  const end_Date = searchParams.get('end_date') || '';
  const status_url = searchParams.get('status') || '';
  const start_Date = searchParams.get('start_date');
  const [endDate, setEndDate] = useState({ endDate: end_Date });
  const [startDate, setStartDate] = useState({ startDate: start_Date });
  const [status, setStatus] = useState(status_url.split(/,+/).filter(Boolean));

  // for active btn
  const [viewUserBtnColor,setViewUserBtnColor] = useState(false)
  const [addUserBtnColor,addViewUserBtnColor] = useState(false)

  // for routing using query params for ggeneral, feedback, bulk order in queue button
  const currentTab = searchParams.get('tab') || 'general'; 
  const[thisCurrentTab,setCurrentTab ]= useState('general')
   useEffect(()=>{
    console.log(thisCurrentTab,"newtab")
   },[thisCurrentTab])
  const handleTabChange = (newTab) => {
     setSearchParams({ tab: newTab });
    // console.log(newTab,"newtab")
    setCurrentTab(newTab)
  };

  // Fetch data based on the current tab
  useEffect(() => {
    console.log(currentTab," console.log(currentTab)")
    // if (currentTab === 'general') {
     
    //   getOrderList();
    // } else if (currentTab === 'feedback') {
    //   getFeedbackOrderList();
    // } else if (currentTab === 'bulkorder') {
    //   getSingleBulkOrder();
    // }
  }, [currentTab, currentPage]);

  // for storing single bulk order showing
  const [bulkOrderList, setBulkOrderList] = useState([]);
  const [isSingleBtnClicked, setIsSingleBtnClicked] = useState(false);

  const handleAddUserModalClose = () => setOpenAddUserModal(false);
  const handleViewUserModalClose = () => setOpenViewUserModal(false);

  const lastPage = rowCount / inputValue;
  const firstRow = (currentPage - 1) * inputValue + 1;
  const lastRow = Math.min(currentPage * inputValue, rowCount);

  const handleInputChange = (event) => {
    const newValue = event.target.value;
    setInputValue(newValue);

    if (newValue >= 5 && newValue <= 20) {
      setInvalidInput(false);
    } else {
      setInvalidInput(true);
    }
  };

  const handlePreviousPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    const totalPages = Math.ceil(rowCount / inputValue);
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleArrowClickBar = (val) => {
    if (menuBar.length) {
      if (menuBar[0] === val) {
        setMenuBar({});
      } else {
        setMenuBar([val]);
      }
    } else setMenuBar([val]);
  };

  useEffect(() => {
    searchParams.set('page', currentPage);
    setSearchParams(searchParams);
  }, [currentPage]);

  const setURL = () => {
    // if (thisCurrentTab === 'general') {
    //   filterURL += `&tab=${thisCurrentTab}`;
      
    //   console.log(thisCurrentTab,"this is ge")
    // } 
    //  if (thisCurrentTab === 'feedback') {
    //   console.log(thisCurrentTab,"this is fe ")
    //   filterURL += `&tab=${thisCurrentTab}`;
    // } 
    //  if (thisCurrentTab === 'bulkorder') {
    //   console.log(thisCurrentTab,"this is bu ")
    //   filterURL += `&tab=${thisCurrentTab}`;
    // }
    if (address) {
      filterURL += `&address=${address}`;
    }
    if (status_url) {
      filterURL += `&status=${status_url}`;
    }
    if (status.length > 0) {
      let newStatus = '';
      status.forEach((item) => (newStatus = newStatus + item + ','));
      filterURL += `&status=${newStatus.slice(0, newStatus.length - 1)}`;
    }
    if (startDate.startDate || endDate.endDate) {
      filterURL += `&start_date=${startDate.startDate || start_Date}&end_date=${endDate.endDate || end_Date
        }`;
    }
  };

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      let isPresentInRef = false;
      for (let i in menuRef.current) {
        if (menuRef.current[i].contains(e.target)) {
          isPresentInRef = true;
        }
      }
      if (menuBar.length && menuRef.current && !isPresentInRef) {
        setStatus([]);
        setMenuBar([]);
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [menuBar]);

  const addToRefs = (el) => {
    if (el && !menuRef.current.includes(el)) {
      menuRef.current.push(el);
    }
  };

  const handleStatusFilter = () => {
    if (status) {
      if (currentTab === 'general') {
     
        getOrderList();
      } else if (currentTab === 'feedback') {
        getFeedbackOrderList();
      } else if (currentTab === 'bulkorder') {
        getSingleBulkOrder();
      }
      // getOrderList();
      let newStatus = '';
      status.forEach((item) => (newStatus = newStatus + item + ','));
      // statusRef.current.style.display = 'block';
      searchParams.set(
        'status',
        newStatus ? newStatus.slice(0, newStatus.length - 1) : null
      );
      setSearchParams(searchParams);
    }
    setMenuBar([]);
  };

  // function which handles the search for orders ID
  const searchOrder = () => {
   if(currentTab === 'bulkorder'){
    axios.get(`${GET_LAYERS_URL}/bulk?order_id=${layerId}`, {
      headers: {
        Authorization: `Token ${token}`,
        Accept: 'application/json',
      },
    })
    .then((response) => {
      setBulkOrderList(response.data.data);
      setRowCount(response.data.count);
      setPageCount(Math.ceil(response.data.count / 10));
     
      console.log(response.data.data)
    })
    .catch((error) => {
      if (error.response.status === 401) {
        navigate('/access-denied');
      } else {
        console.error(error.message);
      }
    });
   }
   else if(currentTab === 'general') {
    axios.get(`${GET_LAYERS_URL}?order_id=${layerId}`, {
      headers: {
        Authorization: `Token ${token}`,
        Accept: 'application/json',
      },
    })
    .then((response) => {
      setOrderList(response.data.data);
      setPageCount(response.data.data.length);
    })
    .catch((error) => {
      console.error(error.message);
    });
   }
   else if(currentTab === 'feedback') {
    axios.get(`${FEEDBACK_ORDER_LIST}?order_id=${layerId}`, {
      headers: {
        Authorization: `Token ${token}`,
        Accept: 'application/json',
      },
    })
    .then((response) => {
      setOrderList(response.data.data);
      setPageCount(response.data.data.length);
    })
    .catch((error) => {
      console.error(error.message);
    });
   }

    // axios.get(`${GET_LAYERS_URL}?order_id=${layerId}`, {
    //     headers: {
    //       Authorization: `Token ${token}`,
    //       Accept: 'application/json',
    //     },
    //   })
    //   .then((response) => {
    //     setOrderList(response.data.data);
    //     setPageCount(response.data.data.length);
    //   })
    //   .catch((error) => {
    //     console.error(error.message);
    //   });
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      searchOrder();
    }
  };
 const  handleAdressSearch =()=>{
  if (currentTab === 'general') {
    getOrderList();
  } else if (currentTab === 'feedback') {
    getFeedbackOrderList();
  } 
  // else if (currentTab === 'bulkorder') {
  //   getSingleBulkOrder();
  // }


 }
  const getOrderList = () => {
    console.log(filterURL, " this is running")
    
    
    // if (!feedbackListActive) {
     setURL();
      axios.get(`${GET_LAYERS_URL}?p=${currentPage}&psz=${inputValue}${filterURL}`, {
          headers: {
            Authorization: `Token ${token}`,
            Accept: 'application/json',
          },
        })
        .then((response) => {
          setOrderList(response.data.data);
          setRowCount(response.data.count);
          // setPageCount(Math.ceil(response.data.count / 10));
          setPageCount(response.data.total_pages);
        })
        .catch((error) => {
          if (error.response.status === 401) {
            navigate('/access-denied');
          } else {
            console.error(error.message);
          }
        });
    // }
  };
  // const hanldeSingleBulkOrder=()=>{
  //   handleTabChange('bulkorder')
  //   getSingleBulkOrder()
  // }
  const getSingleBulkOrder = () => {
      // setIsSingleBtnClicked(true)
    
      setURL();
      axios.get(`${GET_LAYERS_URL}/bulk?p=${currentPage}&psz=${inputValue}${filterURL}`, {
          headers: {
            Authorization: `Token ${token}`,
            Accept: 'application/json',
          },
        })
        .then((response) => {
          setBulkOrderList(response.data.data);
          setRowCount(response.data.count);
          setPageCount(Math.ceil(response.data.count / 10));
         
          console.log(response.data.data)
        })
        .catch((error) => {
          if (error.response.status === 401) {
            navigate('/access-denied');
          } else {
            console.error(error.message);
          }
        });
    
  };


  useEffect(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);

    if (params.get('address')) {
      setAddress(params.get('address'));
    }
    if (params.get('tab')) {
      setCurrentTab(params.get('tab'));
    }

    if (params.get('start_date') || params.get('end_date')) {
      setStartDate({ startDate: params.get('start_date') });
      setEndDate({ endDate: params.get('end_date') });
    }

    if (!invalidInput) {
      if (currentTab === 'general') {
     
        getOrderList();
      } else if (currentTab === 'feedback') {
        getFeedbackOrderList();
      } else if (currentTab === 'bulkorder') {
        getSingleBulkOrder();
      }
       
      }
    
    // if (isSingleBtnClicked) {
    //    getSingleBulkOrder()
    // }
  }, [feedbackListActive, address, pageNum, active, currentPage, inputValue, currentTab]);

  const handleDateFilter = () => {
    if (startDate.startDate || endDate.endDate) {
     
      // getOrderList();
      searchParams.set(
        'start_date',
        startDate.startDate ? startDate.startDate : null
      );
      searchParams.set('end_date', endDate.endDate ? endDate.endDate : null);
      setSearchParams(searchParams);
      if (currentTab === 'general') {
     
        getOrderList();
      } else if (currentTab === 'feedback') {
        getFeedbackOrderList();
      } else if (currentTab === 'bulkorder') {
        getSingleBulkOrder();
      }
    }
    setMenuBar([]);
  };

  const getFeedbackOrderList = () => {
     setURL();
    //  setFeedbackListActive(true)
    axios
      .get(`${FEEDBACK_ORDER_LIST}?p=${pageNum}&psz=10${filterURL}`, {
        headers: {
          Authorization: `Token ${token}`,
          Accept: 'application/json',
        },
      })
      .then((response) => {
        // if (feedbackListActive) {
          setOrderList(response.data.data);
          setPageCount(Math.ceil(feedbackCount / 10));
          setRowCount(response.data.count);
        // }
        setFeedbackCount(response.data.count);
      })
      .catch((error) => {
        console.error(error.message);
      });
  };

  // useEffect(() => {
  //   getFeedbackOrderList();
  // }, [ layerId, pageNum, feedbackListActive]);

  // const handlePageChange = (data) => {
  //   let currentPage = data.selected + 1;
  //   searchParams.set('page', currentPage);
  //   setSearchParams(searchParams);
  // };

  const calculateRemainingTime = (placedTime, expectedTime) => {
    const orderPlacedTime = new Date(`${placedTime}`).getTime();
    const countDownEndTime = orderPlacedTime + expectedTime * 60 * 60 * 1000;
    const currentTime = new Date().getTime();
    const distance = countDownEndTime - currentTime;
    const orderTime = expectedTime * 60 * 60 * 1000;
    const hours = Math.floor(distance / 3600000);
    const remainingMilliseconds = distance % 3600000;
    const minutes = Math.floor(remainingMilliseconds / 60000);
    if (distance > 0) {
      let color;
      const percentage = Math.floor((distance / orderTime) * 100);
      if (percentage > 50 && percentage < 100) {
        color = 'green';
      } else if (percentage > 30 && percentage < 50) {
        color = 'yellow';
      } else if (percentage > 0 && percentage < 30) {
        color = 'red';
      }
      return `${color} ${hours} : ${minutes}`;
    } else {
      return false;
    }
  };

  const logoutBtnClick = () => {
    handleLogout();
  };

  const addUser = (e) => {
    e.preventDefault();
    let formData = new FormData();
    if (openSettings) {
      if (addUserEmail && addUserEmail !== userData.email)
        formData.append('email', addUserEmail);
      if (addUserPassword) formData.append('password', addUserPassword);
      if (addUserFirstName && addUserFirstName !== userData.name)
        formData.append('first_name', addUserFirstName);
      axios
        .put(`${ADD_VIEW_USER}/${userData.id}`, formData, {
          headers: {
            Authorization: `Token ${token}`,
            Accept: 'application/json',
          },
        })
        .then((response) => {
          handleAddUserModalClose();
          handleSuccess(
            TOAST_TYPE.SUCCESS,
            'User details updated successfully.'
          );
        })
        .catch((error) => {
          handleError(error);
        });
    } else {
      formData.append('email', addUserEmail);
      formData.append('password', addUserPassword);
      formData.append('first_name', addUserFirstName);
      axios
        .post(`${ADD_VIEW_USER}`, formData, {
          headers: {
            Authorization: `Token ${token}`,
            Accept: 'application/json',
          },
        })
        .then((response) => {
          handleAddUserModalClose();
          handleSuccess(TOAST_TYPE.SUCCESS, 'User added successfully.');
        })
        .catch((error) => {
          handleError(error);
        });
    }
  };

  const viewUsers = () => {
    axios
      .get(`${ADD_VIEW_USER}`, {
        headers: {
          Authorization: `Token ${token}`,
          Accept: 'application/json',
        },
      })
      .then((response) => {
        setViewUsersList(response.data.data);
      })
      .catch((error) => {
        handleError(error);
      });
  };

  useEffect(() => {
    viewUsers();
  }, [openViewUserModal]);

  const getUserInfo = () => {
    axios
      .get(USER_INFO_URL, {
        headers: {
          Authorization: `Token ${token}`,
          Accept: 'application/json',
        },
      })
      .then((response) => {
        setUserData(response.data.data);
      })
      .catch((error) => {
        console.error(error.message);
      });
  };

  useEffect(() => {
    getUserInfo();
  }, [openAddUserModal]);

  useEffect(() => {
    if (openSettings) {
      setAddUserEmail(userData.email);
      setAddUserFirstName(userData.name);
    } else {
      setAddUserEmail('');
      setAddUserFirstName('');
    }
  }, [openSettings]);

  const updateLayerId = (id) => {
    setLayerId(id);
    if (id === '') {
      // getOrderList();
      if (currentTab === 'general') {
        getOrderList();
      } else if (currentTab === 'feedback') {
        getFeedbackOrderList();
      } else if (currentTab === 'bulkorder') {
        getSingleBulkOrder();
      }
    }
  };

  useEffect(() => {
    updateLayerId(layerId)
  }, [layerId])

  const clearDateFilter = () => {
    setStartDate({ startDate: '' });
    setEndDate({ endDate: '' });
    setActive(!active);
    // getOrderList();
    if (currentTab === 'general') {
     getOrderList();
    } else if (currentTab === 'feedback') {
      getFeedbackOrderList();
    } else if (currentTab === 'bulkorder') {
      getSingleBulkOrder();
    }
    searchParams.delete('start_date');
    searchParams.delete('end_date');
    setSearchParams(searchParams);
  };
  const clearDateFilterOnTabChange = () => {
    setStartDate({ startDate: '' });
    setEndDate({ endDate: '' });
    setActive(!active);
   
    searchParams.delete('start_date');
    searchParams.delete('end_date');
    setSearchParams(searchParams);
  };
  const clearStatusFilterOnTabChange = () => {
    setStatus('');
    setActive(!active);
   
    searchParams.delete('status');
    setSearchParams(searchParams);
  };
  // const clearAllFilterOnTabFilter = () => {
  //   filterURL = '';
  //   setAddress('');
  //   setLayerId('');
  //   setStatus('');
  //   setActive(!active);
  //   setStartDate({ startDate: '' });
  //   searchParams.delete('start_date');
  //   searchParams.delete('end_date');
  //   searchParams.delete('status');
  //   setSearchParams(searchParams);
   
  // };


  const clearStatusFilter = () => {
    setStatus('');
    setActive(!active);
    // getOrderList();
    if (currentTab === 'general') {
     getOrderList();
    } else if (currentTab === 'feedback') {
      getFeedbackOrderList();
    } else if (currentTab === 'bulkorder') {
      getSingleBulkOrder();
    }
    searchParams.delete('status');
    setSearchParams(searchParams);
    // statusRef.current.style.display = 'none';
  };

  const clearAllFilter = () => {
    filterURL = '';
    setAddress('');
    setLayerId('');
    setStatus('');
    setActive(!active);
    setStartDate({ startDate: '' });
    searchParams.delete('start_date');
    searchParams.delete('end_date');
    searchParams.delete('status');
    setSearchParams(searchParams);
    // getOrderList();
    if (currentTab === 'general') {
     
      getOrderList();
    } else if (currentTab === 'feedback') {
      getFeedbackOrderList();
    } else if (currentTab === 'bulkorder') {
      getSingleBulkOrder();
    }
  };

  const handlePageChange = (data) => {
    let currentPage = data.selected + 1;
    setCurrentPage(currentPage)
    searchParams.set('page', currentPage);
    setActive(!active);
    setSearchParams(searchParams);
  };

  const handleKeyDown = (event) => {
    const input = event.target.value;

    if (event.key === "Enter") {
      event.preventDefault();
      if (input) {
        if (input < 0) {
          setJumpToInput(1);
          setCurrentPage(1);
        }
        else if (input > pageCount) {
          setJumpToInput(pageCount);
          setCurrentPage(pageCount);
        } else setCurrentPage(input);
      }
  
  }
  }
  const [isHovered, setIsHovered] = useState(false); 

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div className="orders-dashboard">
      <div className="orders-dashboard-search" style={{display:"flex",flexDirection:"column",alignItems:"baseline"}}>
        <div className="orders-dashboard-search-left upload-dashboard-search-left" style={{marginBottom:"5px"}} >
          
        {/* <input
            style={{ width: "8em" }}
            type="text"
            value={layerId}
            onKeyPress={handleKeyPress}
            placeholder="Search Order ID"
            onChange={(e) => setLayerId(e.target.value)}
          />
        <SearchOutlinedIcon style={{ marginRight: "0.3em" }} className="orders-search-icon-one" /> */}

          <form onSubmit={(e) => {e.preventDefault(); searchOrder() }} style={{marginRight: "10px"}}>
          <div className='search-input-wrapper' style={{ border:"1px solid green",borderRadius:"5px",overflow:"hidden"}} >
          <input
            style={{ width: "15em", paddingLeft: "5px",border:"none"}}
            type="text"
            value={layerId}
            // onKeyPress={handleKeyPress}
            placeholder="Search order ID"
            onChange={(e) => setLayerId(e.target.value)}
            />
          <IconButton type="submit" className="orders-search-icon-one" style={{borderRadius:"5px"}}>
            <SearchOutlinedIcon  style={{color: "white", fontSize: "20px"}} />
          </IconButton>
            </div>
          </form>

          <form onSubmit={e => {e.preventDefault(); handleAdressSearch()}}>
          <div className='search-input-wrapper' style={{ border:"1.5px solid green",borderRadius:"5px",overflow:"hidden"}} >
          <input
            style={{ width: "15em", paddingLeft: "5px" ,border:"none"}}
            type="text"
            value={address}
            // onKeyPress={handleKeyPress}
            placeholder="Search address"
            onChange={(e) => setAddress(e.target.value)}
            />
          <IconButton type="submit" className="orders-search-icon-one" style={{borderRadius:"5px"}}>
            <SearchOutlinedIcon style={{color: "white", fontSize: "20px"}} />
          </IconButton>
          </div>
          </form>
          
          {/* <input
            style={{ width: "9em" }}
            type="text"
            placeholder="Search Address"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
          />
          <SearchOutlinedIcon   className="orders-search-icon-one" /> */}
          {/* <SearchOutlinedIcon className="orders-search-icon"   className="orders-search-icon-one" /> */}
          <button style={{ marginRight: "1rem" }} onClick={clearAllFilter}>
            <FilterListOffIcon className="clear-filter-icon" />
            Clear Filters
          </button>
        </div>

        <div className="upload-dashboard-search-right-container" style={{marginBottom:"5px",display:"flex",flexWrap:`nowrap`}}>
          <div className="upload-dashboard-search-right" >

            <button
              className="upload-dashboard-search-right-one"
              style={{marginLeft:"0",backgroundColor:currentTab === 'general'&&"#E2BB46"}}
              onClick={() => {
                // setFeedbackListActive(false)
                // setIsSingleBtnClicked(false)
                clearDateFilterOnTabChange()
                setLayerId('')
                clearStatusFilterOnTabChange()
                // clearAllFilterOnTabFilter()
                handleTabChange('general')
              }}
            >
              <ListAltOutlinedIcon className="upload-feedback-icon" />
              <span>General</span>
            </button>
            <Badge badgeContent={feedbackCount} color="primary">
              <button onClick={() =>{ 
              
              clearDateFilterOnTabChange()
              setLayerId('')

              clearStatusFilterOnTabChange()
              // clearAllFilterOnTabFilter()

              handleTabChange('feedback')
              // setFeedbackListActive(true)
              // setIsSingleBtnClicked(false)
              
              }}
              style={{background:currentTab === 'feedback' 
                && "#E2BB46" 
              }}   

              >
                <NotificationImportantOutlinedIcon className="upload-feedback-icon" />
                <span>Feedback</span>
              </button>
            </Badge>
            <button
              className="upload-dashboard-search-right-one"
              onClick={() => {setOpenViewUserModal(true)}}
              style={{background:openViewUserModal
                && "#E2BB46" 
              }}  
            >
              <VisibilityIcon className="upload-feedback-icon" />
              <span>View Users</span>
            </button>
            <Modal
              open={openViewUserModal}
              onClose={handleViewUserModalClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <div className="view-user-modal-cont">
                <div className="add-user-head">
                  <span></span>
                  <span>View Users</span>
                  <CloseIcon
                    style={{ cursor: 'pointer' }}
                    onClick={handleViewUserModalClose}
                  />
                </div>
                <div className="view-user-head-column">
                  <span>S.No.</span>
                  <span>Name</span>
                  <span>Email</span>
                </div>
                <div className="view-user-input-main-cont">
                  {viewUsersList &&
                    viewUsersList.map((item, key) => {
                      return (
                        <div className="view-user-input-cont">
                          <span style={{ marginLeft: '0.5rem' }}>
                            {key + 1}.
                          </span>
                          <span>{item.first_name}</span>
                          <span>{item.email}</span>
                        </div>
                      );
                    })}
                </div>
              </div>
            </Modal>
            <button
              className="upload-dashboard-search-right-one"
              onClick={() => {
                setOpenAddUserModal(true);
                setOpenSettings(false);
              }}
              style={{background:openAddUserModal
                && "#E2BB46" 
              }} 
            >
              <PersonAddAltIcon className="upload-feedback-icon" />
              <span>Add User</span>
            </button>
            <Modal
              open={openAddUserModal}
              onClose={handleAddUserModalClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <div className="add-user-modal-cont">
                <form onSubmit={addUser}>
                  <div className="add-user-head">
                    {openSettings ? 'Edit User' : 'Add User'}
                  </div>
                  <div>
                    <div className="add-user-input-cont">
                      <label>Email:</label>
                      <input
                        required={!openSettings}
                        value={addUserEmail}
                        onChange={(e) => setAddUserEmail(e.target.value)}
                        type="email"
                      />
                    </div>
                    <div className="add-user-input-cont">
                      <label>Password:</label>
                      <input
                        required={!openSettings}
                        value={addUserPassword}
                        onChange={(e) => setAddUserPassword(e.target.value)}
                        type="password"
                      />
                    </div>
                    <div className="add-user-input-cont">
                      <label>First Name:</label>
                      <input
                        required={!openSettings}
                        value={addUserFirstName}
                        onChange={(e) => setAddUserFirstName(e.target.value)}
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="add-user-button-cont">
                    <button type="submit" className="save-user-button">
                      {openSettings ? 'Update' : 'Save'}
                    </button>
                    <button
                      className="cancel-user-button"
                      onClick={handleAddUserModalClose}
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            </Modal>
            <button
              className="upload-dashboard-search-right-one"
              onClick={() => navigate('/upload/order/bulk-orders')}
            >
              <span>Projects</span>
            </button>
            {/*new btn for the single bulk order*/}
            <button
              className="upload-dashboard-search-right-one"
              onClick={()=>{
                clearDateFilterOnTabChange()
                setLayerId('')

                clearStatusFilterOnTabChange()
                // clearAllFilterOnTabFilter()
                
                handleTabChange('bulkorder')}}
              style={{width:"auto",background:currentTab === 'bulkorder'&& "#E2BB46"}}
            
            >
              <span>Bulk Order in queue</span>
            </button>
          </div>
          <div className="upload-orders-status-filter-clear">
            {start_Date || end_Date ? (
              <div>
                <div className="filter-div">
                  <span>Created On.</span>
                  <ClearIcon
                    className="close-filter-icon"
                    onClick={clearDateFilter}
                  />
                </div>
              </div>
            ) : null}
            {status_url && !isMobile ? (
              <div>
                <div className="filter-div">
                  <span >Status</span>
                  <ClearIcon
                    className="close-filter-icon"
                    onClick={clearStatusFilter}
                  />
                </div>
              </div>
            ) : null}
          </div>
        </div>
        {localStorage.getItem('role') === 'otter_user' ? (
          <div className="otter-user-right-bar">
            <div style={{ marginRight: '1rem' }}>
              <span style={{ marginRight: '0.5rem' }}>Hi,</span>
              {userData.name}!
            </div>
            <div style={{ marginRight: '1rem' }} className="logout-otter-user">
              <Tooltip
                onClick={() => {
                  setOpenAddUserModal(true);
                  setOpenSettings(true);
                }}
                title="Settings"
              >
                <TuneIcon />
              </Tooltip>
            </div>
            <div className="logout-otter-user">
              <Tooltip title="Logout">
                <LogoutIcon onClick={logoutBtnClick} />
              </Tooltip>
            </div>
          </div>
        ) : null}
      </div>
      {/* Conditional rendering based on currentTab */}
      {currentTab === 'general' && 
        <div className="orders-dashboard-table">
        <UploadOrderTable
          status={status}
          menuBar={menuBar}
          endDate={endDate}
          startDate={startDate}
          addToRefs={addToRefs}
          orderList={orderList}
          setStatus={setStatus}
          setMenuBar={setMenuBar}
          setEndDate={setEndDate}
          setStartDate={setStartDate}
          handleDateFilter={handleDateFilter}
          handleStatusFilter={handleStatusFilter}
          calculateRemainingTime={calculateRemainingTime}
          
        />
      </div>
      }
      {currentTab === 'bulkorder' && 
        <div className="orders-dashboard-table">
        <UploadOrderTableWithBulk
          status={status}
          menuBar={menuBar}
          endDate={endDate}
          startDate={startDate}
          addToRefs={addToRefs}
          orderList={orderList}
          setStatus={setStatus}
          setMenuBar={setMenuBar}
          setEndDate={setEndDate}
          setStartDate={setStartDate}
          handleDateFilter={handleDateFilter}
          handleStatusFilter={handleStatusFilter}
          calculateRemainingTime={calculateRemainingTime}
          bulkOrderList={bulkOrderList}
          isSingleBtnClicked={isSingleBtnClicked}
        />
      </div>

      }
      {currentTab === 'feedback' &&
        <div className="orders-dashboard-table">
       <UploadOrderTableForFeedback 
        status={status}
          menuBar={menuBar}
          endDate={endDate}
          startDate={startDate}
          addToRefs={addToRefs}
          orderList={orderList}
          setStatus={setStatus}
          setMenuBar={setMenuBar}
          setEndDate={setEndDate}
          setStartDate={setStartDate}
          handleDateFilter={handleDateFilter}
          handleStatusFilter={handleStatusFilter}
          calculateRemainingTime={calculateRemainingTime}
      />
      </div>
      }
      {/* {!isSingleBtnClicked &&  <div className="orders-dashboard-table">
        <UploadOrderTable
          status={status}
          menuBar={menuBar}
          endDate={endDate}
          startDate={startDate}
          addToRefs={addToRefs}
          orderList={orderList}
          setStatus={setStatus}
          setMenuBar={setMenuBar}
          setEndDate={setEndDate}
          setStartDate={setStartDate}
          handleDateFilter={handleDateFilter}
          handleStatusFilter={handleStatusFilter}
          calculateRemainingTime={calculateRemainingTime}
          
        />
      </div>} */}
      {/* {isSingleBtnClicked && 
        <div className="orders-dashboard-table">
        <UploadOrderTableWithBulk
          status={status}
          menuBar={menuBar}
          endDate={endDate}
          startDate={startDate}
          addToRefs={addToRefs}
          orderList={orderList}
          setStatus={setStatus}
          setMenuBar={setMenuBar}
          setEndDate={setEndDate}
          setStartDate={setStartDate}
          handleDateFilter={handleDateFilter}
          handleStatusFilter={handleStatusFilter}
          calculateRemainingTime={calculateRemainingTime}
          bulkOrderList={bulkOrderList}
          isSingleBtnClicked={isSingleBtnClicked}
        />
      </div>
      } */}

      {/* <UploadOrderTableForFeedback 
        status={status}
          menuBar={menuBar}
          endDate={endDate}
          startDate={startDate}
          addToRefs={addToRefs}
          orderList={orderList}
          setStatus={setStatus}
          setMenuBar={setMenuBar}
          setEndDate={setEndDate}
          setStartDate={setStartDate}
          handleDateFilter={handleDateFilter}
          handleStatusFilter={handleStatusFilter}
          calculateRemainingTime={calculateRemainingTime}
      /> */}
      <Pagination
        invalidInput={invalidInput}
        jumpToInput={jumpToInput}
        setJumpToInput={setJumpToInput}
        handleKeyDown={handleKeyDown}
        handlePageChange={handlePageChange}
        pageCount={pageCount}
        currentPage={currentPage}
        inputValue={inputValue}
        setInputValue={setInputValue}
        rowCount={rowCount}
      />
    </div>
  );
};

export default UploadOrderDashboard;
