import {
  CREDIT_STATUS_URL,
  CREDIT_REQUEST_URL,
  TRANSACTION_HISTORY_URL,
} from '../../helper/ApiUrl';
import axios from 'axios';
import './CreditManagement.css';
import AuthToken from '../../context/AuthToken';
import { TOAST_TYPE } from '../../utils/constants';
import { userPermission } from '../../helper/permissions';
import React, { useState, useEffect, useRef } from 'react';
import { useUserContext } from '../../context/UserContext';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { useNavigate, useSearchParams } from 'react-router-dom';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';
import TransactionTable from '../TransactionTable/TransactionTable';
import Pagination from '../Pagination/Pagination';

const CreditManagement = ({ handleError, handleSuccess }) => {
  let filterURL = '';
  const menuRef = useRef([]);
  menuRef.current = [];
  const token = AuthToken();
  const navigate = useNavigate();
  const [type, setType] = useState([]);
  const [userId, setUserId] = useState([]);
  const [credits, setCredits] = useState();
  const role = localStorage.getItem('role');
  const [menuBar, setMenuBar] = useState([]);
  const [active, setActive] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [transactions, setTransactions] = useState([]);
  const { selfCredits, setSelfCredits } = useUserContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const pageNum = searchParams.get('page') || 1;
  const [inputValue, setInputValue] = useState('10');
  const end_Date = searchParams.get('end_date');
  const [creditMoney, setCreditMoney] = useState('');
  const start_Date = searchParams.get('start_date');
  const [currentPage, setCurrentPage] = useState(1);
  const [invalidInput, setInvalidInput] = useState(false);
  const [endDate, setEndDate] = useState({ endDate: '' });
  const [jumpToInput, setJumpToInput] = useState(currentPage);
  const [startDate, setStartDate] = useState({ startDate: '' });

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      let isPresentInRef = false;
      for (let i in menuRef.current) {
        if (menuRef.current[i].contains(e.target)) {
          isPresentInRef = true;
        }
      }
      if (menuBar.length && menuRef.current && !isPresentInRef) {
        setType('');
        setUserId([]);
        setMenuBar([]);
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [menuBar]);

  const addToRefs = (el) => {
    if (el && !menuRef.current.includes(el)) {
      menuRef.current.push(el);
    }
  };

  const getCreditsDetail = () => {
    axios
      .get(CREDIT_STATUS_URL, {
        headers: {
          Authorization: `Token ${token}`,
          Accept: 'application/json',
        },
      })
      .then((response) => {
        setCredits(response.data.data.amount__sum);
      })
      .catch((error) => {
        console.error(error.message);
      });
  };

  useEffect(() => {
    getCreditsDetail();
  }, []);

  const setFilterUrl = () => {
    if (userId.length > 0) {
      let newId = '';
      userId.forEach((item) => (newId = newId + item + ','));
      filterURL += `&user_id=${newId.slice(0, newId.length - 1)}`;
    }
    if (startDate.startDate || endDate.endDate) {
      filterURL += `&start_date=${startDate.startDate || start_Date}&end_date=${endDate.endDate || end_Date
        }`;
    }
    if (type.length > 0) {
      let newType = '';
      type.forEach((item) => (newType = newType + item + ','));
      filterURL += `&transaction_type=${newType.slice(0, newType.length - 1)}`;
    }
  };

  const handleUserFilter = () => {
    if (userId.length > 0) {
      getTransactionDetail();
      let newId = '';
      userId.forEach((item) => (newId = newId + item + ','));
      searchParams.set(
        'user_id',
        newId ? newId.slice(0, newId.length - 1) : null
      );
      setSearchParams(searchParams);
    }
    setMenuBar([]);
  };

  const handleDateFilter = () => {
    if (startDate.startDate || endDate.endDate) {
      getTransactionDetail();
      searchParams.set(
        'start_date',
        startDate.startDate ? startDate.startDate : null
      );
      searchParams.set('end_date', endDate.endDate ? endDate.endDate : null);
      setSearchParams(searchParams);
    }
    setMenuBar([]);
  };

  const handleTypeFilter = () => {
    if (type.length > 0) {
      getTransactionDetail();
      let newType = '';
      type.forEach((item) => (newType = newType + item + ','));
      searchParams.set(
        'transaction_type',
        newType ? newType.slice(0, newType.length - 1) : null
      );
      setSearchParams(searchParams);
    }
    setMenuBar([]);
  };

  const clearAllFilters = () => {
    setType([]);
    setUserId([]);
    filterURL = '';
    setActive(!active);
    searchParams.delete('user_id');
    searchParams.delete('end_date');
    searchParams.delete('start_date');
    searchParams.delete('transaction_type');
    setSearchParams(searchParams);
    setStartDate({ startDate: '' });
    setEndDate({ endDate: '' });
    getTransactionDetail();
  };

  const getTransactionDetail = () => {
    setFilterUrl();
    axios
      .get(`${TRANSACTION_HISTORY_URL}?p=${currentPage}&psz=${inputValue}${filterURL}`, {
        headers: {
          Authorization: `Token ${token}`,
          Accept: 'application/json',
        },
      })
      .then((response) => {
        setTransactions([]);
        setTransactions(response.data.data);
        // setPageCount(Math.ceil(response.data.count / 10));
        setPageCount(response.data.total_pages)
      })
      .catch((error) => {
        console.error(error.message);
      });
  };

  useEffect(() => {
    if (!invalidInput)
      getTransactionDetail();
  }, [pageNum, active, inputValue, currentPage]);

  const handlePageChange = (data) => {
    let currentPage = data.selected + 1;
    setCurrentPage(currentPage)
    searchParams.set('page', currentPage);
    setActive(!active);
    setSearchParams(searchParams);
  };

  const handleKeyDown = (event) => {
    const input = event.target.value;
    if (event.key === "Enter") {
      event.preventDefault();
      if (input) {
        if (input < 0) {
          setJumpToInput(1);
          setCurrentPage(1);
        }
        else if (input > pageCount) {
          setJumpToInput(pageCount);
          setCurrentPage(pageCount);
        } else setCurrentPage(input);
      }
    }
  }

  const sendCreditRequest = () => {
    if (creditMoney) {
      const data = { credit: `${creditMoney}` };
      axios
        .post(`${CREDIT_REQUEST_URL}`, data, {
          headers: {
            Authorization: `Token ${token}`,
            Accept: 'application/json',
          },
        })
        .then((response) => {
          setCreditMoney('');
          handleSuccess(TOAST_TYPE.SUCCESS, `${response.data.msg}`);
        })
        .catch((error) => {
          handleError(error);
        });
    } else {
      handleSuccess(TOAST_TYPE.WARNING, 'Please add credit value!');
    }
  };

  const selfCreditRequest = () => {
    if (selfCredits) {
      navigate('/payment');
    } else {
      handleSuccess(TOAST_TYPE.WARNING, 'Please add credit value!');
    }
  };

  useEffect(() => {
    searchParams.set('page', currentPage);
    setSearchParams(searchParams);
  }, [currentPage]);

  return (
    <div className="credit-manage-container">
      <div className="credit-management-one">
        <div className="credit-management-one-a">
          <div className="cm-a-one">
            <span>Request Credit Recharge</span>
          </div>
          <div className="cm-a-two">
         
            <AttachMoneyIcon className="cm-money-icon" />
            <input
              readOnly={
                role === 'user'
                  ? !userPermission.permissions.CREDIT_MODYFY
                  : false
              }
              required
              value={creditMoney}
              type="text"
              onChange={(e) => setCreditMoney(e.target.value)}
            />
            <button
              className="credit-request-btn"
              onClick={sendCreditRequest}
              disabled={
                role === 'user'
                  ? !userPermission.permissions.TEAM_MODYFY
                  : false
              }
            >
              Submit
            </button>
          </div>
          <div className="cm-a-three">
            <p>
              Once you submit a request for credit recharge, our financial team
              will get in touch with you to process you request.
            </p>
          </div>
        </div>
        {/* <div className="credit-management-one-a">
          <div className="cm-a-one">
            <span>Self Credit Recharge</span>
          </div>
          <div className="cm-a-two">
            <AttachMoneyIcon className="cm-money-icon" />
            <input
              required
              value={selfCredits}
              type="text"
              onChange={(e) => setSelfCredits(e.target.value)}
            />
            <button onClick={selfCreditRequest}>Submit</button>
          </div>
          <div class="paypal-powered-by">
            <span class="paypal-button-text immediate" data-v-44bf4aee="">
              Powered by{" "}
            </span>
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/39/PayPal_logo.svg/1200px-PayPal_logo.svg.png"
              alt="PayPal_logo"
              class="paypal-logo paypal-logo-paypal paypal-logo-color-blue"
            />
          </div>
        </div> */}
        <div className="credit-left">
          <button>
            <AttachMoneyIcon /> Organization Credits: {credits}
          </button>
          <button onClick={clearAllFilters}>
            <FilterListOffIcon className="clear-filter-icon" />
            Clear All Filters
          </button>
        </div>
      </div>
      <div className="credit-management-two">
        <TransactionTable
          type={type}
          userId={userId}
          setType={setType}
          menuBar={menuBar}
          setUserId={setUserId}
          addToRefs={addToRefs}
          setMenuBar={setMenuBar}
          setEndDate={setEndDate}
          setStartDate={setStartDate}
          transactions={transactions}
          handleUserFilter={handleUserFilter}
          handleDateFilter={handleDateFilter}
          handleTypeFilter={handleTypeFilter}
        />
      </div>
      <div className="credit-management-three">
        <Pagination
          invalidInput={invalidInput}
          jumpToInput={jumpToInput}
          setJumpToInput={setJumpToInput}
          handleKeyDown={handleKeyDown}
          handlePageChange={handlePageChange}
          pageCount={pageCount}
          currentPage={currentPage}
          inputValue={inputValue}
          setInputValue={setInputValue}
        />
      </div>
    </div>
  );
};

export default CreditManagement;
