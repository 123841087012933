import { unByKey } from "ol/Observable";
import { Fill, Style } from "ol/style";

const hoverEffect = (
  map,
  hex2rgba,
  hoverMapKey,
  setHoverMapKey,
  elem
) => {
  unByKey(hoverMapKey);
  var selectedFeature = null;
  let key = map.on("pointermove", function (e) {
    map.getViewport().style.cursor = "";
    var feature = map.forEachFeatureAtPixel(e.pixel, function (f) {
      return f;
    });
    if (feature && feature.get("title"))
      map.getViewport().style.cursor = "pointer";
    if (feature && feature.get("color")) {
      const elem1 = document.createElement("div");
      const elem2 = document.createElement("div");
      const elem3 = document.createElement("div");

      elem1.innerText = `Id: ${feature.get("hover_id")}`;
      elem2.innerText = `Name: ${feature.get("name")}`;
      elem3.innerText = `Measurement: ${feature.get("measurement")} ${feature.get("measurement_type")}`;

      elem1.classList.add("hover-element");
      elem2.classList.add("hover-element");
      elem3.classList.add("hover-element");

      elem.innerHTML = "";
      elem.appendChild(elem1);
      elem.appendChild(elem2);
      feature.get("name") !== "Tree" && elem.appendChild(elem3);

      elem.style.display = "block";
      elem.style.left = e.pixel[0] + 0 + "px";
      elem.style.top = e.pixel[1] + 100 + "px";
      
      let color = hex2rgba(feature.get("color"), 0.8);
      var selectStyle = new Style({
        fill: new Fill({
          color: color,
        }),
      });
      if (selectedFeature) selectedFeature.setStyle(undefined);
      selectedFeature = feature;
      feature.setStyle(selectStyle);
    } else {
      if (selectedFeature) selectedFeature.setStyle(undefined);
      elem.style.display = "none";
    }
  });
  setHoverMapKey(key);
};

export default hoverEffect;
