import './Toaster.css';
import React from 'react';
import { useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import { TOAST_TYPE } from '../../utils/constants';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';

export default function CustomToaster({
  type,
  message,
  showToaster,
  setShowToaster,
}) {
  useEffect(() => {
    if (showToaster === true) {
      setTimeout(() => {
        setShowToaster(false);
      }, 5000);
    }
  }, [showToaster]);

  return (
    <div className="custom-toaster-container">
      {type === TOAST_TYPE.SUCCESS ? (
        <div className="success-container">
          <CheckIcon />
          <span>
            {message}
            <CloseIcon onClick={() => setShowToaster(false)} className='toaster-close-icon' />
          </span>
        </div>
      ) : type === TOAST_TYPE.WARNING ? (
        <div className="warning-container">
          <WarningAmberRoundedIcon />
          <span>
            {message}
            <CloseIcon onClick={() => setShowToaster(false)} className='toaster-close-icon' />
          </span>
        </div>
      ) : type === TOAST_TYPE.ERROR ? (
        <div className="error-container">
          <CloseRoundedIcon />
          <span>
            {message}
            <CloseIcon onClick={() => setShowToaster(false)} className='toaster-close-icon' />
          </span>
        </div>
      ) : null}
    </div>
  );
}
