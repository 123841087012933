import { Circle as CircleStyle, Fill, Stroke, Style } from 'ol/style';

const measurementStyle = [
  new Style({
    stroke: new Stroke({
      color: '#FFF455',
      width: 3,
      lineDash: [10],
    }),
    fill: new Fill({
      color: 'rgba(255, 244, 85, 0.2)',
    }),
  }),
  new Style({
    image: new CircleStyle({
      radius: 6,
      fill: new Fill({
        color: '#E72929',
      }),
      stroke: new Stroke({
        color: '#FFF',
        width: 2,
      }),
    }),
  }),
];

export default measurementStyle;
