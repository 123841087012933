import './Modal.css';
import React, { useState } from 'react';
import Slider from '@mui/material/Slider';
import { SketchPicker } from 'react-color';
import CloseIcon from '@mui/icons-material/Close';

export function ColorPalette({
  name,
  property,
  setBwidthVal,
  propertyValue,
  uploadedLayer,
  setUploadedLayer,
  setFillOpacityVal,
  setBorderOpacityVal,
}) {
  const [openPalette, changeOpenPalette] = useState(false);

  console.log(`Uploaded`, uploadedLayer);

  const openColorPicker = () => {
    changeOpenPalette(!openPalette);
  };

  const setPropertyColor = (color) => {
    let updatedItemList = uploadedLayer.map((item) => {
      if (item.name === name && property === 'strokeColor') {
        return { ...item, strokeColor: color.hex };
      } else if (item.name === name && property === 'fillColor') {
        return { ...item, fillColor: color.hex };
      }
      return { ...item };
    });
    setUploadedLayer([...updatedItemList]);
  };

  const setBorderOpacity = (event, newborderOpacityVal) => {
    setBorderOpacityVal(newborderOpacityVal);
    let updatedItemList = uploadedLayer.map((item) => {
      if (item.name === name && property === 'strokeOpacity') {
        return { ...item, strokeOpacity: newborderOpacityVal };
      }
      return { ...item };
    });
    setUploadedLayer([...updatedItemList]);
  };

  const setFillOpacity = (event, newFillOpacityVal) => {
    setFillOpacityVal(newFillOpacityVal);
    let updatedItemList = uploadedLayer.map((item) => {
      if (item.name === name) {
        return { ...item, fillOpacity: newFillOpacityVal };
      }
      return { ...item };
    });
    setUploadedLayer([...updatedItemList]);
  };

  const setBorderWidth = (event, newBorderWidthVal) => {
    setBwidthVal(newBorderWidthVal);
    let updatedItemList = uploadedLayer.map((item) => {
      if (item.name === name && property === 'borderWidth') {
        return { ...item, strokeWeight: newBorderWidthVal };
      }
      return { ...item };
    });
    setUploadedLayer([...updatedItemList]);
  };

  if (property === 'strokeOpacity')
    return (
      <div>
        <Slider
          defaultValue={propertyValue}
          step={0.1}
          marks
          min={0}
          max={1}
          onChange={setBorderOpacity}
          className="slider-width-opacity"
        />
      </div>
    );
  else if (property === 'fillOpacity')
    return (
      <div>
        <Slider
          defaultValue={propertyValue}
          step={0.1}
          marks
          min={0}
          max={1}
          onChange={setFillOpacity}
          className="slider-width-opacity"
        />
      </div>
    );
  else if (property === 'borderWidth')
    return (
      <div>
        <Slider
          defaultValue={propertyValue}
          step={1}
          marks
          min={1}
          max={10}
          onChange={setBorderWidth}
          className="slider-width-opacity"
        />
      </div>
    );
  else
    return (
      <div className="setter">
        <button
          onClick={openColorPicker}
          className="open-color-palette-button"
          style={{ backgroundColor: propertyValue }}
        ></button>
        {openPalette && (
          <div className="sketch-picker-container">
            <div className="sketch-picker">
              <SketchPicker color={propertyValue} onChange={setPropertyColor} />
            </div>
            <CloseIcon
              onClick={openColorPicker}
              className="sketch-picker-close-button"
            />
          </div>
        )}
      </div>
    );
}

export default function Modal({
  type,
  name,
  modalRef,
  fillColor,
  borderWidth,
  fillOpacity,
  uploadedLayer,
  setUploadedLayer,
}) {
  const [bwidthVal, setBwidthVal] = useState(borderWidth);
  const [fillOpacityVal, setFillOpacityVal] = useState(fillOpacity);

  return (
    <>
      <div className="modal-container" ref={modalRef}>
        <div className="feature-name">
          <span>{name}</span>
          <CloseIcon
            className="close-modal-icon"
            onClick={() => {
              modalRef.current.style.display = 'none';
            }}
          />
        </div>
        <div className="modal-inner-container">
          <span>Fill & Outline Color</span>
          <div className="properties-change-color-container">
            <ColorPalette
              name={name}
              property={'fillColor'}
              propertyValue={fillColor}
              uploadedLayer={uploadedLayer}
              setUploadedLayer={setUploadedLayer}
            />
          </div>
        </div>
        {type === 'polygon' ? (
          <div className="modal-inner-container">
            <span>Fill Opacity</span>
            <ColorPalette
              name={name}
              property={'fillOpacity'}
              propertyValue={fillOpacityVal}
              uploadedLayer={uploadedLayer}
              setUploadedLayer={setUploadedLayer}
              setFillOpacityVal={setFillOpacityVal}
            />
          </div>
        ) : null}
        <div className="modal-inner-container modal-inner-container-last">
          {type === 'point' ? (
            <span>Symbol Size</span>
          ) : (
            <span>Border Width</span>
          )}
          <ColorPalette
            name={name}
            property={'borderWidth'}
            propertyValue={bwidthVal}
            setBwidthVal={setBwidthVal}
            uploadedLayer={uploadedLayer}
            setUploadedLayer={setUploadedLayer}
          />
        </div>
      </div>
    </>
  );
}
