import * as turf from '@turf/turf';
import GeoJSON from 'ol/format/GeoJSON';
import { unByKey } from 'ol/Observable';
import { Draw, Snap } from 'ol/interaction';
import { TOAST_TYPE } from '../../utils/constants';
import calculateArea from '../OLFeatures/CalculateArea';
import removeAllInteraction from '../OLFeatures/RemoveInteraction';
import removeSourceFeature from '../OLFeatures/RemoveSourceFeature';
import { Fill, Stroke, Style, Circle as CircleStyle } from 'ol/style';

const drawPolygon = (
  map,
  parcelRef,
  polygonList,
  setZoomLayer,
  setParcelEdit,
  setPolygonList,
  setParcelStyle,
  handleSuccess,
  removeEventKey
) => {
  var source;
  unByKey(removeEventKey);
  map.getLayers().forEach((layer) => {
    if (layer.get('title') && layer.get('title') === 'Parcel') {
      source = layer.getLayers().getArray()[0].getSource();
    }
  });

  const drawStartStyle = new Style({
    fill: new Fill({
      color: 'rgba(0,0,0,0)',
    }),
    stroke: new Stroke({
      color: 'rgba(255, 255, 255, 0.7)',
      width: 3,
    }),
    image: new CircleStyle({
      radius: 7,
      fill: new Fill({
        color: 'rgba(173, 216, 230, 0.7)',
      }),
      stroke: new Stroke({
        color: 'rgba(255, 255, 255, 0.7)',
        width: 2,
      }),
    }),
  });

  const drawStyle = new Style({
    stroke: new Stroke({
      color: 'red',
      width: 2,
    }),
    fill: new Fill({
      color: 'rgb(255, 255, 255,0.2)',
    }),
  });

  const draw = new Draw({
    source: source,
    type: 'Polygon',
    style: drawStartStyle,
  });

  const snap = new Snap({
    source: source,
  });

  setZoomLayer(false);
  setParcelEdit(false);
  removeAllInteraction(map);
  map.addInteraction(draw);
  setParcelStyle(drawStyle);
  // document.body.classList.remove('cut-cursor');
  // document.body.classList.remove('edit-cursor');
  // document.body.classList.remove('split-cursor');
  //  document.body.classList.add('crosshair-cursor');

   
  // const mapContainer = document.getElementById('map-cont');
  const mapContainer = document.querySelector('.ol-viewport');

  mapContainer.classList.remove('edit-cursor');
  mapContainer.classList.remove('split-cursor');
  mapContainer.classList.remove('cut-cursor');
  mapContainer.classList.add('crosshair-cursor');
  // let mapContainer = document.getElementById('map-cont');
  // mapContainer.classList.add('crosshair-cursor');

  draw.on('drawend', function (e) {
    map.removeLayer(parcelRef.current);
    let parser = new GeoJSON();
    let drawnFeatures = parser.writeFeaturesObject([e.feature]);
    var feature = e.feature;
    var poly = feature.getGeometry().getCoordinates();
    if (feature.getGeometry().getType() === 'Polygon') {
      var kinkedPoly = turf.polygon(poly);
      var unkinkedPoly = turf.unkinkPolygon(kinkedPoly);
      if (unkinkedPoly.features.length > 1) {
        handleSuccess(
          TOAST_TYPE.WARNING,
          'Self intersecting polygon is not supported'
        );
        removeSourceFeature(map, feature);
        return false;
      }
      // Check for overlaying polygons
      if (source) {
        map.getLayers().forEach((layer) => {
          if (layer.get('title') && layer.get('title') === 'Parcel') {
            source = layer.getLayers().getArray()[0].getSource();
          }
        });
        const drawnPolygon = turf.polygon(poly);
        const existingPolygons = source.getFeatures().map((f) => turf.polygon(f.getGeometry().getCoordinates()));

        for (const existingPolygon of existingPolygons) {

          // Check if the drawn polygon is intersecting an existing polygon
          if (turf.booleanOverlap(drawnPolygon, existingPolygon)) {
            console.log('existingPolygons', source.getFeatures());
            handleSuccess(
              TOAST_TYPE.WARNING,
              'Overlaying polygons are not supported'
            );
            return false;
          }

          // Check if the drawn polygon is inside an existing polygon
          if (turf.booleanContains(existingPolygon, drawnPolygon)) {
            console.log('existingPolygons', source.getFeatures());
            handleSuccess(
              TOAST_TYPE.WARNING,
              'Overlaying polygons are not supported'
            );
            return false;
          }
        }
      }
    }
    const parcelData = {
      id: polygonList.length
        ? parseInt(polygonList[polygonList.length - 1].id) + 1
        : parseInt(polygonList.length) + 1,
      data: [drawnFeatures.features[0].geometry.coordinates[0]],
      visible: true,
      measurement: calculateArea([
        drawnFeatures.features[0].geometry.coordinates[0],
      ]),
    };
    polygonList.push(parcelData);
    setPolygonList([...polygonList]);
  });
  if (polygonList.length > 0) {
    map.addInteraction(snap);
  }
};

export default drawPolygon;