import React from "react";
import "./ExploreProgressBar.css";
import { Tooltip } from "@mui/material";
import { STATUS } from "../../utils/constants";
import { ReactComponent as CheckBlackIcon } from "../../assets/CheckBlack.svg";

const ProgressBar = ({ status, step, setStep, featureSelected, disableEverything }) => {
  const row = [];
  for (let i = 1; i <= 4; i++) {
    if (i < step) {
      if (i === 1)
        row.push(
          <>
            <div className="explore-progress-number completed">
              <CheckBlackIcon className="progress-check" />
            </div>
          </>
        );
      else
        row.push(
          <>
            <hr className="explore-progress-line explore-progress-line-active" />
            <div
              onClick={() => !disableEverything && (status !== STATUS.IN_PROGRESS ? setStep(i) : null)}
              className="explore-progress-number completed"
            >
              <CheckBlackIcon className="progress-check" />
            </div>
          </>
        );
    } else if (i === step) {
      if (i === 1)
        row.push(
          <>
            <div
              onClick={() => !disableEverything && (status !== STATUS.IN_PROGRESS ? setStep(i) : null)}
              className="explore-progress-number activeStep"
            >
              {i}
            </div>
          </>
        );
      else if(step === 4 && disableEverything) {
        row.push(
          <>
            <hr className="explore-progress-line explore-progress-line-active" />
            <div
              onClick={() => !disableEverything && (status !== STATUS.IN_PROGRESS ? setStep(i) : null)}
              className="explore-progress-number completed"
            >
              <CheckBlackIcon className="progress-check" />
            </div>
          </>
        );
      }
      else
        row.push(
          <>
            <hr className="explore-progress-line explore-progress-line-active" />
            <div
              onClick={() => !disableEverything  && (status !== STATUS.IN_PROGRESS ? setStep(i) : null)}
              className="explore-progress-number activeStep"
            >
              {i}
            </div>
          </>
        );
    } else
      row.push(
        <>
          <hr className="explore-progress-line" />
          <div
            onClick={() => !disableEverything && (featureSelected && (featureSelected.length ? setStep(i) : null))}
            className="explore-progress-number pending"
          >
            {i}
          </div>
        </>
      );
  }

  return (
    <div className="explore-progress-main-container">
      <div className="explore-progress-top-container">
        {row.map((item, index) => {
          return (
            <Tooltip
              title={
                index === 0
                  ? "Select Address"
                  : index === 1
                    ? "Parcel Confirmation"
                    : index === 2
                      ? "Feature Selection"
                      : index === 3
                        ? "Place Order"
                        : null
              }
              className="MuiTooltip-tooltip"
            >
              <div key={index}>{item}</div>
            </Tooltip>
          );
        })}
      </div>
    </div>
  );
};

export default ProgressBar;
