import Map from 'ol/Map';
import View from 'ol/View';
import XYZ from 'ol/source/XYZ';
import TileLayer from 'ol/layer/Tile';
import Rotate from 'ol/control/Rotate';
import { defaults } from 'ol/control/defaults';
import resetRotation from '../OLControls/MapRotationControl';

const initializeMap = (mapElement, setMap) => {

  const rotateControl = new Rotate({
    autoHide: false,
    onRotate: resetRotation,
    tipLabel: 'Reset Rotation',
    className: 'custom-rotate-control',
    target: document.querySelector('.map-type-buttons'),
  });
  const initialMap = new Map({
    view: new View({
      zoom: 19,
      rotate: 0,
      maxZoom: 24,
    }),
    layers: [
      new TileLayer({
        source: new XYZ({
          // https://dev.ottermap.com/v1/api/mole/image/google-map/y/en/{x}/{y}/{z}
         
          // https://dev.ottermap.com/v1/api/mole/image/google-map/m/en/{x}/{y}/{z}
          // url: 'https://dev.ottermap.com/v1/api/mole/image/google-map/y/en/{x}/{y}/{z}',

          // working url
          url: 'http://mt0.google.com/vt/lyrs=y&hl=en&x={x}&y={y}&z={z}',
          // url: `${REACT_APP_NEW_WEB_LAYER_URL}/google-map/m/en/{x}/{y}/{z}`,
          
          crossOrigin: 'Anonymous',
        }),
        visible: true,
        title: 'Satellite',
      }),
      new TileLayer({
        source: new XYZ({

          // url: ' http://mt0.google.com/vt/lyrs=y&hl=en&x={x}&y={y}&z={z}',
          // url: ' https://dev.ottermap.com/v1/api/mole/image/google-map/m/en/{x}/{y}/{z}',


          url: 'http://mt0.google.com/vt/lyrs=m&hl=en&x={x}&y={y}&z={z}',

          // url: `${mapUrl}/m/en/{x}/{y}/{z}`,


          crossOrigin: 'Anonymous',
        }),
        visible: false,
        title: 'Road',
      }),
    ],
    target: mapElement.current,
    keyboardEventTarget: document,
    controls: defaults({
      zoom: false,
      rotate: false,
      attribution: false,
    }).extend([rotateControl]),
  });
  setMap(initialMap);
};

export default initializeMap;
