import { toLonLat } from 'ol/proj';

const coordinatesToLatLong = (coordinates, type) => {
  const latLong = [];
  if (type === 'polygon') {
    for (let j = 0; j < coordinates.length; j++) {
      const singlePolygon = coordinates[j];
      const singlePolygonData = [];
      for (let k = 0; k < singlePolygon.length; k++) {
        let lonLat = toLonLat(singlePolygon[k]);
        singlePolygonData.push(lonLat);
      }
      latLong.push(singlePolygonData);
    }
    return latLong;
  } else if (type === 'point') {
    return toLonLat(coordinates[0]);
  } else if (type === 'line') {
    for (let i = 0; i < coordinates.length; i++) {
      let singlePoint = toLonLat(coordinates[i]);
      latLong.push(singlePoint);
    }
    return latLong;
  }
};

export default coordinatesToLatLong;
