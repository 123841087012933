import './MapHeader.css';
import React from 'react';
import { STATUS } from '../../utils/constants';
import AddressBar from '../AddressBar/AddressBar';
import MeasurementPanel from './MeasurementPanel/MeasurementPanel';
import BulkOrderChangeContainer from '../BulkOrderChangeContainer/BulkOrderChangeContainer';
import ToolBar from '../ToolBar/ToolBar';
import ToolBarToday from '../ToolBar/ToolBarToday';

const MapHeader = ({
  id,
  map,
  viewId,
  status,
  address,
  hex2rgba,
  hoverElem,
  notesModal,
  toolbarRef,
  isPreview,
  contentRef,
  layersCount,
  orderNumber,
  handleReset,
  hoverMapKey,
  notesButton,
  handleError,
  bulkOrderId,
  downloadPng,
  areaFeatures,
  downloadData,
  isStudioOrder,
  mapClickable,
  viewPanelRef,
  setZoomLayer,
  handleSuccess,
  uploadedLayer,
  feedbackLayer,
  cutLayersName,
  outerViewName,
  setResetLayer,
  measurementRef,
  setHoverMapKey,
  setAreaFeatures,
  navigateAddress,
  bulkOrderStatus,
  setCutLayersName,
  setFeedbackAdded,
  setUploadedLayer,
  feedbackModalShow,
  setFeedbackMarker,
  pngWithMeasureRefs,
  toggleMapClickable,
  setBulkOrderStatus,
  handleCustomSuccess,
  setFeedbackEditable,
  setFeedbackModalShow,
  isContextInteraction,
  setOrderNotesModalShow,
  setAllFeedbackResolved,
  setFeedbackLayerVisible,
  setOrderNoteLayerVisible,
  toggleFeedbackMarkerVisible,
  toggleOrderNoteMarkerVisible,
  setMultipleContextInteraction,
}) => {
  return (
    <div className="map-header-main-container">
      <div className="map-header-container-one">
        <div className="map-header-container-one-left">
          <AddressBar address={address} navigateAddress={navigateAddress} />
        </div>
        <div className="map-header-container-one-right">ID: {orderNumber}</div>
      </div>
      <div className="map-header-container-two">
        {bulkOrderId && !isPreview && !isStudioOrder && toolbarRef.current.style.display !== "block" ? (
          <BulkOrderChangeContainer
            id={id}
            address={address}
            bulkOrderId={bulkOrderId}
            handleSuccess={handleSuccess}
            bulkOrderStatus={bulkOrderStatus}
            setBulkOrderStatus={setBulkOrderStatus}
            handleCustomSuccess={handleCustomSuccess}
          />
        ) : null}
        {/* to add toolbar in the middle */}
        {/* {isStudioOrder? (<ToolBar/> ):null} */}
        {/* {isStudioOrder? (
         
          <ToolBarToday
        toolbarRef={toolbarRef}
        layerType={layerType}
        lineInteraction={lineInteraction}
        pointInteraction={pointInteraction}
        polygonInteraction={polygonInteraction}
        editInteraction={editInteraction}
        cutInteraction={cutInteraction}
        splitInteraction={splitInteraction}
        hoverMapKey={hoverMapKey}
        map={map}
        id={id}
        layerName={props.layerName}
        viewId={props.viewId}
        layerID={props.layerID}
        uploadedLayer={props.uploadedLayer}
        setUploadedLayer={props.setUploadedLayer}
        setZoomLayer={props.setZoomLayer}
        handleSuccess={props.handleSuccess}
        drawLayer={drawLayer}
        editLayer={editLayer}
        cutLayer={cutLayer}
        SplitInteraction={SplitInteraction}
        unByKey={unByKey}
        setLineInteraction={setLineInteraction}
        setCutInteraction={setCutInteraction}
        setCutAllInteraction={setCutAllInteraction}
        setEditInteraction={setEditInteraction}
        setPointInteraction={setPointInteraction}
        isContextInteraction={props.isContextInteraction}
        setMultipleContextInteraction={props.setMultipleContextInteraction}
        setSplitInteraction={setSplitInteraction}
        setPolygonInteraction={setPolygonInteraction}
        setMultipleSelectInteraction={props.setMultipleSelectInteraction}
        setLayerStyle={setLayerStyle}
        removeAllInteraction={removeAllInteraction}
        hoverEffect={hoverEffect}
        hex2rgba={hex2rgba}
        hoverElem={props.hoverElem}
        cutLayersName={props.cutLayersName}
        setCutLayersName={props.setCutLayersName}
        setUndoStack={props.setUndoStack}
        setRedoStack={props.setRedoStack}
        setCurrentPointerIdx={props.setCurrentPointerIdx}
      />
        ):null} */}
          {/* to add toolbar in the middle */}
      </div>
      <div className="map-header-container-three">
        {status === STATUS.MEASUREMENT_COMPLETED || status === STATUS.DIY || isStudioOrder ? (
          <MeasurementPanel
            id={id}
            map={map}
            viewId={viewId}
            status={status}
            address={address}
            hex2rgba={hex2rgba}
            hoverElem={hoverElem}
            notesModal={notesModal}
            contentRef={contentRef}
            toolbarRef={toolbarRef}
            layersCount={layersCount}
            handleReset={handleReset}
            downloadPng={downloadPng}
            hoverMapKey={hoverMapKey}
            notesButton={notesButton}
            handleError={handleError}
            mapClickable={mapClickable}
            setZoomLayer={setZoomLayer}
            viewPanelRef={viewPanelRef}
            downloadData={downloadData}
            areaFeatures={areaFeatures}
            isStudioOrder={isStudioOrder}
            cutLayersName={cutLayersName}
            handleSuccess={handleSuccess}
            feedbackLayer={feedbackLayer}
            uploadedLayer={uploadedLayer}
            outerViewName={outerViewName}
            setResetLayer={setResetLayer}
            measurementRef={measurementRef}
            setHoverMapKey={setHoverMapKey}
            setAreaFeatures={setAreaFeatures}
            setFeedbackAdded={setFeedbackAdded}
            setCutLayersName={setCutLayersName}
            setUploadedLayer={setUploadedLayer}
            setFeedbackMarker={setFeedbackMarker}
            feedbackModalShow={feedbackModalShow}
            toggleMapClickable={toggleMapClickable}
            setFeedbackEditable={setFeedbackEditable}
            setFeedbackModalShow={setFeedbackModalShow}
            isContextInteraction={isContextInteraction}
            setOrderNotesModalShow={setOrderNotesModalShow}
            setAllFeedbackResolved={setAllFeedbackResolved}
            setFeedbackLayerVisible={setFeedbackLayerVisible}
            setOrderNoteLayerVisible={setOrderNoteLayerVisible}
            pngWithMeasureRefs={pngWithMeasureRefs}
            toggleFeedbackMarkerVisible={toggleFeedbackMarkerVisible}
            toggleOrderNoteMarkerVisible={toggleOrderNoteMarkerVisible}
            setMultipleContextInteraction={setMultipleContextInteraction}
          />
        ) : null}
      </div>
    </div>
  );
};

export default MapHeader;
