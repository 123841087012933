import './Toaster.css';
import React from 'react';
import { useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import { TOAST_TYPE } from '../../utils/constants';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';

export default function Toaster({
  type,
  message,
  showToaster,
  setShowToaster,
}) {
  useEffect(() => {
    if (showToaster === true) {
      setTimeout(() => {
        setShowToaster(false);
      }, 5000);
    }
  }, [showToaster]);

  return (
    <div className="toaster-container">
      <div className={type === TOAST_TYPE.SUCCESS ? "success-container"
        : type === TOAST_TYPE.WARNING ? "warning-container"
          : type === TOAST_TYPE.ERROR ? "error-container" : ""}>
        {type === TOAST_TYPE.SUCCESS ? <CheckIcon />
          : type === TOAST_TYPE.WARNING ? <WarningAmberRoundedIcon />
            : type === TOAST_TYPE.ERROR ? <CloseRoundedIcon /> : null}
        <span>
          {message}
          <CloseIcon onClick={() => setShowToaster(false)} className='toaster-close-icon' />
        </span>
      </div>
    </div>
  );
}
