import './Home.css';
import TopBar from '../TopBar/TopBar';
import Footer from '../Footer/Footer';
import SideBar from '../SideBar/SideBar';
import { Outlet } from 'react-router-dom';
import { constants } from '../../utils/constants';
import React, { useState, useEffect } from 'react';
import { useLoadScript } from '@react-google-maps/api';
import { useScreenType } from '../../utils/commonMethods';
import { useUserContext } from '../../context/UserContext';
import { ReactComponent as SiteLogo } from '../../assets/logo.svg';

const Home = ({ handleError, handleSuccess }) => {
  const isMobile = useScreenType();
  const url = window.location.href;
  const { userInfo } = useUserContext();
  const libraries = ['places', 'drawing'];
  const [isWarningVisible, setWarningVisibility] = useState(true);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: constants.googleMapsApiKey,
    libraries,
  });

  useEffect(() => {
    const storedVisibility = sessionStorage.getItem('paymentWarningVisibility');
    if (storedVisibility === 'true') {
      setWarningVisibility(true);
    } else if (storedVisibility === 'false') {
      setWarningVisibility(false);
    }
  }, []);

  if (loadError) return '';
  if (!isLoaded) return '';

  return (
    <div className="home-container">
      <div className="home-left-container">
        {localStorage.getItem('role') !== 'otter_user' && !isMobile ? (
          <SideBar handleError={handleError} handleSuccess={handleSuccess} />
        ) : !url.includes('order') && isMobile ? (
          <Footer />
        ) : null}
      </div>
      <div className="home-right-container">
        {isWarningVisible && userInfo?.header_data ? (
          <div className="home-right-top-container-background">
            <div className="home-right-top-container">
              <TopBar setWarningVisibility={setWarningVisibility} />
            </div>
          </div>
        ) : null}
        <div className="home-right-bottom-container">
          <Outlet />
        </div>
      </div>
      {!isMobile ? (
        <div
          className={
            url.includes('upload/order/')
              ? 'right-side-logo-div'
              : (url.includes('/order/') || url.includes('/studio'))
                ? 'right-side-logo-div-map'
                : 'right-side-logo-div'
          }
        >
          <SiteLogo className="right-side-logo" />
        </div>
      ) : null}
    </div>
  );
};

export default Home;
