import React, { useState } from "react";
import { ADD_FEEDBACK } from "../../helper/ApiUrl";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import axios from "axios";
import AuthToken from "../../context/AuthToken";
import "./FeedbackForm.css";
import { FEEDBACK_STATUS, TOAST_TYPE } from "../../utils/constants";
import { useEffect } from "react";

// new
import { ORDER_URL, LOGIN_URL } from "../../helper/ApiUrl";

import { Tooltip } from "@material-ui/core";
import VideocamIcon from "@mui/icons-material/Videocam";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import AttachmentIcon from "@mui/icons-material/Attachment";
import CircularProgress from "@mui/material/CircularProgress";
import CloseIcon from "@mui/icons-material/Close";
import ImageIcon from "@mui/icons-material/Image";
import TextFieldsIcon from "@mui/icons-material/TextFields";
import FormatColorTextIcon from "@mui/icons-material/FormatColorText";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import { SketchPicker } from "react-color";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileAlt,
  faFilePdf,
  faFileWord,
  faFileExcel,
  faFileAudio,
  faFileVideo,
  faFilePowerpoint,
} from "@fortawesome/free-regular-svg-icons";
import MediaDialog from "../MediaDialog/MediaDialog";
import path from "path";

//
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { encodeJwtToken } from "../../utils/EncodeJwt";
import { toast } from "react-toastify";

var toolbarOptions = [
  ["bold", "italic", "underline"],

  ["link"],
  [{ list: "ordered" }, { list: "bullet" }],

  [{ header: [1, 2, 3, 4, 5, 6, false] }],

  [{ color: [] }],

  [{ align: [] }],

  ["clean"],
];

const Toolbarmodule = {
  toolbar: toolbarOptions,
};
const editorStyle = {
  backgroundColor: 'white',
  padding: '10px',
  borderBottomLeftRadius: '10px',
  borderBottomRightRadius: '10px'
};


export default function FeedbackForm({
  feedbackMarker,
  feedbackAdded,
  setFeedbackAdded,
  feedbackLayer,
  setFeedbackLayer,
  setFeedbackLayerVisible,
  toggleMapClickable,
  handleSuccess,
  handleError,
  toggleFeedbackMarkerVisible,
  feedbackEditable,
  setFeedbackEditable,
}) {
  const token = AuthToken();
  const { id } = useParams();
  const role = localStorage.getItem("role");
  const [feedbackTitle, setFeedbackTitle] = useState();
  const [feedbackDescription, setFeedbackDescription] = useState();
   // for hiding the something if it will be the in preview mode 
   const location = useLocation();
   const searchParams = new URLSearchParams(location.search);
  const prevfdbkk = searchParams.get("prevfdbk");
  
  const isPreviewPath = location.pathname.includes("/preview");
     // for hiding the something if it will be the in preview mode 

  // HA
  const [attachments, setAttachments] = useState(feedbackMarker.attachments);

  const url = window.location.href;

  const prod = process.env.REACT_APP_PROD;
  const [fontStyle, setFontStyle] = useState("h1");

  const [orderNoteEditable, setOrderNoteEditable] = useState(false);

  const [titleSize, setTitleSize] = useState(false);
  const [Colorbox, setColorBox] = useState(false);
  const [color, setColor] = useState("#000000");
  const [bullets, setBullets] = useState(false);
  const [dialogMedia, setDialogMedia] = useState();
  const [openDialog, setOpenDialog] = useState(false);

  ///
  const [descriptionColor, setDescriptionColor] = useState("#000000");
  const handleOpenDialog = (item) => {
    let p = path.extname(item);
    if (
      p !== ".jpg" &&
      p !== ".jpeg" &&
      p !== ".png" &&
      p !== ".gif" &&
      p !== ".mp4" &&
      p !== ".mov"
    )
      window.open(item, "_blank");
    else {
      setDialogMedia(item);
      setOpenDialog(true);
    }
  };

  const deleteAttachment = (item) => {
    var arr = attachments;
    const index = arr.indexOf(item);
    if (index !== -1) {
      arr.splice(index, 1);
      setAttachments([...arr]);
    }
  };

  const FileIcon = ({ url }) => {
    console.log(url, "getting in URL after uploading the file ");
    let extname = path.extname(url);
    let icon;
    switch (extname) {
      case ".pdf":
        icon = faFilePdf;
        break;
      case ".doc":
      case ".docx":
        icon = faFileWord;
        break;
      case ".ppt":
      case ".pptx":
        icon = faFilePowerpoint;
        break;
      case ".xls":
      case ".xlsx":
        icon = faFileExcel;
        break;
      case ".jpg":
      case ".jpeg":
      case ".png":
      case ".gif":
        return <img style={{ cursor: "pointer" }} src={url} alt={url} />;
      case ".mp3":
      case ".wav":
        icon = faFileAudio;
        break;
      case ".mp4":
      case ".mov":
        icon = faFileVideo;
        break;
      default:
        icon = faFileAlt;
        break;
    }
    return <FontAwesomeIcon className="doc-type-attachment-icon" icon={icon} />;
  };
  //

  useEffect(() => {
    setFeedbackTitle(feedbackMarker.title);
    setFeedbackDescription(feedbackMarker.description);
    if (
      feedbackMarker.status !== FEEDBACK_STATUS.OPEN &&
      feedbackMarker.status !== FEEDBACK_STATUS.REOPEN
    ) {
      setFeedbackEditable(true);
    } else setFeedbackEditable(false);
  }, [feedbackMarker]);

  const titleHandler = (e) => setFeedbackTitle(e.target.value);
  const 
  descriptionHandler = (e) => setFeedbackDescription(e.target.value);

  // HA
  const handleColorChange = (color) => {
    setColor(color.hex);
  };

  const setData = (e) => {
    let element = document.getElementById("attachment-loader");
    element.classList.remove("attachment-loader-hide");
    element.classList.add("attachment-loader-show");
    console.log(e.target.files[0], "  e.target.files[0] e- response");
    let formData = new FormData();

    formData.append("file", e.target.files[0]);

    // formData.append("title", feedbackTitle);
    // formData.append("description", feedbackDescription);
   if(isPreviewPath){ 
    handleSuccess(TOAST_TYPE.WARNING, "Cannot edit feedback!");
    return
   }
   else{
    axios({
      url: `${LOGIN_URL}file/upload?folder=notes`,
      method: "POST",
      data: formData,
      headers: {
        "x-api-key": `${encodeJwtToken()}`,
      },
    })
      .then((res) => {
        if ("data" in res) {
          if (!attachments) setAttachments([res.data.file_url]);
          else setAttachments([...attachments, res.data.file_url]);
          handleSuccess(TOAST_TYPE.SUCCESS, "File added successfully");
          element.classList.remove("attachment-loader-show");
          element.classList.add("attachment-loader-hide");
        }
      })
      .catch((err) => {
        console.log(err, " FeedBack error in upload media files");
        handleError(err);
        element.classList.remove("attachment-loader-show");
        element.classList.add("attachment-loader-hide");
      });

    }
    
  };
  //
  const addFeedback = (e) => {
    e.preventDefault();
    // for the file upload

    let element = document.getElementById("attachment-loader");
    element.classList.remove("attachment-loader-hide");
    element.classList.add("attachment-loader-show");
    // console.log(e.target.files[0], "  e.target.files[0] e- response");
    let formData = new FormData();
    // formData.append("file", e.target.files[0]);
    // for the file upload
    // let formData = new FormData();
    

    if (feedbackMarker.id) {
      formData.append("title", feedbackTitle);
      formData.append("description", feedbackDescription);
      //
      if (attachments)
        formData.append("attachments", JSON.stringify(attachments));

      //
      axios({
        url: `${ADD_FEEDBACK}${id}/feedback/${feedbackMarker.id}`,
        method: "PUT",
        data: formData,
        headers: {
          Authorization: `Token ${token}`,
        },
      })
        .then((res) => {
          if ("data" in res) {
            handleSuccess(TOAST_TYPE.SUCCESS, "Feedback successfully updated");
          }
        })
        .catch((err) => {
          handleError(err);
        });
    } else {
      formData.append("title", feedbackTitle);
      formData.append("description", feedbackDescription);
      formData.append("lat", feedbackMarker.lat);
      formData.append("long", feedbackMarker.long);
      //
      if (attachments)
        formData.append("attachments", JSON.stringify(attachments));

      //
      axios({
        url: `${ADD_FEEDBACK}${id}/feedback`,
        method: "POST",
        data: formData,
        headers: {
          Authorization: `Token ${token}`,
        },
      })
        .then((res) => {
          if ("data" in res) {
            handleSuccess(TOAST_TYPE.SUCCESS, "Feedback successfully added");
          }
        })
        .catch((err) => {
          // handleError(err);
        });
    }
    toggleFeedbackMarkerVisible(false);
    toggleMapClickable(false);
    setFeedbackLayerVisible(false);
    setFeedbackAdded(true);
    setFeedbackTitle("");
    setFeedbackDescription("");
  };

  const onCloseClick = (e) => {
    e.preventDefault();
    toggleFeedbackMarkerVisible(false);
  };

  return (
    <div className="overlay-container">
      <div className="feedback-infowindow-container">
        <form>
          <div className="feedback-title-input-container">
            <div className="feedback-title-label">Title:</div>
            <div className="feedback-title-input">
              <input
                type="text"
                required
                defaultValue={feedbackMarker.title}
                disabled={feedbackEditable}
                onChange={titleHandler}
                value={feedbackTitle}
              />
            </div>
          </div>
          <div className="feedback-description-input-container">
            <div className="feedback-description-label">Description:</div>
            <div className="feedback-description-input">
             

              <textarea
                rows="3"
                required
                defaultValue={feedbackMarker.description}
                disabled={feedbackEditable}
                onChange={descriptionHandler}
                value={feedbackDescription}
              />
            </div>
          </div>

          {/* New code  ReactQUill */}

          {/* <div className="feedback-description-input-container">
            <div className="feedback-description-label">Description:</div>
            <div className="feedback-description-input"> */}
                  {/* <ReactQuill
                    value={Qdescription}
                    onChange={QsetDescription}
                    modules={Toolbarmodule}
                    style={editorStyle}
                  /> */}
                  {/* <ReactQuill
                   defaultValue={feedbackMarker.description}
                  disabled={feedbackEditable}
                  onChange={descriptionHandler}
                   value={feedbackDescription}
                    modules={Toolbarmodule}
                    style={editorStyle}
                  /> */}
                {/* </div>
                </div> */}

                {/* New code  ReactQUill */}

          {/* new code */}
          <div className="order-note-actions-cont" style={{justifyContent:"flex-start",           visibility: isPreviewPath && prevfdbkk === "true" ? "hidden" : "visible",
}}>
            <input
              type="file"
              // disabled={!orderNoteEditable}
              id="imgupload"
              style={{ display: "none" }}
              accept="image/*"
              onChange={setData}
              // onChange={addFeedback}
            />
            <Tooltip
              placement="top"
              title={orderNoteEditable ? "Add Image" : "Edit note to Add File"}
            >
              <label htmlFor="imgupload">
                <ImageIcon style={{ color: "green", cursor: "pointer" }} />
              </label>
            </Tooltip>
            <input
              type="file"
              // disabled={!orderNoteEditable}
              id="vidupload"
              style={{ display: "none" }}
              accept="video/*"
              onChange={setData}
              //  onChange={addFeedback}
            />
            <Tooltip
              placement="top"
              title={orderNoteEditable ? "Add Video" : "Edit note to Add File"}
            >
              <label htmlFor="vidupload">
                <VideocamIcon
                  style={
                    // orderNoteEditable ?
                    { color: "green", cursor: "pointer" }
                    // : { color: "grey" }
                  }
                />
              </label>
            </Tooltip>
            <input
              type="file"
              // disabled={!orderNoteEditable}
              id="attachmentupload"
              style={{ display: "none" }}
              onChange={setData}
              // onChange={addFeedback}
            />
            <Tooltip
              placement="top"
              title={
                orderNoteEditable ? "Add Document" : "Edit note to Add File"
              }
            >
              <label htmlFor="attachmentupload">
                <AttachmentIcon
                  style={
                    // orderNoteEditable  ?
                    { color: "green", cursor: "pointer" }
                    // : { color: "grey" }
                  }
                />
              </label>
            </Tooltip>

            {/* New Code for the font and color  */}
            {/* <Tooltip
                placement="top"
                title={
                  orderNoteEditable ? "Add Document" : "Edit note to Add File"
                }
              >
                <label htmlFor="">
                  <TextFieldsIcon
                    style={
                      orderNoteEditable
                        ? { color: "green", cursor: "pointer" }
                        : { color: "grey" }
                    }
                    onClick={()=>{
                    
                      setTitleSize(!titleSize);
                   
                    }}
                  />
                  <div>
                    {titleSize?(<select
                      value={fontStyle}
                      onChange={(e) => setFontStyle(e.target.value)}
                    >
                      <option value="h1">H1</option>
                      <option value="h2">H2</option>
                      <option value="h3">H3</option>
                      <option value="h4">H4</option>
                      <option value="h5">H5</option>
                      <option value="h6">H6</option>
                    </select>):null}
                  </div>
                </label>
              </Tooltip> */}

            {/* for color */}
            {/* <Tooltip
                placement="top"
                title={
                  orderNoteEditable ? "Add Document" : "Edit note to Add File"
                }
              >
                <label htmlFor="#">
                  <FormatColorTextIcon
                    style={
                      orderNoteEditable
                        ? { color: "green", cursor: "pointer" }
                        : { color: "grey" }
                    }
                    onClick={()=>{
                      setColorBox(!Colorbox);
                    }}
                  />
                  <input type="color" value={descriptionColor} onChange={(e) => setDescriptionColor(e.target.value)} />
                  <div> { Colorbox?(  <SketchPicker color={color} onChangeComplete={handleColorChange} />):null} </div>
                </label>
              </Tooltip> */}
            {/* bullet point */}
            {/* <Tooltip
                placement="top"
                title={
                  orderNoteEditable ? "Add Document" : "Edit note to Add File"
                }
              >
                <label htmlFor="#">
                  <FormatListBulletedIcon
                    style={
                      orderNoteEditable
                        ? { color: "green", cursor: "pointer" }
                        : { color: "grey" }
                    }
                  />
                  <div> 
                  {/* <input
            type="checkbox"
            checked={bullets}
            onChange={(e) => setBullets(e.target.checked)}
          /> */}
            {/* </div>
                </label>
              </Tooltip> */}

            {/* New Code for the font and color  */}
          </div>

          {/* Attachment Container */}
          <div className="attachments-cont">
            {attachments &&
              Array.isArray(attachments) &&
              attachments.map((item, key) => {
                return (
                  <div
                    key={key}
                    onClick={() => handleOpenDialog(item)}
                    className="attachment-item-cont"
                  >
                    {/* {orderNoteEditable && (
                      <CloseIcon
                        className="delete-attachment"
                        onClick={(e) => {
                          e.stopPropagation();
                          deleteAttachment(item);
                        }}
                      />
                    )} */}
                    {
                      <CloseIcon
                        className="delete-attachment"
                        onClick={(e) => {
                          e.stopPropagation();
                          deleteAttachment(item);
                        }}
                        style={{
                      visibility: isPreviewPath && prevfdbkk === "true" ? "hidden" : "visible",
                     }}
                      />
                    }

                    <FileIcon url={item} />
                  </div>
                );
              })}
            <div id="attachment-loader" className="attachment-loader-hide"
            style={{
           visibility: isPreviewPath && prevfdbkk === "true" ? "hidden" : "visible",
        }}
            >
              <CircularProgress color="success" />
            </div>
          </div>
          <MediaDialog
            dialogMedia={dialogMedia}
            openDialog={openDialog}
            setOpenDialog={setOpenDialog}
          />

          {/* new code */}
          {role !== "otter_user" ? (
            <div className="feedback-buttons-container">
              <button className="feedback-cancel-button" onClick={onCloseClick}>
                
               { isPreviewPath && prevfdbkk === "true" ? "Close" : "Cancel"}
              </button>
              {feedbackMarker.status === FEEDBACK_STATUS.OPEN ||
              feedbackMarker.status === FEEDBACK_STATUS.REOPEN ? (
                <button
                  className="feedback-submit-button"
                  disabled={!feedbackTitle || !feedbackDescription}
                  onClick={addFeedback}
                  style={{
                   visibility: isPreviewPath && prevfdbkk === "true" ? "hidden" : "visible"
        }}
                >
                  Submit
                </button>
              ) : null}
            </div>
          ) : null}
        </form>
      </div>
    </div>
  );
}

// --------------------
// import React, { useState, useEffect } from "react";
// import { ADD_FEEDBACK } from "../../helper/ApiUrl";
// import { useParams } from "react-router-dom";
// import axios from "axios";
// import AuthToken from "../../context/AuthToken";
// import "./FeedbackForm.css";
// import { FEEDBACK_STATUS, TOAST_TYPE } from "../../utils/constants";

// import { Tooltip } from "@material-ui/core";
// import VideocamIcon from "@mui/icons-material/Videocam";
// import MoreVertIcon from "@mui/icons-material/MoreVert";
// import AttachmentIcon from "@mui/icons-material/Attachment";
// import CircularProgress from "@mui/material/CircularProgress";
// import CloseIcon from "@mui/icons-material/Close";
// import ImageIcon from "@mui/icons-material/Image";
// import TextFieldsIcon from "@mui/icons-material/TextFields";
// import FormatColorTextIcon from "@mui/icons-material/FormatColorText";
// import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
// import { SketchPicker } from "react-color";

// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//   faFileAlt,
//   faFilePdf,
//   faFileWord,
//   faFileExcel,
//   faFileAudio,
//   faFileVideo,
//   faFilePowerpoint,
// } from "@fortawesome/free-regular-svg-icons";
// import MediaDialog from "../MediaDialog/MediaDialog";
// import path from "path";
// import ReactQuill from "react-quill";
// import "react-quill/dist/quill.snow.css";

// var toolbarOptions = [
//   ["bold", "italic", "underline"],
//   ["link"],
//   [{ list: "ordered" }, { list: "bullet" }],
//   [{ header: [1, 2, 3, 4, 5, 6, false] }],
//   [{ color: [] }],
//   [{ align: [] }],
//   ["clean"],
// ];

// const Toolbarmodule = {
//   toolbar: toolbarOptions,
// };

// export default function FeedbackForm({
//   feedbackMarker,
//   feedbackAdded,
//   setFeedbackAdded,
//   feedbackLayer,
//   setFeedbackLayer,
//   setFeedbackLayerVisible,
//   toggleMapClickable,
//   handleSuccess,
//   handleError,
//   toggleFeedbackMarkerVisible,
//   feedbackEditable,
//   setFeedbackEditable,
// }) {
//   const token = AuthToken();
//   const { id } = useParams();
//   const role = localStorage.getItem("role");
//   const [feedbackTitle, setFeedbackTitle] = useState();
//   const [feedbackDescription, setFeedbackDescription] = useState();
//   const [attachments, setAttachments] = useState([]);
//   const url = window.location.href;
//   const prod = process.env.REACT_APP_PROD;
//   const [fontStyle, setFontStyle] = useState("h1");
//   const [orderNoteEditable, setOrderNoteEditable] = useState(false);
//   const [titleSize, setTitleSize] = useState(false);
//   const [Colorbox, setColorBox] = useState(false);
//   const [color, setColor] = useState("#000000");
//   const [bullets, setBullets] = useState(false);
//   const [dialogMedia, setDialogMedia] = useState();
//   const [openDialog, setOpenDialog] = useState(false);
//   const [descriptionColor, setDescriptionColor] = useState("#000000");

//   useEffect(() => {
//     setFeedbackTitle(feedbackMarker.title);
//     setFeedbackDescription(feedbackMarker.description);
//     if (
//       feedbackMarker.status !== FEEDBACK_STATUS.OPEN &&
//       feedbackMarker.status !== FEEDBACK_STATUS.REOPEN
//     ) {
//       setFeedbackEditable(true);
//     } else setFeedbackEditable(false);
//   }, [feedbackMarker]);

//   const titleHandler = (e) => setFeedbackTitle(e.target.value);
//   const descriptionHandler = (e) => setFeedbackDescription(e.target.value);

//   const handleColorChange = (color) => {
//     setColor(color.hex);
//   };

//   const setData = (e) => {
//     let element = document.getElementById("attachment-loader");
//     element.classList.remove("attachment-loader-hide");
//     element.classList.add("attachment-loader-show");

//     let formData = new FormData();
//     formData.append("file", e.target.files[0]);

//     axios({
//       url: `${LOGIN_URL}file/upload?folder=notes`,
//       method: "POST",
//       data: formData,
//       headers: {
//         Authorization: `Token ${token}`,
//       },
//     })
//       .then((res) => {
//         if ("data" in res) {
//           if (!attachments) setAttachments([res.data.file_url]);
//           else setAttachments([...attachments, res.data.file_url]);
//           handleSuccess(TOAST_TYPE.SUCCESS, "File added successfully");
//           element.classList.remove("attachment-loader-show");
//           element.classList.add("attachment-loader-hide");
//         }
//       })
//       .catch((err) => {
//         handleError(err);
//         element.classList.remove("attachment-loader-show");
//         element.classList.add("attachment-loader-hide");
//       });
//   };

//   const addFeedback = (e) => {
//     e.preventDefault();

//     let formData = new FormData();
//     if (feedbackMarker.id) {
//       formData.append("title", feedbackTitle);
//       formData.append("description", feedbackDescription);
//       if (attachments) formData.append("attachments", JSON.stringify(attachments));

//       axios({
//         url: `${ADD_FEEDBACK}${id}/feedback/${feedbackMarker.id}`,
//         method: "PUT",
//         data: formData,
//         headers: {
//           Authorization: `Token ${token}`,
//         },
//       })
//         .then((res) => {
//           if ("data" in res) {
//             handleSuccess(TOAST_TYPE.SUCCESS, "Feedback successfully updated");
//           }
//         })
//         .catch((err) => {
//           handleError(err);
//         });
//     } else {
//       formData.append("title", feedbackTitle);
//       formData.append("description", feedbackDescription);
//       formData.append("lat", feedbackMarker.lat);
//       formData.append("long", feedbackMarker.long);
//       if (attachments) formData.append("attachments", JSON.stringify(attachments));

//       axios({
//         url: `${ADD_FEEDBACK}${id}/feedback`,
//         method: "POST",
//         data: formData,
//         headers: {
//           Authorization: `Token ${token}`,
//         },
//       })
//         .then((res) => {
//           if ("data" in res) {
//             handleSuccess(TOAST_TYPE.SUCCESS, "Feedback successfully added");
//           }
//         })
//         .catch((err) => {
//           handleError(err);
//         });
//     }
//     toggleFeedbackMarkerVisible(false);
//     toggleMapClickable(false);
//     setFeedbackLayerVisible(false);
//     setFeedbackAdded(true);
//     setFeedbackTitle("");
//     setFeedbackDescription("");
//   };

//   const onCloseClick = (e) => {
//     e.preventDefault();
//     toggleFeedbackMarkerVisible(false);
//   };

//   const handleOpenDialog = (item) => {
//     let p = path.extname(item);
//     if (
//       p !== ".jpg" &&
//       p !== ".jpeg" &&
//       p !== ".png" &&
//       p !== ".gif" &&
//       p !== ".mp4" &&
//       p !== ".mov"
//     )
//       window.open(item, "_blank");
//     else {
//       setDialogMedia(item);
//       setOpenDialog(true);
//     }
//   };

//   const deleteAttachment = (item) => {
//     var arr = attachments;
//     const index = arr.indexOf(item);
//     if (index !== -1) {
//       arr.splice(index, 1);
//       setAttachments([...arr]);
//     }
//   };

//   const FileIcon = ({ url }) => {
//     let extname = path.extname(url);
//     let icon;
//     switch (extname) {
//       case ".pdf":
//         icon = faFilePdf;
//         break;
//       case ".doc":
//       case ".docx":
//         icon = faFileWord;
//         break;
//       case ".ppt":
//       case ".pptx":
//         icon = faFilePowerpoint;
//         break;
//       case ".xls":
//       case ".xlsx":
//         icon = faFileExcel;
//         break;
//       case ".jpg":
//       case ".jpeg":
//       case ".png":
//       case ".gif":
//         return <img style={{ cursor: "pointer" }} src={url} alt={url} />;
//       case ".mp3":
//       case ".wav":
//         icon = faFileAudio;
//         break;
//       case ".mp4":
//       case ".mov":
//         icon = faFileVideo;
//         break;
//       default:
//         icon = faFileAlt;
//         break;
//     }
//     return <FontAwesomeIcon className="doc-type-attachment-icon" icon={icon} />;
//   };

//   return (
//     <div className="overlay-container">
//       <div className="feedback-infowindow-container">
//         <form>
//           <div className="feedback-title-input-container">
//             <div className="feedback-title-label">Add a Title</div>
//             <div className="feedback-title-input">
//               <input
//                 type="text"
//                 required
//                 placeholder="Start writing..."
//                 defaultValue={feedbackMarker.title}
//                 disabled={feedbackEditable}
//                 onChange={titleHandler}
//                 value={feedbackTitle}
//               />
//             </div>
//           </div>
//           <div className="feedback-description-input-container">
//             <div className="feedback-description-label">Description:</div>
//             <div className="feedback-description-input">
//               <textarea
//                 rows="3"
//                 required
//                 placeholder="Start writing..."
//                 defaultValue={feedbackMarker.description}
//                 disabled={feedbackEditable}
//                 onChange={descriptionHandler}
//                 value={feedbackDescription}
//               />
//             </div>
//           </div>

//           <div className="order-note-toolbar-icons">
//             <Tooltip title="Set Title Size" placement="bottom">
//               <TextFieldsIcon
//                 className="toolbar-icon"
//                 onClick={() => {
//                   setTitleSize(!titleSize);
//                   setColorBox(false);
//                   setBullets(false);
//                 }}
//               />
//             </Tooltip>
//             <Tooltip title="Set Title Color" placement="bottom">
//               <FormatColorTextIcon
//                 className="toolbar-icon"
//                 onClick={() => {
//                   setColorBox(!Colorbox);
//                   setTitleSize(false);
//                   setBullets(false);
//                 }}
//               />
//             </Tooltip>
//             <Tooltip title="Add Bullets" placement="bottom">
//               <FormatListBulletedIcon
//                 className="toolbar-icon"
//                 onClick={() => {
//                   setBullets(!bullets);
//                   setColorBox(false);
//                   setTitleSize(false);
//                 }}
//               />
//             </Tooltip>
//           </div>

//           {titleSize && (
//             <div className="fontSize-container">
//               <Tooltip title="Small Heading" placement="top">
//                 <h2 onClick={() => setFontStyle("h2")}>H2</h2>
//               </Tooltip>
//               <Tooltip title="Medium Heading" placement="top">
//                 <h3 onClick={() => setFontStyle("h3")}>H3</h3>
//               </Tooltip>
//               <Tooltip title="Large Heading" placement="top">
//                 <h1 onClick={() => setFontStyle("h1")}>H1</h1>
//               </Tooltip>
//             </div>
//           )}
//           {Colorbox && (
//             <div className="color-box">
//               <SketchPicker color={color} onChangeComplete={handleColorChange} />
//             </div>
//           )}
//           {bullets && (
//             <div className="bullet-container">
//               <ul>
//                 <li
//                   onClick={() =>
//                     setFeedbackDescription((prev) => `${prev}\n• `)
//                   }
//                 >
//                   Bullet 1
//                 </li>
//                 <li
//                   onClick={() =>
//                     setFeedbackDescription((prev) => `${prev}\n• `)
//                   }
//                 >
//                   Bullet 2
//                 </li>
//               </ul>
//             </div>
//           )}

//           <div className="feedback-file-attachment-container">
//             {attachments &&
//               attachments.map((attachment) => (
//                 <div key={attachment} className="feedback-file-preview">
//                   <FileIcon url={attachment} />
//                   <div className="attachment-filename">
//                     {path.basename(attachment)}
//                   </div>
//                   <Tooltip title="Delete" placement="top">
//                     <CloseIcon
//                       className="attachment-delete-icon"
//                       onClick={() => deleteAttachment(attachment)}
//                     />
//                   </Tooltip>
//                 </div>
//               ))}
//           </div>

//           <div className="feedback-buttons">
//             <input
//               type="file"
//               id="file-input"
//               onChange={setData}
//               style={{ display: "none" }}
//             />
//             <label htmlFor="file-input">
//               <Tooltip title="Attach File" placement="top">
//                 <AttachmentIcon className="toolbar-icon" />
//               </Tooltip>
//             </label>

//             <button type="submit" onClick={addFeedback}>
//               Save
//             </button>
//             <button onClick={onCloseClick}>Close</button>
//           </div>
//         </form>
//       </div>
//     </div>
//   );
// }

