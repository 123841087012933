import Feature from "ol/Feature";
import { Point } from "ol/geom";
import { Style } from "ol/style";
import Icon from "ol/style/Icon";
import feedbackMarkerIcon from "../../assets/feedbackMarker.svg";
import { toLonLat } from "ol/proj";
import { FEEDBACK_STATUS } from "../../utils/constants";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import { unByKey } from "ol/Observable";

const  AddingFeedbackOnMap = (mapClickable, element, map, setMapKey, setFeedbackMarker, tempFeedbackMarkerRef, mapKey) => {
    if (mapClickable) {
        element.classList.add("cursor-change");
        let key = map.on("click", function (e) {
            map.getLayers().forEach((layer) => {
                if (
                    layer &&
                    layer.get("title") &&
                    layer.get("title") === "TempFeedbackMarker"
                ) {
                    map.removeLayer(layer);
                }
            });
            var feature = map.forEachFeatureAtPixel(e.pixel, function (feature) {
                return feature;
            });
            if (!feature || (feature.get("geometry") && !feature.get("title"))) {
                const iconFeature = new Feature({
                    geometry: new Point(e.coordinate),
                    id: 10,
                });
                const iconStyle = new Style({
                    image: new Icon({
                        anchor: [0.5, 20],
                        anchorXUnits: "fraction",
                        anchorYUnits: "pixels",
                        src: feedbackMarkerIcon,
                    }),
                });
                iconFeature.setStyle(iconStyle);
                const coordinate = e.coordinate;
                setFeedbackMarker({
                    title: "",
                    description: "",
                    long: toLonLat(coordinate)[0],
                    lat: toLonLat(coordinate)[1],
                    status: FEEDBACK_STATUS.OPEN,
                    attachments:[], //HA
                    // metaData: { color:"" ,fontSize:"" }
                });
                const vecSource = new VectorSource({
                    features: [iconFeature],
                });
                const vectorLayer = new VectorLayer({
                    source: vecSource,
                });
                tempFeedbackMarkerRef.current.getLayers().push(vectorLayer);
                map.addLayer(tempFeedbackMarkerRef.current);
            }
        });
        setMapKey(key);
    } else {
        if (map)
            map.getLayers().forEach((layer) => {
                if (
                    layer &&
                    layer.get("title") &&
                    layer.get("title") === "TempFeedbackMarker"
                ) {
                    map.removeLayer(layer);
                }
            });
        element.classList.remove("cursor-change");
        unByKey(mapKey);
    }
}

export default AddingFeedbackOnMap;