import axios from 'axios';
import Tooltip from '@mui/material/Tooltip';
import '../ProjectIdPage/ProjectIdPage.css';
import { useParams } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import AuthToken from '../../context/AuthToken';
import { useSearchParams } from 'react-router-dom';
import { MOLE_ORDER_URL } from '../../helper/ApiUrl';
import { useLoader } from '../../context/LoaderProvider';
import React, { useState, useEffect, useRef } from 'react';
import { TOAST_TYPE, ORDER_STATUS } from '../../utils/constants';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Drawer, Box, LinearProgress, Typography } from '@mui/material';
import UploadBulkProjectIdDashboard from './UploadBulkProjectIdDashboard';

const UploadBulkProjectId = ({ handleSuccess, handleError }) => {
    const innerRef = useRef();
    const outerRef = useRef();
    const { id } = useParams();
    const [status, setStatus] = useState();
    const [open, setOpen] = useState(false);
    const [action, setAction] = useState('');
    const [progress, setProgress] = useState();
    const [fileData, setFileData] = useState(null);
    const [orderList, setOrderList] = useState([]);
    const [isUpload, setIsUpload] = useState(false);
    const { startLoader, stopLoader } = useLoader();
    const [processedData, setProcessedData] = useState([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const [isDataChanged, setIsDataChanged] = useState(false);
    const [processOrdersIds, setProcessOrdersIds] = useState([]);
    const [selfReviewOrder, setSelfRevieworder] = useState(false);
    const [selectAllChecked, setSelectAllChecked] = useState(false);
    const [selfReviewButton, setSelfReviewButton] = useState(false);
    const [allOrdersProcessed, setAllOrdersProcessed] = useState(false);

    const dashboard = searchParams.get('dashboard') || true;
    const [dashboardStatus, setDashboardStatus] = useState(JSON.parse(dashboard));

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setFileData(file);
    };

    const getProjectList = () => {
        axios({
            url: `${MOLE_ORDER_URL}/${id}`,
            method: 'GET',
            headers: {
                Authorization: `Token ${AuthToken()}`,
            },
        })
            .then((res) => {
                let dataOrder = res?.data?.data;
                setStatus(dataOrder.status);
                let data = dataOrder?.orders;
                // startLoader();
                if (data)
                    setOrderList(data.map((item) => ({ ...item, checked: false })));
                // stopLoader();
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const uploadProjectList = () => {
        const formData = new FormData();
        formData.append('bo_file', fileData);
        axios
            .post(`${MOLE_ORDER_URL}/${id}/upload`, formData, {
                headers: {
                    Authorization: `Token ${AuthToken()}`,
                    Accept: 'application/json',
                },
            })
            .then((res) => {
                if (res.status === 200 || 201) {
                    setFileData('');
                    setIsUpload(!isUpload);
                    handleSuccess(TOAST_TYPE.SUCCESS, `File Uploaded successfully!`);
                } else {
                    handleError(TOAST_TYPE.ERROR, 'Something went wrong!');
                }
            })
            .catch((error) => {
                handleError(error);
            });
    };

    const handleAllCheckboxChange = (e) => {
        const { checked } = e.target;
        if (checked)
            setProcessOrdersIds(
                orderList
                    .filter((item) => item.status === ORDER_STATUS.INITIATE)
                    .map((item) => item.id)
            );
        else setProcessOrdersIds([]);
        setOrderList(
            orderList.map((item) => {
                if (item.status === ORDER_STATUS.INITIATE)
                    return { ...item, checked: !selectAllChecked };
                return item;
            })
        );
        setSelectAllChecked(!selectAllChecked);
    };

    const handleSingleCheckboxChange = (e, id) => {
        const { checked } = e.target;
        if (checked && !processOrdersIds.includes(id))
            setProcessOrdersIds([...processOrdersIds, id]);
        if (!checked && processOrdersIds.includes(id))
            setProcessOrdersIds(processOrdersIds.filter((item) => item !== id));
        setOrderList(
            orderList.map((item) => {
                if (item.id === id) return { ...item, checked: !item.checked };
                return item;
            })
        );
    };

    const LinearProgressWithLabel = (props) => {
        return (
            <Box sx={{ display: 'flex', alignItems: 'center', width: '70%' }}>
                <Box sx={{ width: '100%', mr: 1 }}>
                    <LinearProgress color="inherit" variant="determinate" {...props} />
                </Box>
                <Box sx={{ minWidth: 35 }}>
                    <Typography variant="body2">{`${Math.round(
                        props.value
                    )}%`}</Typography>
                </Box>
            </Box>
        );
    };

    const processOrders = async (orderId) => {
        try {
            const response = await axios({
                url: `${MOLE_ORDER_URL}/${id}/process/${orderId}`,
                method: 'PATCH',
                headers: {
                    Authorization: `Token ${AuthToken()}`,
                    Accept: 'application/json',
                },
            });
            if (response.data) return response.data;
        } catch (error) {
            handleError(error);
            throw error;
        }
    };

    const handleProcess = async () => {
        setOpen(true);
        setProgress(0);
        setIsDataChanged(true);
        for (const orderId of processOrdersIds) {
            const data = await processOrders(orderId);
            if (data) {
                const updatedValues = {
                    id: orderId,
                    latitude: data.lat,
                    longitude: data.long,
                    parcel_area: data.parcel_area,
                    status: ORDER_STATUS.PROCESSED,
                    checked: false,
                };
                setProcessedData((prev) => [...prev, updatedValues]);
                setProgress((prevProgress) => prevProgress + 1);
            }
        }
    };

    const handleClose = (event, reason) => {
        if (reason === 'backdropClick') return;
        handleCancelProcess();
    };

    const handleCancelProcess = () => {
        setOpen(false);
        setIsDataChanged(false);
        setProcessOrdersIds([]);
    };

    const handleMoveToDraft = async () => {
        setStatus(ORDER_STATUS.DRAFT);
        try {
            const response = await axios({
                url: `${MOLE_ORDER_URL}/${id}/place`,
                method: 'POST',
                headers: {
                    Authorization: `Token ${AuthToken()}`,
                    Accept: 'application/json',
                },
            });
        } catch (error) {
            handleError(error);
            throw error;
        }
    };

    useEffect(() => {
        getProjectList();
    }, [isUpload]);

    useEffect(() => {
        if (fileData) uploadProjectList();
    }, [fileData]);

    useEffect(() => {
        if (progress === processOrdersIds.length) {
            handleCancelProcess();
            setSelectAllChecked(false);
            handleSuccess(TOAST_TYPE.SUCCESS, `Orders processed successfully!`);
        }
    }, [progress]);

    useEffect(() => {
        const handleBeforeUnload = (e) => {
            if (isDataChanged)
                e.returnValue =
                    'Orders processing is in progress. Do you really want to refresh the page?';
        };
        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [isDataChanged]);

    useEffect(() => {
        if (orderList.length) {
            const updatedSelectAllChecked = orderList
                .filter((item) => item.status === ORDER_STATUS.INITIATE)
                .every((item) => item.checked);
            setSelectAllChecked(updatedSelectAllChecked);
            setAllOrdersProcessed(
                orderList.every((item) => item.status === ORDER_STATUS.PROCESSED)
            );
            setSelfReviewButton(
                orderList.some(function (obj) {
                    return obj.status === ORDER_STATUS.PROCESSED;
                })
            );
        }
    }, [orderList]);

    useEffect(() => {
        if (processedData.length)
            setOrderList(
                orderList.map((item) => {
                    const matchingObj = processedData.find(
                        (firstObj) => firstObj.id === item.id
                    );
                    if (matchingObj) return { ...item, ...matchingObj };
                    else return item;
                })
            );
    }, [processedData]);

    useEffect(() => {
        if (allOrdersProcessed) getProjectList();
    }, [allOrdersProcessed]);

    // useEffect(() => {
    //   if (orderList.length < 1) {
    //     startLoader();
    //   } else {
    //     stopLoader();
    //   }
    // }, [orderList]);

    const handleDownload = () => {
        // Define the path to your CSV file
        const filePath = '/ottermap-project.csv';

        // Create a link element
        const link = document.createElement('a');

        // Set the link's href to the file path
        link.href = process.env.PUBLIC_URL + filePath;

        // Set the download attribute to specify the file name
        link.download = 'ottermap-project.csv';

        // Append the link to the body
        document.body.appendChild(link);

        // Trigger a click on the link
        link.click();

        // Remove the link from the body
        document.body.removeChild(link);
    };

    const handleClickOutSide = (e) => {
        if (innerRef.current) {
            if (!innerRef.current.contains(e.target)) {
                outerRef.current.style.opacity = 0;
                outerRef.current.style.pointerEvents = 'none';
            }
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutSide, true);
        startLoader();
        setTimeout(() => {
            stopLoader();
        }, 1000);
    }, []);

    useEffect(() => {
        searchParams.set('dashboard', dashboardStatus);
        setSearchParams(searchParams);
    }, [dashboard, dashboardStatus]);

    const handleAction = () => {
        // if (action === 'Review & Submit') {
        //   handleMoveToDraft();
        // } else
        if (action === 'Self Review & Submit') {
            handleMoveToDraft();
            setDashboardStatus(true);
        } else if (action === 'Submit for Ottermap Review') {
            handleMoveToDraft();
            setDashboardStatus(true);
        }
    };

    useEffect(() => {
        if (status && status === ORDER_STATUS.DRAFT && !dashboardStatus)
            setSelfRevieworder(true);
    }, [status, dashboardStatus]);

    if (status && status === ORDER_STATUS.DRAFT && dashboardStatus)
        return (
            <UploadBulkProjectIdDashboard
                bulkId={id}
                handleError={handleError}
                handleSuccess={handleSuccess}
                getProjectList={getProjectList}
                setDashboardStatus={setDashboardStatus}
            />
        );
    else
        return (
            <div className="my-projects-container">
                <div
                    style={{ flexDirection: 'row-reverse' }}
                    className="orders-action-container"
                >
                    {status === ORDER_STATUS.DRAFT ? (
                        <div
                            onClick={() => setDashboardStatus(true)}
                            style={{ width: '12em' }}
                            className="excel-dashboard-switch-cont"
                        >
                            <span>Processed Orders</span>
                            <ArrowForwardIosIcon
                                style={{ fontSize: '1em', marginLeft: '0.5em' }}
                            />
                        </div>
                    ) : null}
                    <Drawer
                        anchor="top"
                        open={open}
                        onClose={handleClose}
                        className="popup-drawer"
                    >
                        <div>
                            {parseInt(progress)} of {processOrdersIds.length} processed
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <LinearProgressWithLabel
                                value={
                                    progress *
                                    (100 /
                                        (processOrdersIds.length ? processOrdersIds.length : 1))
                                }
                            />
                        </div>
                    </Drawer>
                </div>
                <div className="orders-table-container">
                    {orderList?.length > 0 ? (
                        <table>
                            <thead>
                                <tr>
                                    {!allOrdersProcessed ? (
                                        <th>
                                            <input
                                                type="checkbox"
                                                checked={selectAllChecked}
                                                onChange={(e) => handleAllCheckboxChange(e)}
                                            />
                                            <span style={{ marginLeft: '2%' }}>Select All</span>
                                        </th>
                                    ) : null}
                                    <th>Site Id</th>
                                    <th>Address</th>
                                    <th>Zip Code</th>
                                    <th>Lat/Lng</th>
                                    <th>Area</th>
                                </tr>
                            </thead>
                            <tbody>
                                {orderList.map((item, index) => {
                                    {
                                        /* if (selfReviewOrder && item.status === ORDER_STATUS.INITIATE || !selfReviewOrder) */
                                    }
                                    return (
                                        <tr key={item.id}>
                                            {!allOrdersProcessed ? (
                                                <td>
                                                    {item.status === ORDER_STATUS.INITIATE ? (
                                                        <input
                                                            type="checkbox"
                                                            checked={item.checked}
                                                            onChange={(e) =>
                                                                handleSingleCheckboxChange(e, item.id)
                                                            }
                                                        />
                                                    ) : null}
                                                </td>
                                            ) : null}
                                            <td>{item.site_id}</td>
                                            <td>{item.full_address}</td>
                                            <td>{item.zipcode}</td>
                                            <td>
                                                {item.latitude && item.longitude
                                                    ? item.latitude.toFixed(2) +
                                                    '/ ' +
                                                    item.longitude.toFixed(2)
                                                    : '--'}
                                            </td>
                                            <td>{item.parcel_area ? item.parcel_area : '--'}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    ) : (
                        <div className="no-bulk-project-container" style={{ width: '38%'  }}>
                            {status === ORDER_STATUS.DRAFT ? (
                                <h4>All orders has been submitted for measurements.</h4>
                            ) : (
                                <h2>No order uploaded in this project.</h2>
                            )}
                            {status !== ORDER_STATUS.DRAFT ? (
                                <p style={{ textAlign: 'center' }}>
                                    {/* Click the button below to download a sample CSV for reference.
                                    Once you've reviewed the format, proceed to upload your CSV
                                    file with the orders. */}
                                    Download sample CSV, review format, then upload your orders CSV.
                                </p>
                            ) : null}
                            <div className="upload-order-with-excel" >
                                {/* {status !== ORDER_STATUS.DRAFT ? (
                                    <label htmlFor="sample" onClick={handleDownload} id="downloadSample">
                                        Download Sample
                                    </label>
                                ) : null} */}
                            </div>
                            <div className='downloaadSampleDiv'>
                            {status !== ORDER_STATUS.DRAFT ? (
                                    <label htmlFor="sample" onClick={handleDownload} id="downloadSample">
                                        Download Sample
                                    </label>
                                ) : null}
                            </div>
                        </div>
                    )}
                </div>
           {
            
            
            status === ORDER_STATUS.EVALUATING ||
                    status === ORDER_STATUS.PRE_DRAFT ||
                    selfReviewOrder === true ? (
                    <div className=" orders-action-container">
                        <Tooltip title="Someone from Ottermap will review the parcels">
                            <button
                                onClick={() => {
                                    outerRef.current.style.opacity = 1;
                                    setAction('Submit for Ottermap Review');
                                    outerRef.current.style.pointerEvents = 'auto';
                                }}
                                disabled={!selfReviewButton}
                                className={!selfReviewButton ? 'disable-button' : ''}
                            >
                                Submit for Ottermap Review
                            </button>
                        </Tooltip>
                        <Tooltip title="Validate the parcel before measurement.">
                            <button
                                onClick={() => {
                                    setAction('Self Review & Submit');
                                    outerRef.current.style.opacity = 1;
                                    outerRef.current.style.pointerEvents = 'auto';
                                }}
                                disabled={!selfReviewButton}
                                className={!selfReviewButton ? 'disable-button' : ''}
                            >
                                Self Review & Submit
                            </button>
                        </Tooltip>
                        {!allOrdersProcessed && (
                            <button
                                className={processOrdersIds.length == 0 ? 'disable-button' : ''}
                                onClick={handleProcess}
                                disabled={!processOrdersIds.length}
                            >
                                Calculate Area
                            </button>
                        )}
                        {/* <button
                  onClick={() => {
                    setAction('Review & Submit');
                    outerRef.current.style.opacity = 1;
                    outerRef.current.style.pointerEvents = 'auto';
                  }}
                >
                  Review & Submit
                </button>} */}
                    </div>
                ) : null}
                <div ref={outerRef} className="review-and-submit-warning-outer">
                    <div
                        ref={innerRef}
                        className={
                            action === 'Submit for Ottermap Review'
                                ? 'review-and-submit-warning-inner-b'
                                : 'review-and-submit-warning-inner'
                        }
                    >
                        <div className="raise-feedback-inner-top">
                            <span
                                onClick={() => {
                                    outerRef.current.style.opacity = 0;
                                    outerRef.current.style.pointerEvents = 'none';
                                }}
                            >
                                X
                            </span>
                        </div>
                        <div className="review-and-submit-warning-inner-mid">
                            {action === 'Self Review & Submit' ? (
                                <p>Confirm self-review and place orders?</p>
                            ) : action === 'Submit for Ottermap Review' ? (
                                <div>
                                    <p>Confirm Ottermap Team review and orders?</p>
                                    <span>
                                        Note: Orders with unclear location or Parcel will be emailed
                                        for your confirmation.
                                    </span>
                                </div>
                            ) : (
                                <p>Are you sure, you want to proceed?</p>
                            )}
                        </div>
                        <div className="view-modal-bttns">
                            <button
                                onClick={() => {
                                    outerRef.current.style.opacity = 0;
                                    outerRef.current.style.pointerEvents = 'none';
                                }}
                            >
                                Cancel
                            </button>
                            <button
                                onClick={() => {
                                    handleAction();
                                    outerRef.current.style.opacity = 0;
                                    outerRef.current.style.pointerEvents = 'none';
                                }}
                            >
                                Proceed
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
};

export default UploadBulkProjectId;
