import React, { useState, useEffect } from "react";
import axios from "axios";
import './MyDocuments.css';
import './FolderFile.css';
import { FILE_UPLOAD,MOLE_ORDER_FILE_FOLDER } from '../../helper/ApiUrl';
import AuthToken from '../../context/AuthToken';
import Imageview from "./Imageview";
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';
import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    styled,
    Menu
} from '@mui/material';
import DocumentContainer from "../../components/DocumentContainer/DocumentContainer";
import { useNavigate } from "react-router-dom";
import ToggleButtons from "./ToggleButton";

import OttermapLoaderGif from '../../assets/ottermap-loading-gif.gif';


import useMediaQuery from '@mui/material/useMediaQuery';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import DownloadIcon from '@mui/icons-material/Download'; // Replace with your actual icon
import PreviewIcon from '@mui/icons-material/Visibility'; // Replace with your actual icon
import DeleteIcon from '@mui/icons-material/Delete'; // Replace with your actual icon
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ImageView from "./Imageview";
import {TextField } from '@mui/material';




const StyledFormControl = styled(FormControl)(({ theme }) => ({
    minWidth: 200,
    maxHeight: '300px',
    margin: theme.spacing(2),
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
    display: 'flex',
    minHeight: '48px', // Adjust the height as needed
    alignItems: 'center',
}));

const MyDocumentsComponent = () => {
    const isSmallerThan820px = useMediaQuery('(max-width:820px)');
    const [documents, setDocuments] = useState([]);
    const [layout, setLayout] = useState('list');
    const [selectedImage, setSelectedImage] = useState(null);
    const [query, setQuery] = useState('');
    const [selectedFileType, setSelectedFileType] = useState('');
    const token = AuthToken();
    const [selectedImageIndex, setSelectedImageIndex] = useState(null); // Store the current index
  const [all_URLS, setAll_URLS] = useState([]); 

    const fileTypes = [
        'PDF', 'DOC', 'DOCX', 'XLS', 'XLSX', 'PPT', 'PPTX', 'TXT', 'CSV',
        'JPEG', 'JPG', 'PNG', 'GIF',
    ];

    const filteredDocuments = documents?.filter(doc => {
        const matchesQuery = doc.file_name.toLowerCase().includes(query.toLowerCase());
        const matchesFileType = selectedFileType ? doc.file_name.toLowerCase().endsWith(selectedFileType.toLowerCase()) : true;
        return matchesQuery && matchesFileType;
    });

    useEffect(() => {
        getMyDocuments();
    }, []);

    const getMyDocuments = () => {
        axios
            .get(`${FILE_UPLOAD}`, {
                headers: {
                    Authorization: `Token ${token}`,
                    Accept: 'application/json',
                },
            })
            .then((response) => {
                setDocuments(response.data.data);
                const metaData = response.data.data // Accessing meta_data from response
             if (Array.isArray(metaData)) {
      const imageUrls = metaData.map(item => item.file_url); // Extracting file_url from each item
      console.log(imageUrls); // Array of file URLs
      setAll_URLS(imageUrls); // Storing the array of URLs
           } 
            })
            .catch((error) => {
                console.error('Error fetching documents', error);
            });
    };
    const handleCloseImage = () => {
        setSelectedImage(null);
        // setCurrentView('folder');
      };

    const handleDelete = async (filename) => {
        console.log("delete clicked") 
        const filenameArr = []
        filenameArr.push(filename)
     
         try {
           if(filename){
             console.log(filename,"selectedImageName")
           await axios.delete(`${FILE_UPLOAD}`, {
             headers: {
               Authorization: `Token ${token}`,
             },
             data: { 
              
               file_keys:filenameArr 
              },
           });
           
           getMyDocuments()
         }
         } catch (error) {
           console.error("Error deleting files:", error);
         }
       };

    const handleDownloadImage = (selectedImg) => {
        const link = document.createElement('a');
        link.href = selectedImg;
        link.download = selectedImg.split('/').pop();
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleFileTypeChange = (event) => {
        setSelectedFileType(event.target.value);
    };

    const handleClearFilter = () => {
        setSelectedFileType('');
    };

    const [activeTab, setActiveTab] = useState('bulkImages');

    const navigate = useNavigate()

    // for popup on vetcal icon 
const [anchorEl, setAnchorEl] = useState(null);
const [selectedDoc, setSelectedDoc] = useState(null);

const handleMenuOpen = (event, doc) => {
  setAnchorEl(event.currentTarget);
  setSelectedDoc(doc);
};

const handleMenuClose = () => {
  setAnchorEl(null);
  setSelectedDoc(null);
};

const handleDownload = () => {
  if (selectedDoc) {
    handleDownloadImage(selectedDoc.file_url);
    handleMenuClose();
  }
};

const handleDeleteDoc = () => {
  if (selectedDoc) {
    handleDelete(selectedDoc.file_name);
    handleMenuClose();
  }
};
// ////
// for preview image 
// Function to handle image preview
const previewImagehandler = (fileUrl) => {
    const index = all_URLS.indexOf(fileUrl); // Find the index of the image URL
    setSelectedImageIndex(index); // Set the current index
    setSelectedImage(fileUrl); // Set the selected image to display in the viewer
    // setCurrentView('image');
  };
  
  // Function to handle next image
  const handleNextImage = () => {
    if (selectedImageIndex < all_URLS.length - 1) {
      setSelectedImageIndex(selectedImageIndex + 1); // Move to next image
      setSelectedImage(all_URLS[selectedImageIndex + 1]); // Update the selected image
    }
  };
  
  // Function to handle previous image
  const handlePrevImage = () => {
    if (selectedImageIndex > 0) {
      setSelectedImageIndex(selectedImageIndex - 1); // Move to previous image
      setSelectedImage(all_URLS[selectedImageIndex - 1]); // Update the selected image
    }
  };

    return (
        <div className="mydocscomponent_container">
                   {/* 10Aug for routing */}
                   
         <ToggleButtons />
 
          {/* 10Aug for routing */}

            {/* Header section with search and filter options */}

            {!isSmallerThan820px && <div className='toggle_btn_div' style={{ display: "grid", justifyContent: "space-between", alignItems: "center", gridTemplateColumns:"70% auto", height:"8%"}}>
                {/* <div className="documents-filter-container"> */}

                <div className="" style={{border:"" ,display:"flex",alignItems:"center",justifyContent:"space-between", height:"fit-content"}} >

                    {/* <div 
                    className="" style={{border:"1px solid #519259",overflow:"hidden",borderRadius:"5px", display:"grid",
                     gridTemplateColumns:"90% auto",
                      
                      width:"60%"}}> */}
                        <TextField
                            label="Search by Name"
                            variant="outlined"
                            fullWidth
                            // type="text"
                            // placeholder="Search by Name"
                            onChange={(e) => setQuery(e.target.value)}
                            style={{ width: "60%",padding:"0px 0px !important",}}
                        />
                        {/* <div
                      style={{background:"#519259",height:"100%",display:"flex",justifyContent:"center",alignItems:"center"}}
                     >
                      <box-icon name='search' color='#ffffff' ></box-icon>
                     
                     </div> */}
                    {/* </div> */}

                    {/* {selectedFileType && (
                        <button onClick={handleClearFilter} className="documents-filter-clear" style={{width:"fit-content"}} >
                            <FilterListOffIcon className="clear-filter-icon" />
                            Clear
                        </button>
                    )}

                    <div className="documents-filter">
                        <StyledFormControl>
                            <InputLabel id="file-type-select-label" style={{top:"-5px",fontSize:"0.8rem"}}>File Type</InputLabel>
                            <Select
                                labelId="file-type-select-label"
                                id="file-type-select"
                                value={selectedFileType}
                                onChange={handleFileTypeChange}
                                style={{marginBottom:"0"}}
                            >
                                {fileTypes.map((fileType) => (
                                    <StyledMenuItem sx={{fontSize: "clamp(14px, 4vw, 0.90vw) !important"}} key={fileType} value={fileType}>
                                        {fileType}
                                    </StyledMenuItem>
                                ))}
                            </Select>
                        </StyledFormControl>
                    </div> */}
                </div>

                <div className="toggle_layout_btn_container" style={{ display: "flex", overflow: "hidden" }}>
                    <button
                        style={{ backgroundColor: layout === 'list' ? '#519259' : 'grey', color: layout === 'list' ? 'white' : 'black' }}
                        onClick={() => setLayout('list')}
                    >
                        <box-icon name='list-ul' color='#ffffff'></box-icon>
                    </button>
                    <button
                        style={{ backgroundColor: layout === 'grid' ? '#519259' : 'grey', color: layout === 'grid' ? 'white' : 'black' }}
                        onClick={() => setLayout('grid')}
                    >
                        <box-icon name='grid-horizontal' color='#ffffff'></box-icon>
                    </button>
                </div>
            </div>}

            {isSmallerThan820px && <div className='toggle_btn_div' style={{ display: "flex", flexDirection: "column", justifyContent: "", alignItems: "center", width: "100%"}}>
                {/* <div className="documents-filter-container"> */}

                <div style={{ display: "grid", gap: "10px", justifyContent: "space-evenly", alignItems: "center", gridTemplateColumns:"75% auto", width: "100%"}}>
                <div className="" style={{border:"1px solid #519259",overflow:"hidden",borderRadius:"5px", width :"100%", display:"grid",gridTemplateColumns:"85% auto"}}>
                        <input
                            type="text"
                            placeholder="Search by Name"
                            onChange={(e) => setQuery(e.target.value)}
                            style={{ width: "100%", border:'none', padding: "0.5rem" }}
                        />
                        <div
                      style={{background:"#519259",height:"100%",display:"flex",justifyContent:"center",alignItems:"center"}}
                     ><box-icon name='search' color='#ffffff' ></box-icon></div>
                </div>
                <div className="toggle_layout_btn_container" style={{ display: "flex", overflow: "hidden", width: "100%", gap: "0px", height: "100%", borderRadius: "5px" }}>
                    <button
                        style={{ backgroundColor: layout === 'list' ? '#519259' : 'grey', color: layout === 'list' ? 'white' : 'black', margin: "0px" }}
                        onClick={() => setLayout('list')}
                    >
                        <box-icon name='list-ul' color='#ffffff'></box-icon>
                    </button>
                    <button
                        style={{ backgroundColor: layout === 'grid' ? '#519259' : 'grey', color: layout === 'grid' ? 'white' : 'black', margin: "0px" }}
                        onClick={() => setLayout('grid')}
                    >
                        <box-icon name='grid-horizontal' color='#ffffff'></box-icon>
                    </button>
                </div>
                </div>
                {/* <div className="" style={{width:"100%" ,display:"flex",alignItems:"center",justifyContent:"flex-end", height:"fit-content", padding: "15px 10px 0px 5px"}} >

                    {selectedFileType && (
                        <button onClick={handleClearFilter} className="documents-filter-clear" style={{width:"fit-content", marginRight: "10px"}} >
                            <FilterListOffIcon className="clear-filter-icon" />
                            Clear
                        </button>
                    )}

                    <div className="documents-filter" >
                        <StyledFormControl sx={{margin: "0px !important"}}>
                            <InputLabel id="file-type-select-label" style={{top:"-5px",fontSize:"0.8rem"}}>File Type</InputLabel>
                            <Select
                                labelId="file-type-select-label"
                                id="file-type-select"
                                value={selectedFileType}
                                onChange={handleFileTypeChange}
                                style={{marginBottom:"0"}}
                            >
                                {fileTypes.map((fileType) => (
                                    <StyledMenuItem key={fileType} value={fileType}>
                                        {fileType}
                                    </StyledMenuItem>
                                ))}
                            </Select>
                        </StyledFormControl>
                    </div>
                </div> */}

            </div>}

            {/* Document display section */}
{/* otter-245 */}
<div className={`documents-${layout}-layout `} style={{height:layout === 'grid' ? "90%":"90%" ,overflow:layout === 'grid' ? "scroll":"none",padding:layout === 'grid' ? "1rem":"1rem" ,boxSizing:"border-box" }}>

  {documents.length === 0 && filteredDocuments.length === 0 ? (
    <div className="alternate-table-container Loaders-Div">
      {filteredDocuments.length === 0 ? (
        <img src={OttermapLoaderGif} alt="Ottermap Loader Gif" />
      ) : (
        <h1>Loading...</h1>
      )}
    </div>
  ) : documents.length > 0 && filteredDocuments.length === 0 ? (
    <div className="alternate-table-container Loaders-Div">
      <h1>No file found</h1>
    </div>
  ) : (
    layout === 'grid' ? (
      
      filteredDocuments.map((doc) => (
        <div key={doc.id} className="card">
          <div className="grid_img_box" onClick={() => previewImagehandler(doc.file_url)}>
            <img src={doc.file_url} alt={doc.file_name} />
          </div>
          <div className="file__name_title" style={{boxSizing:"border"}}>
            <h5 style={{ marginTop:"0px !important" ,padding:"0.2rem",fontSize:"0.8rem"}}>{doc.file_name}</h5>
          </div>
        </div>
      ))
    ) : (
        <Paper style={{ width: '100%', height: '100%', overflow: 'scroll' ,}}>
        <Table stickyHeader aria-label="documents table">
          <TableHead>
            <TableRow>
              <TableCell><strong>Name</strong></TableCell>
              <TableCell><strong>Action</strong></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredDocuments.map((doc) => (
              <TableRow key={doc.id} hover style={{ cursor: 'pointer' }}>
                <TableCell 
                // onClick={() => setSelectedImage(doc.file_url)}
                // previewImagehandler
                onClick={() => previewImagehandler(doc.file_url)}

                    
                    >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {doc.file_name}
                  </div>
                </TableCell>
                <TableCell>
                  <IconButton onClick={(e) => handleMenuOpen(e, doc)}>
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                    PaperProps={{
                      style: {
                        boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.1)', // Light shadow
                        borderRadius: '8px', // Optional: rounded corners
                      }
                    }}
                  >
                    <MenuItem onClick={handleDownload}>
                      <DownloadIcon style={{ marginRight: 8 }} /> Download
                    </MenuItem>
                    {/* <MenuItem onClick={handleDeleteDoc}>
                      <DeleteIcon style={{ marginRight: 8 }} /> Delete
                    </MenuItem> */}
                  </Menu>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
      

      
      

    )
  )}
 
</div>


        {isSmallerThan820px && <div className="documents__container"  style={{padding: "5px", display: "flex", flexDirection: "column", gap: "10px", paddingBottom: "150px"}}>
        {filteredDocuments.length === 0 ? (
        <div className="alternate-table-container">
            {/* <h1 >Loading...</h1> */}
          </div>
        ) : filteredDocuments.length < 1 ? (
          <div className="alternate-table-container">
            {/* <h1>No documents found :</h1> */}
          </div>
        )
        : filteredDocuments.map((doc) => (
                    <div
                        key={doc.id}
                        // onClick={() => setSelectedImage(doc.file_url)}
                    >
                        {layout === 'grid' ? (
                            <div style={{display: "flex", flexDirection :"column", gap: "0px", backgroundColor: "#519259"}}>
                                <div className="grid_img_box" onClick={() => setSelectedImage(doc.file_url)}>
                                    <img src={doc.file_url} alt={doc.file_name}   />
                                </div>
                                <div className="file__name_title">
                                    <h4>{doc.file_name}</h4>
                                </div>
                            </div>
                        ) : (
                            <div style={{padding: "10px 0px", height: "40px", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                                <div className="namediv"  onClick={() => setSelectedImage(doc.file_url)}>{doc.file_name}</div>
                                <div className="downloaddiv checkboxdiv" title="Download" onClick={() => handleDownloadImage(doc.file_url)}>
                                    <box-icon type='solid' name='download'></box-icon>
                                </div>
                                <div className="previewdiv checkboxdiv" title="Preview" onClick={() => setSelectedImage(doc.file_url)}>
                                    <box-icon name='show-alt'></box-icon>
                                </div>
                                {/* <div className="previewdiv checkboxdiv" title="Delete" onClick={() =>{
                                  handleDelete(doc.file_name)
                                } }>
                              <box-icon type='solid' name='trash-alt'></box-icon>
                              </div> */}
                            </div>
                        )}
                    </div>
                ))}
          </div>}

            {/* Image view modal */}
            {/* {selectedImage && (
              
                <Imageview
                    imageSrc={selectedImage}
                    onClose={() => setSelectedImage(null)}
                    onDownload={() => handleDownloadImage(selectedImage)}
                />
                
            )} */}
            {selectedImage && (
              
              <ImageView
    images={all_URLS} 
    currentIndex={selectedImageIndex} 
    onClose={handleCloseImage} 
    onDownload={() => handleDownloadImage(selectedImage)} 
    onNext={handleNextImage} 
    onPrev={handlePrevImage} 
  />
              
          )}
        </div>
    );
};

export default MyDocumentsComponent;
