import './Pagination.css';
import React, { useState } from 'react';
import ReactPaginate from 'react-paginate';
import { MenuItem, Select } from '@mui/material';

function Pagination({
  invalidInput,
  jumpToInput,
  setJumpToInput,
  handleKeyDown,
  handlePageChange,
  pageCount,
  rowCount,
  currentPage,
  inputValue,
  setInputValue,
  UploadeBulkProjectorderPage
}) {
  const [rowOptions, setRowOptions] = useState(['10', '20', '30', '40', '50']);

  return (
    <div className="pagination">
      <div className="pagination-left">
        <label htmlFor="rowsPerPage">Jump to page</label>
        <div className={`custom-number-input ${invalidInput ? 'invalid' : ''}`}>
          <input
            type="number"
            id="rowsPerPage"
            min="1"
            max="20"
            value={jumpToInput}
            onChange={(e) => setJumpToInput(e.target.value)}
            onKeyDown={handleKeyDown}
          />
        </div>
      </div>
      <div className="pagination-container">
        <ReactPaginate
          nextLabel=">"
          pageRangeDisplayed={2}
          marginPagesDisplayed={1}
          pageCount={pageCount}
          previousLabel="<"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          onPageChange={handlePageChange}
          previousClassName="page-item page-item-prev"
          previousLinkClassName="page-link"
          nextClassName="page-item page-item-next"
          nextLinkClassName="page-link"
          breakLabel="..."
          breakClassName="page-item page-item-break"
          breakLinkClassName="page-link-break"
          containerClassName="pagination-container"
          activeClassName="active-page-item"
          renderOnZeroPageCount={null}
          disabledClassName="disabled-page-item"
          forcePage={currentPage - 1}
        />
        {!UploadeBulkProjectorderPage?(<div className="total-property-count">
          {rowCount} {Number(rowCount) > 1 ? 'Properties' : 'Property'}
        </div>):
       ( <div className="total-property-count">
          {rowCount} {Number(rowCount) > 1 ? 'Projects' : 'Project'}
        </div>)
        }
      </div>

      <div className="pagination-right">
        <label htmlFor="rowsPerPage">Maximum Rows per page</label>
        <div className="custom-select">
          <Select
            value={inputValue}
            onChange={(e) => {
              setInputValue(e.target.value);
              const currentUrl = window.location.pathname;
              if (currentUrl.includes('dashboard'))
                localStorage.setItem('rowsPerPage', e.target.value);
            }}
          >
            {rowOptions.map((item) => {
              return <MenuItem sx={{fontSize: "clamp(14px, 4vw, 0.90vw) !important"}} value={item}>{item}</MenuItem>;
            })}
          </Select>
        </div>
      </div>
    </div>
  );
}

export default Pagination;
