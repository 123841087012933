import './SettingsLeftBar.css';
import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { useLocation, Link } from 'react-router-dom';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';

const SettingsLeftBar = ({ setSettingsAnchor }) => {
  const { pathname } = useLocation();

  const listItems = [
    {
      text: 'Profile',
      icon: <ManageAccountsOutlinedIcon />,
      path: '/setting/user',
    },
    {
      text: 'Organization',
      icon: <SettingsOutlinedIcon />,
      path: '/setting/org',
    },
    {
      text: 'Credit Management',
      icon: <CreditCardOutlinedIcon />,
      path: '/setting/credit',
    },
    {
      text: 'Feature Management',
      icon: <CreditCardOutlinedIcon />,
      path: `/setting/feature`,
    },
  ];

  return (
    <div className="settings-left-bar">
      <List>
        {listItems.map((item) => {
          const { text, icon, path, selected } = item;
          return (
            <Link onClick={() => setSettingsAnchor(null)} to={path} key={text} className="setting-list-item">
              <ListItem
                disablePadding
                selected={selected}
                className={pathname === path ? 'active' : null}
              >
                <ListItemButton>
                  <ListItemText
                    primary={text}
                    className="setting-list-item-text"
                  />
                </ListItemButton>
              </ListItem>
            </Link>
          );
        })}
      </List>
    </div>
  );
};

export default SettingsLeftBar;
