import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, Box, Typography, IconButton } from '@mui/material';
import './ExploreFeaturesModal.css';
import { FormControlLabel, FormGroup } from '@material-ui/core';
import useMediaQuery from "@mui/material/useMediaQuery";
import Checkbox from '@mui/material/Checkbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import { v4 as uuidv4 } from 'uuid';
import { TOAST_TYPE } from "../../utils/constants";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

const UserFormModal = ({  handleSuccess, setFeatureSelected, featureSelected, setStep, step, snowFeatureSelected, snowFeaturesList, landscapeFeatureSelected, landscapeFeaturesList, setSnowFeatureSelected, setLandscapeFeatureSelected}) => {

  const isLargerThan820 = useMediaQuery('(min-width:820px)');
  const isLargerThan1100 = useMediaQuery('(min-width:1100px)');


  useEffect(() => {

    console.log("helloFeatureSeelcted")
    const newFeatureSelected = [
      ...(snowFeatureSelected ? snowFeaturesList : []),
      ...(landscapeFeatureSelected ? landscapeFeaturesList : []),
    ];
  
    // Only update if there's a change
    if (JSON.stringify(featureSelected) !== JSON.stringify(newFeatureSelected)) {
      setFeatureSelected(newFeatureSelected);
    }
  }, [snowFeatureSelected, landscapeFeatureSelected])

  const _handleConfirmFeature = () => {

    if(featureSelected?.length === 0) {
      handleSuccess(TOAST_TYPE.WARNING, "Please select at least one feature.");

    }else if (featureSelected?.length !== 0) {
      setStep(4)
      handleSuccess(TOAST_TYPE.SUCCESS, "Features added successfully.");
    }
  }

  return (
 
<Box sx={{ width:  isLargerThan820 ? "22%" : isLargerThan1100 ? "30%" : "100%", position: isLargerThan820 ? "absolute" : "relative", left: isLargerThan820 ? "7%" : "0%", top: isLargerThan820 ? "3%" : "0%", zIndex: "780", borderRadius: "8px", marginBottom: !isLargerThan820 && "15%", background: isLargerThan820 ? "white" : "transparent", padding: isLargerThan820 ? "0.8%" : "0%"}}>
<Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between", background: "#e9f6e0", borderRadius: "5px 5px 5px 5px"}}>
<IconButton sx={{margin: "10px", marginBottom: "0px", width: "fit-content"}} onClick={() => setStep(2)} className='ottermap-iconButton' aria-label="delete" size="medium">
  <KeyboardBackspaceIcon fontSize="inherit" />
</IconButton>
<Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "10px 20px"}} >
  <Typography sx={{fontSize: "clamp(20px, 1.10vw, 1.10vw) !important", fontWeight: "700"}}>Select Features</Typography>
</Box>
</Box>

<Box sx={{padding: "10px", overflow: "scroll !important", background: "white",  borderRadius: "0px 0px 5px 5px"}}>
          <Box sx={{ height: "100%" }}>
          
              <FormGroup>
              <FormControlLabel control={<Checkbox checked={snowFeatureSelected} onChange={() => setSnowFeatureSelected(!snowFeatureSelected)} color="success" />} label="Snow Features" />
              <FormControlLabel control={<Checkbox checked={landscapeFeatureSelected} onChange={() => setLandscapeFeatureSelected(!landscapeFeatureSelected)} color="success" />} label="Landscape Features" />
              <Divider />

              <List sx={{ padding: "0px", maxHeight: "50vh !important", overflow: "scroll"}}>
                  {snowFeatureSelected && snowFeaturesList?.map(({ id, name }) => (
                    <React.Fragment key={uuidv4()}>
                    <ListItem key={id} sx={{marginTop: "2px" , color: snowFeatureSelected ? "#519259" : "#bdbdbd", background: snowFeatureSelected ? "#E8F6E0" : "white", borderRadius: "5px"}}>
                    {name}
                      {/* <FormControlLabel sx={{
                        '.MuiFormControlLabel-label': {
                          fontSize: 'clamp(13px,  0.80vw, 1vw) !important',
                        },
                      }}  label={name} /> */}
                    </ListItem>
                    </React.Fragment>
                  ))}
                   {landscapeFeatureSelected && landscapeFeaturesList?.map(({ id, name }) => (
                    <React.Fragment key={uuidv4()}>
                    <ListItem key={id} sx={{marginTop: "2px" , color: landscapeFeatureSelected ? "#519259" : "#bdbdbd", background: landscapeFeatureSelected ? "#E8F6E0" : "white", borderRadius: "5px"}}>
                    {name}
                      {/* <FormControlLabel sx={{
                        '.MuiFormControlLabel-label': {
                          fontSize: 'clamp(13px, 0.80vw, 1vw) !important',
                        },
                      }} control={<Checkbox color="default" disabled checked={landscapeFeatureSelected}  />} label={name} /> */}
                    </ListItem>
                    </React.Fragment>
                  ))}
                </List>
                {/* <List sx={{ padding: "0px"}}>
                  {landscapeFeaturesList?.map(({ id, name }) => (
                    <React.Fragment key={uuidv4()}>
                    <ListItem key={id} sx={{color: landscapeFeatureSelected ? "#519259" : "#bdbdbd"}}>
                    {name}
                      <FormControlLabel sx={{
                        '.MuiFormControlLabel-label': {
                          fontSize: 'clamp(13px, 0.80vw, 1vw) !important',
                        },
                      }} control={<Checkbox color="default" disabled checked={landscapeFeatureSelected}  />} label={name} />
                    </ListItem>
                    </React.Fragment>
                  ))}
                </List> */}
              {/* <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <FormControlLabel control={<Checkbox checked={snowFeatureSelected} onChange={() => setSnowFeatureSelected(!snowFeatureSelected)} color="success" />} label="Snow Features" />
                </AccordionSummary>
                <AccordionDetails sx={{padding: "0px"}}>
                <List sx={{ padding: "0px"}}>
                  {snowFeaturesList?.map(({ id, name }) => (
                    <React.Fragment key={uuidv4()}>
                    <Divider />
                    <ListItem key={id} sx={{padding: "0px 16px"}}>
                      <FormControlLabel sx={{
                        '.MuiFormControlLabel-label': {
                          fontSize: 'clamp(13px,  0.80vw, 1vw) !important',
                        },
                      }} control={<Checkbox  color="default" disabled checked={snowFeatureSelected}  />} label={name} />
                    </ListItem>
                    </React.Fragment>
                  ))}
                </List>
                </AccordionDetails>
              </Accordion> */}

              {/* <Accordion>
                <AccordionSummary
                  sx={{margin: "0px !important"}}
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                >
                  <FormControlLabel control={<Checkbox checked={landscapeFeatureSelected} onChange={() => setLandscapeFeatureSelected(!landscapeFeatureSelected)} color="success" />} label="Landscape Features" />
                </AccordionSummary>
                <AccordionDetails sx={{padding: "0px"}}>
                <List sx={{ padding: "0px"}}>
                  {landscapeFeaturesList?.map(({ id, name }) => (
                    <React.Fragment key={uuidv4()}>
                    <Divider />
                    <ListItem key={id} sx={{padding: "0px 16px"}}>
                      <FormControlLabel sx={{
                        '.MuiFormControlLabel-label': {
                          fontSize: 'clamp(13px, 0.80vw, 1vw) !important',
                        },
                      }} control={<Checkbox color="default" disabled checked={landscapeFeatureSelected}  />} label={name} />
                    </ListItem>
                    </React.Fragment>
                  ))}
                </List>

                </AccordionDetails>
              </Accordion> */}
              
              </FormGroup>

          </Box>
</Box >

<Button onClick={() =>_handleConfirmFeature()} variant="contained" className='ottermap-button' type="submit" fullWidth sx={{ fontSize: "clamp(14px, 0.80vw, 1vw)", height: "100%", minHeight: "35px", maxHeight: "6vh",padding: "2% 10%", marginTop: "3%" }}> Confirm Selected Feature </Button>
</Box>
  );
};

export default UserFormModal;
