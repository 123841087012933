import Feature from 'ol/Feature';
import { Polygon } from 'ol/geom';
import * as turf from '@turf/turf';
import { v4 as uuidv4 } from 'uuid';
import { Draw } from 'ol/interaction';
import GeoJSON from 'ol/format/GeoJSON';
import { TOAST_TYPE } from '../../utils/constants';
import cutLineString from '../DIYFeatures/CutLineString';
import calculateArea from '../DIYFeatures/CalculateArea';
import removeAllInteraction from '../OLFeatures/RemoveInteraction';
import { Fill, Stroke, Style, Circle as CircleStyle } from 'ol/style';

const cutStartStyle = new Style({
  stroke: new Stroke({
    color: "white",
    width: 3,
    lineDash: [10],
  }),
  fill: new Fill({
    color: "rgba(0, 0, 255, 0.1)",
  }),
  image: new CircleStyle({
    radius: 7,
    fill: new Fill({
      color: 'rgba(173, 216, 230, 0.7)',
    }),
    stroke: new Stroke({
      color: 'rgba(255, 255, 255, 0.7)',
      width: 2,
    }),
  }),
});

const draw = new Draw({
  type: 'Polygon',
  style: cutStartStyle,
});

const cutLayer = (
  map,
  setUndoStack,
  setRedoStack,
  setZoomLayer,
  handleSuccess,
  uploadedLayer,
  cutLayersName,
  setUploadedLayer,
  setCutLayersName,
  setCurrentPointerIdx,
) => {

  setZoomLayer(false);
  removeAllInteraction(map);
  map.addInteraction(draw);
  // document.body.classList.remove('edit-cursor');
  // document.body.classList.remove('split-cursor');
  // document.body.classList.remove('crosshair-cursor');
  // document.body.classList.add('cut-cursor');


  // const mapContainer = document.getElementById('map-cont');
  const mapContainer = document.querySelector('.ol-viewport');
  mapContainer.classList.remove('edit-cursor');
  mapContainer.classList.remove('split-cursor');
  mapContainer.classList.remove('crosshair-cursor');
  mapContainer.classList.add('cut-cursor');

  // let mapContainer = document.getElementById('map-cont');
  // mapContainer.classList.add('cut-cursor');

  var drawend = draw.on('drawend', function (e) {
    let olParser = new GeoJSON();
    let drawnFeaturesArray = olParser.writeFeaturesObject([e.feature]);
    var feature = e.feature;
    var poly = feature.getGeometry().getCoordinates();
    if (feature.getGeometry().getType() === 'Polygon') {
      var kinkedPoly = turf.polygon(poly);
      var unkinkedPoly = turf.unkinkPolygon(kinkedPoly);
      if (unkinkedPoly.features.length > 1) {
        handleSuccess(
          TOAST_TYPE.WARNING,
          'Self intersecting polygon is not supported'
        );
        return false;
      }
    }
    let drawnFeatureCoordinates =
      drawnFeaturesArray.features[0].geometry.coordinates[0];
    const drawnFeature = new Feature({
      geometry: new Polygon([drawnFeatureCoordinates]),
    });
    const drawnPolygon = drawnFeature.getGeometry();

    let stateUpdate = JSON.parse(JSON.stringify(uploadedLayer));
    setUndoStack((prevUndoStack) => [...prevUndoStack, stateUpdate]);
    setRedoStack([]);

    map.getLayers().forEach((layer) => {
      if (layer.get('title') && layer.get('title') === 'Order') {
        layer.getLayers().forEach((item) => {
          if (item.get('visible')) {
            const featuresArray = item.getSource().getFeatures();
            const featuresInsideDrawnPolygon = featuresArray.filter(
              (feature) => {
                const featureGeometry = feature.getGeometry();
                return drawnPolygon.intersectsExtent(
                  featureGeometry.getExtent()
                );
              }
            );
            featuresInsideDrawnPolygon.map((feature) => {
              cutLayersName.push(`${item.get('title')}`);
              setCutLayersName(cutLayersName);
              const featureId = feature.values_.id;
              if (feature.getGeometry().getType() === 'Polygon') {
                const selectedPolygon = feature.getGeometry();
                const firstPolygon = turf.polygon(
                  selectedPolygon.getCoordinates()
                );
                const secondPolygon = turf.polygon(
                  drawnPolygon.getCoordinates()
                );
                const intersection = turf.intersect(
                  firstPolygon,
                  secondPolygon
                );
                if (intersection) {
                  const finalPolygon = turf.difference(
                    firstPolygon,
                    intersection
                  );

                  if (!finalPolygon) {
                    uploadedLayer.map((layer) => {
                      if (layer.name === feature.get('name')) {
                        layer.data = layer.data.filter(
                          (feature) => feature.id != `${featureId}`
                        );
                      }
                    });
                  }

                  var maxNewId;
                  uploadedLayer.forEach((layer) => {
                    if (layer.name === feature.get('name')) {
                      maxNewId = layer.data.reduce((maxId, obj) => {
                        return Math.max(maxId, obj.newId)
                      }, 0);
                    }
                  });

                  if (
                    finalPolygon &&
                    finalPolygon.geometry.type === 'MultiPolygon'
                  ) {
                    uploadedLayer.map((layer) => {
                      if (layer.name === feature.get('name')) {
                        layer.data = layer.data.filter(
                          (feature) => feature.id != `${featureId}`
                        );
                      }
                    });
                    setUploadedLayer(uploadedLayer);
                    finalPolygon.geometry.coordinates.forEach((dataItem, index) => {
                      const frontEndId = uuidv4();
                      uploadedLayer.map((layer) => {
                        if (layer.name === feature.get('name')) {
                          const cutData = {
                            id: frontEndId,
                            newId: maxNewId + index + 1,
                            data: dataItem,
                            visible: true,
                            measurement: calculateArea(dataItem, true),
                          };
                          layer.data.push(cutData);
                        }
                      });
                      setUploadedLayer(uploadedLayer);
                    });
                  } else if (
                    finalPolygon &&
                    finalPolygon.geometry.type === 'Polygon'
                  ) {
                    uploadedLayer.map((layer) => {
                      if (layer.name === feature.get('name')) {
                        layer.data = layer.data.filter(
                          (feature) => feature.id != `${featureId}`
                        );
                      }
                    });
                    setUploadedLayer(uploadedLayer);
                    const olCoord = finalPolygon.geometry.coordinates;
                    const length = finalPolygon.geometry.coordinates[0].length;
                    if (length > 0) {
                      uploadedLayer.map((layer) => {
                        if (layer.name === feature.get('name')) {
                          const cutData = {
                            id: featureId,
                            newId: maxNewId + 1,
                            data: olCoord,
                            visible: true,
                            measurement: calculateArea(olCoord, true),
                          };
                          layer.data.push(cutData);
                        }
                      });

                      setUploadedLayer(uploadedLayer);
                    }
                  }
                }
              } else if (feature.getGeometry().getType() === 'MultiPoint') {
                uploadedLayer.map((layer) => {
                  if (layer.name === feature.get('name')) {
                    layer.data = layer.data.filter(
                      (feature) => feature.id != `${featureId}`
                    );
                  }
                });
                setUploadedLayer(uploadedLayer);
              } else if (feature.getGeometry().getType() === 'LineString') {
                const featureId = feature.values_.id;
                const selectedLine = feature.getGeometry();
                const line = turf.lineString(selectedLine.getCoordinates());
                const polygon = turf.polygon(drawnPolygon.getCoordinates());
                const backendData = {
                  line_geojson: { type: 'FeatureCollection', features: [line] },
                  poly_geojson: polygon,
                };
                cutLineString(
                  featureId,
                  item.get('title'),
                  backendData,
                  uploadedLayer,
                  setUploadedLayer
                );
              }
            });
          }
        });
      }
    });
    setUploadedLayer([...uploadedLayer]);
  });
  setCurrentPointerIdx(drawend)
};

export default cutLayer;
