import "./ExploreLeftBar.css";
import Tooltip from "@mui/material/Tooltip";
import { Fill, Stroke, Style } from "ol/style";
import useMediaQuery from "@mui/material/useMediaQuery";
import total_area_icon from "../../assets/total-area.svg";
import ContentCutIcon from "@mui/icons-material/ContentCut";
import edit_parcel_icon from "../../assets/edit-parcel.svg";
import draw_parcel_icon from "../../assets/draw-parcel.svg";
import { usePublicAddressContext } from "../../context/PublicAddressContext";
import React, { useCallback, useEffect, useRef, useState } from "react";
import cutPolygon from "../../OpenLayers/OLInteractions/CutInteraction";
import drawPolygon from "../../OpenLayers/OLInteractions/DrawInteraction";
import Button from "@mui/material/Button";
import removeAllInteraction from "../../OpenLayers/OLFeatures/RemoveInteraction";
import { TOAST_TYPE } from "../../utils/constants";
import CottageIcon from '@mui/icons-material/Cottage';
import ExplorePolygonCheckBox from './../ExplorePolygonCheckBox/ExplorePolygonCheckbox.jsx';
const ExploreLeftBar = ({
  map,
  markerRef,
  parcelRef,
  oldAddress,
  editPolygon,
  polygonList,
  setZoomLayer,
  searchBarRef,
  handleSuccess,
  setParcelEdit,
  setOldAddress,
  saveDiyParcel,
  setParcelStyle,
  setPolygonList,
  draftDiyParcel,
  featureSelected,
  setHoverParcelId,
  setNearmapcoords,
  nearmapImageDate,
  setFeatureSelected,
  setHoverParcelElement,
  removeEventKey,
  userData,
  setUserData,
  setStep,
  step,
}) => {
  let path = polygonList;
  const diySelectRef = useRef();
  const diySelectInnerRef = useRef();
  const { address, latLng, setLatLng, setAddress } = usePublicAddressContext();
  const [isDrawActive, setIsDrawActive] = useState(false);
  const [isEditActive, setIsEditActive] = useState(false);
  const [isCutActive, setIsCutActive] = useState(false);
  const [open, setOpen] = useState(true);
  const isLargerThan820 = useMediaQuery("(min-width:820px)");


  const confirmParcel = () => {

    setParcelEdit(false)
    setParcelStyle([
      new Style({
      stroke: new Stroke({
        color: "red",
        width: 2,
      }),
      fill: new Fill({
        color: "rgb(255, 255, 255,0.2)",
      }),
    }),
    ])
    
    setIsEditActive(false)
    setIsDrawActive(false)
    setIsCutActive(false)
    document.body.classList.remove("cut-cursor");
    document.body.classList.remove("edit-cursor");
    document.body.classList.remove("split-cursor");
    document.body.classList.remove("crosshair-cursor");

    if (!path.length)
      handleSuccess(TOAST_TYPE.WARNING, "Draw one or more parcel");
    else {
      handleSuccess(TOAST_TYPE.SUCCESS, "Property boundary confirmed successfully");
      removeAllInteraction(map);
      setStep(3)
      setIsEditActive(false)
      setIsDrawActive(false)
      setIsCutActive(false)
    }
  };


  const result = path.reduce(
    (total, currentValue) =>
      (total = parseFloat(total) + parseFloat(currentValue.measurement)),
    0
  );

  const escFunction = useCallback((event) => {
    if (event.key === "Escape") {
      removeAllInteraction(map);
      setIsDrawActive(false);
      setIsEditActive(false);
      setIsCutActive(false);
      document.body.classList.remove("cut-cursor");
      document.body.classList.remove("edit-cursor");
      document.body.classList.remove("split-cursor");
      document.body.classList.remove("crosshair-cursor");
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);

  const handleClickOutSide = (e) => {
    if (diySelectInnerRef.current) {
      if (!diySelectInnerRef.current.contains(e.target)) {
        diySelectRef.current.style.opacity = 0;
        diySelectRef.current.style.pointerEvents = "none";
      }
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutSide, true);
  }, []);

  useEffect(() => {
    if (address !== oldAddress) {
      setPolygonList([]);
      setFeatureSelected([]);
      setOldAddress(address);
    }
  }, [address]);

  useEffect(() => {
    if (!oldAddress) {
      setOldAddress(address);
    }
  }, []);

  useEffect(() => {
    setIsDrawActive(false);
    setIsEditActive(false);
    setIsCutActive(false);
  }, []);


  return (
    <div className="explore-left-bar-container">
      <div className="explore-left-bar-one">
        <div
          style={{
            display: "flex",
            gap: "5px",
            width: "100%",
            alignItems: "center",
          }}
        >
          <CottageIcon className="property-icon" sx={{ color: "#054635", marginRight: "5px"}} />
          <p style={{ width: "98%", marginTop: "1px", fontSize: "clamp(13px, 1vw, 1vw)" }}>{address}</p>
        </div>
        {/* <CreateIcon
          onClick={() => {
            setAddress(null);
            setLatLng([0, 0]);
          }}
          className="create-icon"
        /> */}
      </div>
      <div className="explore-left-bar-two">
        Modify Parcel
        <div
          style={{ marginTop: "20px" }}
          className="draw-edit-button-cont explore-left-bar-parcel-btn"
        >
          <Tooltip title="Draw Polygon" placement="top">
            <button
              onClick={() => {
                removeAllInteraction(map);
                drawPolygon(
                  map,
                  parcelRef,
                  polygonList,
                  setZoomLayer,
                  setParcelEdit,
                  setPolygonList,
                  setParcelStyle,
                  handleSuccess,
                  removeEventKey
                );
                setIsDrawActive(true);
                setIsEditActive(false);
                setIsCutActive(false);
              }}
              className={
                isDrawActive
                  ? "draw-parcel-button-active"
                  : "draw-parcel-button"
              }
            >
              <img src={draw_parcel_icon} alt="draw_parcel_icon" />
              Draw
            </button>
          </Tooltip>
          <Tooltip title="Modify Polygon" placement="top">
            <button
              onClick={() => {
                removeAllInteraction(map);
                editPolygon();
                setIsEditActive(true);
                setIsDrawActive(false);
                setIsCutActive(false);
              }}
              className={
                isEditActive
                  ? "edit-parcel-button-active"
                  : "edit-parcel-button"
              }
            >
              <img src={edit_parcel_icon} alt="edit_parcel_icon" />
              Edit
            </button>
          </Tooltip>
          <Tooltip title="Cut Polygon" placement="top">
            <button
              onClick={() => {
                removeAllInteraction(map);
                cutPolygon(
                  map,
                  polygonList,
                  setZoomLayer,
                  setParcelEdit,
                  setPolygonList,
                  setParcelStyle,
                  handleSuccess
                );
                setIsDrawActive(false);
                setIsCutActive(true);
                setIsEditActive(false);
              }}
              className={
                isCutActive ? "cut-parcel-button-active" : "cut-parcel-button"
              }
            >
              <ContentCutIcon className="cut-parcel-icon" /> Cut
            </button>
          </Tooltip>
        </div>
        <div
          style={{
            padding: "0px",
            overflow: "hidden",
            marginBottom: "0px",
            borderRadius: "5px",
            height: "100%",
          }}
          className="parcel-id-container diy-parcel-id-container"
        >
          <div
            style={{
              padding: path?.length > 0 ? "0px 10px 0px 0px" : "0px",
              maxHeight: "20vh",
              height: "100%",
            }}
            className="parcel-ids diy-parcel-ids"
          >
            {path?.map((poly, index) => (
              <ExplorePolygonCheckBox
                map={map}
                data={poly}
                path={path}
                id={poly.id}
                index={index}
                key={poly.id}
                setPath={setPolygonList}
                setNearmapcoords={setNearmapcoords}
                setHoverParcelId={setHoverParcelId}
                setHoverParcelElement={setHoverParcelElement}
                polygonList={polygonList}
                setPolygonList={setPolygonList}
                // reActivateFunctionality={reActivateFunctionality}
                isDrawActive={isDrawActive}
                isEditActive={isEditActive}
                isCutActive={isCutActive}
                setIsDrawActive={setIsDrawActive}
                setIsEditActive={setIsEditActive}
                setIsCutActive={setIsCutActive}
              />
            ))}
          </div>
          <div
            style={{
              background: "#eceff1",
              borderTop: path?.length > 0 && "1px solid ",
              borderColor: "#b0bec5",
              borderRadius: "0px",
              margin: "0px",
              height: "18px",
              fontSize: "clamp(13px, 1vw, 1vw)"
            }}
            className="total-area-container"
          >
            <img src={total_area_icon} alt="total_area_icon" />
            <p sx={{margin: "0px", fontSize: "clamp(13px, 1vw, 1vw)"}}>Total area {result.toFixed(2)} acre</p>
          </div>
        </div>
      </div>

      <Button
        sx={{ marginBottom: !isLargerThan820 && "15%" }}
        className="ottermap-button"
        onClick={() => {
          // setStep(3);
          confirmParcel()
        }}
      >
        Confirm Boundary
      </Button>
    </div>
  );
};

export default ExploreLeftBar;
