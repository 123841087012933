import { Draw, Snap, Modify, Select } from 'ol/interaction';

const removeAllInteraction = (map) => {
  var drawInteraction = false;
  var snapInteraction = false;
  var selectInteraction = false;
  var modifyInteraction = false;
  map.getInteractions().forEach(function (interaction) {
    if (interaction instanceof Select) {
      selectInteraction = interaction;
    } else if (interaction instanceof Draw) {
      drawInteraction = interaction;
    } else if (interaction instanceof Snap) {
      snapInteraction = interaction;
    } else if (interaction instanceof Modify) {
      modifyInteraction = interaction;
    }
  });

  document.body.classList.remove('cut-cursor');
  document.body.classList.remove('edit-cursor');
  document.body.classList.remove('split-cursor');
  document.body.classList.remove('crosshair-cursor');

  const mapContainer = document.querySelector('.ol-viewport');
  mapContainer.classList.remove('edit-cursor');
  mapContainer.classList.remove('split-cursor');
  mapContainer.classList.remove('crosshair-cursor');
  mapContainer.classList.remove('cut-cursor');


  if (drawInteraction) map.removeInteraction(drawInteraction);
  if (snapInteraction) map.removeInteraction(snapInteraction);
  if (selectInteraction) map.removeInteraction(selectInteraction);
  if (modifyInteraction) map.removeInteraction(modifyInteraction);
};

export default removeAllInteraction;
