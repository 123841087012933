import { transform } from "ol/proj";

const latLongToCoordinates = (coordinates) => {
  const polygonWithHoles = [];
  for (let j = 0; j < coordinates.length; j++) {
    const latLong = coordinates[j];
    var coords = [];
    for (let k = 0; k < latLong.length; k++) {
      var singlePolygon = latLong[k];
      if (singlePolygon)
        coords.push(
          transform(
            [parseFloat(singlePolygon[0]), parseFloat(singlePolygon[1])],
            "EPSG:4326",
            "EPSG:3857"
          )
        );
    }
    polygonWithHoles.push(coords);
  }
  return polygonWithHoles;
};

export default latLongToCoordinates;
