import React from "react";
import "./TermAndConditions.css";
import { ReactComponent as SiteLogo } from "../../assets/logo.svg";

const TermAndConditions = () => {
  return (
    <div className="terms-cond-cont">
      <SiteLogo className="terms-comp-logo" />
      <div style={{ marginBottom: '0.5em' }} className="terms-head">Onboarding Agreement</div>
      <div style={{ fontSize: "1.2em", fontWeight: '500', marginBottom: "0.5em" }} className="terms-head">Terms and Conditions</div>
      <div className="terms-cont-1">
        <div><span className="terms-cont-1-head"><strong>Recipient</strong></span><span>Signee</span></div>
        <div><span className="terms-cont-1-head"><strong>Provider</strong></span><span>Moonsate Private Limited (owns the brand Ottermap)<br />
          RZF-1/173C, Sector 1, Dwarka, New Delhi - 110045<br />
          Recipient agrees to purchase from Provider, and Provider agrees to deliver the services described below (the “Services”) and associated work product (“Deliverables”) to the Recipient on the following terms and conditions (collectively, the “Agreement”) effective as of the last date of signature below (“Effective Date”).</span>
        </div>
        <div><span className="terms-cont-1-head"><strong>Services Description</strong></span><span><strong>Services - </strong>Provider shall provide access to it’s property measurement platform for the Recipient to order, visualize, store and share property data as per the terms and conditions of this agreement.<br /><br />
          <strong>Deliverables - </strong>Provider shall deliver property data (maps, measurements, imagery collectively referred to as the “Deliverables”) to the Recipient within the delivery time promised through the platform. </span></div>
        <div><span className="terms-cont-1-head"><strong>Date of Validity</strong></span><span>Twelve months from the Effective Date of last subscription recharge.</span></div>
      </div>
      <div className="terms-cont-2">
        <div><span><strong>1.&emsp;</strong></span><span><strong>PERSONNEL: </strong>Provider will supply qualified personnel to provide the Services. Provider is solely responsible for the supervision and control of its personnel and fulfilment of all obligations as the employer in performance of Services. Each Party will appoint an authorized representative to be the other Party's principal contact for all matters related to the agreement. Each Party’s representative may be changed only by written intimation to the other Party.</span></div>

        <div><span><strong>2.&emsp;</strong></span><span><strong>PERMITTED USES: </strong>Recipient has the limited rights to use the Deliverables only for internal use which includes -<br /></span></div>
        <div style={{ gridTemplateColumns: '2.5% 97.5%', marginBottom: '0', marginLeft: '1.5em' }}>
          <span>
            1.&emsp;</span><span>to install the Deliverables on as many individual computers as needed in its premises, including internal computer network<br /></span>
        </div>
        <div style={{ gridTemplateColumns: '2.5% 97.5%', marginBottom: '0', marginLeft: '1.5em' }}><span>
          2.&emsp;</span><span>to use the Deliverables for internal needs such as creation of job maps, service reports for customers (defined as the individual or organization operating out of the address linked to areas of interest in Service Description), quotes and internal service records.<br /></span>
        </div>
        <div style={{ gridTemplateColumns: '2.5% 97.5%', marginBottom: '0', marginLeft: '1.5em' }}><span>
          3.&emsp;</span><span>to alter or modify the Deliverables to produce value added products (VAP) and/or derivatives;<br /></span></div>
        <div style={{ gridTemplateColumns: '2.5% 97.5%', marginBottom: '0', marginLeft: '1.5em' }}><span>
          4.&emsp;</span><span>to make the Deliverables and/or any VAP available to contractors and consultants, only for use on behalf of the customer,<br /></span>
        </div>
        <div style={{ gridTemplateColumns: '2.5% 97.5%', marginBottom: '0', marginLeft: '1.5em' }}><span>
          5.&emsp;</span><span>The Recipient acknowledges that -<br /></span>
        </div>
        <div style={{ display: 'flex', margin: '0 0 0 4em' }}><span>
          a.&emsp;</span><span>The Provider owns the property outlines, imagery and the measurements contained in the deliverables;<br /></span></div>
        <div style={{ display: 'flex', margin: '0 0 0 4em' }}><span>
          a.&emsp;</span><span>All rights not granted by the Provider rest with the Provider;<br /></span></div>
        <div style={{ display: 'flex', margin: '0 0 2em 4em' }}><span>
          a.&emsp;</span><span>Contractors, consultants and customer will not sell, rent or lease the Deliverables</span></div>

        <div><span><strong>3.&emsp;</strong></span><span><strong>LIMITED LICENSE: </strong>To the extent that the Recipient provides tools, software, data, or other materials to Provider beyond the scope of the Deliverables (collectively, the “Recipient Materials”), Recipient grants Provider a limited, personal, non-transferable, non-exclusive license to use the Recipient Materials solely in Provider’s performance of the Services. Provider acknowledges and agrees that Recipient owns all Intellectual Property Rights in and to the Recipient Materials. Nothing in this Agreement will be deemed to grant, transfer or assign any right, title, interest or ownership of the Recipient Materials, all of which is hereby expressly reserved by Recipient. On completion of the Services, Provider will return all Recipient Materials to Recipient.</span></div>

        <div><span><strong>4.&emsp;</strong></span><span><strong>PAYMENT TERMS/EXPENSES: </strong>Recipient will pay undisputed invoices for fees that have been properly submitted within ten (10) days of receipt. It is clarified that the Recipient shall be deemed to have waived the right to dispute the delivery of any Service, after the expiry of ten (10) days from the delivery of the Service by Recipient. Each Party will be responsible for their own respective taxes as it relates to this Agreement. Additional terms -<br />
        </span></div>
        <div style={{ display: 'flex', margin: '0 0 0 4em' }}><span>
          a.&emsp;</span><span>Subscription is annual, paid upfront for platform access<br /></span></div>
        <div style={{ display: 'flex', margin: '0 0 0 4em' }} ><span>
          b.&emsp;</span><span>The subscription gives access to a quota of property data that Recipient can use over one year starting from execution date.<br /></span></div>
        <div style={{ display: 'flex', margin: '0 0 0 4em' }} ><span>
          c.&emsp;</span><span>If Recipient runs out of credits, Recipient can buy more quota at the same rate for the remainder of the year<br /></span></div>
        <div style={{ display: 'flex', margin: '0 0 0 4em' }} ><span>
          d.&emsp;</span><span>Recipient can set up as many accounts as required to access the platform.<br /></span></div>
        <div style={{ display: 'flex', margin: '0 0 2em 4em' }} ><span>
          e.&emsp;</span><span>Provider gives a money back guarantee to the Recipient in case the Recipient feels the platform is not working. In such case, the cost of the properties delivered during the contract period will be deducted from the annual subscription cost and the remaining amount will be returned to the Recipient.</span>
        </div>

        <div><span><strong>5.&emsp;</strong></span><span><strong>TERM & TERMINATION: </strong>The term of this Agreement commences as of the Effective Date and (“Term”), and continues until terminated pursuant to this Agreement, at the earlier of, (a) either party terminating the agreement upon issuing 30 days’ prior written notice, or (b) at the expiry of 12 months from the Effective Date.</span></div>

        <div><span><strong>6.&emsp;</strong></span><span><strong>CONFIDENTIALITY: </strong>The parties agree that all business, technical, and financial information the Provider obtains from the Recipient is confidential information of the Recipient, including Recipient Materials (“Confidential Information”). Except as expressly set forth in this Agreement, Provider will hold in confidence and not use or disclose any Confidential Information to any third parties and will similarly bind its employees in writing. Upon request of Recipient, Provider will return Recipient’s Confidential Information. Each Party may disclose Confidential Information to its employees, subcontractors, only to the extent necessary to fulfill each Party’s obligations under this Agreement.</span></div>

        <div><span><strong>7.&emsp;</strong></span><span><strong>LIMITATION OF LIABILITY: </strong>Under no circumstances will either party be liable for any loss of profits, cost or procurement of substitute products or services, loss of data, or incidental, indirect, special, punitive, exemplary, or consequential damages, even if the party has been advised of the possibility of these damages. The foregoing limitations will however, not be applicable for (i) breach of representations or warranties; (ii) a party’s breach of its confidentiality obligations; and (iii) willful misconduct. Otherwise and notwithstanding anything to the contrary, the Provider’s aggregate liability under this Agreement will be limited to the amount of the Fees. Each party acknowledges and agrees that the Provider nor any other person has guaranteed or warranted that any particular financial results will be achieved by the Company.</span></div>

        <div><span><strong>8.&emsp;</strong></span><span><strong>PUBLICITY: </strong>Each party agrees not to use the other’s name, trade names, trademarks, or other designation without the other party's prior written consent, including, but not limited to, press releases about this Agreement. All notices required or permitted under this Agreement will be delivered to the addresses listed above.</span></div>

        <div><span><strong>9.&emsp;</strong></span><span><strong>DISPUTE RESOLUTION: </strong>The rights and obligations of the parties to this Agreement shall be governed by and construed in accordance with the laws and in the state and federal courts of New Delhi, India (“Venue”), without regard to its conflict of law principles. Any action related to this Agreement shall be brought only in the state and federal courts of Venue and both parties waive any objection to the personal jurisdiction of and venue in such courts. Regardless of anything to the contrary in this Agreement, the Parties agree that any controversy or claim arising out of or relating to this Agreement, or the breach thereof, shall be settled exclusively by arbitration in Venue, in accordance with the rules then in force, of JAMS, and judgment upon the award rendered may be entered in any court having jurisdiction thereof. The arbitrator shall award the prevailing Party its costs and reasonable attorney fees.</span></div>

        <div><span><strong>10.&emsp;</strong></span><span><strong>SURVIVAL: </strong>Obligations by their nature that are intended to survive termination of this Agreement, including but not limited to Section 1 (Personnel), Section 6 (Confidentiality), Section 7 (Limitation of Liability), Section 9 (Dispute Resolution) and Section 10 (Survival), will survive the termination of this Agreement.</span></div>

        <div><span><strong>11.&emsp;</strong></span><span><strong>ENTIRE AGREEMENT: </strong>This Agreement constitutes the entire agreement between the parties regarding the subject matter hereof and supersedes any and all prior negotiations, promises, commitments, undertakings, and agreements of the parties relating thereto. This Agreement may be modified or amended only by a written instrument duly executed by the parties. Additional Services may be agreed by written amendment to this Agreement if duly executed by both parties.</span></div>
      </div>
    </div>
  );
};

export default TermAndConditions;
