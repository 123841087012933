import "./TeamTable.css";
import React from "react";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";

const TeamTable = ({ usersDetails }) => {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className="team-table-heading">Name</TableCell>
            <TableCell className="team-table-heading">Role</TableCell>
            <TableCell className="team-table-heading">Email</TableCell>
            <TableCell className="team-table-heading">Permission</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {usersDetails &&
            usersDetails.map((user) => {
              return (
                <TableRow>
                  <TableCell className="team-table-cell">{user.name}</TableCell>
                  <TableCell className="team-table-cell">{user.role}</TableCell>
                  <TableCell className="team-table-cell">
                    {user.email}
                  </TableCell>
                  <TableCell className="team-table-cell">
                    All Org. Properties
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TeamTable;
