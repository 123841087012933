import axios from 'axios';
import Tooltip from '@mui/material/Tooltip';
import AuthToken from '../../context/AuthToken';
import CloseIcon from '@mui/icons-material/Close';
import React, { useRef, useState, useEffect } from 'react';
import { ADD_TAG_URL, CREATE_PROPERTY_URL } from '../../helper/ApiUrl';

const BulkOrderTags = ({
  tagRef,
  activeTag,
  orderTags,
  selectedIds,
  setOrderTags,
  setActiveTag,
  setSelectedIds,
}) => {
  const token = AuthToken();
  const suggestionRef = useRef();
  const [tagName, setTagName] = useState('');
  const [allTags, setAllTags] = useState([]);

  const addTagOnClick = (tagName) => {
    setOrderTags([...orderTags, tagName]);
  };

  const handleFocus = () => {
    suggestionRef.current.style.display = 'block';
  };

  const removeTagOnClick = (tagName) => {
    const tagsLeft = orderTags.filter((tag) => tag !== tagName);
    if (tagsLeft) {
      setOrderTags(tagsLeft);
    }
  };

  const addTagOnEnter = (event) => {
    const newTag = allTags.find((tag) => tag.name === tagName);
    const tagData = tagName.toLocaleLowerCase();
    if (!newTag) {
      if (event.key === 'Enter') {
        setTagName('');
        setOrderTags([...orderTags, tagData]);
        suggestionRef.current.style.display = 'none';
      }
    }
  };

  const handleSearch = (data) => {
    const array = data.filter((item) =>
      item.name.toLowerCase().includes(tagName)
    );
    return array;
  };

  const finalTagArray = allTags.filter((item) => {
    if (orderTags) {
      return !orderTags.find((tag) => item.name === tag);
    } else {
      return allTags;
    }
  });

  const getAllTags = () => {
    axios
      .get(`${ADD_TAG_URL}/tags`, {
        headers: {
          Authorization: `Token ${token}`,
          Accept: 'application/json',
        },
      })
      .then((response) => {
        setAllTags(response.data.data);
      })
      .catch((error) => {
        console.error(error.message);
      });
  };

  useEffect(() => {
    getAllTags();
  }, [activeTag]);

  const updateOldTags = () => {
    if (orderTags?.length > 0) {
      let idsList = '';
      selectedIds.forEach((id) => (idsList += id + '%2C'));
      idsList = idsList.slice(0, idsList.length - 3);

      let tagsList = '';
      orderTags.forEach((tag) => (tagsList += tag + '%2C'));
      tagsList = tagsList.slice(0, tagsList.length - 3);

      axios
        .put(
          `${CREATE_PROPERTY_URL}?id=${idsList}&tags=${tagsList}`,
          {},
          {
            headers: {
              Authorization: `Token ${token}`,
              Accept: 'application/json',
            },
          }
        )
        .then((response) => {
          setOrderTags([]);
          setSelectedIds([]);
          setActiveTag(!activeTag);
          tagRef.current.style.display = 'none';
        })
        .catch((error) => {
          console.error(error.message);
        });
    }
  };

  return (
    <div
      className="order-tags-div"
      style={{
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }}
      ref={tagRef}
    >
      <div className="tags-div-close-icon-container">
        <CloseIcon
          style={{ color: '#000000', cursor: 'pointer', fontSize: '0.8em' }}
          onClick={() => {
            setOrderTags([]);
            tagRef.current.style.display = 'none';
          }}
        />
      </div>
      <div className="order-tags-div-zero">
        <div className="address-div-one-bottom">
          {orderTags &&
            orderTags.map((tag) => {
              return (
                <Tooltip title={tag}>
                  <span
                    style={{
                      color: '#06372a',
                      backgroundColor: '#f9f490',
                      cursor: 'pointer',
                    }}
                    onClick={() => removeTagOnClick(tag)}
                  >
                    {tag.slice(0, 3)}..X
                  </span>
                </Tooltip>
              );
            })}
        </div>
      </div>
      <div
        className="order-tags-div-one"
        onClick={() => {
          suggestionRef.current.style.display = 'none';
        }}
      >
        <h4>Tags</h4>
      </div>
      <div className="order-tags-div-two">
        <input
          type="text"
          value={tagName}
          onFocus={handleFocus}
          onKeyPress={addTagOnEnter}
          placeholder="Search or Create New"
          onChange={(e) => setTagName(e.target.value)}
        />
        <div className="tag-suggestion-outer" ref={suggestionRef}>
          <div className="tag-suggestion">
            {finalTagArray &&
              handleSearch(finalTagArray).map((tag, index) => {
                return (
                  <div
                    key={index}
                    onClick={() => {
                      addTagOnClick(tag.name);
                    }}
                  >
                    {tag.tag_type === 'user' ? (
                      <span
                        key={index}
                        style={{
                          color: '#06372a',
                          background: '#f9f490',
                          cursor: 'pointer',
                        }}
                      >
                        {tag.name}
                      </span>
                    ) : null}
                  </div>
                );
              })}
            <div>
              <span className="create-a-new-tag-div">
                Press Enter to create a new tag
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="order-tags-div-three">
        <div className="tags-div-three-top">
          <span>Common Tags</span>
          <div className="common-tags">
            {finalTagArray &&
              finalTagArray.map((tag, index) => {
                return (
                  <div
                    onClick={() => {
                      addTagOnClick(tag.name);
                    }}
                  >
                    {tag.tag_type === 'user' ? (
                      <span
                        key={index}
                        style={{
                          color: '#06372a',
                          background: '#f9f490',
                          cursor: 'pointer',
                        }}
                      >
                        {tag.name}
                      </span>
                    ) : null}
                  </div>
                );
              })}
          </div>
        </div>
        <div className="tags-div-three-bottom">
          <span>Admin Tags</span>
          <div className="common-tags">
            {allTags &&
              allTags.map((tag, index) => {
                return (
                  <div key={index}>
                    {tag.tag_type === 'admin' ? (
                      <span
                        key={index}
                        style={{
                          color: '#06372a',
                          background: '#f9f490',
                          cursor: 'pointer',
                        }}
                      >
                        {tag.name}
                      </span>
                    ) : null}
                  </div>
                );
              })}
          </div>
        </div>
      </div>
      <div className="order-tags-div-four">
        <button onClick={updateOldTags}>✓ Submit</button>
      </div>
    </div>
  );
};

export default BulkOrderTags;
