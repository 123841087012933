import * as turf from "@turf/turf";
import { CONVERT, TOAST_TYPE } from "./constants";

export const changeToLatLng = (coordinates) => {
    return coordinates.map((val) => ({
        lng: parseFloat(val[0]), // x value
        lat: parseFloat(val[1]), // y value
    }));
};

export const calculateArea = (arr) => {
    var myarray = {
        type: "Polygon",
        coordinates: [[[]]],
    };
    arr.forEach((val) => {
        let x = [];
        x.push(val.lng());
        x.push(val.lat());
        myarray.coordinates[0].push(x);
    });
    myarray.coordinates[0].shift();
    var arr = parseFloat(turf.area(myarray) / CONVERT.sqm_acre).toFixed(2);
    return arr;
};

export const totalFeatureMeasurement = ((data) => {
    let measure = 0;
    data.map((item) => {
        if (item.visible) {
            measure += parseFloat(item.measurement);
        }

    })
    return measure.toFixed(2);
});