import React, { useContext, useState, createContext } from "react";

const AddressContext = createContext();

export function AddressContextProvider({ children }) {
  const [address, setAddress] = useState(null);
  const [latLng, setLatLng] = useState([0, 0]);


  return (
    <AddressContext.Provider
      value={{
        address,
        setAddress,
        latLng,
        setLatLng
      }}
    >
      {children}
    </AddressContext.Provider>
  );
}

export function useAddressContext() {
  const context = useContext(AddressContext);
  if (context === undefined) {
    throw new Error("Context must be used within a Provider");
  }
  return context;
}
