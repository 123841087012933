import React from 'react';
import './ProgressBar.css';

const ProgressBar = ({ draft, total, completed, inProgress }) => {
  const calculatePercentage = (value) => (value / total) * 100;

  return (
    <>
      <div className="progress-bar-container">
        <>
          {completed > 0 && (
            <div
              className="progress-bar-item bulk-completed"
              style={{ width: `${calculatePercentage(completed)}%` }}
            >
              {completed}
            </div>
          )}
        </>
        <>
          {inProgress > 0 && (
            <div
              className="progress-bar-item bulk-in-progress"
              style={{ width: `${calculatePercentage(inProgress)}%` }}
            >
              {inProgress}
            </div>
          )}
        </>
        {draft > 0 && (
          <div
            className="progress-bar-item bulk-draft"
            style={{ width: `${calculatePercentage(draft)}%` }}
          >
            {draft}
          </div>
        )}
      </div>
      {completed > 0 || inProgress > 0 || draft > 0 ? (
        <div className="progress-labels">
          <div className="progress-labels-dic" style={{ width: "37%" }}>
            <span style={{ background: '#9ADE7B' }}>{completed}</span>
            <p>Completed</p>
          </div>
          <div className="progress-labels-dic" style={{ width: "41%" }}>
            <span style={{ background: '#6DB9EF' }}>{inProgress}</span>
            <p>In Progress</p>
          </div>
          <div className="progress-labels-dic" style={{ width: "22%" }}>
            <span style={{ background: '#DF826C' }}>{draft}</span>
            <p>Draft</p>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default ProgressBar;
